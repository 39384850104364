import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ManageTeam from "./manageteam";
import * as UserServices from "../../services/user";
import Cropmodal from "./../ProfileSetup/cropmodal";
import Modal from "react-bootstrap/Modal";
import packageJson from "./../../../package.json";
import * as utils from "../../common/util";
import { ToastContainer, toast } from "react-toastify";

const Setting = (props) => {
	// console.log("settings props", props.params);
	const navigate = useNavigate();
	const [isManageTeam, setisManageTeam] = useState("none");
	const firstupdate = useRef(true);
	const [team, setTeam] = useState([]);
	const [istoastc, setIstoastc] = useState(false);
	const [docs, setDocs] = useState([]);
	const [update, setUpdate] = useState(false);
	const [showsubmittedmodal, setShowSubmittedModal] = useState(false);
	const [edit, setEdit] = useState(false);
	const [file, setFile] = useState();
	const [filebavatar, setFilebavatar] = useState();
	const [state, setState] = useState({
		personalbio: props.params.bio1,
		website: props.params.business[0].website,
		noofemployees: props.params.business[0].noofemployees,
		businessname: props.params.business[0].name,
	});
	const [answer, setAnswer] = useState("");
	const [firstAnswer, setFirstAnswer] = useState("");
	const [secondAnswer, setSecondAnswer] = useState("");
	const [thirdAnswer, setThirdAnswer] = useState("");
	const [fourthAnswer, setFourthAnswer] = useState("");
	const [fifthAnswer, setFifthAnswer] = useState("");
	const [sixthAnswer, setSixthAnswer] = useState("");
	const [seventhAnswer, setSeventhAnswer] = useState("");
	const [eightAnswer, setEightAnswer] = useState("");

	var today = new Date();
	const userName = props.params.email.replace(/[^a-zA-Z0-9]/g, "") + "/";

	const hideManageTeam = () => {
		setisManageTeam("none");
	};
	const handleShow = () => {
		setEdit(true);
		// let version = localStorage.getItem("version");
		// if (version != packageJson.version) {
		// 	if ("caches" in window) {
		// caches.keys().then((names) => {
		// 	// Delete all the cache files
		// 	names.forEach((name) => {
		// 		caches.delete(name);
		// 	});
		// });

		// Makes sure the page reloads. Changes are only visible after you refresh.
		// window.location.reload(true);
	};

	// localStorage.clear();
	// localStorage.setItem("version", packageJson.version);
	// 	}
	// };
	const handleClose = () => setEdit(false);

	useLayoutEffect(() => {
		UserServices.GetUserSettingsById(props.params.id).then((existUser) => {
			//   console.log(existUser.userSettingsByUserID.answers);
			setAnswer(existUser.userSettingsByUserID.answers);
			let tempAnswers = existUser.userSettingsByUserID.answers.split("|");
			setFirstAnswer(tempAnswers[0]);
			setSecondAnswer(tempAnswers[1]);
			setThirdAnswer(tempAnswers[2]);
			setFourthAnswer(tempAnswers[3]);
			setFifthAnswer(tempAnswers[4]);
			setSixthAnswer(tempAnswers[5]);
			setSeventhAnswer(tempAnswers[6]);
			setEightAnswer(tempAnswers[7]);
		});
	});

	useLayoutEffect(() => {
		// console.log("props.params.clinicname", props.params);

		if (firstupdate.current || update) {
			let clinic = props.params.clinicname;

			UserServices.getAllDoctors(clinic).then(function (result) {
				// console.log("doctors", result.data.usersByClinicName);
				setTeam(result.data.usersByClinicNameAll);
			});
		}
		setUpdate(false);
	}, [update]);

	useEffect(() => {
		UserServices.GetSumsubDocs(
			// "level-106e6748-fa62-4fb6-9d87-57d67d6fb480"
			// "636de11178e59270608aa6a4"
			props.params.id
			// "63d75d72536a1affe7ebe740"
		).then((result) => {
			// console.log("sumsub details", result.docSets);
			setDocs(result.docSets);
			// console.log(result);
			// navigate("../personalprofile", { state: state.params });
		});
	}, []);

	const handleChange = (event) => {
		if (event.target.name === "businessname") {
			state.businessname = event.target.value;
		}
		if (event.target.name === "personalbio") {
			state.personalbio = event.target.value.replaceAll(/\n/g, "\\n");
		}
		if (event.target.name === "businesswebsite") {
			state.website = event.target.value;
		}
		setState({ ...state, params: state.params });
		// console.log(state);
	};

	const handleSelectChange = (event) => {
		state.noofemployees = event.target.value;
		setState({ ...state, params: state.params });
		// console.log(state);
	};

	const handleCloseSubmittedModal = () => {
		setShowSubmittedModal(false);
	};

	const handleShowSubmittedModal = () => {
		setShowSubmittedModal(true);
	};

	const onImageChange = (image) => {
		image = new File([image], "Profile.png", { type: "image/png" });
		// console.log(image);
		state.avatar = image;
		setState({ ...state, params: state.params });
		setFile(image);
	};

	const onImageChangebavatar = (image) => {
		image = new File([image], "Business.png", { type: "image/png" });
		// console.log(image);
		state.bavatar = image;
		setState({ ...state, params: state.params });
		setFilebavatar(image);
	};

	const showNextStep = (doctortype) => {
		// console.log("values", state, file, filebavatar);
		setIstoastc(true);
		var updateVariables1;
		if (props.params.isadmin) {
			updateVariables1 = UserServices.returnUpdateVariables({
				id: props.params.id,
				bio1: state.personalbio,
				clinicname: state.businessname,
				business: [
					{
						website: state.website,
						name: state.businessname,
						noofemployees: state.noofemployees,
					},
				],
			});
		} else {
			updateVariables1 = UserServices.returnUpdateVariables({
				id: props.params.id,
				bio1: state.personalbio,
			});
		}
		const updateVariables = updateVariables1;
		// console.log(updateVariables);

		(async function anyNameFunction() {
			const formData = new FormData();
			formData.append("user", userName);
			if (file !== undefined) {
				formData.append("file1", file);
			}
			if (props.params.isadmin) {
				if (filebavatar !== undefined) {
					formData.append("file2", filebavatar);
				}
			}
			if (file !== undefined || filebavatar !== undefined) {
				UserServices.Imageuploader(formData).then((result) => {
					// console.log("res", result);
				});
			}

			if (props.params.isadmin) {
				UserServices.getDoctors(props.params.clinicname).then(function (
					result
				) {
					// console.log("doctors", result.data.usersByClinicName);
					var upvar = UserServices.returnUpdateVariables({
						id: props.params.id,
						bio1: state.personalbio,
						clinicname: state.businessname,
						business: [
							{
								website: state.website,
								name: state.businessname,
								noofemployees: state.noofemployees,
							},
						],
					});
					UserServices.UpdateUser(upvar).then((value) => {
						result.data.usersByClinicName.forEach((doctor) => {
							// patient_detials(doctor.id);
							// console.log("ids", doctor);
							updateVariables1 = UserServices.returnUpdateVariables({
								id: doctor.id,
								// bio1: state.personalbio,
								clinicname: state.businessname,
								business: [
									{
										website: state.website,
										name: state.businessname,
										noofemployees: state.noofemployees,
									},
								],
							});
							var updateVariables = updateVariables1;
							UserServices.UpdateUser(updateVariables).then((value) => {
								// console.log(value);
								// window.location.reload();
							});
						});

						setEdit(false);
						toast.success("Successfully record saved", {
							toastId: "calender",
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
						window.scrollTo(0, 0);
					});
				});
				setTimeout(() => {
					(async function anyNameFunction() {
						var c = await UserServices.GetUserByEmail(props.params.email);
						navigate("../dashboard", { state: c.userByEmail[0] });
					})();
					setIstoastc(false);
				}, 3000);
			} else {
				UserServices.UpdateUser(updateVariables).then((value) => {
					// console.log(value);
					// window.location.reload();
					setEdit(false);
					toast.success("Successfully record saved", {
						toastId: "calender",
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					window.scrollTo(0, 0);
					setTimeout(() => {
						(async function anyNameFunction() {
							var c = await UserServices.GetUserByEmail(props.params.email);
							navigate("../dashboard", { state: c.userByEmail[0] });
						})();
						setIstoastc(false);
					}, 3000);
				});
			}
		})();
	};

	const getButtonClass = () => {
		// console.log(state);
		let rvalue = "aebuttonblack";
		let urlregex =
			/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;
		let validbussinesswebsite = urlregex.test(state.website);
		let _blogo = props.params.business[0].bavatar;
		let svalue;
		if (props.params.isadmin) {
			svalue =
				(state.personalbio === "" ? "@#@" : state.personalbio) +
				(state.website === "" ? "@#@" : state.website) +
				(state.noofemployees === "" ? "@#@" : state.noofemployees) +
				(!validbussinesswebsite ? "@#@" : validbussinesswebsite) +
				(state.businessname === "" ? "@#@" : state.businessname);
		} else {
			svalue = state.personalbio === "" ? "@#@" : state.personalbio;
		}

		// console.log(svalue);

		if (svalue.indexOf("@#@") >= 0) {
			rvalue = "aebuttongrey";
		}
		return rvalue;
	};

	return (
		<>
			<Modal
				show={showsubmittedmodal}
				onHide={handleCloseSubmittedModal}
				aria-labelledby="contained-modal-title-vcenter"
				size="lg"
			>
				<Modal.Body>
					{/* <div
            style={{
              cursor: "pointer",
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
            onClick={() => {
              handleCloseSubmittedModal();
            }}
          >
            <img src="./images/closeone.png"></img>
          </div> */}
					<div style={{ padding: "20px 20px" }}>
						<div className="row" style={{ borderRadius: "10px 10px" }}>
							<div className="col-xl col-lg col-md col-xs col-sm">
								<img
									alt="Close"
									onClick={() => {
										handleCloseSubmittedModal();
									}}
									style={{
										cursor: "pointer",
										float: "right",
									}}
									src="../images/close.png"
								></img>
							</div>
						</div>
						<div className="row">
							<div className="col-11">
								<div className="f-fm fm-w6-s36">
									This questionnaire has been submitted.
								</div>
							</div>

							<div className="row pt-5" style={{ paddingLeft: "20px" }}>
								<div
									className="col-1"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingLeft: "0px",
										width: "40px",
									}}
								>
									<span
										style={{
											height: "28px",
											width: "28px",
											background: "#F4F4F4",
											borderRadius: "14px",
											display: "inline-block",
											textAlign: "center",
										}}
									>
										1
									</span>
								</div>
								<div
									className="col-9 f-fm fm-w6-s18"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "0px",
									}}
								>
									<span className="f-fm fm-w6-s18">
										How many days a week do you work as a doctor / nurse?
									</span>
								</div>
								<div
									className="col-2"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "30px",
									}}
								>
									<select value={firstAnswer} disabled>
										<option value=""></option>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
										<option value="6">6</option>
										<option value="7">7</option>
									</select>
									<span
										className="f-fm fm-w6-s18"
										style={{ paddingLeft: "10px" }}
									>
										days
									</span>
								</div>
								<div style={{ paddingLeft: "0px" }}>
									<hr
										style={{ paddingLeft: "20px", size: "1px", color: "grey" }}
									/>
								</div>
							</div>
							<div className="row" style={{ paddingLeft: "20px" }}>
								<div
									className="col-1"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingLeft: "0px",
										width: "40px",
									}}
								>
									<span
										style={{
											height: "28px",
											width: "28px",
											background: "#F4F4F4",
											borderRadius: "14px",
											display: "inline-block",
											textAlign: "center",
										}}
									>
										2
									</span>
								</div>
								<div
									className="col-9 f-fm fm-w6-s18"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "0px",
									}}
								>
									<span className="f-fm fm-w6-s18">
										How many hours do you work per day on average?
									</span>
								</div>
								<div
									className="col-2"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "30px",
									}}
								>
									<select value={secondAnswer} disabled>
										<option value=""></option>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
										<option value="6">6</option>
										<option value="7">7</option>
										<option value="8">8</option>
										<option value="9">9</option>
										<option value="10">10</option>
										<option value="11">11</option>
										<option value="12">12</option>
										<option value="13">13</option>
										<option value="14">14</option>
										<option value="15">15</option>
										<option value="16">16</option>
										<option value="17">17</option>
										<option value="18">18</option>
										<option value="19">19</option>
										<option value="20">20</option>
										<option value="21">21</option>
										<option value="22">22</option>
										<option value="23">23</option>
										<option value="24">24</option>
									</select>
									<span
										className="f-fm fm-w6-s18"
										style={{ paddingLeft: "10px" }}
									>
										hours
									</span>
								</div>
								<div style={{ paddingLeft: "0px" }}>
									<hr
										style={{ paddingLeft: "20px", size: "1px", color: "grey" }}
									/>
								</div>
							</div>
							<div className="row" style={{ paddingLeft: "20px" }}>
								<div
									className="col-1"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingLeft: "0px",
										width: "40px",
									}}
								>
									<span
										style={{
											height: "28px",
											width: "28px",
											background: "#F4F4F4",
											borderRadius: "14px",
											display: "inline-block",
											textAlign: "center",
										}}
									>
										3
									</span>
								</div>
								<div
									className="col-9 f-fm fm-w6-s18"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "0px",
									}}
								>
									<span className="f-fm fm-w6-s18">
										How many minutes do you spend on each consultation on
										average?
									</span>
								</div>
								<div
									className="col-2"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "30px",
									}}
								>
									<select value={thirdAnswer} disabled>
										<option value=""></option>
										<option value="5">5</option>
										<option value="10">10</option>
										<option value="15">15</option>
										<option value="20">20</option>
										<option value="25">25</option>
										<option value="30">30</option>
										<option value="35">35</option>
										<option value="40">40</option>
										<option value="45">45</option>
										<option value="50">50</option>
										<option value="55">55</option>
										<option value="60">60</option>
									</select>
									<span
										className="f-fm fm-w6-s18"
										style={{ paddingLeft: "10px" }}
									>
										minutes
									</span>
								</div>
								<div style={{ paddingLeft: "0px" }}>
									<hr
										style={{ paddingLeft: "20px", size: "1px", color: "grey" }}
									/>
								</div>
							</div>
							<div className="row" style={{ paddingLeft: "20px" }}>
								<div
									className="col-1"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingLeft: "0px",
										width: "40px",
									}}
								>
									<span
										style={{
											height: "28px",
											width: "28px",
											background: "#F4F4F4",
											borderRadius: "14px",
											display: "inline-block",
											textAlign: "center",
										}}
									>
										4
									</span>
								</div>
								<div
									className="col-9 f-fm fm-w6-s18"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "0px",
									}}
								>
									<span className="f-fm fm-w6-s18">
										On average, how many patients receiving hyaluronic acid
										injections do you see per day?
									</span>
								</div>
								<div
									className="col-2"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "30px",
									}}
								>
									<select value={fourthAnswer} disabled>
										<option value=""></option>
										<option value="0">0</option>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
										<option value="6">6</option>
										<option value="7">7</option>
										<option value="8">8</option>
										<option value="9">9</option>
										<option value="10">10</option>
									</select>
									<span
										className="f-fm fm-w6-s18"
										style={{ paddingLeft: "10px" }}
									>
										patients
									</span>
								</div>
								<div style={{ paddingLeft: "0px" }}>
									<hr
										style={{ paddingLeft: "20px", size: "1px", color: "grey" }}
									/>
								</div>
							</div>
							<div className="row" style={{ paddingLeft: "20px" }}>
								<div
									className="col-1"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingLeft: "0px",
										width: "40px",
									}}
								>
									<span
										style={{
											height: "28px",
											width: "28px",
											background: "#F4F4F4",
											borderRadius: "14px",
											display: "inline-block",
											textAlign: "center",
										}}
									>
										5
									</span>
								</div>
								<div
									className="col-9 f-fm fm-w6-s18"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "0px",
									}}
								>
									<span className="f-fm fm-w6-s18">
										How many hyaluronic acid syringes do you use on average per
										patient?
									</span>
								</div>
								<div
									className="col-2"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "30px",
									}}
								>
									<select value={fifthAnswer} disabled>
										<option value=""></option>
										<option value="0">0</option>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
										<option value="6">6</option>
										<option value="7">7</option>
										<option value="8">8</option>
										<option value="9">9</option>
										<option value="10">10</option>
										<option value="11">11</option>
										<option value="12">12</option>
										<option value="13">13</option>
										<option value="14">14</option>
										<option value="15">15</option>
										<option value="16">16</option>
										<option value="17">17</option>
										<option value="18">18</option>
										<option value="19">19</option>
										<option value="20">20</option>
									</select>
									<span
										className="f-fm fm-w6-s18"
										style={{ paddingLeft: "10px" }}
									>
										syringes
									</span>
								</div>
								<div style={{ paddingLeft: "0px" }}>
									<hr
										style={{ paddingLeft: "20px", size: "1px", color: "grey" }}
									/>
								</div>
							</div>
							<div className="row" style={{ paddingLeft: "20px" }}>
								<div
									className="col-1"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingLeft: "0px",
										width: "40px",
									}}
								>
									<span
										style={{
											height: "28px",
											width: "28px",
											background: "#F4F4F4",
											borderRadius: "14px",
											display: "inline-block",
											textAlign: "center",
										}}
									>
										6
									</span>
								</div>
								<div
									className="col-9 f-fm fm-w6-s18"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "0px",
									}}
								>
									<span className="f-fm fm-w6-s18">
										How many minutes on average do you spend preparing each
										syringe of hyaluronic acid (including syringe loading time
										and disinfection of the injection area)?
									</span>
								</div>
								<div
									className="col-2"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "30px",
									}}
								>
									<select value={sixthAnswer} disabled>
										<option value=""></option>
										<option value="5">5</option>
										<option value="10">10</option>
										<option value="15">15</option>
										<option value="20">20</option>
										<option value="25">25</option>
										<option value="30">30</option>
										<option value="35">35</option>
										<option value="40">40</option>
										<option value="45">45</option>
										<option value="50">50</option>
										<option value="55">55</option>
										<option value="60">60</option>
									</select>
									<span
										className="f-fm fm-w6-s18"
										style={{ paddingLeft: "10px" }}
									>
										minutes
									</span>
								</div>
								<div style={{ paddingLeft: "0px" }}>
									<hr
										style={{ paddingLeft: "20px", size: "1px", color: "grey" }}
									/>
								</div>
							</div>
							<div className="row" style={{ paddingLeft: "20px" }}>
								<div
									className="col-1"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingLeft: "0px",
										width: "40px",
									}}
								>
									<span
										style={{
											height: "28px",
											width: "28px",
											background: "#F4F4F4",
											borderRadius: "14px",
											display: "inline-block",
											textAlign: "center",
										}}
									>
										7
									</span>
								</div>
								<div
									className="col-9 f-fm fm-w6-s18"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "0px",
									}}
								>
									<span className="f-fm fm-w6-s18">
										How many minutes on average do you spend injecting each
										syringe of hyaluronic acid?
									</span>
								</div>
								<div
									className="col-2"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "30px",
									}}
								>
									<select value={seventhAnswer} disabled>
										<option value=""></option>
										<option value="5">5</option>
										<option value="10">10</option>
										<option value="15">15</option>
										<option value="20">20</option>
										<option value="25">25</option>
										<option value="30">30</option>
										<option value="35">35</option>
										<option value="40">40</option>
										<option value="45">45</option>
										<option value="50">50</option>
										<option value="55">55</option>
										<option value="60">60</option>
									</select>
									<span
										className="f-fm fm-w6-s18"
										style={{ paddingLeft: "10px" }}
									>
										minutes
									</span>
								</div>
								<div style={{ paddingLeft: "0px" }}>
									<hr
										style={{ paddingLeft: "20px", size: "1px", color: "grey" }}
									/>
								</div>
							</div>
							<div className="row" style={{ paddingLeft: "20px" }}>
								<div
									className="col-1"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingLeft: "0px",
										width: "40px",
									}}
								>
									<span
										style={{
											height: "28px",
											width: "28px",
											background: "#F4F4F4",
											borderRadius: "14px",
											display: "inline-block",
											textAlign: "center",
										}}
									>
										8
									</span>
								</div>
								<div
									className="col-9 f-fm fm-w6-s18"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "0px",
									}}
								>
									<span className="f-fm fm-w6-s18">
										How many minutes on average do you spend after each
										injection to observe the result and make adjustments if
										necessary?
									</span>
								</div>
								<div
									className="col-2"
									style={{
										display: "flex",
										justifyContent: "left",
										alignItems: "center",
										paddingLeft: "30px",
									}}
								>
									<select value={eightAnswer} disabled>
										<option value=""></option>
										<option value="5">5</option>
										<option value="10">10</option>
										<option value="15">15</option>
										<option value="20">20</option>
										<option value="25">25</option>
										<option value="30">30</option>
										<option value="35">35</option>
										<option value="40">40</option>
										<option value="45">45</option>
										<option value="50">50</option>
										<option value="55">55</option>
										<option value="60">60</option>
									</select>
									<span
										className="f-fm fm-w6-s18"
										style={{ paddingLeft: "10px" }}
									>
										minutes
									</span>
								</div>
								<div style={{ paddingLeft: "0px" }}>
									<hr
										style={{ paddingLeft: "20px", size: "1px", color: "grey" }}
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{isManageTeam === "none" && (
				<div className="row" style={{ width: "100%", paddingTop: "4vh" }}>
					<div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 col-sm-12">
						<div className="card scard" style={{ height: "100%" }}>
							<div className="card-body">
								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
									<div className="row">
										<div className="col-xl-1 col-lg-1 col-md-1 col-xs-1 col-sm-1"></div>
										<div className="col-xl-9 col-lg-6 col-md-6 col-xs-6 col-sm-6">
											<label
												style={{
													color: "#ACACAC",
													height: "15px",
													width: "91px",
												}}
												className="f-fm fm-w6-s12"
											>
												BUSINESS INFO
											</label>
										</div>
										<div className="col-xl-3 col-lg-3 col-md-3 col-xs-3 col-sm-3"></div>
									</div>
									<div className="row">
										<div className="col-xl-2 col-lg-2 col-md-2 col-xs-2 col-sm-2"></div>
										<div
											style={{
												display: "flex",
												textAlign: "center",
												justifyContent: "center",
												paddingTop: "10vh",
												paddingBottom: "6vh",
											}}
										>
											<img
												onError={(e) => {
													e.target.src = "../images/Healthcare-Clinic-icon.png";
												}}
												loading="lazy"
												alt="Bussiness avatar"
												style={{
													width: "100px",
													height: "100px",
													borderRadius: "50%",
												}}
												src={
													props.params.business[0].bavatar
												}
											/>
										</div>
									</div>

									<div className="row">
										<div
											className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12"
											style={{
												display: "flex",
												textAlign: "center",
												justifyContent: "center",
											}}
										>
											<label className="f-fm fm-w7-s24">
												{props.params.clinicname}
											</label>
										</div>
									</div>
									<br />
									<div className="row">
										<div className="col-xl-1 col-lg-1 col-md-1 col-xs-1 col-sm-1"></div>
										<div
											className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6 f-fm fm-w4-s16 "
											style={{ paddingTop: "2vh", paddingBottom: "2vh" }}
										>
											Type: {props.params.business[0].btype}
										</div>
									</div>
									<div className="row">
										<div className="col-xl-1 col-lg-1 col-md-1 col-xs-1 col-sm-1"></div>
										<div
											className="col-xl-11 col-lg-6 col-md-6 col-xs-6 col-sm-6 f-fm fm-w4-s16  "
											style={{ paddingTop: "2vh", paddingBottom: "2vh" }}
										>
											Website:&nbsp;
											<a
												className="f-fm fm-w4-s16"
												href={"https://" + props.params.business[0].website}
												style={{ color: "#4786FF" }}
											>
												{props.params.business[0].website}
											</a>
										</div>
									</div>
									<div className="row">
										<div className="col-xl-1 col-lg-1 col-md-1 col-xs-1 col-sm-1"></div>
										<div
											className="col-xl-11  col-lg-6 col-md-6 col-xs-6 col-sm-6 f-fm fm-w4-s16  "
											style={{ paddingTop: "2vh" }}
										>
											Size:&nbsp; {props.params.business[0].noofemployees}
											&nbsp;employees
										</div>
									</div>

									<div className="row pt-5 pb-5">
										<div className="col-xl-1 col-lg-1 col-md-1 col-xs-1 col-sm-1"></div>
										{team.slice(0, 4).map((key) => (
											<div
												key={key.avatar}
												className="col-2"
												style={{ paddingRight: "0px", paddingLeft: "20px" }}
											>
												<img
													onError={(e) => {
														e.target.src = "../images/usernew.png";
													}}
													src={
														key.avatar +
														"?" +
														today.getHours() +
														today.getMinutes() +
														today.getSeconds()
													}
													alt="avatar"
													style={{
														width: "56px",
														height: "56px",
														borderRadius: "50%",
													}}
												/>
											</div>
										))}

										{team.length > 4 && (
											<div
												className="col-auto"
												style={{ paddingRight: "0px", paddingLeft: "20px" }}
											>
												<div
													style={{
														display: "flex",
														height: "56px",
														width: "56px",
														border: "1px solid #777777",
														borderRadius: "50%",
													}}
												>
													<p
														style={{ fontFamily: "Mulish", margin: "auto" }}
														className="fm-w6-s12"
													>
														+ {team.length - 4}
													</p>
												</div>
											</div>
										)}
									</div>
									{props.params.isadmin && (
										<div className="row">
											<div className="col-xl-1 col-lg-1 col-md-1 col-xs-1 col-sm-1"></div>
											<div
												className="col-xl-6  col-lg-6 col-md-6 col-xs-6 col-sm-6 managebuttonresp"
												style={{ paddingRight: "0px", paddingLeft: "15px" }}
											>
												<button
													type="btn"
													style={{
														borderRadius: "40px",
														width: "231px",
														height: "60px",
														background: "#000000",
													}}
													onClick={() => {
														setisManageTeam("block");
													}}
												>
													<label
														style={{ color: "white", cursor: "pointer" }}
														className=" f-fm fm-w6-s20"
													>
														Manage Team
													</label>
												</button>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="col-xl-7 col-lg-7 col-md-12 col-xs-12 col-sm-12">
						<div className="card scard" style={{ height: "100%" }}>
							<div className="card-body">
								<div
									style={{ paddingLeft: "10px" }}
									className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12"
								>
									<div>
										{" "}
										<h5 style={{ color: "#ACACAC" }} className="f-fm fm-w6-s12">
											PERSONAL INFO{" "}
											<span
												className="f-fm fm-w6-s12"
												style={{ color: "#ACACAC", paddingLeft: "10px" }}
											>
												(Public)
											</span>
										</h5>
									</div>{" "}
									<hr />
									<div className="row">
										<div className="col-xl-11  col-lg-8 col-md-8 col-xs-8 col-sm-8 pb-2">
											<div className="f-fm fm-w7-s24">
												{props.params.firstName} &nbsp; {props.params.lastName}
											</div>
										</div>
										<div className="col-xl-1 col-lg-3 col-md-3 col-xs-3 col-sm-3">
											<div
												style={{
													display: "flex",
													height: "30px",
													width: "30px",
													border: "none",
													borderRadius: "50%",
													background: "#F4F4F4",
													cursor: "pointer",
												}}
												onClick={handleShow}
											>
												<img
													alt=""
													loading="lazy"
													src="./images/Group258.png"
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xl-8 col-lg-8 col-md-8 col-xs-8 col-sm-8">
											<div className="f-fm fm-w4-s14 color-7">
												{props.params.email}
											</div>
										</div>
										<div className="col-xl-3 col-lg-3 col-md-3 col-xs-3 col-sm-3"></div>
									</div>
									<div className="row">
										<div className="col-xl-8 col-lg-8 col-md-8 col-xs-8 col-sm-8">
											<div className="f-fm fm-w4-s14 color-7">
												{props.params.primaryTelephone}
											</div>
										</div>
										<div className=" col-xl-3 col-lg-3 col-md-3 col-xs-3 col-sm-3"></div>
									</div>
									<br></br>
									<div className="row">
										<div className="col-xl-12  col-lg-6 col-md-6 col-xs-6 col-sm-6 ">
											<div
												style={{ color: "#000000" }}
												className="f-fm fm-w4-s14 "
											>
												{props.params.bio1}
												<br></br> {props.params.bio2}
											</div>
										</div>
									</div>
									{props.params.isadmin === true && (
										<>
											<div className="row">
												<div className="col-xl-8 col-lg-8 col-md-8 col-xs-8 col-sm-8">
													<div
														className="f-fm fm-w6-s12 "
														style={{ paddingTop: "80px", color: "#ACACAC" }}
													>
														QUESTIONNAIRE FORM
														<span
															className="f-fm fm-w6-s12"
															style={{
																color: "#ACACAC",
																paddingLeft: "10px",
															}}
														>
															(Private)
														</span>
													</div>
												</div>
												<div className=" col-xl-3 col-lg-3 col-md-3 col-xs-3 col-sm-3"></div>
											</div>
											<hr></hr>
											{answer !== "" && (
												<div className="row">
													<div className="col-xl-5 col-lg-6 col-md-6 col-xs-6 col-sm-6">
														<div className="f-fm fm-w4-s14">
															Doctors Questionnaire
														</div>
													</div>
													<div
														className="col-xl-3 col-lg-3 col-md-3 col-xs-3 col-sm-3 f-fm fm-w6-s14"
														style={{
															color: "#00C3A0",
															textAlign: "end",
														}}
													>
														<img
															loading="lazy"
															alt=""
															src="./images/Vector 8.png"
														/>
														&nbsp;Submitted
													</div>

													<div
														className="col-xl-3 col-lg-3 col-md-3 col-xs-3 col-sm-3 profile f-fm fm-w6-s14"
														style={{
															color: "#ACACAC",
															textAlign: "end",
														}}
													>
														<label
															style={{
																color: "#ACACAC",
																lineHeight: "18px",
																textDecorationLine: "underline",
																cursor: "pointer",
															}}
															onClick={() => {
																handleShowSubmittedModal();
															}}
														>
															View Form
														</label>
													</div>
												</div>
											)}
											{answer === "" && (
												<div className="f-fm fm-w4-s16">
													Please answer questioniare form, by adding your first
													Treatment.
												</div>
											)}
										</>
									)}
									<div className="row">
										<div className="col-xl-8 col-lg-8 col-md-8 col-xs-8 col-sm-8">
											<div
												className="f-fm fm-w6-s12 "
												style={{ paddingTop: "80px", color: "#ACACAC" }}
											>
												DOCUMENT
												<span
													className="f-fm fm-w6-s12"
													style={{
														color: "#ACACAC",
														paddingLeft: "10px",
													}}
												>
													(Private)
												</span>
											</div>
										</div>
										<div className=" col-xl-3 col-lg-3 col-md-3 col-xs-3 col-sm-3"></div>
									</div>
									<hr></hr>
									{Object.values(docs).map((i, index) => {
										return (
											<>
												<div className="row" key={index}>
													<div className="col-xl-5 col-lg-6 col-md-6 col-xs-6 col-sm-6">
														<div className="f-fm fm-w4-s14">
															{i.idDocSetType}
														</div>
														{i.types.map((t) => {
															return (
																<span className="f-fm fm-w4-s14" key={t}>
																	{t}{" "}
																</span>
															);
														})}
													</div>
													<div
														className="col-xl-3 col-lg-3 col-md-3 col-xs-3 col-sm-3 f-fm fm-w6-s14"
														style={{
															color: "#00C3A0",
															textAlign: "end",
														}}
													>
														<img
															loading="lazy"
															alt=""
															src="./images/Vector 8.png"
														/>
														&nbsp;Approved
													</div>

													<div
														className="col-xl-3 col-lg-3 col-md-3 col-xs-3 col-sm-3 profile f-fm fm-w6-s14"
														style={{
															color: "#ACACAC",
															textAlign: "end",
														}}
													>
														<label
															style={{
																color: "#ACACAC",
																lineHeight: "18px",
																textDecorationLine: "underline",
															}}
														>
															File added
														</label>
													</div>
												</div>
												<div className="thin">
													<hr />
												</div>
											</>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					{istoastc && (
						<ToastContainer
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							theme="colored"
						/>
					)}
				</div>
			)}
			{isManageTeam === "block" && (
				<ManageTeam
					hideManagTeam={hideManageTeam}
					team={team}
					params={props}
					update={(e) => setUpdate(e)}
				></ManageTeam>
			)}

			<Modal
				show={edit}
				onHide={handleClose}
				dialogClassName="modal-sm-30px modal-lg modal-md"
				position="top-right"
			>
				<Modal.Body>
					<div>
						<div className="row">
							<div className="col-xl col-lg col-md col-xs col-sm">
								<img
									alt="Close"
									onClick={handleClose}
									style={{
										cursor: "pointer",
										padding: "30px",
										float: "right",
										paddingRight: "52px",
									}}
									src="../images/close.png"
								></img>
							</div>
						</div>
						<div className="row" style={{ padding: "20px" }}>
							<div className="col-xl col-lg col-md col-xs col-sm">
								<div className="row">
									<div className="col-xl col-lg col-md col-xs col-sm">
										<label className="f-fm fm-w7-s18 color-00">
											Edit Profile
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col-xl col-lg col-md col-xs col-sm">
										<hr
											style={{
												color: "rgb(149 142 142)",
												backgroundColor: "#000000",
												height: 2,
											}}
										/>
									</div>
								</div>

								<div className="row">
									<div className="form-outline pt-3">
										<label
											className="form-label form f-fm fm-w6-s12"
											htmlFor="form3Example8"
											style={{ marginLeft: "0px" }}
										>
											BIO
										</label>
										<textarea
											className="form-control"
											id="exampleFormControlTextarea1"
											rows="3"
											name="personalbio"
											defaultValue={props.params.bio1}
											onChange={handleChange}
										></textarea>
										<div className="form-notch">
											<div
												className="form-notch-leading"
												style={{ width: "9px" }}
											></div>
											<div
												className="form-notch-middle"
												style={{ width: "55.2px" }}
											></div>
											<div className="form-notch-trailing"></div>
										</div>
									</div>
								</div>
								<div class="row pt-3">
									<div className="form-outline">
										<label
											className="form-label form f-fm fm-w6-s12"
											htmlFor="form3Example8"
											style={{ marginLeft: "0px" }}
										>
											Your Avatar
										</label>
										<br></br>
										<Cropmodal
											onImageChange={onImageChange}
											ttype="edit"
											ttypetext={
												props.params.avatar +
												"?" +
												today.getHours() +
												today.getMinutes() +
												today.getSeconds()
											}
										></Cropmodal>
										<label
											className="form-label form f-fm fm-w6-s10"
											htmlFor="form3Example8"
											style={{
												marginLeft: "0px",
												fontSize: "10px",
												color: "darkgrey",
											}}
										>
											Note: Click on the image to edit.
										</label>
										<br></br>
										<div className="form-notch">
											<div
												className="form-notch-leading"
												style={{ width: "9px" }}
											></div>
											<div
												className="form-notch-middle"
												style={{ width: "55.2px" }}
											></div>
											<div className="form-notch-trailing"></div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{props.params.isadmin && (
							<div className="row" style={{ padding: "20px" }}>
								<div className="col-xl col-lg col-md col-xs col-sm">
									<div className="row">
										<div className="col-xl col-lg col-md col-xs col-sm">
											<label className="f-fm fm-w7-s18 color-00">
												Edit Business
											</label>
										</div>
									</div>

									<div className="row">
										<div className="col-xl col-lg col-md col-xs col-sm">
											<hr
												style={{
													color: "rgb(149 142 142)",
													backgroundColor: "#000000",
													height: 2,
												}}
											/>
										</div>
									</div>

									<div className="row pb-3">
										<div className="form-outline pt-3">
											<label
												className="form-label form  f-fm fm-w6-s12"
												htmlFor="form3Example8"
												style={{ marginLeft: "0px" }}
											>
												Business Website
											</label>
											<input
												type="text"
												id="txtbusinesswebsite"
												style={{ height: "60px" }}
												name="businesswebsite"
												defaultValue={props.params.business[0].website}
												className="form-control form-control-lg"
												// value={state.website}
												onChange={handleChange}
											/>
											<div className="form-notch">
												<div
													className="form-notch-leading"
													style={{ width: "9px" }}
												></div>
												<div
													className="form-notch-middle"
													style={{ width: "55.2px" }}
												></div>
												<div className="form-notch-trailing"></div>
											</div>
										</div>
									</div>
									<div class="row pb-3">
										<div className="form-outline">
											<label
												className="form-label form f-fm fm-w6-s12"
												htmlFor="form3Example8"
												style={{ marginLeft: "0px" }}
											>
												Your Business Avatar
											</label>
											<br></br>
											<Cropmodal
												onImageChange={onImageChangebavatar}
												ttype="edit"
												ttypetext={
													props.params.business[0].bavatar +
													"?" +
													today.getHours() +
													today.getMinutes() +
													today.getSeconds()
												}
											></Cropmodal>
											<label
												className="form-label form f-fm fm-w6-s10"
												htmlFor="form3Example8"
												style={{
													marginLeft: "0px",
													fontSize: "10px",
													color: "darkgrey",
												}}
											>
												Note: Click on the image to edit.
											</label>
											<br></br>
											<div className="form-notch">
												<div
													className="form-notch-leading"
													style={{ width: "9px" }}
												></div>
												<div
													className="form-notch-middle"
													style={{ width: "55.2px" }}
												></div>
												<div className="form-notch-trailing"></div>
											</div>
										</div>
									</div>
									<div class="row pb-3">
										<div className="form-outline">
											<label
												className="form-label form f-fm fm-w6-s12"
												htmlFor="form3Example8"
												style={{ marginLeft: "0px" }}
											>
												Number Of Employees
											</label>
											<select
												className="form-select form-select-lg mb-3"
												name="noofemployees"
												defaultValue={props.params.business[0].noofemployees}
												aria-label=".form-select-lg example"
												onChange={handleSelectChange}
											>
												<option value=""></option>
												<option value="1-10">1-10</option>
												<option value="11-50">11-50</option>
												<option value="51-200">51-200</option>
												<option value="201-500">201-500</option>
												<option value="501-1000">501-1000</option>
												<option value="1001-5000">1001-5000</option>
												<option value=">5000">&gt;5000</option>
											</select>
											<div className="form-notch">
												<div
													className="form-notch-leading"
													style={{ width: "9px" }}
												></div>
												<div
													className="form-notch-middle"
													style={{ width: "55.2px" }}
												></div>
												<div className="form-notch-trailing"></div>
											</div>
										</div>
									</div>
									<div class="row pb-3">
										<div className="form-outline">
											<label
												className="form-label form f-fm fm-w6-s12"
												htmlFor="form3Example8"
												style={{ marginLeft: "0px" }}
											>
												Business Name
											</label>
											<input
												type="text"
												id="txtbusinessname"
												style={{ height: "60px" }}
												name="businessname"
												defaultValue={props.params.business[0].name}
												className="form-control form-control-lg"
												// value={state.email}
												onChange={handleChange}
											/>
											<div className="form-notch">
												<div
													className="form-notch-leading"
													style={{ width: "9px" }}
												></div>
												<div
													className="form-notch-middle"
													style={{ width: "55.2px" }}
												></div>
												<div className="form-notch-trailing"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="row">
							<div className="col d-flex justify-content-center">
								<utils.aeButton
									classNames="aebutton fm-w6-s18"
									text="Update"
									enabled="false"
									disabledClass={getButtonClass()}
									onClick={() => showNextStep("Doctor")}
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default Setting;
