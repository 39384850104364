import React, { useState, useEffect, useLayoutEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { format } from "date-fns";
import * as PatientServices from "../../services/patient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import * as utils from "../../common/util";
import * as UserServices from "../../services/user";
import {
	sendRescheduleEmailNotification,
	SendTestNotification,
} from "../../services/notificationservices";
import * as PatientpaymentService from "../../services/patientpayment";
// import { Baseurl, Loader } from "../../common/util";
import axios from "axios";
const baseurl = utils.Baseurl() + "create-refund";

const ApprovalModel = (props) => {
	console.log("props app", props);
	const [show, setShow] = useState(true);
	const [loading, setLoading] = useState(false);
	const [showdecline, setShowDecline] = useState(false);
	const [showreschedule, setShowReschedule] = useState(true);
	const [showcancel, setShowCancel] = useState(false);
	const [showsorry, setShowSorry] = useState(false);
	// const [request, setRequest] = useState(false);
	const [appointment, setappointment] = useState(props.appointment);
	const [previosHistoryArray, setPreviosHistoryArray] = useState([]);
	const [historyTempArray, setHistoryTempArray] = useState([]);
	const [duphistoryTempArray, setdupHistoryTempArray] = useState([]);
	// const [appointmentids, setAppointmentids] = useState([]);
	const [data, setData] = useState([]);
	const [first, setFirst] = useState("");
	// const [doctorids, setDoctorids] = useState([]);
	// const [amount, setAmount] = useState("");
	// const [rescheduleDisable, setRescheduleDisable] = useState("");
	const [availbleTime, setAvailbleTime] = useState();
	const [filteredAppt, setFilteredAppt] = useState([]);
	const [type, setType] = useState([]);
	// const [reschDate, setReschDate] = useState();
	const [disableStartTime, setDisableStartTime] = useState(false);
	const [id, setId] = useState([]);
	const [id1, setId1] = useState([]);
	const [workingDaysArray, setWorkingDaysArray] = useState([]);

	var temp = [];

	useEffect(() => {
		setShowReschedule(true);
	}, [props]);

	useEffect(() => {
		UserServices.GetUserSettingsById(props.params.id).then((existUser) => {
			console.log("pink", existUser.userSettingsByUserID);
			console.log("pink1", existUser.userSettingsByUserID.appointment[0]);
			setAvailbleTime(
				parseInt(existUser.userSettingsByUserID.appointment[0].interval)
			);
		});

		(async function () {
			var c = await UserServices.GetUserSettingsById(props.params.id);
			var a = c.userSettingsByUserID.calendar[0];
			console.log("settings", a);
			var v = a.days.split("|");

			v.forEach((ele) => {
				var a = ele.split("-");
				if (!temp.includes(a[0])) {
					temp.push(a[0]);
					setWorkingDaysArray((prev) => [...prev, a[0]]);
				}
				if (ele === v[v.length - 1]) {
					setFirst("lok");
				}
			});
			console.log("ele", workingDaysArray);
		})();
	}, [props]);

	const getIntervalDates = (dateTime, interval) => {
		//let day = dateTime.slice(0,2);
		let time = dateTime.split("to");
		console.log("-times:" + dateTime + " : " + JSON.stringify(time));
		const sTime = moment(time[0], "HH:mm").add(interval, "minutes");

		let value = {
			interval: "00:" + (interval >= 60 ? "59" : interval),
			startTime: sTime.hour() + ":" + sTime.minute(),

			endTime: time[1],
		};
		//console.log('-startTime: ' + moment(time[0], 'HH:mm').add(interval, 'minutes').hour);

		var inputDataFormat = "HH:mm";
		var outputFormat = "HH:mm";

		var tmp =
			interval >= 60
				? moment(value.interval, inputDataFormat).add(1, "minute")
				: moment(value.interval, inputDataFormat);
		var dif = tmp - moment().startOf("day");

		var startIntervalTime = moment(value.startTime, inputDataFormat).add(
			-dif,
			"ms"
		);
		var endIntervalTime = moment(value.startTime, inputDataFormat);
		var finishTime = moment(value.endTime, inputDataFormat);

		var intervals = [];
		while (startIntervalTime.format("x") < finishTime.format("x")) {
			var format =
				startIntervalTime.format(outputFormat) +
				"-" +
				endIntervalTime.format(outputFormat);

			intervals.push(format);
			startIntervalTime.add(dif, "ms");
			endIntervalTime.add(dif, "ms");
		}
		return intervals;
	};
	console.log("avail", availbleTime);

	const [date, setDate] = useState(true);
	const [tempArray, setTempArray] = useState([]);
	const [timearray, setTimeArray] = useState([]);
	const [lateFeesTimeArray, setLateFeesTimeArray] = useState([]);

	const onChangeDate = (date) => {
		console.log("enter", workingDaysArray);

		let l = new Date(date);

		if (workingDaysArray.includes(l.toString().split(" ")[0])) {
			(async function () {
				var c = await UserServices.GetUserSettingsById(props.params.id);
				var a = c.userSettingsByUserID.calendar[0];
				console.log("settings", a);
				var v = a.days.split("|");
				var w = a.latefeesamount.split("|");
				if (a.latefeesamount !== "") {
					w.forEach((e) => {
						var b = e.split("-");
						console.log(b[0], l.toString().split(" ")[0]);
						if (b[0] === l.toString().split(" ")[0]) {
							v.forEach((ele) => {
								var a = ele.split("-");

								if (a[0] === l.toString().split(" ")[0]) {
									const t = getIntervalDates(
										a[1],
										availbleTime === undefined ? 20 : availbleTime
									);
									console.log(t);
									setTimeArray(t);
									const lateFeeTime = moment(a[1].split("to")[1], "HH:mm")
										.add(-1, "hour")
										.valueOf();
									console.log(lateFeeTime);

									let l = [];

									t.forEach((e) => {
										console.log(
											e,
											moment(e.split("-")[0], "HH:mm").valueOf(),
											lateFeeTime
										);
										if (
											lateFeeTime <= moment(e.split("-")[0], "HH:mm").valueOf()
										) {
											l.push(e.split("-")[0]);
										}
									});
									console.log(l);
									setLateFeesTimeArray(l);
								}
								if (ele === v[v.length - 1]) {
									setFirst("lokop");
								}
							});
						} else {
							v.forEach((ele) => {
								var a = ele.split("-");
								console.log(a[1]);
								if (a[0] === l.toString().split(" ")[0]) {
									const t = getIntervalDates(
										a[1],
										availbleTime === undefined ? 20 : availbleTime
									);
									setTimeArray(t);
								}
								if (ele === v[v.length - 1]) {
									setFirst("lok");
								}
							});
						}
					});
				}
				if (a.latefeesamount === "") {
					v.forEach((ele) => {
						var a = ele.split("-");
						const t = getIntervalDates(
							a[1],
							availbleTime === undefined ? 20 : availbleTime
						);
						setTimeArray(t);
						if (ele === v[v.length - 1]) {
							setFirst("lokesh");
						}
					});
				}
			})();
			setDisableStartTime(false);
			var catearr = props.allappointments.forEach(function (elem) {
				if (elem.status === "Accepted") {
					if (
						elem.appointmentdate.split("T")[0].split("-")[1] ===
							date.split("-")[1] &&
						elem.appointmentdate.split("T")[0].split("-")[2] ===
							date.split("-")[2]
					) {
						if (!id.includes(elem._id)) {
							tempArray.push(elem.starttime);
							id.push(elem._id);
						}
					}
				}
				if (elem.cstatus === "Accepted") {
					if (
						elem.consultationdate.split("T")[0].split("-")[1] ===
							date.split("-")[1] &&
						elem.consultationdate.split("T")[0].split("-")[2] ===
							date.split("-")[2]
					) {
						if (!id1.includes(elem._id)) {
							tempArray.push(elem.cstarttime);
							id1.push(elem._id);
						}
					}
				}
			});
		}

		console.log("block", tempArray, timearray, first);
		setFilteredAppt(tempArray);
	};

	useLayoutEffect(() => {
		console.log(props.allappointments);
		if (props.allappointments.length > 0) {
			let l = props.allappointments.sort((a, b) =>
				a.appointmentdate > b.appointmentdate ? 1 : -1
			);
			console.log("loki", l);
			const beforeConsultTime1 = moment(
				l[0].appointmentdate.split("T")[0],
				"YYYY-MM-DD HH:mm"
			)
				.subtract(1, "days")
				.format("YYYY-MM-DD");
			console.log(beforeConsultTime1);

			onChangeDate(beforeConsultTime1);
		}
	}, [workingDaysArray, props, filteredAppt, first]);

	const [history, setHistory] = useState(false);
	const [history1, setHistory1] = useState(false);

	const handleCloseReschedule = () => {
		setShowReschedule(false);
		props.empty();
		// setShow(true);
		// props.show();
	};

	const handleCloseSorry = () => {
		setShowSorry(false);
		setShow(true);
		// props.show();
	};

	const handleShowSorry = () => {
		setShowSorry(true);
		setShowReschedule(false);
	};
	// console.log(props.hideapointment);

	const handleApprove = (value) => {
		const deeplinkContent = {
			screen: "AppointmentScreen",
			appointmentId: props.reschedule._id,
			navigateTo: "AppointmentDetails",
		};
		if (value === "Approved") {
			(async function anyNameFunction() {
				const updateSettingsvariables =
					PatientServices.returnUpdateAppointments({
						id: props.appointment._id,
						videostatus: "Approved",
						onboardingactiontime: moment().format("YYYY-MM-DD HH:mm:ss"),
					});
				// console.log("lok", updateSettingsvariables);
				PatientServices.UpdatePatientAppointment(updateSettingsvariables).then(
					(val) => {
						console.log("value", val);

						let patientdata = {
							senddata: {
								audience: {
									OR: [
										{
											android_channel:
												appointment.patient_details[0].channelid.split("|"),
										},
										{
											ios_channel:
												appointment.patient_details[0].channelid.split("|"),
										},
									],
								},
								notification: {
									alert:
										"The " +
										appointment.doctor_details[0].firstName +
										" approved your onboarding videos.",
									android: {
										title: "Approved onboarding videos",
									},
									ios: {
										title: "Approved onboarding videos",
									},
									actions: {
										open: {
											content: JSON.stringify(deeplinkContent),
											type: "deep_link",
											fallback_url:
												"patientapp://aesthetik.app/appointment_screen/" +
												props.reschedule._id,
										},
									},
								},
								device_types: ["android", "ios"],
							},
							role: "dashboardpatient",
							patientid: appointment.patient_details[0]._id.toString(),
							kind: "consultation",
						};
						SendTestNotification(patientdata).then((res) => {});

						setLoading(true);
						window.scrollTo(0, 0);
						setTimeout(() => {
							// setIstoastg(false);
							window.location.reload();
						}, 3000);
					}
				);
			})();
		}
		if (value === "Declined") {
			(async function anyNameFunction() {
				const updateSettingsvariables =
					PatientServices.returnUpdateAppointments({
						id: props.appointment._id,
						videostatus: "Declined",
						onboardingactiontime: moment().format("YYYY-MM-DD HH:mm:ss"),
					});
				// console.log("lok", updateSettingsvariables);
				PatientServices.UpdatePatientAppointment(updateSettingsvariables).then(
					(val) => {
						console.log("value", val);
						// sendRescheduleEmailNotification(
						//   props.reschedule.patient_details[0].firstName,
						//   props.reschedule.patient_details[0].email,
						//   props.reschedule.doctor_details[0].firstName,
						//   format(
						//     new Date(props.reschedule.consultationdate.split("T")[0]),
						//     "do MMM yyyy"
						//   ) +
						//     // props.reschedule.consultationdate.split("T")[0] +
						//     " from " +
						//     props.reschedule.cstarttime +
						//     " - " +
						//     props.reschedule.cendtime,
						//   format(new Date(date1), "do MMM yyyy") +
						//     " from " +
						//     startTime +
						//     " - " +
						//     endTime,
						//   props.reschedule.consultationtype
						// );
						// let doctordata = {
						//   senddata: {
						//     audience: {
						//       OR: [
						//         {
						//           android_channel:
						//             props.reschedule.doctor_details[0].channelid.split("|"),
						//         },
						//         {
						//           ios_channel:
						//             props.reschedule.doctor_details[0].channelid.split("|"),
						//         },
						//       ],
						//     },
						//     notification: {
						//       // alert:
						//       //   "The " +
						//       //   props.reschedule.doctor_details[0].firstName +
						//       //   " confirms the new appoint date and time. ie from " +
						//       //   format(
						//       //     new Date(props.reschedule.consultationdate.split("T")[0]),
						//       //     "do MMM yyyy"
						//       //   ) +
						//       //   // props.reschedule.consultationdate.split("T")[0] +
						//       //   " from " +
						//       //   props.reschedule.cstarttime +
						//       //   " - " +
						//       //   props.reschedule.cendtime +
						//       //   " to " +
						//       //   format(new Date(date1), "do MMM yyyy") +
						//       //   " from " +
						//       //   startTime +
						//       //   " - " +
						//       //   endTime,
						//       alert:
						//         "Thanks for confirming the new appoint date and time with " +
						//         props.reschedule.patient_details[0].firstName,
						//       android: {
						//         title: "Reschedule " + props.reschedule.consultationtype,
						//       },
						//       ios: {
						//         title: "Reschedule " + props.reschedule.consultationtype,
						//       },
						//     },
						//     device_types: ["android", "ios"],
						//   },
						//   role: "doctor",
						// };
						let patientdata = {
							senddata: {
								audience: {
									OR: [
										{
											android_channel:
												appointment.patient_details[0].channelid.split("|"),
										},
										{
											ios_channel:
												appointment.patient_details[0].channelid.split("|"),
										},
									],
								},
								notification: {
									alert:
										"The " +
										appointment.doctor_details[0].firstName +
										" declined your onboarding videos please schedule a live consultation",
									android: {
										title: "schedule a live consultation",
									},
									ios: {
										title: "schedule a live consultation",
									},
									actions: {
										open: {
											content: JSON.stringify(deeplinkContent),
											type: "deep_link",
											fallback_url:
												"patientapp://aesthetik.app/appointment_screen/{apptId}",
										},
									},
								},
								device_types: ["android", "ios"],
							},
							role: "dashboardpatient",
							patientid: appointment.patient_details[0]._id.toString(),
							kind: "consultation",
						};
						SendTestNotification(patientdata).then((res) => {});
						// SendTestNotification(doctordata).then((res) => {
						//   window.location.reload();
						// });

						// toast.success("Successfully record saved", {
						//   toastId: "calender",
						//   position: "top-right",
						//   autoClose: 5000,
						//   hideProgressBar: false,
						//   closeOnClick: true,
						//   pauseOnHover: true,
						//   draggable: true,
						//   progress: undefined,
						// });
						setLoading(true);
						window.scrollTo(0, 0);
						setTimeout(() => {
							// setIstoastg(false);
							window.location.reload();
						}, 3000);
					}
				);
			})();
		}
		if (value === "Reschedule") {
			console.log(timearray, filteredAppt);
			if (timearray.length === filteredAppt.length) {
				handleShowSorry();
				return;
			} else {
				(async function anyNameFunction() {
					let updateSettingsvariables = "";
					if (props.reschedule.consultationtype === "consultation") {
						updateSettingsvariables = PatientServices.returnUpdateAppointments({
							id: props.reschedule._id,
							isconsultationrescheduled: true,
						});
					} else {
						updateSettingsvariables = PatientServices.returnUpdateAppointments({
							id: props.reschedule._id,
							isappointmentrescheduled: true,
						});
					}
					// console.log("lok", updateSettingsvariables);
					PatientServices.UpdatePatientAppointment(
						updateSettingsvariables
					).then((val) => {
						console.log("value", val);

						let patientdata = {
							senddata: {
								audience: {
									OR: [
										{
											android_channel:
												props.reschedule.patient_details[0].channelid.split(
													"|"
												),
										},
										{
											ios_channel:
												props.reschedule.patient_details[0].channelid.split(
													"|"
												),
										},
									],
								},
								notification: {
									alert:
										"" +
										props.reschedule.doctor_details[0].firstName +
										(props.reschedule.consultationtype === "consultation"
											? " wishes to  reschedule the consultation"
											: " wishes to  reschedule the appointment"),
									android: {
										title:
											props.reschedule.consultationtype === "consultation"
												? "Reschedule Consultation"
												: "Reschedule Appointment",
									},
									ios: {
										title:
											props.reschedule.consultationtype === "consultation"
												? "Reschedule Consultation"
												: "Reschedule Appointment",
									},
									actions: {
										open: {
											content: JSON.stringify(deeplinkContent),
											type: "deep_link",
											fallback_url:
												"patientapp://aesthetik.app/appointment_screen/" +
												props.reschedule._id,
										},
									},
								},
								device_types: ["android", "ios"],
							},
							role: "dashboardpatient",
							patientid: props.reschedule.patient_details[0]._id.toString(),
							kind:
								props.reschedule.consultationtype === "consultation"
									? "consultation"
									: "appointment",
							appointmentid: props.reschedule._id,
						};
						SendTestNotification(patientdata).then((res) => {});
						// SendTestNotification(doctordata).then((res) => {
						//   window.location.reload();
						// });

						// toast.success("Successfully record saved", {
						//   toastId: "calender",
						//   position: "top-right",
						//   autoClose: 5000,
						//   hideProgressBar: false,
						//   closeOnClick: true,
						//   pauseOnHover: true,
						//   draggable: true,
						//   progress: undefined,
						// });
						setLoading(true);
						window.scrollTo(0, 0);
						setTimeout(() => {
							// setIstoastg(false);
							window.location.reload();
						}, 3000);
					});
				})();
			}
		}
	};

	useEffect(() => {
		setappointment(props.appointment);
	}, [props]);

	return (
		<>
			<Modal
				show={showsorry}
				onHide={handleCloseSorry}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header>
					<Modal.Title>
						<Modal.Body style={{ paddingTop: 0 }}>
							<div
								style={{
									cursor: "pointer",
									width: "100%",
									display: "flex",
									justifyContent: "end",
								}}
								onClick={() => {
									handleCloseSorry();
								}}>
								<img alt="close" src="./images/closeone.png"></img>
							</div>
							<div className="f-fm fm-w7-s30 pt-4">
								We are sorry but you cannot send a request to reschedule
							</div>
							<div className="f-fm fm-w4-s18" style={{ marginBottom: "100px" }}>
								It looks like you don’t have any time available for the patient
								to reschedule the consultation 24 hours before the upcoming
								treatment.
							</div>
						</Modal.Body>
					</Modal.Title>
				</Modal.Header>
			</Modal>

			<Modal
				show={showreschedule}
				onHide={handleCloseReschedule}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				{loading === true && <utils.Loader />}
				<Modal.Header>
					<Modal.Title>
						<Modal.Body style={{ paddingTop: 0 }}>
							<div
								style={{
									cursor: "pointer",
									width: "100%",
									display: "flex",
									justifyContent: "end",
								}}
								onClick={() => {
									handleCloseReschedule();
								}}>
								<img alt="close" src="./images/closeone.png"></img>
							</div>
							{props.reschedule.consultationtype === "appointment" && (
								<div className="f-fm fm-w7-s30 pt-4">
									Do you want to send a request to your patient to reschedule
									the appointment?
								</div>
							)}
							{props.reschedule.consultationtype === "consultation" && (
								<div className="f-fm fm-w7-s30 pt-4">
									Request patient reschedule live consultation?
								</div>
							)}

							<div className="f-fm fm-w4-s18">
								To ensure the best possible experience of your service, we
								kindly recommend limiting the number of rescheduling requests.
							</div>
							<div
								className="col-9 f-fm fm-w4-s16 pt-7"
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "end",
								}}>
								<button
									style={{
										width: "197px",
										height: "60px",
										borderRadius: "40px",
										backgroundColor: "#000",
										color: "#fff",
										border: "1px solid #000000",
									}}
									onClick={() => {
										handleApprove("Reschedule");
									}}>
									Send Request
								</button>
							</div>
						</Modal.Body>
					</Modal.Title>
				</Modal.Header>
			</Modal>
		</>
	);
};

export default ApprovalModel;
