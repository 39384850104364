import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const IdleTimeOutModal = ({
	showModal,
	handleContinue,
	handleLogout,
	remainingTime,
}) => {
	return (
		<Modal show={showModal} onHide={handleContinue}>
			<Modal.Header
			// closeButton
			>
				<Modal.Title>You Have Been Idle!</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Your session is Timed Out. Please login to Continue
			</Modal.Body>
			<Modal.Footer style={{ justifyContent: "center" }}>
				{/* <Button variant="danger" onClick={handleLogout}>
					Logout
				</Button> */}
				<Button
					style={{
						borderRadius: "40px",
						width: "85px",
					}}
					variant="dark"
					onClick={handleContinue}
				>
					Ok
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
