import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../common/logo";
import * as utils from "../common/util";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { useContext } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import Popupforhome from "./popupforhome";

const Faq = () => {
	const navigate = useNavigate();
	const defaultac = "0";
	const COLLAPSE = "accordion-button";
	const NOCOLLAPSE = "accordion-button collapsed";
	function CustomToggle({ children, eventKey, callback }) {
		const { activeEventKey } = useContext(AccordionContext);

		const decoratedOnClick = useAccordionButton(
			eventKey,
			() => callback && callback(eventKey)
		);

		const isCurrentEventKey = activeEventKey === eventKey;

		return (
			<div
				// aria-expanded="true"

				className={isCurrentEventKey ? COLLAPSE : NOCOLLAPSE}
				style={{
					width: "100%",
					backgroundColor: "transparent",
					color: "#fff",
					cursor: "pointer",
				}}
				onClick={decoratedOnClick}
			>
				{children}
			</div>
		);
	}

	const [windowSize, setWindowSize] = useState(getWindowSize());
	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}
	const firstupdate = useRef(true);
	const firststateupdate = useRef(true);
	const [isPageLoaded, setisPageLoaded] = useState(false);
	// const navigate = useNavigate();
	const [show, setShow] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);
	const [hoverbar, setHoverBar] = useState(false);
	var timer = null;
	const controlNavbar1 = (event) => {
		console.log(event.target.className);
		if (
			(event.target.className.indexOf("jnav") > 0 && hoverbar) ||
			window.scrollY === 0
		) {
			setShow(true);
			setHoverBar(true);
			clearTimeout(timer);
			timer = null;
		}
	};
	const controlNavbar = () => {
		if (typeof window !== "undefined") {
			//console.log(window.scrollY, lastScrollY, hoverbar)
			if (window.scrollY === 0) {
				console.log("ell", hoverbar);
				clearTimeout(timer);
				timer = null;
				setHoverBar(false);
				setShow(true);
			} else if (window.scrollY > lastScrollY) {
				// if scroll down hide the navbar
				setShow(false);
			} else {
				// if scroll up show the navbar
				//console.log("else", window.scrollY, lastScrollY, hoverbar)
				setShow(true);
				if (hoverbar) {
					const timer = setTimeout(() => {
						setShow(true);
					}, 4000);
				} else {
					if (window.scrollY !== 0) {
						timer = setTimeout(() => {
							if (window.scrollY != 0) {
								setShow(false);
							}
						}, 4000);
					}
				}
			}

			// remember current page location to use in the next move
			setLastScrollY(window.scrollY);
		}
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", controlNavbar);
			//window.addEventListener('mouseover', controlNavbar1);
			window.document
				.getElementById("mynav")
				.addEventListener("mousemove", controlNavbar1);

			// cleanup function
			return () => {
				window.removeEventListener("scroll", controlNavbar);
				window.document
					.getElementById("mynav")
					.removeEventListener("mousemove", controlNavbar1);
			};
		}
	}, [lastScrollY]);

	useEffect(() => {
		if (firstupdate.current) {
			(async function anyNameFunction() {
				const timer = setTimeout(() => {
					setisPageLoaded(true);
					firstupdate.current = false;
					firststateupdate.current = false;
				}, 2000);
				return () => clearTimeout(timer);
			})();
		}
	}, []);

	const handleMouseEnter = () => {
		setHoverBar(true);
		setShow(true);
	};
	const handleMouseMove = () => {
		setHoverBar(true);
		setShow(true);
	};
	const handleMouseLeave = () => {};

	return (
		<div className="body-bg-home ccolor">
			<div className="faq">
				<nav
					className=" navbar container-fluid fixed-topmobile  navbar-expand-lg navbar-dark bg-lightmobile navh"
					style={{ height: "70px", backgroundColor: "#000000" }}
				>
					<div className="container-fluid " style={{}}>
						<Logo logo="dark-light1"></Logo>
						<div className="">
							<Popupforhome></Popupforhome>
						</div>
					</div>
				</nav>
			</div>
			<nav
				id="mynav"
				onMouseMove={handleMouseMove}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				className={`navbar  dvall1 container-fluid   fixed-top  navbar-expand-lg navbar-dark bg-light bg-light1  ${
					!show && "hidden"
				}`}
			>
				<div className="container-fluid jnav navh">
					{/* <div className="popupnav">  */}
					<Logo logo="dark-light" className="jnav"></Logo>
					<div className="tabletnav">
						<Popupforhome></Popupforhome>
					</div>

					<div
						className="navbar-collapse collapse jnav"
						style={{ whiteSpace: "nowrap", width: "100%", height: "100px" }}
						id="navbarCollapse"
					>
						<div className="navbar-nav p-4 pr-r20 p-lg-0 jnav" id="navbarnav">
							<a
								className="nav-link1 f-rl fm-w5-s20 jnav"
								href="/everyone"
								style={{ color: "#FFFFFF" }}
							>
								For Everyone
								<div
									className="jnav"
									style={{
										backgroundColor: "transparent",
										borderRadius: "5px",
										height: "3px",
										marginTop: "3px",
										width: "123px",
									}}
								></div>
							</a>
							<a
								className="nav-link1  f-rl fm-w5-s20 jnav"
								href="/business"
								style={{ color: "#FFFFFF" }}
							>
								For business
								<div
									className="jnav"
									style={{
										backgroundColor: "transparent",
										borderRadius: "5px",
										height: "3px",
										marginTop: "3px",
									}}
								></div>
							</a>
							<a
								className="nav-link1  f-rl fm-w5-s20 jnav"
								href="/login"
								style={{ color: "#FFFFFF" }}
							>
								Login
								<div
									className="jnav"
									style={{
										backgroundColor: "transparent",
										borderRadius: "5px",
										height: "3px",
										marginTop: "3px",
									}}
								></div>
							</a>
						</div>

						<div className="d-flex burger jnav">
							<button
								className="btn-rounded-black jnav"
								style={{
									border: "1px solid #fff",
								}}
								onClick={() => navigate("/signup/social")}
							>
								Business Sign Up
							</button>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<a href="../downloads/app-debug.apk" download className="jnav">
								<button className="btn-rounded-white jnav">
									{" "}
									Download the App
								</button>
							</a>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</div>
					</div>
				</div>
			</nav>
			<div className="container-fluid">
				<div className="row no-gutters ">
					<div
						className="col "
						style={{
							backgroundColor: "#1F1F1F",
							backgroundImage: "url('../images/Rectangle224.png')",
							backgroundRepeat: "no-repeat",
							//   width:"1000px",
							// height: "1450px",
						}}
					>
						<div
							className="row mobilefaq"
							style={{ paddingTop: "222px", paddingBottom: "151px" }}
						>
							<div className="col ">
								<div className="row">
									<div className="fm-w3-s50 color-FF  dcenter fm-w3-s501 ">
										Frequently Asked Questions
									</div>
								</div>
								<div className="row ">
									<div
										className="fm-w3-s16 color-FF fffaq1"
										style={{ textAlign: "center" }}
									>
										Have a question? We are here to help.
										<br />
										Can't find the answer you are looking for?&nbsp;
										<span
											// style={{ textDecoration: "underline" }}
											style={{ cursor: "pointer", textDecoration: "underline" }}
											onClick={() => navigate("/contactus")}
										>
											Contact Us
										</span>
										&nbsp; directly.
									</div>
								</div>
								<div
									className="dcenter"
									style={{
										marginTop: "60px",
										// color: "#fff",
										// height: "1px",
										width: "100vw",
									}}
								>
									<span
										style={{
											// marginTop: "60px",
											backgroundColor: "#fff",
											height: "1px",
											width: "16px",
										}}
									></span>
								</div>
								<Accordion defaultActiveKey={defaultac} className="ddcenter">
									<div className="faqcard ">
										<Card.Header>
											<CustomToggle
												className="accordion-collapse collapse "
												style={{}}
												eventKey="0"
											>
												<div className="fffaq"> What is Aesthetik App?</div>
											</CustomToggle>
										</Card.Header>
										<Accordion.Collapse eventKey="0">
											<div className="fbody fffaq1">
												Revolutionize your beauty routine with our cutting-edge
												solution. A marketplace that empowers the Aesthetic
												industry providing a tool for On-demand virtual clinics.
												Our intuitive Doctors Dashboard and user-friendly app
												connects you seamlessly with your medical providers,
												allowing for a personalized and stress-free experience.
												With our patent app, you can rest assured that your
												in-person appointments will be a breeze.
											</div>
										</Accordion.Collapse>
									</div>
									<div className="faqcard ">
										<Card.Header>
											<CustomToggle
												className="accordion-collapse collapse fffaq"
												style={{}}
												eventKey="1"
											>
												<div className="fffaq">
													What services does Aesthetik.app provide?
												</div>
											</CustomToggle>
										</Card.Header>
										<Accordion.Collapse eventKey="1">
											<div className="fbody fffaq1">
												<ul>
													<li>
														For medical professional platform that allows
														doctors and clinics to set up your calendar and
														manage your patient appointments. Consultant with
														patients with live video, view treatments, and track
														your staff orders for an on-demand home service. All
														this is managed in dashboard and doctors app.
													</li>
													<br />

													<li>
														{" "}
														For patients they can book on-demand cosmetic
														treatments with medically approved professionals,
														secure check-in and KYC scan ID fascial recognition.
													</li>
												</ul>
											</div>
										</Accordion.Collapse>
									</div>
									<div className="faqcard ">
										<Card.Header>
											<CustomToggle
												className="accordion-collapse collapse fffaq"
												style={{}}
												eventKey="2"
											>
												<div className="fffaq">
													{" "}
													How can I get started with Aesthetik.app?
												</div>
											</CustomToggle>
										</Card.Header>
										<Accordion.Collapse eventKey="2">
											<div className="fbody fffaq1">
												To get started with Aesthetik.app, simply visit our
												website and sign up for an account. Once registered, you
												can explore through our intuitive and user-friendly
												interface to help you get started quickly.
											</div>
										</Accordion.Collapse>
									</div>
									<div className="faqcard ">
										<Card.Header>
											<CustomToggle
												className="accordion-collapse collapse fffaq"
												style={{}}
												eventKey="3"
											>
												<div className="fffaq">
													{" "}
													What are the pricing options for using Aesthetik.app?{" "}
												</div>
											</CustomToggle>
										</Card.Header>
										<Accordion.Collapse eventKey="3">
											<div className="fbody fffaq1">
												Aesthetik.app offers flexible pricing options to suit
												various needs. We have different subscription plans,
												including monthly and annual options, with varying
												levels of features and benefits.
												<br />
												<br />
												<ul>
													<li>
														Standard: £19.99 per month per clinic for up to 3
														doctors
													</li>
													<li>
														Business: £25.99 per month per clinic for up to 5
														doctors
													</li>
													<li>
														Premium: £49.99 per month per clinic for up to 10
														doctors
													</li>

													<ul>
														<li>
															£7.99 per additional doctor added to the account.
														</li>
														<li>
															Any subscriptions paid annually will have 15%
															saving.
														</li>
													</ul>
												</ul>
												<br />
												<div style={{ fontStyle: "italic" }}>
													{" "}
													<span
														style={{ color: "#e10000", fontStyle: "italic" }}
													>
														15% transactional fee{" "}
													</span>{" "}
													( note this will not be listed on the website they
													will see it when the click subscribe during the
													onboarding process.)
												</div>
											</div>
										</Accordion.Collapse>
									</div>
									<div className="faqcard ">
										<Card.Header>
											<CustomToggle
												className="accordion-collapse collapse fffaq"
												style={{}}
												eventKey="4"
											>
												<div className="fffaq">
													{" "}
													Is there a free trial available for Aesthetik.app?{" "}
												</div>
											</CustomToggle>
										</Card.Header>
										<Accordion.Collapse eventKey="4">
											<div className="fbody fffaq1">
												Yes, the first 30 days are free with full access to the
												features and platforms.
											</div>
										</Accordion.Collapse>
									</div>
									<div className="faqcard ">
										<Card.Header>
											<CustomToggle
												className="accordion-collapse collapse fffaq"
												style={{}}
												eventKey="5"
											>
												<div className="fffaq">
													{" "}
													What support options are available if I encounter any
													issues or have questions?
												</div>
											</CustomToggle>
										</Card.Header>
										<Accordion.Collapse eventKey="5">
											<div className="fbody fffaq1">
												Aesthetik.app offers support to users in case of any
												issues or questions. We have a dedicated support team
												that can be reached through our website's contact form
												or via email. We strive to provide timely and helpful
												assistance to ensure a smooth experience for our users.
											</div>
										</Accordion.Collapse>
									</div>
									<div className="faqcard ">
										<Card.Header>
											<CustomToggle
												className="accordion-collapse collapse fffaq"
												style={{}}
												eventKey="6"
											>
												<div className="fffaq">
													Is my data secure and protected when using
													Aesthetik.app?
												</div>
											</CustomToggle>
										</Card.Header>
										<Accordion.Collapse eventKey="6">
											<div className="fbody fffaq1">
												Yes, the security and protection of user data are a top
												priority for Aesthetik.app. We employ industry-standard
												security measures to safeguard your information. Our
												platform uses encryption protocols to protect data
												transmission and follows best practices to secure data
												storage. We have a privacy policy in place that outlines
												how we handle and protect user data.
											</div>
										</Accordion.Collapse>
									</div>

									{/* <div className="faqcard">
                    <Card.Header>
                      <CustomToggle eventKey="1">Click me!</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>Hello! I'm another body</Card.Body>
                    </Accordion.Collapse>
                  </div> */}
								</Accordion>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="view1920" style={{ backgroundColor: "#000000" }}>
				{" "}
				{utils.Footer1920()}
			</div>
			<div className="view992" style={{ backgroundColor: "#000000" }}>
				{" "}
				{utils.Footer1()}
			</div>
			<div
				className="view19201 view9921"
				style={{ backgroundColor: "#000000" }}
			>
				{" "}
				{utils.Footer()}
			</div>
		</div>
	);
};
export default Faq;
