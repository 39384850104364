import React from "react";
import { useState, useLayoutEffect, useEffect, useRef } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";
import * as PatientpaymentService from "../../services/patientpayment";
import * as UserServices from "../../services/user";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import * as TreatmentServices from "../../services/treatments";
import * as utils from "../../common/util";
import {
	sendRescheduleEmailNotification,
	SendTestNotification,
} from "../../services/notificationservices";

const DropDownContainer = styled("div")``;
const DropDownHeader = styled("div")``;
const DropDownListContainer = styled("div")``;
const DropDownList = styled("ul")``;
const ListItem = styled("li")``;

function Payments(props) {
	console.log("props", props);
	const baseurl = utils.Baseurl() + "create-refund";
	const [data, setData] = useState([]);
	const [percentageValue, setPercentageValue] = useState("");
	const [selectedpatientname, setSelectedpatientname] = useState("");
	const [selectedcost, setSelectedcost] = useState("");
	const [selecteddateandtime, setSelecteddateandtime] = useState("");
	const [selectedtreatments, setSelectedtreatments] = useState("");
	const [finalamount, setFinalamount] = useState("");
	const [selectedtax, setSelectedtax] = useState("");
	// const [selectedstripeaccount, setSelectedstripeaccount] = useState("");
	const [selectedpaymentintent, setSelectedpaymentintent] = useState("");
	const [selectedpostpaymentintent, setSelectedpostpaymentintent] =
		useState("");
	const [appointmentid, setAppointmentid] = useState("");
	const [doctorids, setDoctorids] = useState([]);
	const [doctorchannelid, setDoctorchannelid] = useState([]);
	const [patientchannelid, setPatientchannelid] = useState([]);
	const [appointmentids, setAppointmentids] = useState([]);
	const [appointmentdata, setAppointmentdata] = useState([]);
	const [patientid, setPatientid] = useState("");
	const [doctorid, setDoctorid] = useState("");
	const [tempappointmentdata, setTempAppointmentdata] = useState([]);
	const [showResults, setShowResults] = useState("none");
	const [first, setFirst] = useState(true);
	const [treatmentDetails, setTreatmentDetails] = useState([]);
	const [tempArray, setTempArray] = useState([]);
	const catmenu = useRef(null);
	const [loading, setLoading] = useState(false);
	const [showcancel, setShowCancel] = useState(false);

	const handleCloseCancel = () => {
		setShowCancel(false);
		setPercentageValue("");
	};

	const handlemousedown = (e) => {
		if (catmenu.current && isOpen && !catmenu.current.contains(e.target)) {
			setIsOpen(false);
		}
	};
	document.addEventListener("mousedown", handlemousedown);

	function myFunction() {
		if (showResults === "none") {
			setShowResults("block");
		} else {
			setShowResults("none");
		}
	}

	const [treatments, setTreatments] = useState([]);
	const [price] = useState([]);
	useEffect(() => {
		(async function anyNameFunction() {
			var duplicate = false;
			var defaulttreatments = await TreatmentServices.GetGlobalTreatments();
			// console.log("treatments", defaulttreatments);
			if (props.params.isadmin) {
				let i = 1;
				defaulttreatments.globaltreatments.forEach((treat) => {
					// console.log("df: " + i);
					// let j = 1;
					duplicate = false;
					treatments.forEach((t) => {
						if (t.photo1 === treat.photo1) {
							duplicate = true;
						}
						// console.log(
						// 	"ts: " +
						// 		j +
						// 		" userid:  " +
						// 		treat.userid +
						// 		" params.id: " +
						// 		params.id +
						// 		" dup: " +
						// 		duplicate
						// );
						// j = j + 1;
					});

					if (treat.userid === props.params.id && !duplicate) {
						console.log(treat);
						console.log(treatments);
						// setTreatments((prev) => [...prev, treat]);

						treatments.push(treat);
						treatmentDetails.push(treat.treatmentname);
						tempArray.push(treat.id);
						price.push(treat.sellingprice);
					}
					// }
					i = i + 1;
				});
				// setTreatments(treatments.globaltreatments);
			} else {
				defaulttreatments.globaltreatments.forEach((treat) => {
					treatments.forEach((t) => {
						if (t.photo1 === treat.photo1) {
							duplicate = true;
						}
					});
					// if () {
					if (
						treat.assigneddoctors.length > 0 &&
						treat.assigneddoctors[0].includes(props.params.id) &&
						!duplicate
					) {
						console.log(treat);
						console.log(treatments);
						// setTreatments((prev) => [...prev, treat]);
						treatments.push(treat);
						treatmentDetails.push(treat.treatmentname);
						tempArray.push(treat.id);
						price.push(treat.sellingprice);
					}
					// }
				});
				// setTreatments(treatments.globaltreatments);
			}
			console.log("tttt2", treatments);
			console.log("tttt2d", treatmentDetails);
			console.log("tttt2ta", tempArray);
			console.log("tttt2p", price);
		})();
	}, []);

	const getvalue = (tid) => {
		// (async function anyNameFunction() {
		// 	if (!tempArray.includes(tid)) {
		// 		const existUser = await TreatmentServices.GetTreatmentById(tid);
		// 		console.log("sss", existUser);
		// 		setExistUser(existUser);
		// 		if (!tempArray.includes(tid)) {
		// 			treatmentDetails.push(existUser.treatmentsByID.treatmentname);
		// 			tempArray.push(existUser.treatmentsByID.id);
		// 		}
		// 	}
		// 	// setTreatmentDetails(tempArray)
		// })();

		console.log("loki", treatmentDetails);

		var index = tempArray.indexOf(tid);

		return treatmentDetails[index];
	};
	useEffect(() => {
		if (props.params.isadmin) {
			if (first) {
				UserServices.getDoctors(props.params.clinicname).then(function (
					result
				) {
					Object.values(result.data.usersByClinicName).forEach((doctors) => {
						console.log("userid", doctors.id);
						if (!doctorids.includes(doctors.id)) {
							doctorids.push(doctors.id);
							patient_analytics(doctors.id);
						}
					});
				});
				setFirst(false);
			}
		} else {
			patient_analytics(props.params.id);
		}
	}, []);

	const patient_analytics = (id) => {
		UserServices.GetAppointment(id).then((app) => {
			Object.values(app.data).forEach((ids) => {
				if (!appointmentids.includes(ids._id)) {
					PatientpaymentService.GetPatientpaymentByAppointmentIdAllSuccess(
						ids._id
					).then(function (result) {
						// console.log("doctors", result.patientpaymentByAppointmentIDSuccess);
						if (
							result.patientpaymentByAppointmentIDAllSuccess[0] != undefined
						) {
							setData((prev) => [
								...prev,
								result.patientpaymentByAppointmentIDAllSuccess[0],
							]);
						}
					});
					appointmentids.push(ids._id);
					appointmentdata.push(ids);
					tempappointmentdata.push(ids);

					// if (
					//   result.patientpaymentByAppointmentIDSuccess[0][app.data.length - 1]
					//     ._id === ids._id
					// ) {
					// setFinalAppointmentdata(appointmentdata);
					// }
					//   setAppointmentids((prev) => [...prev, ids._id]);
					//   setAppointmentdata((prev) => [...prev, ids]);
				}
				// if (!patientids.includes(ids.patientid)) {
				// 	patientids.push(ids.patientid);
				// 	setTotal((prev) => prev + 1);
				// 	if (ids.patient_details[0].gender === "Male") {
				// 		setMales((prev) => prev + 1);
				// 	} else if (ids.patient_details[0].gender === "Female") {
				// 		setFemales((prev) => prev + 1);
				// 	} else {
				// 		setOthers((prev) => prev + 1);
				// 	}

				// 	var age = calculate_age(ids.patient_details[0].dob);
				// 	if (18 <= age && age <= 30) {
				// 		setFirstagerange((prev) => prev + 1);
				// 	}
				// 	if (31 <= age && age <= 35) {
				// 		setSecondagerange((prev) => prev + 1);
				// 	}
				// 	if (36 <= age && age <= 40) {
				// 		setThirdagerange((prev) => prev + 1);
				// 	}
				// 	if (41 <= age && age <= 45) {
				// 		setFouragerange((prev) => prev + 1);
				// 	}
				// 	if (46 <= age && age <= 50) {
				// 		setFiveagerange((prev) => prev + 1);
				// 	}
				// 	if (50 < age) {
				// 		setSixagerange((prev) => prev + 1);
				// 	}
				// }
			});
		});
	};

	console.log("doctorids", doctorids);
	console.log("appointmentids", appointmentdata);

	console.log("data", data);

	const options = ["Date", "Transaction id", "Treatment", "Amount"];

	const [isOpen, setIsOpen] = useState(false);
	const [filterList, setFilterList] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const toggling = () => {
		setIsOpen(!isOpen);
	};

	const onOptionClicked = (value) => {
		setSelectedOption(value);
		setIsOpen(false);
	};

	const sortByFilter = (event) => {
		// console.log("rhh", event);
		if (event === "Amount") {
			let res = filterList.sort((a, b) =>
				parseInt(a.amount) > parseInt(b.amount) ? 1 : -1
			);
			console.log(res);
			setFilterList(res);
			return;
		}
		if (event === "Treatment") {
			let res = filterList.sort((a, b) => (a.array > b.array ? 1 : -1));
			console.log(res);
			setFilterList(res);
			return;
		}
		if (event === "Date") {
			let res = filterList.sort((a, b) => (a.date < b.date ? 1 : -1));
			console.log(res);
			setFilterList(res);
			return;
		}
		if (event === "Transaction id") {
			let res = filterList.sort((a, b) =>
				a.paymentintent > b.paymentintent ? 1 : -1
			);
			console.log(res);
			setFilterList(res);
			return;
		}
	};

	let tarray = [];
	const functionTemp = (tre) => {
		return tre.split(",").map((k) => {
			return <>{getvalue(k)}</>;
		});
	};

	useEffect(() => {
		appointmentdata.forEach((apd) => {
			data.forEach((item) => {
				if (apd._id === item.appointmentid) {
					var treatmentstatus = "";
					if (item.cancelledstatus === "success") {
						if (apd.missedby === "patient") {
							treatmentstatus = (
								<p
									className="d-flex align-items-center"
									style={{
										color: "#404040",
										fontSize: "14px",
										fontFamily: "Mulish",
										fontStyle: "normal",
										fontWeight: 600,
										lineHeight: "normal",
										whiteSpace: "nowrap",
									}}>
									Missed (by Patient)
								</p>
							);
						}
						if (apd.missedby === "doctor") {
							treatmentstatus = (
								<p
									className="d-flex align-items-center"
									style={{
										color: "#404040",
										fontSize: "14px",
										fontFamily: "Mulish",
										fontStyle: "normal",
										fontWeight: 600,
										lineHeight: "normal",
										whiteSpace: "nowrap",
									}}>
									Missed (by Doctor)
								</p>
							);
						}
						if (apd.cancelledby === "patient") {
							treatmentstatus = (
								<p
									className="d-flex align-items-center"
									style={{
										color: "#ACACAC",
										fontSize: "14px",
										fontFamily: "Mulish",
										fontStyle: "normal",
										fontWeight: 600,
										whiteSpace: "nowrap",
										lineHeight: "normal",
									}}>
									Cancelled (by Patient)
								</p>
							);
						}
						if (apd.cancelledby === "doctor") {
							treatmentstatus = (
								<p
									className="d-flex align-items-center"
									style={{
										color: "#ACACAC",
										fontSize: "14px",
										fontFamily: "Mulish",
										fontStyle: "normal",
										fontWeight: 600,
										lineHeight: "normal",
										whiteSpace: "nowrap",
									}}>
									Cancelled (by Doctor)
								</p>
							);
						}
					} else {
						treatmentstatus = (
							<>
								<p
									className="d-flex align-items-center"
									style={{
										color: "#00C3A0",
										fontSize: "14px",
										fontFamily: "Mulish",
										fontStyle: "normal",
										fontWeight: 600,
										lineHeight: "normal",
										whiteSpace: "nowrap",
									}}>
									Completed
									<i
										class="fa fa-light fa-check"
										style={{
											marginLeft: "5px",
										}}></i>
								</p>
							</>
						);
					}

					let obj = {
						paymentintent: item.prepaymentintent,
						postpaymentintent: item.postpaymentintent,
						refund: item.refund,
						tax: item.tax,
						isrefunded: item.refund !== "0" ? true : false,
						amount:
							item.cancelledstatus === "success"
								? (
										(parseFloat(item.cancelledsubtotal) +
											parseFloat(item.cancelledtax)) *
										1
								  ).toFixed(2)
								: (item.amount * 1).toFixed(2),
						patientname:
							apd.patient_details[0].firstName +
							" " +
							apd.patient_details[0].lastName,
						cat: treatmentstatus,
						stripeaccount: apd.doctor_details[0].stripeaccount,
						patientchannelid: apd.patient_details[0].channelid.split("|"),
						doctorchannelid: apd.doctor_details[0].channelid.split("|"),
						appointmentid: apd._id.toString(),
						patientid: apd.patient_details[0]._id.toString(),
						date: item.date,
						starttime: apd.starttime,
						doctor: apd.doctor_details[0].firstName,
						doctorid: apd.doctor_details[0]._id.toString(),
						array: functionTemp(apd.treatmentid[0])[0].props.children,
					};
					tarray.push(obj);
				}
			});
			if (appointmentdata[appointmentdata.length - 1]._id === apd._id) {
				console.log("tarray", tarray);
				setFilterList(tarray);
				// This is the last object in the array
				// Perform your desired operations here
			}
		});
	}, [data]);

	useEffect(() => {
		var am = (
			(parseFloat(percentageValue) *
				(parseFloat(selectedcost) - parseFloat(selectedtax))) /
			100
		).toFixed(2);
		// console.log("am", am);
		setFinalamount(am);
	}, [percentageValue]);

	function sendRefund() {
		setLoading(true);
		console.log(
			"enter refund",
			finalamount,
			percentageValue,
			selectedpaymentintent
		);
		axios
			.post(baseurl, {
				// amount: finalamount,
				paymentintent: selectedpaymentintent,
				percentage: percentageValue,
				// stripeaccount: selectedstripeaccount,
			})
			.then((response) => {
				console.log("response refund", response);
				if (selectedpostpaymentintent !== "") {
					axios
						.post(baseurl, {
							// amount: finalamount,
							paymentintent: selectedpostpaymentintent,
							percentage: percentageValue,
							// stripeaccount: selectedstripeaccount,
						})
						.then((response) => {
							console.log("response refund", response);
							window.location.reload();
						})
						.catch((err) => console.log(err.message));
				} else {
					window.location.reload();
				}

				const deeplinkContent = {
					screen: "AppointmentScreen",
					appointmentId: appointmentid,
					navigateTo: "AppointmentDetails",
				};

				let doctordata = {
					senddata: {
						audience: {
							OR: [
								{
									android_channel: doctorchannelid,
								},
								{
									ios_channel: doctorchannelid,
								},
							],
						},
						notification: {
							alert:
								"You have refunded " +
								selectedpatientname +
								" for " +
								selectedtreatments,
							android: {
								title: percentageValue + " refund",
							},
							ios: {
								title: percentageValue + " refund",
							},
							actions: {
								open: {
									content: JSON.stringify(deeplinkContent),
									type: "deep_link",
									fallback_url:
										"doctorapp://aesthetik.app/appointment_screen/" +
										appointmentid,
								},
							},
						},
						device_types: ["android", "ios"],
					},
					role: "dashboarddoctor",
					doctorid: doctorid,
					kind: "appointment",
					appointmentid: appointmentid,
				};

				let admindoctordata = {
					senddata: {
						audience: {
							OR: [
								{
									android_channel: props.params.channelid.split("|"),
								},
								{
									ios_channel: props.params.channelid.split("|"),
								},
							],
						},
						notification: {
							alert:
								"You have refunded " +
								selectedpatientname +
								" for " +
								selectedtreatments,
							android: {
								title: percentageValue + " refund",
							},
							ios: {
								title: percentageValue + " refund",
							},
							actions: {
								open: {
									content: JSON.stringify(deeplinkContent),
									type: "deep_link",
									fallback_url:
										"doctorapp://aesthetik.app/appointment_screen/" +
										appointmentid,
								},
							},
						},
						device_types: ["android", "ios"],
					},
					role: "dashboarddoctor",
					doctorid: props.params.id,
					kind: "appointment",
					appointmentid: appointmentid,
				};

				let patientdata = {
					senddata: {
						audience: {
							OR: [
								{
									android_channel: patientchannelid,
								},
								{
									ios_channel: patientchannelid,
								},
							],
						},
						notification: {
							alert:
								props.params.firstName +
								" has issued a for refunded " +
								selectedtreatments,
							android: {
								title: percentageValue + " refund",
							},
							ios: {
								title: percentageValue + " refund",
							},
							actions: {
								open: {
									content: JSON.stringify(deeplinkContent),
									type: "deep_link",
									fallback_url:
										"patientapp://aesthetik.app/appointment_screen/" +
										appointmentid,
								},
							},
						},
						device_types: ["android", "ios"],
					},
					role: "dashboardpatient",
					patientid: patientid,
					kind: "appointment",
					appointmentid: appointmentid,
				};

				SendTestNotification(patientdata).then((res) => {});

				if (doctorid === props.params.id) {
					SendTestNotification(admindoctordata).then((res) => {});
				} else {
					SendTestNotification(doctordata).then((res) => {});
					SendTestNotification(admindoctordata).then((res) => {});
				}

				setLoading(true);
				window.scrollTo(0, 0);
				setTimeout(() => {
					// setIstoastg(false);
					window.location.reload();
				}, 3000);
			})
			.catch((err) => console.log(err.message));
	}

	return (
		<div className="col-xl-11 col-lg-11 col-md-11 col-xs-11 col-sm-11 pt-4">
			<Modal
				show={showcancel}
				onHide={handleCloseCancel}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				{loading === true && <utils.Loader />}

				<Modal.Body style={{ padding: "25px" }}>
					<div
						style={{
							cursor: "pointer",
							width: "100%",
							display: "flex",
							justifyContent: "end",
						}}
						onClick={() => {
							handleCloseCancel();
						}}>
						<img alt="close" src="./images/closeone.png"></img>
					</div>
					<div className="f-fm fm-w7-s30 pt-4">
						Offer refund to your patient
					</div>
					<div className="f-fm fm-w5-s16 pt-4">{selectedpatientname}</div>
					<div className="f-fm fm-w5-s16">
						Treatment on {selecteddateandtime}
					</div>
					<div className="f-fm fm-w5-s16">
						{selectedtreatments} &nbsp;&nbsp;
						<span className="f-fm fm-w7-s16 fw-bolder">£ {selectedcost}</span>
					</div>
					<div className="f-fm fm-w7-s18 pt-3">Select refund option</div>
					<div className="row pb-4">
						<div className="col">
							<label className="f-fm fm-w4-s18 color-AC">
								<input
									type="radio"
									name="favourite_food"
									value="25%"
									onChange={(e) => {
										setPercentageValue(e.target.value);
									}}></input>
								&nbsp;25%
							</label>
						</div>
						<div className="col">
							<label className="f-fm fm-w4-s18 color-AC">
								<input
									type="radio"
									name="favourite_food"
									value="50%"
									onChange={(e) => {
										setPercentageValue(e.target.value);
									}}></input>
								&nbsp;50%
							</label>
						</div>
						<div className="col">
							<label className="f-fm fm-w4-s18 color-AC">
								<input
									type="radio"
									name="favourite_food"
									value="75%"
									onChange={(e) => {
										setPercentageValue(e.target.value);
									}}></input>
								&nbsp;75%
							</label>
						</div>
						<div className="col">
							<label className="f-fm fm-w4-s18 color-AC">
								<input
									type="radio"
									name="favourite_food"
									value="100%"
									onChange={(e) => {
										setPercentageValue(e.target.value);
									}}></input>
								&nbsp;100%
							</label>
						</div>
					</div>

					{percentageValue !== "" && (
						<div
							className="f-fm fm-w4-s12 color-00"
							style={{ backgroundColor: "#F4F4F4", padding: "20px 20px" }}>
							You will offer a refund of{" "}
							<span style={{ fontWeight: "bold" }}>
								{percentageValue} of the treatment fee (£ {finalamount})
							</span>
							. Patient will be notified via email. The refund amount will be
							processed back to your patient's original payment method in 7-10
							business days.
						</div>
					)}

					<div
						className="col-9 f-fm fm-w4-s16 pt-4"
						style={{
							// cursor: "pointer",
							width: "100%",
							display: "flex",
							justifyContent: "end",
						}}>
						<button
							style={{
								width: "165px",
								height: "50px",
								borderRadius: "40px",
								backgroundColor: percentageValue === "" ? "grey" : "#000",
								color: "#fff",
								border: percentageValue === "" ? "none" : "1px solid #000000",
								cursor: "pointer",
							}}
							disabled={percentageValue === "" ? true : false}
							onClick={() => {
								sendRefund();
							}}>
							Confirm
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<div className="card scard" style={{ padding: "30px 30px" }}>
				<div
					className="card-body"
					style={{ color: "#ACACAC", padding: "5px", fontWeight: "bold" }}>
					BUSINESS TRANSACTIONS
				</div>
				<span>
					<hr style={{ margin: "0px 0px" }}></hr>
				</span>
				<div
					className="col-lg-2 col-md-3 col-sm-4"
					style={{ width: "143px", paddingTop: "20px", paddingBottom: "10px" }}
					ref={catmenu}>
					<DropDownContainer className="customdropmain">
						<DropDownHeader
							onClick={toggling}
							className="form-select form-select-lg mb-1 select-round-custom-dropdown-payments"
							style={{
								boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)",
								paddingTop: "10px",
							}}>
							{selectedOption && (
								<p
									className="f-fm fm-w4-s14 color-7"
									style={{ width: "inherit" }}>
									{selectedOption}
								</p>
							)}
							{!selectedOption && (
								<p className="f-fm fm-w4-s16 color-7">Sort By</p>
							)}
						</DropDownHeader>
						{isOpen && (
							<div
								style={{
									width: "223px",
									height: "283px",
									position: "absolute",
								}}>
								<DropDownListContainer className="customdropcontainer">
									<DropDownList className="customdroptwo">
										{options.map((k) => (
											<ListItem
												key={k}
												className="f-fm fm-w5-s14 color-7 customdroplistitemtwo"
												onClick={() => {
													onOptionClicked(k);
													sortByFilter(k);
												}}
												value={k}>
												{k}
											</ListItem>
										))}
									</DropDownList>
								</DropDownListContainer>
							</div>
						)}
					</DropDownContainer>
				</div>
				<div className="table-responsive">
					<table className="table table-borderless">
						<thead>
							<tr style={{ borderBottom: "hidden" }}>
								<th
									scope="col"
									style={{ color: "#ACACAC", paddingLeft: "0px" }}
									className="col-xl-2">
									TRANSACTION ID
								</th>
								<th
									scope="col"
									style={{ color: "#ACACAC" }}
									className="col-xl-2">
									Date
								</th>
								<th
									scope="col"
									style={{ color: "#ACACAC" }}
									className="col-xl-2">
									Patient Name
								</th>
								<th
									scope="col"
									style={{ color: "#ACACAC" }}
									className="col-xl-3">
									Treatment
								</th>
								<th
									scope="col"
									style={{ color: "#ACACAC", textAlign: "left" }}
									className="col-xl-3">
									Amount
								</th>
								<th
									scope="col"
									style={{ color: "#ACACAC", textAlign: "left" }}
									className="col-xl-2">
									Assigned doctor
								</th>
								<th
									scope="col"
									style={{
										color: "#ACACAC",
										textAlign: "left",
										whiteSpace: "nowrap",
									}}
									className="col-xl-2">
									Treatment Status
								</th>
								<th scope="col" className="col-xl-1"></th>
							</tr>
						</thead>
						<tbody>
							{filterList.map((item, key) => {
								return (
									<>
										<tr key={key} style={{ borderBottom: "hidden" }}>
											<td
												style={{ textAlign: "left" }}
												className="f-fm fm-w6-s14">
												{item.paymentintent}
											</td>
											<td
												style={{ textAlign: "left" }}
												className="f-fm fm-w6-s14">
												{item.date}
											</td>
											<td
												style={{ textAlign: "left" }}
												className="f-fm fm-w6-s14">
												{item.patientname}
											</td>
											<td className="f-fm fm-w6-s14">{item.array}</td>
											<td
												style={{ textAlign: "left" }}
												className="f-fm fm-w6-s14">
												£ {item.amount}
											</td>
											<td
												style={{ textAlign: "left" }}
												className="f-fm fm-w6-s14">
												{item.doctor}
											</td>
											<td
												style={{ textAlign: "left" }}
												className="f-fm fm-w6-s14">
												{item.cat}
											</td>

											<td style={{ textAlign: "center" }}>
												{item.isrefunded === false && (
													<OverlayTrigger
														trigger="click"
														rootClose
														placement="left-start"
														overlay={
															<Popover
																id="popover-basic"
																style={{
																	height: "60px",
																	width: "170px",
																	backgroundColor: "rgba(0, 0, 0, 0.8)",
																	borderRadius: "10px",
																}}>
																<Popover.Body style={{ padding: "0px 0px" }}>
																	<div
																		style={{
																			color: "rgba(255, 255, 255, 0.8)",
																			paddingTop: "20px",
																			paddingLeft: "20px",
																			cursor: "pointer",
																		}}
																		onClick={() => {
																			setShowCancel(true);
																			setSelectedcost(item.amount);
																			setSelectedpatientname(item.patientname);
																			setSelecteddateandtime(
																				item.date + " at " + item.starttime
																			);
																			setSelectedtreatments(item.array);
																			// setSelectedstripeaccount(
																			// 	item.stripeaccount
																			// );
																			setSelectedpaymentintent(
																				item.paymentintent
																			);
																			setSelectedtax(item.tax);
																			setSelectedpostpaymentintent(
																				item.postpaymentintent
																			);
																			setPatientchannelid(
																				item.patientchannelid
																			);
																			setDoctorchannelid(item.doctorchannelid);
																			setAppointmentid(item.appointmentid);
																			setPatientid(item.patientid);
																			setDoctorid(item.doctorid);
																		}}
																		className="f-fm fm-w4-s14">
																		Refund
																	</div>
																</Popover.Body>
															</Popover>
														}
														style={{ alignItems: "end" }}>
														<img
															loading="lazy"
															src="./images/Group210.png"
															alt="avatar"
															style={{ cursor: "pointer" }}></img>
													</OverlayTrigger>
												)}
												{item.isrefunded === true && (
													<p
														className="f-fm fm-w6-s12"
														style={{
															backgroundColor: "#f4f4f4",
															padding: "5px",
															borderRadius: "15px",
															whiteSpace: "nowrap",
														}}>
														Refunded (£ {item.refund})
													</p>
												)}
											</td>
										</tr>
									</>
								);
							})}
							{filterList.length === 0 && (
								<>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td
											style={{ textAlign: "right" }}
											className="f-fm fm-w6-s14">
											No Payments
										</td>
										<td></td>
										<td></td>
									</tr>
								</>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default Payments;
