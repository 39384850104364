import React from "react";

const Logo = (props) => {
	return (
		<>
			{!props && (
				<a
					className="navbar-brand pl-n20 jnav"
					href="/"
					style={{ paddingLeft: "50px" }}
				>
					<img
						className="jnav"
						loading="lazy"
						src="../images/Aes2ALPHA.png"
						style={{ width: "43px", height: "47px" }}
						alt="..."
					></img>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<label
						className="f-fm fm-w7-s28 color-FF jnav"
						style={{
							lineHeight: "35px",
							verticalAlign: "middle",
							cursor: "pointer",
						}}
					>
						Aesthetik
					</label>
				</a>
			)}
			{props.logo === "dark" && (
				<a
					className="navbar-brand d-flex align-items-center  px-lg-5 jnav "
					href="/"
				>
					<img
						className="jnav"
						loading="lazy"
						src="../images/AES6ALPHA.png"
						style={{ width: "43px", height: "47px" }}
						alt="..."
					></img>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<label
						className="f-fm fm-w7-s28 color-00 jnav "
						style={{
							lineHeight: "35px",
							verticalAlign: "middle",
							cursor: "pointer",
						}}
					>
						Aesthetik
					</label>
				</a>
			)}
			{props.logo === "dark1" && (
				<a
					className="navbar-brand d-flex align-items-center  px-lg-5 jnav "
					href="/"
				>
					<img
						className="jnav"
						loading="lazy"
						src="../images/AES6ALPHA.png"
						style={{ width: "25.57px", height: "28px" }}
						alt="..."
					></img>
					&nbsp;&nbsp;
					<label
						className="f-fm  color-00"
						style={{
							fontSize: "17.04px",
							fontWeight: "700",
							lineHeight: "21.39px",
							verticalAlign: "middle",
							cursor: "pointer",
						}}
					>
						Aesthetik
					</label>
				</a>
			)}
			{props.logo === "dark-light" && (
				<a
					className="navbar-brand d-flex align-items-center  px-lg-5 jnav "
					href="/"
				>
					<img
						className="jnav"
						loading="lazy"
						src="../images/AES6ALPHA.png"
						style={{ width: "43px", height: "47px" }}
						alt="..."
					></img>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<label
						className="f-fm fm-w7-s28 color-FF"
						style={{
							lineHeight: "35px",
							verticalAlign: "middle",
							cursor: "pointer",
						}}
					>
						Aesthetik
					</label>
				</a>
			)}
			{props.logo === "dark-light1" && (
				<a
					className="navbar-brand d-flex align-items-center dcenter  px-lg-5 jnav "
					href="/"
				>
					<img
						className="jnav"
						loading="lazy"
						src="../images/AES6ALPHA.png"
						style={{ width: "25.57px", height: "28px" }}
						alt="..."
					></img>
					&nbsp;&nbsp;
					<label
						className="f-fm color-FF"
						style={{
							fontSize: "17.04px",
							fontWeight: "700",
							lineHeight: "21.39px",
							verticalAlign: "middle",
							cursor: "pointer",
						}}
					>
						Aesthetik
					</label>
				</a>
			)}
		</>
	);
};

export default Logo;