import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../common/logo";
import * as utils from "../common/util";
import Popupforhome from "./popupforhome";
import { Card } from "react-bootstrap";
import { validEmailExp, validPasswordExp } from "../common/validations";
import { TextField } from "@material-ui/core";
import { sendContactusEmailNotification } from "../services/notificationservices";
import * as ContactusServices from "../services/contactusServices";
import "./website1920.css";
import { ToastContainer, toast } from "react-toastify";

const Contactus = () => {
	const [windowSize, setWindowSize] = useState(getWindowSize());
	const [istoastg, setIstoastg] = useState(false);

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}
	const firstupdate = useRef(true);
	const firststateupdate = useRef(true);
	const [isPageLoaded, setisPageLoaded] = useState(false);
	const navigate = useNavigate();
	const [show, setShow] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);
	const [hoverbar, setHoverBar] = useState(false);
	const [buttondisable, setButtondisable] = useState(false);

	var timer = null;
	const controlNavbar1 = (event) => {
		// console.log(event.target.className);
		if (
			(event.target.className.indexOf("jnav") > 0 && hoverbar) ||
			window.scrollY === 0
		) {
			setShow(true);
			setHoverBar(true);
			clearTimeout(timer);
			timer = null;
		}
	};
	const controlNavbar = () => {
		if (typeof window !== "undefined") {
			//console.log(window.scrollY, lastScrollY, hoverbar)
			if (window.scrollY === 0) {
				console.log("ell", hoverbar);
				clearTimeout(timer);
				timer = null;
				setHoverBar(false);
				setShow(true);
			} else if (window.scrollY > lastScrollY) {
				// if scroll down hide the navbar
				setShow(false);
			} else {
				// if scroll up show the navbar
				//console.log("else", window.scrollY, lastScrollY, hoverbar)
				setShow(true);
				if (hoverbar) {
					const timer = setTimeout(() => {
						setShow(true);
					}, 4000);
				} else {
					if (window.scrollY !== 0) {
						timer = setTimeout(() => {
							if (window.scrollY != 0) {
								setShow(false);
							}
						}, 4000);
					}
				}
			}

			// remember current page location to use in the next move
			setLastScrollY(window.scrollY);
		}
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", controlNavbar);
			//window.addEventListener('mouseover', controlNavbar1);
			window.document
				.getElementById("mynav")
				.addEventListener("mousemove", controlNavbar1);

			// cleanup function
			return () => {
				window.removeEventListener("scroll", controlNavbar);
				window.document
					.getElementById("mynav")
					.removeEventListener("mousemove", controlNavbar1);
			};
		}
	}, [lastScrollY]);

	useEffect(() => {
		if (firstupdate.current) {
			(async function anyNameFunction() {
				const timer = setTimeout(() => {
					setisPageLoaded(true);
					firstupdate.current = false;
					firststateupdate.current = false;
				}, 2000);
				return () => clearTimeout(timer);
			})();
		}
	}, []);

	const handleMouseEnter = () => {
		setHoverBar(true);
		setShow(true);
	};
	const handleMouseMove = () => {
		setHoverBar(true);
		setShow(true);
	};
	const handleMouseLeave = () => {};
	// const [firstname, setfirstname] = useState("");
	// const [lastname, setlastname] = useState("");
	// const [email, setemail] = useState("");
	// const [phone, setphone] = useState("");
	// const [textarea, settextarea] = useState("");
	const [state, setState] = useState({
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		textarea: "",
		validemail: false,
		pcolor: "#FFFFFF",
		ecolor: "#FFFFFF",
	});

	const getButtonClass = () => {
		let vtext =
			(state.firstname === "" ? "@#@" : state.firstname) +
			(state.lastname === "" ? "@#@" : state.lastname) +
			(state.email === "" ? "@#@" : state.email) +
			(state.phone === "" ? "@#@" : state.phone) +
			(state.textarea === "" ? "@#@" : state.textarea);

		let validf = state.validemail ? "true" : "@#@";
		vtext = vtext + validf;
		let rvalue = "aebuttongrey notallowed";

		if (vtext.indexOf("@#@") >= 0 && buttondisable === false) {
			rvalue = "aebuttongrey notallowed";
		} else {
			rvalue = "aebuttonwhite";
		}
		return rvalue;
	};

	const handlefirstnameChange = (event) => {
		setButtondisable(false);
		let ecolor = "#FFFFFF";
		setState({ ...state, [event.target.name]: event.target.value });
		setState((prevState) => ({
			firstname: prevState.firstname,
			lastname: prevState.lastname,
			email: prevState.email,
			phone: prevState.phone,
			textarea: prevState.textarea,
			validemail: prevState.validemail,
			pcolor: prevState.pcolor,
			ecolor: ecolor,
			ptype: prevState.ptype,
		}));
		console.log("2");
		// console.log(state);
	};

	const handlelastnameChange = (event) => {
		setButtondisable(false);
		let ecolor = "#FFFFFF";
		setState({ ...state, [event.target.name]: event.target.value });
		setState((prevState) => ({
			firstname: prevState.firstname,
			lastname: prevState.lastname,
			email: prevState.email,
			phone: prevState.phone,
			textarea: prevState.textarea,
			validemail: prevState.validemail,
			pcolor: prevState.pcolor,
			ecolor: ecolor,
			ptype: prevState.ptype,
		}));
		console.log("2");
		// console.log(state);
	};
	const handlephoneChange = (event) => {
		setButtondisable(false);
		let ecolor = "#FFFFFF";
		setState({ ...state, [event.target.name]: event.target.value });
		setState((prevState) => ({
			firstname: prevState.firstname,
			lastname: prevState.lastname,
			email: prevState.email,
			phone: prevState.phone,
			textarea: prevState.textarea,
			validemail: prevState.validemail,
			pcolor: prevState.pcolor,
			ecolor: ecolor,
			ptype: prevState.ptype,
		}));
		console.log("2");
		console.log(state);
	};
	const handletextareaChange = (event) => {
		setButtondisable(false);
		let ecolor = "#FFFFFF";
		setState({ ...state, [event.target.name]: event.target.value });
		setState((prevState) => ({
			firstname: prevState.firstname,
			lastname: prevState.lastname,
			email: prevState.email,
			phone: prevState.phone,
			textarea: prevState.textarea,
			validemail: prevState.validemail,
			pcolor: prevState.pcolor,
			ecolor: ecolor,
			ptype: prevState.ptype,
		}));
		console.log("2");
		console.log(state);
	};

	const handleEmailChange = (event) => {
		setButtondisable(false);
		let ecolor = "#FFFFFF";

		setState({ ...state, [event.target.name]: event.target.value });

		let validemail = validEmailExp.test(event.target.value);
		state.validemail = validemail;

		console.log("valid email", validemail, event.target.value);
		if (validemail) {
			ecolor = "#FFFFFF";
		} else {
			ecolor = "red";
		}

		setState((prevState) => ({
			firstname: prevState.firstname,
			lastname: prevState.lastname,
			email: prevState.email,
			phone: prevState.phone,
			textarea: prevState.textarea,
			validemail: validemail,
			pcolor: prevState.pcolor,
			ecolor: ecolor,
			ptype: prevState.ptype,
		}));

		console.log("2");
		console.log(state);
	};

	const submitus = () => {
		if (buttondisable === false) {
			console.log("ff");
			setIstoastg(true);
			setButtondisable(true);
			(async function anyNameFunction() {
				setState((prevState) => ({
					firstname: prevState.firstname,
					lastname: prevState.lastname,
					email: prevState.email,
					phone: prevState.phone,
					textarea: prevState.textarea,
					validemail: prevState.validemail,
					pcolor: prevState.pcolor,
					ecolor: prevState.ecolor,
					ptype: prevState.ptype,
				}));
				const variables = ContactusServices.returnCreateVariables({
					...state,
					textarea: state.textarea.replaceAll(/\n/g, "\\n"),
				});
				console.log(variables);
				// setErrMsg("");
				const tmpUser = await ContactusServices.CreateContactus(variables);
				console.log(tmpUser);
				if (tmpUser.contactus.id !== null) {
					sendContactusEmailNotification(
						state.firstname + " " + state.lastname,
						state.email.toLowerCase(),
						state.textarea
					);
					toast.success("Mail sent Succesfully!!!", {
						toastId: "calender",
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					window.scrollTo(0, 0);
					setTimeout(() => {
						setIstoastg(false);
						window.location.reload();
					}, 3000);
				}
			})();
		}
	};

	return (
		<div className="body-bg-home ccolor">
			<center>
				<div className="faq">
					<nav
						className=" navbar container-fluid fixed-topmobile  navbar-expand-lg navbar-dark bg-lightmobile navh"
						style={{ height: "70px", backgroundColor: "#000000" }}>
						<div className="container-fluid">
							<Logo logo="dark-light1"></Logo>
							<div>
								<Popupforhome></Popupforhome>
							</div>
						</div>
					</nav>
				</div>
				<nav
					id="mynav"
					onMouseMove={handleMouseMove}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					className={`navbar  dvall1 container-fluid   fixed-top  navbar-expand-lg navbar-dark bg-light bg-light1  ${
						!show && "hidden"
					}`}>
					<div className="container-fluid jnav navh">
						{/* <div className="popupnav">  */}
						<Logo logo="dark-light" className="jnav"></Logo>
						<div className="tabletnav">
							<Popupforhome></Popupforhome>
						</div>

						<div
							className="navbar-collapse collapse jnav"
							style={{ whiteSpace: "nowrap", width: "100%", height: "100px" }}
							id="navbarCollapse">
							<div className="navbar-nav p-4 pr-r20 p-lg-0 jnav" id="navbarnav">
								<a
									className="nav-link1 f-rl fm-w5-s20 jnav"
									href="/everyone"
									style={{ color: "#FFFFFF" }}>
									For Everyone
									<div
										className="jnav"
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
											width: "123px",
										}}></div>
								</a>
								<a
									className="nav-link1  f-rl fm-w5-s20 jnav"
									href="/business"
									style={{ color: "#FFFFFF" }}>
									For business
									<div
										className="jnav"
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
										}}></div>
								</a>
								<a
									className="nav-link1  f-rl fm-w5-s20 jnav"
									href="/login"
									style={{ color: "#FFFFFF" }}>
									Login
									<div
										className="jnav"
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
										}}></div>
								</a>
							</div>

							<div className="d-flex burger jnav">
								<button
									className="btn-rounded-black jnav"
									style={{
										border: "1px solid #fff",
									}}
									onClick={() => navigate("/signup/social")}>
									Business Sign Up
								</button>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<a href="../downloads/app-debug.apk" download className="jnav">
									<button className="btn-rounded-white jnav">
										{" "}
										Download the App
									</button>
								</a>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						</div>
					</div>
				</nav>
				<div className="container-fluid">
					<div className="row no-gutters ">
						<div
							className="col "
							style={{
								backgroundColor: "#1F1F1F",
								backgroundImage: "url('../images/Rectangle224.png')",
								backgroundRepeat: "no-repeat",
								//   width:"1000px",
								// height: "1450px",
							}}>
							<div
								className="row mobilefaq mainsection"
								style={{ paddingTop: "130px", paddingBottom: "85px" }}>
								<div className="col ">
									<div className="row">
										<div className="fm-w3-s50 color-FF  dcenter fm-w3-s501 ">
											Chat To Our Team
										</div>
									</div>
									<div className="row ">
										<div
											className="fm-w3-s16 color-FF fffaq1"
											style={{ textAlign: "center" }}>
											Need help with something? Get in touch with our team.
											<br />
											and we will get back to you ASAP.
										</div>
									</div>
									<div className="dcenter ptcontact">
										<div
											className="maincardw"
											style={{
												backgroundColor: "transporent",
												border: "0.5px dashed #FFF",
												borderRadius: "20px",
											}}>
											<div className="paddingbody" style={{}}>
												<div className="row">
													<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12 ">
														{/* <div className="contactusfont">FirstName</div> */}
														{/* <input
															type="text"
															className="contactusinput fieldw"
															name="firstname"
															id="textcolor"
															value={state.firstname}
															onChange={handlefirstnameChange}
														></input> */}
														<TextField
															id="standard-basic"
															label="First Name"
															variant="standard"
															className="contactusinput fieldw"
															name="firstname"
															value={state.firstname}
															onChange={handlefirstnameChange}
														/>
													</div>
													<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12 ptcontact11">
														{/* <div className="contactusfont ptcontact11">
															Lastname
														</div> */}
														{/* <input
															type="text"
															className="contactusinput fieldw"
															id="textcolor"
															name="lastname"
															value={state.lastname}
															onChange={handlelastnameChange}
														></input> */}
														<TextField
															className="contactusinput fieldw "
															id="standard-basic"
															label="Last Name"
															variant="standard"
															name="lastname"
															value={state.lastname}
															onChange={handlelastnameChange}
														/>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12 ptcontact1">
														{/* <div className="contactusfont ptcontact1">
															Email Address
														</div> */}
														<TextField
															id="standard-basic"
															label="Email Address"
															variant="standard"
															className="contactusinput fieldw "
															name="email"
															value={state.email}
															onChange={handleEmailChange}
														/>
														{/* <input
															type="text"
															className="contactusinput fieldw"
															id="textcolor"
															name="email"
															value={state.email}
															onChange={handleEmailChange}
														></input> */}
													</div>
													<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12 ownarrow ptcontact1">
														{/* <div className="contactusfont ptcontact1">
															Phone
														</div> */}
														{/* <input
															type="number"
															id="textcolor"
															name="phone"
															className="contactusinput fieldw"
															value={state.phone}
															onChange={handlephoneChange}
														></input> */}
														<TextField
															id="standard-basic"
															label="Phone"
															variant="standard"
															name="phone"
															className="contactusinput fieldw "
															value={state.phone}
															onChange={handlephoneChange}
														/>
													</div>
												</div>
												{/* <div className="row">
													<div className="col">
														<div className="contactusfont ptcontact1">
															How can we help?
														</div>
													</div>
												</div> */}
												<div className="row">
													<div className="col-12 ptcontact1">
														{/* <textarea
															id="textcolor"
															className="contactusinput httxtarea"
															name="textarea"
															value={state.textarea}
															onChange={handletextareaChange}
															style={{ width: "100%" }}
															rows={3}
														></textarea> */}
														{/* <TextField
															id="outlined-multiline-flexible"
															label="Multiline"
															multiline
															maxRows={4}
														/>
														<TextField
															id="outlined-textarea"
															label="Multiline Placeholder"
															placeholder="Placeholder"
															multiline
														/> */}
														<TextField
															id="outlined-multiline-static"
															label="How can we help?"
															multiline
															minRows={3}
															name="textarea"
															value={state.textarea}
															onChange={handletextareaChange}
															style={{ width: "100%" }}
															// defaultValue="Default Value"
														/>
													</div>
												</div>
												<div className="row ptcontact">
													<div
														className="fm-w55-s122 color-FF "
														style={{ textAlign: "center" }}>
														By clicking the sign up button you agree to
														our&nbsp;
														<span
															style={{
																textDecoration: "underline",
																color: "var(--brown-1, #AF805E)",
															}}>
															Terms of Use &nbsp;
														</span>
														and&nbsp;
														<span
															style={{
																textDecoration: "underline",
																color: "var(--brown-1, #AF805E)",
															}}>
															Privacy Policy
														</span>
													</div>
												</div>
												<div className="row ptcontact">
													<div className="col">
														{/* <button
                              className="faqbutton btntext"
                              style={{ border: "none" }}
                              onClick={submitus}
                            >
                              Submit
                            </button> */}
														<utils.aeButton
															classNames="aebutton1 faqbutton btntext"
															text="Submit"
															enabled="false"
															disabledClass={getButtonClass()}
															onClick={submitus}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row ">
					<div
						className="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12 col-12"
						style={{
							backgroundColor: "#b58a6c",
							display: "flex",
							flexDirection: "Column",
							justifyContent: "flex-end",
							alignItems: "center",
						}}>
						<div
							className="txtcontact  wsimgs  pt767 dcenter"
							style={{
								// marginTop: "150px",
								marginBottom: "34px",
								// lineHeight: "60.73px",
								fontWeight: "500px",
							}}>
							START A TREATMENT AT YOUR
							<br />
							PLACE RIGHT NOW!
						</div>
						<div
							className="roundWhiteDownload1920 roundWhiteDownloadcontact mbutton1920 roundWhiteDownloadmobilecontact homechangebtncontact  homechangebtnincontact"
							style={{
								marginTop: "20px",
							}}>
							Download the App&nbsp;&nbsp;&nbsp;&nbsp;
							<img
								className=" homechangebtnarrow"
								loading="lazy"
								alt=""
								src="../images/Arrowb4.png"
								style={{ width: "38.11px", height: "12px" }}></img>
						</div>

						<div className="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-12 dcenter ">
							<img
								className="whmobile wh992 imgw"
								loading="lazy"
								alt=""
								src="../images/iPhone13.png"
								style={{
									width: "100%",
								}}></img>
						</div>
					</div>

					<div
						className="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 col-12 "
						style={{ padding: "0px", backgroundColor: "#b58a6c" }}>
						<div className="">
							<img
								className="whimg1 "
								src="../images/Group 602.png"
								style={{ float: "right", width: "100%" }}
								alt=""
							/>
						</div>
					</div>
				</div>
				<div className="view1920" style={{ backgroundColor: "#000000" }}>
					{" "}
					{utils.Footer1920()}
				</div>
				<div className="view992" style={{ backgroundColor: "#000000" }}>
					{" "}
					{utils.Footer1()}
				</div>
				<div
					className="view19201 view9921"
					style={{ backgroundColor: "#000000" }}>
					{" "}
					{utils.Footer()}
				</div>
			</center>
			{istoastg && (
				<ToastContainer
					toastId="general"
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
				/>
			)}
		</div>
	);
};
export default Contactus;
