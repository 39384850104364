import React from "react";

const Groupimage = () => {
	return (
		// <div style={{}}>
		//   <div className="row">
		//     <div className="col" style={{paddingBottom:"18.6px",paddingLeft:"20px",paddingRight:"20px"}}>
		//       <img
		//         src="../images/groupimg1.png"
		//         style={{ width: "100%" }}
		//         alt=""
		//       />
		//     </div>
		//   </div>
		//   <div className="row">
		//     <div className="col" style={{paddingBottom:"9.6px",paddingLeft:"20px",paddingRight:"20px"}}>
		//       <img
		//         src="../images/groupimg2.png"
		//         style={{ width: "100%" }}
		//         alt=""
		//       />
		//     </div>
		//   </div>
		//   {/* <div className="row">
		//     <div className="col" style={{paddingBottom:"120px"}}>
		//       <img
		//         src="../images/groupimg3.png"
		//         style={{ width: "100%" }}
		//         alt=""
		//       />
		//     </div>
		//   </div> */}
		// </div>
		<div
			className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 col-12 view19201"
			style={{
				// marginBottom: "100px",
				display: "flex",
				justifyContent: "space-between",
				flexDirection: "row",
			}}
		>
			<div className="row justify-content-center">
				<img
					className="p-2 col-6"
					loading="lazy"
					alt=""
					src="../images/Group577.png"
					// style={{ width: "300px", height: "300px" }}
				></img>

				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group591.png"
				></img>

				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group578.png"
				></img>

				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group579.png"
				></img>

				<img
					className="p-2 col-6"
					loading="lazy"
					alt=""
					src="../images/Group580.png"
					// style={{ width: "300px", height: "300px" }}
				></img>

				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group581.png"
				></img>

				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group582.png"
				></img>

				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group583.png"
				></img>
				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group590.png"
				></img>
				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group584.png"
				></img>
				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group585.png"
				></img>
				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group586.png"
				></img>
				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group587.png"
				></img>
				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group588.png"
				></img>
				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/Group589.png"
				></img>
				<img
					className="p-2 col-6"
					// style={{ width: "300px", height: "300px" }}
					loading="lazy"
					alt=""
					src="../images/imagelastm.png"
				></img>
			</div>
		</div>
	);
};
export default Groupimage;
