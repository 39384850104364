import React, { useState, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import * as UserServices from "../../services/user";
import Addmember from "./addmumberpop";
import { sendInvitationEmailNotification } from "../../services/notificationservices";
import { ToastContainer, toast } from "react-toastify";

const ManageTeam = (props) => {
	console.log("manage team props", props);
	const [arr, setArr] = useState([]);
	const [istoastc, setIstoastc] = useState(false);
	var today = new Date();

	useEffect(() => {
		setArr(props.team);
		console.log(props.team);
	}, [props.team]);

	const deleteTreatmentHandler = (id) => {
		UserServices.DeleteUser(id).then(function (result) {
			console.log(result);
			if (result.data.deleteUser.id) {
				props.update(true);
			}
		});
	};

	const resendInvitation = (id, email, name) => {
		setIstoastc(true);
		console.log("id", id, email, name);
		sendInvitationEmailNotification(
			id,
			props.params.params.firstName + " " + props.params.params.lastName,
			email,
			window.location.origin + "/inviteaccept/",
			props.params.params.business[0].name,
			props.params.params.firstName + props.params.params.lastName,
			name
		);

		toast.success("Invitation resent succesfully", {
			toastId: "calender",
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		window.scrollTo(0, 0);
		setTimeout(() => {
			setIstoastc(false);
		}, 3000);
	};

	return (
		<div className="roundedWhite-80p">
			<div style={{ padding: "30px" }}>
				<div className="col-12">
					<div className="row">
						<div
							className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6"
							style={{ paddingTop: "4vh", paddingBottom: "4vh" }}
						>
							<label
								className="f-fm fm-w6-s20"
								style={{ cursor: "pointer" }}
								onClick={props.hideManagTeam}
							>
								&lt;&nbsp;&nbsp;Manage team
							</label>
						</div>
						<div
							className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6 rightremove"
							style={{
								justifycontent: "end",
								paddingTop: "2vh",
							}}
						>
							<Addmember
								customerid={props.params.params.customerid}
								stripeaccount={props.params.params.stripeaccount}
								clinicname={props.params.params.clinicname}
								business={props.params.params.business[0]}
								name={
									props.params.params.firstName +
									" " +
									props.params.params.lastName
								}
								adminid={props.params.params.id}
							/>
						</div>
					</div>
					<div className="row">
						<div className="table-responsive">
							<table className="table table-responsive">
								<thead>
									<tr>
										<th className="col-1" style={{ color: "#ACACAC" }}>
											{" "}
										</th>
										<th className="col-5" style={{ color: "#ACACAC" }}>
											{" "}
											NAME
										</th>
										<th className="col-5" style={{ color: "#ACACAC" }}>
											{" "}
											TEAM PERMISSION
										</th>
										<th
											className="col-3"
											style={{ textAlign: "center", color: "#ACACAC" }}
										>
											MORE
										</th>
									</tr>
								</thead>
								<tbody>
									{arr.map((key) => {
										return (
											<tr style={{ borderBottom: "hidden" }} key={key.email}>
												<td style={{ textAlign: "end" }}>
													<img
														onError={(e) => {
															e.target.src = "../images/usernew.png";
														}}
														src={
															key.avatar +
															"?" +
															today.getHours() +
															today.getMinutes() +
															today.getSeconds()
														}
														style={{
															width: "56px",
															height: "56px",
															borderRadius: "50%",
														}}
														alt="avatar"
													></img>
												</td>
												<td className="f-fm fm-w6-s20">
													{key.firstName}
													{key.notifyme === true &&
														key.complete !== "complete" && (
															// <div
															// 	style={{
															// 		width: "69px",
															// 		height: "21px",
															// 		background: "#ACACAC",
															// 		borderRadius: "10.50px",
															// 	}}
															// >
															// 	<div
															// 		style={{
															// 			color: "white",
															// 			fontSize: 14,
															// 			fontFamily: "Mulish",
															// 			fontWeight: "500",
															// 			lineHeight: 21,
															// 			wordWrap: "break-word",
															// 		}}
															// 	>
															// 		Pending
															// 	</div>
															// </div>
															<span
																style={{
																	marginLeft: "14px",
																	display: "inline-block",
																	textAlign: "center",
																	width: "69px",
																	height: "21px",
																	borderRadius: "10.5px",
																	background: "#ACACAC",
																	color: "#FFF",
																	fontFamily: "Mulish",
																	fontSize: "14px",
																	fontStyle: "normal",
																	fontWeight: "500",
																	lineHeight: "150%",
																}}
															>
																Pending
															</span>
														)}
													<br></br>
													<span className="f-fm fm-w5-s14">{key.email}</span>
												</td>
												<td className="fm-w6-s16">
													{key.isadmin === true && key.notifyme === false
														? "Admin user"
														: "Member"}
												</td>
												<td style={{ textAlign: "center" }}>
													<OverlayTrigger
														trigger="click"
														rootClose
														placement="left-start"
														overlay={
															<Popover
																id="popover-basic"
																style={{
																	height: "auto",
																	width: "150px",
																	backgroundColor: "#fff",
																	borderRadius: "10px",
																}}
															>
																<Popover.Body
																	style={{
																		padding: "0px",
																		paddingTop: "13px",
																	}}
																>
																	<div
																		style={{
																			color: "#777777",
																			cursor: "pointer",
																			background: "#F4F4F4",
																			width: "100%",
																			height: "32px",
																			paddingTop: "5px",
																			paddingLeft: "10px",
																		}}
																		onClick={() => {
																			// deleteTreatmentHandler(key.id);
																		}}
																		className="f-fm fm-w5-s14"
																	>
																		Remove
																	</div>
																	{key.notifyme === true &&
																		key.complete !== "complete" && (
																			<div
																				style={{
																					color: "#777777",
																					cursor: "pointer",
																					background: "#F4F4F4",
																					width: "100%",
																					height: "32px",
																					paddingTop: "5px",
																					paddingLeft: "10px",
																				}}
																				onClick={() => {
																					// deleteTreatmentHandler(key.id);
																					resendInvitation(
																						key.id,
																						key.email,
																						key.firstName
																					);
																				}}
																				className="f-fm fm-w5-s14"
																			>
																				Resend Invitation
																			</div>
																		)}
																</Popover.Body>
															</Popover>
														}
														style={{ alignItems: "end" }}
													>
														<img
															src="./images/Group210.png"
															alt="avatar"
															style={{ cursor: "pointer" }}
														></img>
													</OverlayTrigger>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			{istoastc && (
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
				/>
			)}
		</div>
	);
};
export default ManageTeam;
