import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
// import { Animated } from "react-animated-css";
import Logo from "../common/logo";
import * as utils from "../common/util";
import Homemobile from "./homemobile";

import Popupforhome from "./popupforhome";
import "./website1920.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Home = () => {
	const responsive = {
		// desktop1: {
		// 	breakpoint: { max: 1980, min: 1850 },
		// 	items: 6,
		// 	// slidesToSlide: 1, // optional, default to 1.
		// 	partialVisibilityGutter: 40,
		// },
		// desktop: {
		// 	breakpoint: { max: 1850, min: 1580 },
		// 	items: 5,
		// 	// slidesToSlide: 1, // optional, default to 1.
		// 	partialVisibilityGutter: 40,
		// },
		// tablet: {
		// 	breakpoint: { max: 1579, min: 1300 },
		// 	items: 4,
		// 	// slidesToSlide: 1, // optional, default to 001.
		// 	partialVisibilityGutter: 30,
		// },
		// tablet1: {
		// 	breakpoint: { max: 1300, min: 860 },
		// 	items: 3,
		// 	// slidesToSlide: 1, // optional, default to 1.
		// 	partialVisibilityGutter: 30,
		// },
		// mobile: {
		// 	breakpoint: { max: 860, min: 0 },
		// 	items: 2,
		// 	// slidesToSlide: 1, // optional, default to 1.
		// 	partialVisibilityGutter: 30,
		// },
		desktop: {
			breakpoint: {
				max: 3000,
				min: 1400,
			},
			items: 5,
			partialVisibilityGutter: 40,
		},
		desktop1: {
			breakpoint: {
				max: 1400,
				min: 1024,
			},
			items: 4,
			partialVisibilityGutter: 40,
		},
		mobile: {
			breakpoint: {
				max: 767,
				min: 0,
			},
			items: 2,
			partialVisibilityGutter: 30,
		},
		tablet: {
			breakpoint: {
				max: 1024,
				min: 767,
			},
			items: 3,
			partialVisibilityGutter: 30,
		},
	};
	const [windowSize, setWindowSize] = useState(getWindowSize());
	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);
	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}
	const firstupdate = useRef(true);
	const firststateupdate = useRef(true);
	const [isPageLoaded, setisPageLoaded] = useState(false);
	const navigate = useNavigate();
	const [show, setShow] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);
	const [hoverbar, setHoverBar] = useState(false);
	var timer = null;
	const controlNavbar1 = (event) => {
		console.log(event.target.className);
		if (
			(event.target.className.indexOf("jnav") > 0 && hoverbar) ||
			window.scrollY === 0
		) {
			setShow(true);
			setHoverBar(true);
			clearTimeout(timer);
			timer = null;
		}
	};
	const controlNavbar = () => {
		if (typeof window !== "undefined") {
			//console.log(window.scrollY, lastScrollY, hoverbar)
			if (window.scrollY === 0) {
				console.log("ell", hoverbar);
				clearTimeout(timer);
				timer = null;
				setHoverBar(false);
				setShow(true);
			} else if (window.scrollY > lastScrollY) {
				// if scroll down hide the navbar
				setShow(false);
			} else {
				// if scroll up show the navbar
				//console.log("else", window.scrollY, lastScrollY, hoverbar)
				setShow(true);
				if (hoverbar) {
					const timer = setTimeout(() => {
						setShow(true);
					}, 4000);
				} else {
					if (window.scrollY !== 0) {
						timer = setTimeout(() => {
							if (window.scrollY != 0) {
								setShow(false);
							}
						}, 4000);
					}
				}
			}

			// remember current page location to use in the next move
			setLastScrollY(window.scrollY);
		}
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", controlNavbar);
			//window.addEventListener('mouseover', controlNavbar1);
			window.document
				.getElementById("mynav")
				.addEventListener("mousemove", controlNavbar1);

			// cleanup function
			return () => {
				window.removeEventListener("scroll", controlNavbar);
				console.log("mmm", window.document.getElementById("mynav"));
				if (window.document.getElementById("mynav")) {
					window.document
						.getElementById("mynav")
						.removeEventListener("mousemove", controlNavbar1);
				}
			};
		}
	}, [lastScrollY]);

	useEffect(() => {
		if (firstupdate.current) {
			(async function anyNameFunction() {
				const timer = setTimeout(() => {
					setisPageLoaded(true);
					firstupdate.current = false;
					firststateupdate.current = false;
				}, 2000);
				return () => clearTimeout(timer);
			})();
		}
	}, []);

	const handleMouseEnter = () => {
		setHoverBar(true);
		setShow(true);
	};
	const handleMouseMove = () => {
		setHoverBar(true);
		setShow(true);
	};
	const handleMouseLeave = () => {};

	return (
		<>
			<div className="body-bg-home ccolor">
				<center>
					{/* <nav className=" dvall1 navbar container-fluid fixed-top  navbar-expand-lg navbar-dark bg-light">
						<div className="container-fluid" style={{ height: "100px" }}>

							<Logo logo="dark-light"></Logo>

							<button
								type="button"
								className="navbar-toggler mr-sm rr collapsed"
								data-bs-toggle="collapse"
								data-bs-target="#navbarCollapse"
								aria-expanded="false"
							>
								<span className="navbar-toggler-icon"></span>
							</button>

							<div
								className="navbar-collapse collapse"
								style={{ whiteSpace: "nowrap", width: "100%", height: "101px" }}
								id="navbarCollapse"
							>
								<div className="navbar-nav p-4 pr-r20 p-lg-0">
									<a
										className="nav-link1 f-rl fm-w5-s20"
										href="/everyone"
										style={{ color: "#FFFFFF" }}
									>
										For Everyone
										<div
											style={{
												backgroundColor: "transparent",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
												width: "123px",
											}}
										></div>
									</a>
									<a
										className="nav-link1  f-rl fm-w5-s20"
										href="/business"
										style={{ color: "#FFFFFF" }}
									>
										For business
										<div
											style={{
												backgroundColor: "transparent",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
											}}
										></div>
									</a>
									<a
										className="nav-link1  f-rl fm-w5-s20"
										href="/login"
										style={{ color: "#FFFFFF" }}
									>
										Login
										<div
											style={{
												backgroundColor: "transparent",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
											}}
										></div>
									</a>
								</div>
								<div className="d-flex burger">
									<button
										className="btn-rounded-black"
										onClick={() => navigate("/signup/social")}
									>
										Business Sign Up
									</button>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<a href="../downloads/app-debug.apk" download>
										<button className="btn-rounded-white">
											{" "}
											Download the App
										</button>
									</a>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								</div>
							</div>
						</div>
					</nav> */}

					<nav
						id="mynav"
						onMouseMove={handleMouseMove}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						className={`navbar  dvall1 container-fluid   fixed-top  navbar-expand-lg navbar-dark bg-light bg-light1  ${
							!show && "hidden"
						}`}
					>
						<div className="container-fluid jnav navh">
							{/* <div className="popupnav">  */}
							<Logo logo="dark-light" className="jnav"></Logo>
							{/* </div> */}
							{/* <div  className="groupimage380"> <Logo logo="dark1"></Logo></div> */}
							{/* <div className="popup"> */}
							{/* <button
								type="button"
								className="navbar-toggler mr-sm  collapsed "
								data-bs-toggle="collapse"
								data-bs-target="#navbarCollapse"
								aria-expanded="false"
							>
								<img
									className=""
									loading="lazy"
									alt=""
									src="../images/Frame 17.png"
									style={{ width: "30px", height: "30px" }}
								></img>
							</button> */}
							<div className="tabletnav">
								<Popupforhome></Popupforhome>
							</div>
							{/* </div> */}
							{/* <div className="groupimage380">
              <Popupforbusiness></Popupforbusiness>
            </div> */}
							<div
								className="navbar-collapse collapse jnav"
								style={{ whiteSpace: "nowrap", width: "100%", height: "100px" }}
								id="navbarCollapse"
							>
								{/* <div className="navbar-nav p-4 p-lg-0 pr-r20 jnav" id="navbarnav">
									<a
										className="nav-link1 f-rl fm-w7-s20 jnav"
										href="/everyone"
										style={{
											color: "#000000",
										}}
									>
										For Everyone
										<div className="jnav"
											style={{
												backgroundColor: "transparent",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
											}}
										></div>
									</a>
									<a
										className="nav-link1 f-rl fm-w5-s20 jnav"
										href="/business"
										style={{
											color: "#AF805E",
										}}
									>
										For business
										<div className="jnav"
											style={{
												backgroundColor: "#B58A6C",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
												width: "123px",
											}}
										></div>
									</a>
									<a
										className="nav-link1 f-rl fm-w5-s20 jnav"
										href="/login"
										style={{
											color: "#000000",
										}}
									>
										Login
										<div className="jnav"
											style={{
												backgroundColor: "transparent",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
											}}
										></div>
									</a>
								</div> */}

								<div
									className="navbar-nav p-4 pr-r20 p-lg-0 jnav"
									id="navbarnav"
								>
									<a
										className="nav-link1 f-rl fm-w5-s20 jnav"
										href="/everyone"
										style={{ color: "#FFFFFF" }}
									>
										For Everyone
										<div
											className="jnav"
											style={{
												backgroundColor: "transparent",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
												width: "123px",
											}}
										></div>
									</a>
									<a
										className="nav-link1  f-rl fm-w5-s20 jnav"
										href="/business"
										style={{ color: "#FFFFFF" }}
									>
										For business
										<div
											className="jnav"
											style={{
												backgroundColor: "transparent",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
											}}
										></div>
									</a>
									<a
										className="nav-link1  f-rl fm-w5-s20 jnav"
										href="/login"
										style={{ color: "#FFFFFF" }}
									>
										Login
										<div
											className="jnav"
											style={{
												backgroundColor: "transparent",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
											}}
										></div>
									</a>
								</div>

								<div className="d-flex burger jnav">
									<button
										className="btn-rounded-black jnav"
										style={{
											border: "1px solid #fff",
										}}
										onClick={() => navigate("/signup/social")}
									>
										Business Sign Up
									</button>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<a
										href="../downloads/app-debug.apk"
										download
										className="jnav"
									>
										<button
											className="btn-rounded-white jnav"
											style={{
												border: "1px solid #000",
											}}
										>
											{" "}
											Download the App
										</button>
									</a>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								</div>
							</div>
						</div>
					</nav>
				</center>
				<div className="groupimage380">
					<Homemobile />
				</div>
				<div className="container-fluid dvall1">
					<div className="row no-gutters bgimgsize">
						<div
							className="col  "
							style={{
								backgroundImage: "url('../images/Background.png')",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
							}}
						>
							<div className="row" style={{}}>
								<div
									className="col-xl-12 col-lg-8 col-md-6 col-xs-1 col-sm-1 d-flex justify-content-start"
									style={{ textAlign: "left" }}
								>
									<div
										className="f-rl  fm-w2-s68 color-FF f-s-w pb332 fstablet plhome"
										style={{
											paddingLeft: "160px",
											lineHeight: "82.18px",
											paddingTop: "250px",
											paddingBottom: "165px",
										}}
									>
										MEET THE BEST
										<div className="">COSMETIC TREATMENTS</div>
										<div
											className=""
											style={{ fontSize: "88", fontWeight: "600" }}
										>
											AT HOME
										</div>
										<div
											className="roundWhiteDownload1920 roundWhiteDownload mbutton1920 mt61 roundWhiteDownloadmobile homechangebtn homechangebtnin"
											style={{
												marginTop: "20px",
											}}
										>
											Download the App&nbsp;&nbsp;&nbsp;&nbsp;
											<img
												className="homechangebtnarrow"
												loading="lazy"
												alt=""
												src="../images/Arrowb4.png"
												style={{ width: "38.11px", height: "12px" }}
											></img>
										</div>
									</div>
								</div>
							</div>

							<div
								className="row  ptpf ptpb150 viewforhome pt1200 pt1400"
								style={{
									paddingTop: "72px",
									//   marginBottom: "277px",
									paddingLeft: "80px",
									paddingBottom: "50px",
								}}
							>
								<div className="col-xl-8 col-lg-10 col-md-12 col-xs-8 col-sm-12">
									<div className="row color-FF">
										<div className="col-xl col-lg col-md col-xs col-sm">
											<div className="row">
												<div className="col">
													<img
														className="h40w40 homeiconswh"
														loading="lazy"
														src="../images/onlinechat.png"
														alt="logo"
														style={{ width: "60px", height: "61px" }}
													></img>
												</div>
											</div>
											<div className="row pt-4 pt1920">
												<div
													className="col fm-w60-s22 smallimg s18 homeiconfs"
													style={{ lineHeight: "23.48px" }}
												>
													FREE CONSULTATION
												</div>
											</div>
											<div className="row pt-3 pt1920">
												<div
													className="col-xl-11 fm-w4-s20 smallimg1 pt1920 s16 homeiconfs1"
													style={{ lineHeight: "22.59px" }}
												>
													Talk face-to-face with your doctor online to discuss
													your unique style
												</div>
											</div>
										</div>
										{/* <div class="col-xl-1"></div> */}
										<div className="col-xl col-lg col-md col-xs col-sm homeml">
											<div className="row paddingtop">
												<div className="col">
													<img
														className="h40w40 homeiconswh"
														loading="lazy"
														src="../images/booking.png"
														alt="logo"
														style={{ width: "53px", height: "60px" }}
													></img>
												</div>
											</div>
											<div className="row pt-4 pt1920">
												<div
													className="col fm-w60-s22 smallimg s18 homeiconfs"
													style={{ lineHeight: "23.48px" }}
												>
													INSTANT BOOKING
												</div>
											</div>
											<div className="row pt-3 pt1920">
												<div
													className="col-xl-11 fm-w4-s20 smallimg1 pt1920 s16 homeiconfs1"
													style={{ lineHeight: "22.59px" }}
												>
													A seamless booking process, no payment taken till the
													treatment is completed
												</div>
											</div>
										</div>
										{/* <div class="col-xl-1"></div> */}
										<div className="col-xl col-lg col-md col-xs col-sm homeml">
											<div className="row paddingtop">
												<div className="col">
													<img
														className="h40w40 homeiconswh"
														loading="lazy"
														src="../images/loan.png"
														alt="logo"
														style={{ width: "62px", height: "62px" }}
													></img>
												</div>
											</div>
											<div className="row pt-4 pt1920">
												<div
													className="col fm-w60-s22 smallimg s18 homeiconfs"
													style={{ lineHeight: "23.48px" }}
												>
													SECURE HOME SERVICE
												</div>
											</div>
											<div className="row pt-3 pt1920">
												<div
													className="col-xl-11 fm-w4-s20 smallimg1 pt1920 s16 homeiconfs1"
													style={{ lineHeight: "22.59px" }}
												>
													Secure check-in with a meet and greet verification
													code.
												</div>
											</div>
										</div>
										{/* <div class="col-xl-1"></div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row no-gutters">
						<div
							className="col mt1920"
							style={{
								background:
									"linear-gradient(90deg, #AF805E 10.93%, #979289 101.33%)",
							}}
						>
							<div
								className="row"
								style={{ paddingBottom: "100px", paddingTop: "100px" }}
							>
								<div className="col d-flex justify-content-center">
									<div
										className="f-rl fm-w6-s48 homepage color-FF fs1920 s32 homefs"
										style={{ textAlign: "center", lineHeight: "46.96px" }}
									>
										UNIQUE EXPERIENCE
										<div className="f-rl fm-w3-s48 homepage1 color-FF s32 homefs1">
											Seamless connection between you and your doctor
										</div>
									</div>
									<br></br>
								</div>
							</div>
						</div>
					</div>
					<div className="row no-gutters homereverse">
						<div
							className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 col-12"
							// style={{
							// 	backgroundImage: "url('../images/Rectangle164.png')",
							// 	backgroundRepeat: "no-repeat",
							// 	backgroundSize: "cover",
							// }}
						>
							<div className="row" style={{}}>
								<div
									className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 col-12"
									style={{
										background: "rgba(0, 0, 0, 0.9)",
										backdropFilter: "blur(2px)",
									}}
								>
									<div className="row pt-7 pt1920 ">
										<div
											className="col f-rl fm-w3-s58 fm-w6-s88 color-FF fs-fw s40 lh fstablet"
											style={{ paddingLeft: "123px", textAlign: "left" }}
										>
											BOOK ON-DEMAND<br></br>
											COSMETIC <br></br> TREATMENTS
										</div>
									</div>
									<div
										className="row  pt-3 pt65 minmanage pt1920 pf1920"
										style={{ paddingLeft: "116px" }}
									>
										<div className="roundWhiteDownload1921 roundWhiteDownload1 roundWhiteDownloadmobile homechangebtn1 homechangebtnin1">
											&nbsp;&nbsp;&nbsp;Visit User App&nbsp;&nbsp;&nbsp;&nbsp;
											<img
												className="homechangebtnarrow1"
												loading="lazy"
												alt=""
												src="../images/Arrowb4.png"
												style={{ width: "38.11px", height: "12px" }}
											></img>
										</div>
									</div>
									<div className="row pt-4 pt1920 pr1920">
										<div
											className="col"
											style={{
												display: "flex",

												justifyContent: "flex-end",
											}}
										>
											<img
												className="mt152"
												loading="lazy"
												src="../images/home screen 1.png"
												alt=".."
												style={{
													width: "474px",
													height: "548px",
												}}
											></img>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12  "
							style={{ padding: "0px" }}
						>
							<img
								className=""
								loading="lazy"
								alt=""
								src="../images/Rectangle164.png"
								style={{
									width: "100%",
									height: "100%",
								}}
							></img>
						</div>
					</div>
					<div className="row no-gutters homenew">
						<div
							className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 "
							// style={{
							// 	backgroundImage: "url('../images/Rectangle217.png')",
							// 	backgroundSize: "costyle={{padding:"0px"}}ver",
							// 	backgroundRepeat: "no-repeat",
							// }}
							style={{ padding: "0px" }}
						>
							<img
								className=""
								loading="lazy"
								alt=""
								src="../images/Rectangle217.png"
								style={{
									width: "100%",
									height: "100%",
								}}
							></img>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 col-12">
							<div className="row" style={{ height: "100%" }}>
								<div
									className="col"
									style={{ background: "rgba(255, 255, 255, 0.8)" }}
								>
									<div
										className="row pt-7 maxmanage bussiness00"
										style={{ marginLeft: "132px", textAlign: "left" }}
									>
										<div
											className="col f-rl fm-w3-s58 s40 fstablet"
											style={{ lineHeight: "68.09px" }}
										>
											HELP DOCTORS <br></br> SERVICE BETTER
										</div>
									</div>
									<div
										className="row pt-5 pt1920 minmanage1 "
										style={{ paddingLeft: "132px" }}
									>
										<div className="col-5">
											<div
												className="roundBlackDownload  roundBlackDownloadmobile homechangebtn2 homechangebtnin2"
												style={{ cursor: "pointer" }}
												onClick={() => navigate("/login")}
											>
												&nbsp;&nbsp;&nbsp;&nbsp;Manage
												Business&nbsp;&nbsp;&nbsp;&nbsp;
												<img
													className="homechangebtnarrow2"
													loading="lazy"
													alt=""
													src="../images/Arrow4.png"
													style={{ width: "38.11px", height: "12px" }}
												></img>
											</div>
											<br></br>
										</div>
									</div>
									<div className="row pt-5 bussiness000">
										<div
											className=" d-flex col f-rl fm-w3-s58"
											style={{ justifyContent: "end", paddingRight: "0px" }}
										>
											<img
												className="wh1600"
												loading="lazy"
												alt=""
												src="../images/macbookpro.png"
												style={{ maxWidth: "100%" }}
											></img>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className="row no-gutters"
						style={{
							backgroundColor: "#000000",
						}}
					>
						<div
							className="col"
							style={{ paddingLeft: "140px", paddingBottom: "100px" }}
						>
							<div className="pt-9 ourtreatmenthome ourimgs fstablet">
								<div
									className="color-FF f-rl fm-w3-s60 fs fstablet"
									style={{ paddingLeft: "0px" }}
								>
									OUR TREATMENTS
								</div>
							</div>
							{/* <div
								className="row pt-4 pt1920 ourimgs2 view1920"
								style={{ float: "right" }}
							>
								<div className="col" style={{ padding: "0px" }}>
									<img
										loading="lazy"
										alt=""
										src="../images/Frame 31.png"
										style={{
											width: "100%",
										}}
									></img>
								</div>
							</div> */}
							{/* <div
								class="scroll-container"
								style={{
									marginBottom: "100px",
									display: "flex",
									justifyContent: "space-between",
									flexDirection: "row",
								}}
							>
								<img src="../images/Group577.png" alt="Facial Treatment" />
								<img src="../images/Group591.png" alt="Chin and neck" />
								<img src="../images/Group578.png" alt="Hands and Arms" />
								<img src="../images/Group579.png" alt="Legs and Feet" />
								<img src="../images/Group580.png" alt="Facial Treatment" />
								<img src="../images/Group581.png" alt="Chin and neck" />
								<img src="../images/Group582.png" alt="Hands and Arms" />
								<img src="../images/Group583.png" alt="Legs and Feet" />
								<img src="../images/Group590.png" alt="Facial Treatment" />
								<img src="../images/Group584.png" alt="Chin and neck" />
								<img src="../images/Group585.png" alt="Chin and neck" />
								<img src="../images/Group586.png" alt="Chin and neck" />
								<img src="../images/Group587.png" alt="Chin and neck" />
								<img src="../images/Group588.png" alt="Chin and neck" />
								<img src="../images/Group589.png" alt="Chin and neck" />
								<img src="../images/imagelast1.png" alt="Chin and neck" />
							</div> */}
							{/* <Carousel
								swipeable={false}
								draggable={false}
								showDots={false}
								responsive={responsive}
								ssr={true} // means to render carousel on server-side.
								infinite={true}
								autoPlay={true}
								autoPlaySpeed={2000}
								keyBoardControl={true}
								customTransition="all .8"
								transitionDuration={500}
								// containerClass="carousel-container"
								removeArrowOnDeviceType={["tablet", "mobile"]}
								// deviceType={this.props.deviceType}
								// dotListClass="custom-dot-list-style"
								itemClass="carousel-item-padding-40-px"
								style={{
									marginBottom: "100px",
									display: "flex",
									justifyContent: "space-between",
									flexDirection: "row",
								}}
								className="scroll-container"
							>
								<div>
									<img
										src="../images/Group577.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group591.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group578.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group579.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group580.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group581.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group582.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group583.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group590.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group584.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group585.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group586.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group587.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group588.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group589.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/imagelast1.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
							</Carousel> */}

							{/* <Carousel
								additionalTransfrom={0}
								arrows
								autoPlay
								autoPlaySpeed={1}
								centerMode={false}
								// className=""
								containerClass="container-with-dots"
								customTransition="all 10s linear"
								dotListClass=""
								draggable
								focusOnSelect={false}
								infinite
								itemClass=""
								keyBoardControl
								// minimumTouchDrag={80}
								pauseOnHover
								renderArrowsWhenDisabled={false}
								renderButtonGroupOutside={false}
								renderDotsOutside={false}
								// removeArrowOnDeviceType={[
								// 	"desktop",
								// 	"tablet",
								// 	"mobile",
								// ]}
								// responsive={{
								// 	desktop: {
								// 		breakpoint: {
								// 			max: 3000,
								// 			min: 1024,
								// 		},
								// 		items: 3,
								// 		partialVisibilityGutter: 40,
								// 	},
								// 	mobile: {
								// 		breakpoint: {
								// 			max: 464,
								// 			min: 0,
								// 		},
								// 		items: 1,
								// 		partialVisibilityGutter: 30,
								// 	},
								// 	tablet: {
								// 		breakpoint: {
								// 			max: 1024,
								// 			min: 464,
								// 		},
								// 		items: 2,
								// 		partialVisibilityGutter: 30,
								// 	},
								// }}
								responsive={responsive}
								rewind={false}
								rewindWithAnimation={false}
								rtl={false}
								// shouldResetAutoplay
								showDots={false}
								sliderClass=""
								slidesToSlide={10}
								swipeable
								transitionDuration={10000}
								className="scroll-container"
								style={{
									marginBottom: "100px",
									display: "flex",
									justifyContent: "space-between",
									flexDirection: "row",
								}}
							>
								<div>
									<img
										src="../images/Group577.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group591.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group578.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group579.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group580.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group581.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group582.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group583.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group590.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group584.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group585.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group586.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group587.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group588.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/Group589.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
								<div>
									<img
										src="../images/imagelast1.png"
										width="380px"
										height="380px"
										alt="Facial Treatment"
									/>
								</div>
							</Carousel> */}

							<Carousel
								additionalTransfrom={0}
								arrows
								autoPlay
								autoPlaySpeed={1}
								infinite
								centerMode={false}
								// className=""
								containerClass="container-with-dots"
								customTransition="all 3s linear"
								// swipeable={false}
								draggable={false}
								showDots={false}
								responsive={responsive}
								// ssr={true} // means to render carousel on server-side.
								// infinite={true}
								// autoPlay={true}
								// autoPlaySpeed={1000}
								keyBoardControl={true}
								// customTransition="all .8"
								// transitionDuration={500}
								// containerClass="carousel-container"
								removeArrowOnDeviceType={[
									"desktop",
									"desktop1",
									"tablet",
									"mobile",
								]}
								// deviceType={this.props.deviceType}
								// dotListClass="custom-dot-list-style"
								itemClass="carousel-item-padding-10-px"
								rewind={false}
								rewindWithAnimation={false}
								rtl={false}
								// shouldResetAutoplay
								// showDots={false}
								sliderClass=""
								slidesToSlide={1}
								swipeable
								transitionDuration={3000}
								style={{
									marginBottom: "300px",
									display: "flex",
									justifyContent: "space-between",
									flexDirection: "row",
								}}
								className="scroll-container"
							>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle1.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">BOTOX</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle2.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">HYALURONIC ACID</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle3.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">LIPS HA</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle4.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">JAWLINE HA</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle5.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">PRP</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle6.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">NOSE JOB HA</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle7.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">EYES</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle8.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">SKIN CARE</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle9.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">CHIN</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle10.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">PEELING</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle11.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">CHEEK BONE HA</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle12.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">BODY(ANY)</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle13.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">BUTTOCK HA</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle14.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">ARM BTX</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle15.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">EAR LOBES HA</div>
									</div>
								</div>
								<div
									style={{
										backgroundImage: "url('../images/Rectangle16.png')",
									}}
								>
									<div
										style={{ position: "absolute", top: "75%", left: "10%" }}
									>
										<div className="tlist">DARK CIRCLES</div>
									</div>
								</div>
							</Carousel>

							{/* <div
								className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 col-12 view19201"
								style={{
									marginBottom: "100px",
									display: "flex",
									justifyContent: "space-between",
									flexDirection: "row",
								}}
							>
								<div className="row">
									<img
										loading="lazy"
										alt=""
										src="../images/Group577.png"
										style={{ width: "300px", height: "300px" }}
									></img>

									<img
										style={{ width: "300px", height: "300px" }}
										loading="lazy"
										alt=""
										src="../images/Group578.png"
									></img>

									<img
										style={{ width: "300px", height: "300px" }}
										loading="lazy"
										alt=""
										src="../images/Group579.png"
									></img>

									<img
										style={{ width: "300px", height: "300px" }}
										loading="lazy"
										alt=""
										src="../images/Group580.png"
									></img>

									<img
										loading="lazy"
										alt=""
										src="../images/Group577.png"
										style={{ width: "300px", height: "300px" }}
									></img>

									<img
										style={{ width: "300px", height: "300px" }}
										loading="lazy"
										alt=""
										src="../images/Group578.png"
									></img>

									<img
										style={{ width: "300px", height: "300px" }}
										loading="lazy"
										alt=""
										src="../images/Group579.png"
									></img>

									<img
										style={{ width: "300px", height: "300px" }}
										loading="lazy"
										alt=""
										src="../images/Group580.png"
									></img>
								</div>
							</div> */}
						</div>
					</div>
					<center>
						<div className=" row no-gutters">
							<div>
								<div className="row ">
									<div
										className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 col-12"
										style={{
											backgroundColor: "#b58a6c",
											display: "flex",
											flexDirection: "Column",
											justifyContent: "flex-end",
											alignItems: "center",
										}}
									>
										<div
											className="f-rl fm-w6-s42p5 color-FF finalimgs wsimgs homefs pt992"
											style={{
												// marginTop: "150px",
												marginBottom: "34px",
												lineHeight: "60.73px",
												fontWeight: "500px",
											}}
										>
											START A TREATMENT AT YOUR
											<br />
											PLACE RIGHT NOW!
										</div>
										<div
											className="roundWhiteDownload1920 roundWhiteDownload mbutton1920 roundWhiteDownloadmobile homechangebtn homechangebtnin"
											style={{
												marginTop: "20px",
											}}
										>
											Download the App&nbsp;&nbsp;&nbsp;&nbsp;
											<img
												className=" homechangebtnarrow"
												loading="lazy"
												alt=""
												src="../images/Arrowb4.png"
												style={{ width: "38.11px", height: "12px" }}
											></img>
										</div>

										<div className="col-xl-9 col-lg-9 col-md-9 col-xs-12 col-sm-12">
											<img
												className="whmobile wh992"
												loading="lazy"
												alt=""
												src="../images/iPhone13.png"
												style={{
													width: "100%",
												}}
											></img>
										</div>
									</div>

									<div
										className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 col-12 bgcolor992"
										style={{ padding: "0px", backgroundColor: "#b58a6c" }}
									>
										<div className="">
											<img
												className="whimg padding992"
												src="../images/Group 602.png"
												style={{ float: "right", width: "100%" }}
												alt=""
											/>
										</div>
									</div>

									<div
										className="view1920"
										style={{ backgroundColor: "#000000" }}
									>
										{" "}
										{utils.Footer1920()}
									</div>
									<div
										className="view992"
										style={{ backgroundColor: "#000000" }}
									>
										{" "}
										{utils.Footer1()}
									</div>
									<div
										className="view19201 view9921"
										style={{ backgroundColor: "#000000" }}
									>
										{" "}
										{utils.Footer()}
									</div>
								</div>
							</div>
						</div>
					</center>
				</div>
			</div>
		</>
	);
};

export default Home;
