import React from "react";

import * as utils from "../common/util";
import { useNavigate, Link } from "react-router-dom";
import Popupforbusiness from "./popupforbusiness";
import "./website1920.css";
import Logo from "../common/logo";

const Businessmobile = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="container-fluid" style={{ backgroundColor: "#000000" }}>
				<nav
					className="businesmobilenav navh nav navbar   container-fluid   fixed-topmobile  navbar-expand-lg navbar-dark bg-light bg-light1"
					style={{ height: "70px" }}
				>
					<div className="container-fluid navh">
						<Logo logo="dark1"></Logo>

						<div className="groupimage380">
							<Popupforbusiness></Popupforbusiness>
						</div>
						<div
							className="navbar-collapse collapse"
							style={{ whiteSpace: "nowrap", width: "100%", height: "100px" }}
							id="navbarCollapse"
						>
							<div className="navbar-nav p-4 p-lg-0 pr-r20">
								<a
									className="nav-link1 f-rl fm-w7-s20"
									href="/everyone"
									style={{
										color: "#FFFFFF",
									}}
								>
									For Everyone
									<div
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
										}}
									></div>
								</a>
								<a
									className="nav-link1 f-rl fm-w5-s20"
									href="/business"
									style={{
										color: "#AF805E",
									}}
								>
									For business
									<div
										style={{
											backgroundColor: "#B58A6C",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
											width: "123px",
										}}
									></div>
								</a>
								<a
									className="nav-link1 f-rl fm-w5-s20"
									href="/login"
									style={{
										color: "#FFFFFF",
									}}
								>
									Login
									<div
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
										}}
									></div>
								</a>
							</div>
							<div className="d-flex burger">
								<button
									className="btn-rounded-black"
									style={{
										border: "1px solid #fff",
									}}
									onClick={() => navigate("/signup/social")}
								>
									Business Sign Up
								</button>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<a href="../downloads/app-debug.apk" download>
									<button
										className="btn-rounded-white"
										style={{
											border: "1px solid #000",
										}}
									>
										{" "}
										Download the App
									</button>
								</a>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						</div>
					</div>
				</nav>

				<div className="row no-gutters" style={{ paddingTop: "70px" }}>
					<div
						className="col"
						style={{
							height: "480px",
							backgroundColor: "#fff",
							backgroundImage: "url('../images/Rectangle 200.png')",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}
					>
						<div className="row " style={{ marginTop: "98px" }}>
							<div className="col-12">
								<div className="row no-gutters">
									<div
										className="f-rl  displaycenter "
										style={{
											fontWeight: "700",
											color: "#AF805E",
											fontSize: "20px",
											textAlign: "left",
											paddingLeft: "20px",
											lineHeight: "23.48px",
										}}
									>
										For Clinics and Doctors
									</div>
								</div>
								<div className="row">
									<div
										className="f-rl  color-00 displaycenter"
										style={{
											fontWeight: "300",
											fontSize: "40px",
											textAlign: "left",
											paddingLeft: "20px",
											paddingBottom: "24px",
											paddingTop: "24px",
											lineHeight: "46.96px",
										}}
									>
										HELPING YOU SERVICE BETTER
									</div>
								</div>
								<div className="row no-gutters">
									<div
										className="f-rl  color-00 displaycenter"
										style={{
											fontWeight: "400",
											fontSize: "20px",
											textAlign: "left",
											paddingLeft: "20px",
											lineHeight: "23.48px",
											paddingBottom: "111px",
										}}
									>
										with a simple solution to help you service your customer
										while in their own homes.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row no-gutters" style={{ backgroundColor: "#FFFFFF" }}>
					<div className="col" style={{}}>
						<div
							className="f-rl "
							style={{
								fontWeight: "300",
								fontSize: "40px",
								textAlign: "left",
								// marginLeft: "20px",
								paddingTop: "88px",
								paddingLeft: "8px",
								paddingBottom: "21px",
								lineHeight: "46.98px",
							}}
						>
							Manage Your Schedule
						</div>

						<div
							className="f-rl "
							style={{
								fontWeight: "400",
								fontSize: "16px",
								textAlign: "left",
								paddingLeft: "8px",
								lineHeight: "20.08px",
								paddingBottom: "30px",
							}}
						>
							A simple way to set up your calender and manage your patient
							appointments.
						</div>
					</div>
				</div>
				<div className="row no-gutters" style={{ backgroundColor: "#FFFFFF" }}>
					<div className="col imgchange1">
						<img
							className=" img577"
							loading="lazy"
							alt=""
							src="../images/mobile1.png"
							style={{
								paddingBottom: "30px",
								float: "left",
								paddingLeft: "8px",
							}}
						></img>
					</div>
					<div className="col imgchange">
						<img
							className=" img577"
							loading="lazy"
							alt=""
							src="../images/resize1.png"
							style={{
								paddingBottom: "30px",
								float: "left",
								paddingLeft: "8px",
							}}
						></img>
					</div>
				</div>
				<div
					className="row no-gutters"
					style={{ backgroundColor: "#FFFFFF", paddingLeft: "0px" }}
				>
					<div className="col" style={{}}>
						<img
							className="s3202 img1577"
							loading="lazy"
							alt=""
							src="../images/calendar.png"
							style={{
								width: "342px",
								float: "left",
								paddingLeft: "2px",
								paddingBottom: "150px",
							}}
						></img>
					</div>
				</div>

				<div className="row no-gutters" style={{ backgroundColor: "#FFFFFF" }}>
					<div className="col" style={{}}>
						<div
							className="f-rl "
							style={{
								fontWeight: "300",
								fontSize: "40px",
								textAlign: "left",
								// marginLeft: "20px",
								// paddingTop: "88px",
								paddingLeft: "8px",
								paddingBottom: "21px",
								lineHeight: "46.98px",
							}}
						>
							On-demand Home Service
						</div>

						<div
							className="f-rl "
							style={{
								fontWeight: "400",
								fontSize: "16px",
								textAlign: "left",
								paddingLeft: "8px",
								lineHeight: "20.08px",
								paddingBottom: "30px",
							}}
						>
							Consultant with patients with live video, view treatments, and
							track your orders.
						</div>
					</div>
				</div>
				<div className="row no-gutters" style={{ backgroundColor: "#FFFFFF" }}>
					<div className="col imgchange1">
						<img
							className=" img577"
							loading="lazy"
							alt=""
							src="../images/mobile3.png"
							style={{
								paddingBottom: "15px",
								float: "left",
								paddingLeft: "8px",
							}}
						></img>
					</div>
					<div className="col imgchange">
						<img
							className=" img577"
							loading="lazy"
							alt=""
							src="../images/resize2.png"
							style={{
								paddingBottom: "15px",
								float: "left",
								paddingLeft: "8px",
							}}
						></img>
					</div>
				</div>
				<div
					className="row no-gutters"
					style={{ backgroundColor: "#FFFFFF", paddingLeft: "0px" }}
				>
					<div className="col" style={{ paddingLeft: "0px" }}>
						<img
							className="s3202 img3577"
							loading="lazy"
							alt=""
							src="../images/Treatments.png"
							style={{
								width: "365px",
								float: "left",
								paddingLeft: "4px",
								paddingBottom: "150px",
							}}
						></img>
					</div>
				</div>
				<div className="row no-gutters" style={{ backgroundColor: "#FFFFFF" }}>
					<div className="col" style={{}}>
						<div
							className="f-rl "
							style={{
								fontWeight: "300",
								fontSize: "40px",
								textAlign: "left",
								// marginLeft: "20px",

								paddingLeft: "8px",
								paddingBottom: "21px",
								lineHeight: "46.98px",
							}}
						>
							Secure and Safe Check-in
						</div>

						<div
							className="f-rl "
							style={{
								fontWeight: "400",
								fontSize: "16px",
								textAlign: "left",
								paddingLeft: "8px",
								lineHeight: "20.08px",
								paddingBottom: "30px",
							}}
						>
							Home service via secure pin code with Uber-like map tracking.
						</div>
					</div>
				</div>
				<div className="row no-gutters" style={{ backgroundColor: "#FFFFFF" }}>
					<div className="col imgchange1">
						<img
							className=" img577"
							loading="lazy"
							alt=""
							src="../images/mobile5.png"
							style={{
								paddingBottom: "30px",
								float: "left",
								paddingLeft: "8px",
							}}
						></img>
					</div>
					<div className="col imgchange">
						<img
							className=" img577"
							loading="lazy"
							alt=""
							src="../images/resize3.png"
							style={{
								paddingBottom: "30px",
								float: "left",
								paddingLeft: "8px",
							}}
						></img>
					</div>
				</div>
				<div
					className="row no-gutters"
					style={{ backgroundColor: "#FFFFFF", paddingLeft: "0px" }}
				>
					<div className="col" style={{}}>
						<img
							className="s3202 img2577"
							loading="lazy"
							alt=""
							src="../images/check in.png"
							style={{
								width: "355px",
								float: "left",
								paddingLeft: "0px",
								paddingBottom: "135px",
							}}
						></img>
					</div>
				</div>
				<div className="row no-gutters" style={{ backgroundColor: "#FFFFFF" }}>
					<img
						loading="lazy"
						alt=""
						src="../images/mobile7.png"
						style={{
							width: "100%",
							height: "574px",
							padding: "0px",
						}}
					></img>
				</div>
				<div className="row no-gutters" style={{ backgroundColor: "#EDE3DC" }}>
					<div className="row no-gutters">
						<div className="col">
							<div
								className="f-rl "
								style={{
									fontWeight: "300",
									fontSize: "40px",
									textAlign: "left",
									lineHeight: "46.96px",
									padding: "63px 0px 20px 8px",
								}}
							>
								Manage Your Business In <br /> The <br />
								Dashboard
							</div>
						</div>
					</div>
					<div className="row no-gutters">
						<div className="col " style={{ paddingLeft: "0px" }}>
							<img
								className="s3202 macw576business mobilewidth"
								loading="lazy"
								alt=""
								src="../images/macbook.png"
								style={{
									height: "252px",
									marginBottom: "65px",
									float: "left",
									paddingRight: "30px",
								}}
							></img>
						</div>
					</div>
				</div>

				<div className="row no-gutters" style={{ backgroundColor: "#FFFFFF" }}>
					<img
						loading="lazy"
						alt=""
						src="../images/mobile8.png"
						style={{ width: "100%", height: "574px", padding: "0px" }}
					></img>
				</div>
				<div className="row no-gutters" style={{ backgroundColor: "#F3F1F0" }}>
					<div className="row no-gutters">
						<div className="col">
							<div
								className="f-rl "
								style={{
									fontWeight: "300",
									fontSize: "40px",
									textAlign: "left",
									lineHeight: "46.96px",
									padding: "63px 0px 35px 8px",
								}}
							>
								Make The <br /> Treatment <br />
								Easy By Using Doctor’s App
							</div>
						</div>
					</div>
					<div className="row no-gutters">
						<div className="col">
							<img
								className="mobilewidth1"
								style={{
									width: "250px",
									height: "299",
									float: "left",
									paddingLeft: "8px",
									paddingBottom: "80px",
								}}
								loading="lazy"
								alt=""
								src="../images/mobile9.png"
								// style={{ height: "299px" }}
							></img>
						</div>
					</div>
				</div>

				<div className="row" style={{ backgroundColor: "#FFFFFF" }}>
					<div
						className="col"
						style={{
							backgroundColor: "#b58a6c",
							height: "300px",
						}}
					>
						<div className="row ">
							<div className="col-12">
								<div
									className="f-rl  color-FF "
									style={{
										// textAlign: "center",
										fontWeight: "300",
										fontSize: "40px",
										paddingTop: "77px",
										lineHeight: "46.96px",
										paddingBottom: "42px",
										// paddingLeft:"38px"
									}}
								>
									Let's Get Started
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col f425 " style={{}}>
								<button
									style={{
										height: "60px",
										width: "185px",
										fontWeight: "400",
										fontSize: "28px",
										borderRadius: "42px",
										color: "#ffffff",
										backgroundColor: "#000000",
										lineHeight: "53px",
									}}
									className=" f-rl float"
									onClick={() => navigate("/signup/social")}
								>
									Sign Up
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="row no-gutters" style={{ backgroundColor: "#FFFFFF" }}>
					<img
						loading="lazy"
						alt=""
						src="../images/Rectangle 201.png"
						style={{ width: "100%", padding: "0px" }}
					></img>
				</div>
				<div className="row no-gutters">{utils.Footer1()}</div>
			</div>
		</>
	);
};
export default Businessmobile;
