import React, { useState, useEffect, useLayoutEffect } from "react";
// import Card from "react-bootstrap/Card";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { format } from "date-fns";
import * as PatientServices from "../../services/patient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import * as utils from "../../common/util";
import * as UserServices from "../../services/user";
import {
	sendRescheduleEmailNotification,
	SendTestNotification,
} from "../../services/notificationservices";
import * as PatientpaymentService from "../../services/patientpayment";
// import { Baseurl, Loader } from "../../common/util";
import axios from "axios";
const baseurl = utils.Baseurl() + "create-refund";
// import Button from "react-bootstrap/Button";

const ApprovalModel = (props) => {
	console.log("props app", props);
	const [show, setShow] = useState(true);
	const [loading, setLoading] = useState(false);
	const [showdecline, setShowDecline] = useState(false);
	const [showreschedule, setShowReschedule] = useState(false);
	const [showcancel, setShowCancel] = useState(false);
	const [showsorry, setShowSorry] = useState(false);
	const [request, setRequest] = useState(false);
	const [appointment, setappointment] = useState(props.appointment);
	const [previosHistoryArray, setPreviosHistoryArray] = useState([]);
	const [historyTempArray, setHistoryTempArray] = useState([]);
	const [duphistoryTempArray, setdupHistoryTempArray] = useState([]);
	const [appointmentids, setAppointmentids] = useState([]);
	const [data, setData] = useState([]);
	const [first, setFirst] = useState("");
	const [doctorids, setDoctorids] = useState([]);
	const [amount, setAmount] = useState("");
	const [rescheduleDisable, setRescheduleDisable] = useState("");

	// useEffect(() => {

	// }, [appointment]);

	useEffect(() => {
		PatientpaymentService.GetPatientpaymentByAppointmentIdSuccess(
			props.appointment._id
		).then(function (result) {
			console.log("doctors", result.patientpaymentByAppointmentIDSuccess);
			setAmount(result.patientpaymentByAppointmentIDSuccess[0]);
		});
		if (props.appointment.cendtime !== "") {
			const beforeConsultTime1 = moment(
				props.appointment.consultationdate.split("T")[0] +
					" " +
					props.appointment.cendtime,
				"YYYY-MM-DD HH:mm"
			).add(-24, "hours");
			const currentTime = moment();

			// console.log(
			//   "diff",
			//   beforeConsultTime1.format("YYYY-MM-DD HH:mm"),
			//   currentTime.format("YYYY-MM-DD HH:mm")
			// );

			if (currentTime.valueOf() > beforeConsultTime1.valueOf()) {
				// alert("under 24 hours");
				setRescheduleDisable(true);
			} else {
				// alert("greater than 24 hours");
				setRescheduleDisable(false);
			}
		}
	}, [props]);

	var aptaddress = utils.FormatAddress(appointment);

	const currentTime = moment().valueOf();

	const consultationtDateTime = (appointment) => {
		return moment(
			appointment.consultationdate.split("T")[0] + " " + appointment.cstarttime,
			"YYYY-MM-DD HH:mm"
		)
			.add(-24, "hours")
			.valueOf();
	};

	const appointmentDateTime = (appointment) => {
		return moment(
			appointment.appointmentdate.split("T")[0] + " " + appointment.starttime,
			"YYYY-MM-DD HH:mm"
		)
			.add(-48, "hours")
			.valueOf();
	};

	const cancelledTime = (appointment) => {
		return moment(
			appointment.cancelledtime.split("|")[0],
			"YYYY-MM-DD HH:mm"
		).valueOf();
	};

	const [availbleTime, setAvailbleTime] = useState();
	const [filteredAppt, setFilteredAppt] = useState([]);
	const [type, setType] = useState([]);
	const [reschDate, setReschDate] = useState();
	const [disableStartTime, setDisableStartTime] = useState(false);
	const [id, setId] = useState([]);
	const [id1, setId1] = useState([]);
	const [workingDaysArray, setWorkingDaysArray] = useState([]);
	var temp = [];

	useEffect(() => {
		UserServices.GetUserSettingsById(props.params.id).then((existUser) => {
			console.log("pink", existUser.userSettingsByUserID);
			console.log("pink1", existUser.userSettingsByUserID.appointment[0]);
			setAvailbleTime(
				parseInt(existUser.userSettingsByUserID.appointment[0].interval)
			);
		});

		(async function () {
			var c = await UserServices.GetUserSettingsById(props.params.id);
			var a = c.userSettingsByUserID.calendar[0];
			console.log("settings", a);
			var v = a.days.split("|");

			v.forEach((ele) => {
				var a = ele.split("-");
				if (!temp.includes(a[0])) {
					temp.push(a[0]);
					setWorkingDaysArray((prev) => [...prev, a[0]]);
				}
				if (ele === v[v.length - 1]) {
					setFirst("lok");
				}
			});
			console.log("ele", workingDaysArray);
		})();
	}, [props]);

	// const handler = (event) => {
	//   setStartTime(event.split("-")[0]);
	//   setEndTime(event.split("-")[1]);
	// };

	const [tarray, setTArray] = useState();
	const generate_series = (step) => {
		const dt = new Date(2022, 0, 1);
		const rc = [];
		while (dt.getDate() === 1) {
			rc.push(dt.toLocaleTimeString("en-US").replace(":00", ""));
			dt.setMinutes(dt.getMinutes() + step);
		}
		console.log("rcc", rc);
		// setTArray(rc)
		return rc;
	};
	// const timearray = generate_series(parseInt(availbleTime));

	const getIntervalDates = (dateTime, interval) => {
		//let day = dateTime.slice(0,2);
		let time = dateTime.split("to");
		console.log("-times:" + dateTime + " : " + JSON.stringify(time));
		const sTime = moment(time[0], "HH:mm").add(interval, "minutes");

		let value = {
			interval: "00:" + (interval >= 60 ? "59" : interval),
			startTime: sTime.hour() + ":" + sTime.minute(),
			//startTime: time[0],
			endTime: time[1],
		};
		//console.log('-startTime: ' + moment(time[0], 'HH:mm').add(interval, 'minutes').hour);

		var inputDataFormat = "HH:mm";
		//var outputFormat = "HH:mm a";
		var outputFormat = "HH:mm";

		var tmp =
			interval >= 60
				? moment(value.interval, inputDataFormat).add(1, "minute")
				: moment(value.interval, inputDataFormat);
		var dif = tmp - moment().startOf("day");

		var startIntervalTime = moment(value.startTime, inputDataFormat).add(
			-dif,
			"ms"
		);
		var endIntervalTime = moment(value.startTime, inputDataFormat);
		var finishTime = moment(value.endTime, inputDataFormat);

		var intervals = [];
		while (startIntervalTime.format("x") < finishTime.format("x")) {
			//   console.log("startIntervalTime", startIntervalTime, finishTime);
			//   console.log("finishTime", finishTime);
			var format =
				startIntervalTime.format(outputFormat) +
				"-" +
				endIntervalTime.format(outputFormat);
			//var format = startIntervalTime.format(outputFormat);
			intervals.push(format);
			startIntervalTime.add(dif, "ms");
			endIntervalTime.add(dif, "ms");
		}
		return intervals;
	};
	console.log("avail", availbleTime);
	//   const timearray = getIntervalDates(
	//     "09:00to17:00",
	//     availbleTime === undefined ? 20 : availbleTime
	//   );
	//   console.log("naresh", timearray);

	const [date1, setDate] = useState("");
	const [tempArray, setTempArray] = useState([]);
	const [timearray, setTimeArray] = useState([]);
	const [lateFeesTimeArray, setLateFeesTimeArray] = useState([]);

	const onChangeDate = (date) => {
		console.log("enter", workingDaysArray);
		// setId([]);
		// setId1([]);
		// setFilteredAppt([]);
		// setTempArray([]);
		let l = new Date(date);
		// console.log(l.toString().split(" ")[0]);
		// console.log(workingDaysArray);
		// setDate(date.target.value);
		if (workingDaysArray.includes(l.toString().split(" ")[0])) {
			(async function () {
				var c = await UserServices.GetUserSettingsById(props.params.id);
				var a = c.userSettingsByUserID.calendar[0];
				console.log("settings", a);
				var v = a.days.split("|");
				var w = a.latefeesamount.split("|");
				if (a.latefeesamount !== "") {
					w.forEach((e) => {
						var b = e.split("-");
						console.log(b[0], l.toString().split(" ")[0]);
						if (b[0] === l.toString().split(" ")[0]) {
							v.forEach((ele) => {
								var a = ele.split("-");

								if (a[0] === l.toString().split(" ")[0]) {
									const t = getIntervalDates(
										a[1],
										availbleTime === undefined ? 20 : availbleTime
									);
									console.log(t);
									setTimeArray(t);
									const lateFeeTime = moment(a[1].split("to")[1], "HH:mm")
										.add(-1, "hour")
										.valueOf();
									console.log(lateFeeTime);

									let l = [];

									t.forEach((e) => {
										console.log(
											e,
											moment(e.split("-")[0], "HH:mm").valueOf(),
											lateFeeTime
										);
										if (
											lateFeeTime <= moment(e.split("-")[0], "HH:mm").valueOf()
										) {
											l.push(e.split("-")[0]);
										}
									});
									console.log(l);
									setLateFeesTimeArray(l);
								}
								if (ele === v[v.length - 1]) {
									setFirst("lokop");
								}
							});
						} else {
							v.forEach((ele) => {
								var a = ele.split("-");
								console.log(a[1]);
								if (a[0] === l.toString().split(" ")[0]) {
									const t = getIntervalDates(
										a[1],
										availbleTime === undefined ? 20 : availbleTime
									);
									setTimeArray(t);
								}
								if (ele === v[v.length - 1]) {
									setFirst("lok");
								}
							});
						}
					});
				}
				if (a.latefeesamount === "") {
					v.forEach((ele) => {
						var a = ele.split("-");
						const t = getIntervalDates(
							a[1],
							availbleTime === undefined ? 20 : availbleTime
						);
						setTimeArray(t);
						if (ele === v[v.length - 1]) {
							setFirst("lok");
						}
					});
				}
			})();
			setDisableStartTime(false);
			var catearr = props.allappointments.forEach(function (elem) {
				if (elem.status === "Accepted") {
					if (
						elem.appointmentdate.split("T")[0].split("-")[1] ===
							date.split("-")[1] &&
						elem.appointmentdate.split("T")[0].split("-")[2] ===
							date.split("-")[2]
					) {
						if (!id.includes(elem._id)) {
							tempArray.push(elem.starttime);
							id.push(elem._id);
						}
					}
				}
				if (elem.cstatus === "Accepted") {
					if (
						elem.consultationdate.split("T")[0].split("-")[1] ===
							date.split("-")[1] &&
						elem.consultationdate.split("T")[0].split("-")[2] ===
							date.split("-")[2]
					) {
						if (!id1.includes(elem._id)) {
							tempArray.push(elem.cstarttime);
							id1.push(elem._id);
						}
					}
				}
			});
		}
		//  else {
		//   setStartTime("Start Time");
		//   setEndTime("");
		//   setDisableStartTime(true);
		//   setShowAlert(true);
		//   setShow(false);
		// }

		console.log("block", tempArray, timearray, first);
		setFilteredAppt(tempArray);
	};

	useLayoutEffect(() => {
		console.log(props.allappointments);
		if (props.allappointments.length > 0) {
			let l = props.allappointments.sort((a, b) =>
				a.appointmentdate > b.appointmentdate ? 1 : -1
			);
			console.log("loki", l);
			const beforeConsultTime1 = moment(
				l[0].appointmentdate.split("T")[0],
				"YYYY-MM-DD HH:mm"
			)
				.subtract(1, "days")
				.format("YYYY-MM-DD");
			console.log(beforeConsultTime1);
			// const parsedDate = moment("2023-07-27", "YYYY-MM-DD", true);
			// console.log(
			//   parsedDate.isValid() &&
			//     parsedDate.year() !== 0 &&
			//     parsedDate.month() !== 0 &&
			//     parsedDate.date() !== 0
			// );
			onChangeDate(beforeConsultTime1);
		}

		// UserServices.GetUserSettingsById(props.params.id).then((existUser) => {
		//   // console.log("pink", existUser.userSettingsByUserID);
		//   // console.log("pink1", existUser.userSettingsByUserID.appointment[0]);
		//   setAvailbleTime(
		//     parseInt(existUser.userSettingsByUserID.appointment[0].interval)
		//   );
		// });
	}, [workingDaysArray, props, filteredAppt, first]);

	const getPatients = (patientid, date, doctorid) => {
		// setPreviosHistoryArray([]);
		// setHistoryTempArray([]);
		historyTempArray.length = 0;
		previosHistoryArray.length = 0;
		setdupHistoryTempArray([]);

		UserServices.GetAppointmentOfPatientswithDoctors(patientid, doctorid).then(
			(app) => {
				console.log("appdata", app);
				app.data.map((st) => {
					// console.log("guy", date);
					console.log(
						"hist",
						st.appointmentdate.split("T")[0].split("-")[2],
						date.split("T")[0].split("-")[2]
					);
					if (!historyTempArray.includes(st._id)) {
						const d1 = st.appointmentdate.split("T")[0];
						const clickDate = date.split("T")[0];

						if (
							moment(d1, "YYYY-MM-DD").valueOf() <
							moment(clickDate, "YYYY-MM-DD").valueOf()
						) {
							previosHistoryArray.push(st);
							historyTempArray.push(st._id);
						}
					}
				});

				console.log("guy", previosHistoryArray);
				setdupHistoryTempArray(previosHistoryArray);
			}
		);
		return previosHistoryArray;
	};
	const [history, setHistory] = useState(false);
	const [history1, setHistory1] = useState(false);
	const handleHistory = () => {
		setHistory(!history);
		setHistory1(true);
	};
	const handleClose = () => {
		setShow(false);
		props.show();
	};
	const handleCloseDecline = () => {
		setShowDecline(false);
		setShow(true);
		// props.show();
	};
	const handleCloseReschedule = () => {
		setShowReschedule(false);
		setShow(true);
		// props.show();
	};
	const handleCloseCancel = () => {
		setShowCancel(false);
		setShow(true);
		// props.show();
	};
	const handleCloseSorry = () => {
		setShowSorry(false);
		setShow(true);
		// props.show();
	};
	const handleShowDecline = () => {
		setShowDecline(true);
		setShow(false);
	};
	const handleShowReschedule = () => {
		setShowReschedule(true);
		setShow(false);
	};
	// const handleConfirmCancel = () => {
	// 	//  console.log(props.params)
	// 	(async function anyNameFunction() {
	// 		const updateSettingsvariables = PatientServices.returnUpdateAppointments({
	// 			id: props.appointment._id,
	// 			cancelledby: "doctor",
	// 			cancelledtime: moment().utcOffset("+00:00").format("YYYY-MM-DD HH:mm"),
	// 			cancellationstage: "consultation",
	// 		});
	// 		// console.log("lok", updateSettingsvariables);
	// 		PatientServices.UpdatePatientAppointment(updateSettingsvariables).then(
	// 			(value) => {
	// 				console.log("value", value);
	// 				// toast.success("Successfully record saved", {
	// 				// 	toastId: "calender",
	// 				// 	position: "top-right",
	// 				// 	autoClose: 5000,
	// 				// 	hideProgressBar: false,
	// 				// 	closeOnClick: true,
	// 				// 	pauseOnHover: true,
	// 				// 	draggable: true,
	// 				// 	progress: undefined,
	// 				// });
	// 				// setShowCancel(false);
	// 				// setShow(false);
	// 				setLoading(true);
	// 				window.scrollTo(0, 0);
	// 				setTimeout(() => {
	// 					// setIstoastg(false);
	// 					window.location.reload();
	// 				}, 3000);
	// 			}
	// 		);
	// 	})();
	// };

	const handleConfirmCancel = () => {
		const deeplinkContent = {
			screen: "AppointmentScreen",
			appointmentId: props.appointment._id,
			navigateTo: "AppointmentDetails",
		};
		//  console.log(props.params)
		(async function anyNameFunction() {
			const updateSettingsvariables = PatientServices.returnUpdateAppointments({
				id: props.appointment._id,
				cancelledby: "doctor",
				cancelledtime: moment().utcOffset("+00:00").format("YYYY-MM-DD HH:mm"),
				cancellationstage: "consultation",
			});
			// console.log("lok", updateSettingsvariables);
			PatientServices.UpdatePatientAppointment(updateSettingsvariables).then(
				(value) => {
					console.log("value", value);
					let doctordata = {
						senddata: {
							audience: {
								OR: [
									{
										android_channel:
											props.appointment.doctor_details[0].channelid.split("|"),
									},
									{
										ios_channel:
											props.appointment.doctor_details[0].channelid.split("|"),
									},
								],
							},
							notification: {
								alert:
									"You cancelled however " +
									props.appointment.patient_details[0].firstName +
									" was not charged.",

								android: {
									title: "Cancelled consultation",
								},
								ios: {
									title: "Cancelled consultation",
								},
								actions: {
									open: {
										content: JSON.stringify(deeplinkContent),
										type: "deep_link",
										fallback_url:
											"doctorapp://aesthetik.app/appointment_screen/" +
											props.appointment._id,
									},
								},
							},
							device_types: ["android", "ios"],
						},
						role: "dashboarddoctor",
						doctorid: props.appointment.doctor_details[0]._id.toString(),
						kind: "consultation",
						appointmentid: props.appointment._id,
					};
					let patientdata = {
						senddata: {
							audience: {
								OR: [
									{
										android_channel:
											props.appointment.patient_details[0].channelid.split("|"),
									},
									{
										ios_channel:
											props.appointment.patient_details[0].channelid.split("|"),
									},
								],
							},
							notification: {
								alert:
									"" +
									props.appointment.doctor_details[0].firstName +
									" cancelled, you were not charged.",
								android: {
									title: "Cancelled consultation",
								},
								ios: {
									title: "Cancelled consultation",
								},
								actions: {
									open: {
										content: JSON.stringify(deeplinkContent),
										type: "deep_link",
										fallback_url:
											"patientapp://aesthetik.app/appointment_screen/" +
											props.appointment._id,
									},
								},
							},
							device_types: ["android", "ios"],
						},
						role: "dashboardpatient",
						patientid: props.appointment.patient_details[0]._id.toString(),
						kind: "consultation",
						appointmentid: props.appointment._id,
					};
					SendTestNotification(patientdata).then((res) => {});
					SendTestNotification(doctordata).then((res) => {});
					// toast.success("Successfully record saved", {
					// 	toastId: "calender",
					// 	position: "top-right",
					// 	autoClose: 5000,
					// 	hideProgressBar: false,
					// 	closeOnClick: true,
					// 	pauseOnHover: true,
					// 	draggable: true,
					// 	progress: undefined,
					// });
					// setShowCancel(false);
					// setShow(false);
					setLoading(true);
					window.scrollTo(0, 0);
					setTimeout(() => {
						// setIstoastg(false);
						window.location.reload();
					}, 3000);
				}
			);
		})();
	};
	const handleShowCancel = () => {
		setShowCancel(true);
		setShow(false);
	};
	const handleShowSorry = () => {
		setShowSorry(true);
		setShowReschedule(false);
	};
	console.log(props.hideapointment);
	const hideAndTrue = () => {
		setShow(false);
		props.show();
	};

	const handleApprove = (value) => {
		const deeplinkContent = {
			screen: "AppointmentScreen",
			appointmentId: props.appointment._id,
			navigateTo: "AppointmentDetails",
		};
		setLoading(true);
		if (value === "Approved") {
			(async function anyNameFunction() {
				const updateSettingsvariables =
					PatientServices.returnUpdateAppointments({
						id: props.appointment._id,
						videostatus: "Approved",
						onboardingactiontime: moment()
							.utcOffset("+00:00")
							.format("YYYY-MM-DD HH:mm"),
					});
				// console.log("lok", updateSettingsvariables);
				PatientServices.UpdatePatientAppointment(updateSettingsvariables).then(
					(val) => {
						console.log("value", val);
						// sendRescheduleEmailNotification(
						//   props.reschedule.patient_details[0].firstName,
						//   props.reschedule.patient_details[0].email,
						//   props.reschedule.doctor_details[0].firstName,
						//   format(
						//     new Date(props.reschedule.consultationdate.split("T")[0]),
						//     "do MMM yyyy"
						//   ) +
						//     // props.reschedule.consultationdate.split("T")[0] +
						//     " from " +
						//     props.reschedule.cstarttime +
						//     " - " +
						//     props.reschedule.cendtime,
						//   format(new Date(date1), "do MMM yyyy") +
						//     " from " +
						//     startTime +
						//     " - " +
						//     endTime,
						//   props.reschedule.consultationtype
						// );
						let doctordata = {
							senddata: {
								audience: {
									OR: [
										{
											android_channel:
												props.appointment.doctor_details[0].channelid.split(
													"|"
												),
										},
										{
											ios_channel:
												props.appointment.doctor_details[0].channelid.split(
													"|"
												),
										},
									],
								},
								notification: {
									alert:
										"You approved " +
										props.appointment.patient_details[0].firstName +
										" onboarding video  ",

									android: {
										title: "Onboarding video",
									},
									ios: {
										title: "Onboarding video",
									},
									actions: {
										open: {
											content: JSON.stringify(deeplinkContent),
											type: "deep_link",
											fallback_url:
												"doctorapp://aesthetik.app/appointment_screen/" +
												props.appointment._id,
										},
									},
								},
								device_types: ["android", "ios"],
							},
							role: "dashboarddoctor",
							doctorid: props.appointment.doctor_details[0]._id.toString(),
							kind: "consultation",
							appointmentid: props.appointment._id,
						};
						let patientdata = {
							senddata: {
								audience: {
									OR: [
										{
											android_channel:
												props.appointment.patient_details[0].channelid.split(
													"|"
												),
										},
										{
											ios_channel:
												props.appointment.patient_details[0].channelid.split(
													"|"
												),
										},
									],
								},
								notification: {
									alert:
										"The " +
										props.appointment.doctor_details[0].firstName +
										" approved your video.",
									android: {
										title: "Onboarding video",
									},
									ios: {
										title: "Onboarding video",
									},
									actions: {
										open: {
											content: JSON.stringify(deeplinkContent),
											type: "deep_link",
											fallback_url:
												"patientapp://aesthetik.app/appointment_screen/" +
												props.appointment._id,
										},
									},
								},
								device_types: ["android", "ios"],
							},
							role: "dashboardpatient",
							patientid: props.appointment.patient_details[0]._id.toString(),
							kind: "consultation",
							appointmentid: props.appointment._id,
						};
						SendTestNotification(patientdata).then((res) => {});
						SendTestNotification(doctordata).then((res) => {});

						// toast.success("Successfully record saved", {
						// 	toastId: "calender",
						// 	position: "top-right",
						// 	autoClose: 5000,
						// 	hideProgressBar: false,
						// 	closeOnClick: true,
						// 	pauseOnHover: true,
						// 	draggable: true,
						// 	progress: undefined,
						// });

						window.scrollTo(0, 0);
						setTimeout(() => {
							// setIstoastg(false);
							window.location.reload();
						}, 3000);
					}
				);
			})();
		}

		if (value === "Declined") {
			(async function anyNameFunction() {
				const updateSettingsvariables =
					PatientServices.returnUpdateAppointments({
						id: props.appointment._id,
						videostatus: "Declined",
						onboardingactiontime: moment()
							.utcOffset("+00:00")
							.format("YYYY-MM-DD HH:mm"),
					});
				// console.log("lok", updateSettingsvariables);
				PatientServices.UpdatePatientAppointment(updateSettingsvariables).then(
					(val) => {
						console.log("value", val);
						// sendRescheduleEmailNotification(
						//   props.reschedule.patient_details[0].firstName,
						//   props.reschedule.patient_details[0].email,
						//   props.reschedule.doctor_details[0].firstName,
						//   format(
						//     new Date(props.reschedule.consultationdate.split("T")[0]),
						//     "do MMM yyyy"
						//   ) +
						//     // props.reschedule.consultationdate.split("T")[0] +
						//     " from " +
						//     props.reschedule.cstarttime +
						//     " - " +
						//     props.reschedule.cendtime,
						//   format(new Date(date1), "do MMM yyyy") +
						//     " from " +
						//     startTime +
						//     " - " +
						//     endTime,
						//   props.reschedule.consultationtype
						// );
						let doctordata = {
							senddata: {
								audience: {
									OR: [
										{
											android_channel:
												props.appointment.doctor_details[0].channelid.split(
													"|"
												),
										},
										{
											ios_channel:
												props.appointment.doctor_details[0].channelid.split(
													"|"
												),
										},
									],
								},
								notification: {
									alert:
										"You declined " +
										props.appointment.patient_details[0].firstName +
										" onboarding video  ",

									android: {
										title: "Onboarding video",
									},
									ios: {
										title: "Onboarding video",
									},
									actions: {
										open: {
											content: JSON.stringify(deeplinkContent),
											type: "deep_link",
											fallback_url:
												"doctorapp://aesthetik.app/appointment_screen/" +
												props.appointment._id,
										},
									},
								},
								device_types: ["android", "ios"],
							},
							role: "dashboarddoctor",
							doctorid: props.appointment.doctor_details[0]._id.toString(),
							kind: "consultation",
							appointmentid: props.appointment._id,
						};

						let patientdata = {
							senddata: {
								audience: {
									OR: [
										{
											android_channel:
												props.appointment.patient_details[0].channelid.split(
													"|"
												),
										},
										{
											ios_channel:
												props.appointment.patient_details[0].channelid.split(
													"|"
												),
										},
									],
								},
								notification: {
									alert:
										"The " +
										props.appointment.doctor_details[0].firstName +
										" declined your video",
									android: {
										title: "Onboarding video",
									},
									ios: {
										title: "Onboarding video",
									},
									actions: {
										open: {
											content: JSON.stringify(deeplinkContent),
											type: "deep_link",
											fallback_url:
												"patientapp://aesthetik.app/appointment_screen/" +
												props.appointment._id,
										},
									},
								},
								device_types: ["android", "ios"],
							},
							role: "dashboardpatient",
							patientid: props.appointment.patient_details[0]._id.toString(),
							kind: "consultation",
							appointmentid: props.appointment._id,
						};

						SendTestNotification(patientdata).then((res) => {});
						SendTestNotification(doctordata).then((res) => {});

						// toast.success("Successfully record saved", {
						//   toastId: "calender",
						//   position: "top-right",
						//   autoClose: 5000,
						//   hideProgressBar: false,
						//   closeOnClick: true,
						//   pauseOnHover: true,
						//   draggable: true,
						//   progress: undefined,
						// });

						window.scrollTo(0, 0);
						setTimeout(() => {
							// setIstoastg(false);
							window.location.reload();
						}, 3000);
					}
				);
			})();
		}
		if (value === "Reschedule") {
			console.log(timearray, filteredAppt);
			if (timearray.length === filteredAppt.length) {
				handleShowSorry();
				return;
			} else {
				(async function anyNameFunction() {
					const updateSettingsvariables =
						PatientServices.returnUpdateAppointments({
							id: props.appointment._id,
							isconsultationrescheduled: true,
						});
					// console.log("lok", updateSettingsvariables);
					PatientServices.UpdatePatientAppointment(
						updateSettingsvariables
					).then((val) => {
						console.log("value", val);
						// sendRescheduleEmailNotification(
						//   props.reschedule.patient_details[0].firstName,
						//   props.reschedule.patient_details[0].email,
						//   props.reschedule.doctor_details[0].firstName,
						//   format(
						//     new Date(props.reschedule.consultationdate.split("T")[0]),
						//     "do MMM yyyy"
						//   ) +
						//     // props.reschedule.consultationdate.split("T")[0] +
						//     " from " +
						//     props.reschedule.cstarttime +
						//     " - " +
						//     props.reschedule.cendtime,
						//   format(new Date(date1), "do MMM yyyy") +
						//     " from " +
						//     startTime +
						//     " - " +
						//     endTime,
						//   props.reschedule.consultationtype
						// );
						// let doctordata = {
						//   senddata: {
						//     audience: {
						//       OR: [
						//         {
						//           android_channel:
						//             props.reschedule.doctor_details[0].channelid.split("|"),
						//         },
						//         {
						//           ios_channel:
						//             props.reschedule.doctor_details[0].channelid.split("|"),
						//         },
						//       ],
						//     },
						//     notification: {
						//       // alert:
						//       //   "The " +
						//       //   props.reschedule.doctor_details[0].firstName +
						//       //   " confirms the new appoint date and time. ie from " +
						//       //   format(
						//       //     new Date(props.reschedule.consultationdate.split("T")[0]),
						//       //     "do MMM yyyy"
						//       //   ) +
						//       //   // props.reschedule.consultationdate.split("T")[0] +
						//       //   " from " +
						//       //   props.reschedule.cstarttime +
						//       //   " - " +
						//       //   props.reschedule.cendtime +
						//       //   " to " +
						//       //   format(new Date(date1), "do MMM yyyy") +
						//       //   " from " +
						//       //   startTime +
						//       //   " - " +
						//       //   endTime,
						//       alert:
						//         "Thanks for confirming the new appoint date and time with " +
						//         props.reschedule.patient_details[0].firstName,
						//       android: {
						//         title: "Reschedule " + props.reschedule.consultationtype,
						//       },
						//       ios: {
						//         title: "Reschedule " + props.reschedule.consultationtype,
						//       },
						//     },
						//     device_types: ["android", "ios"],
						//   },
						//   role: "doctor",
						// };
						let patientdata = {
							senddata: {
								audience: {
									OR: [
										{
											android_channel:
												props.appointment.patient_details[0].channelid.split(
													"|"
												),
										},
										{
											ios_channel:
												props.appointment.patient_details[0].channelid.split(
													"|"
												),
										},
									],
								},
								notification: {
									alert:
										"" +
										props.appointment.doctor_details[0].firstName +
										" wishes to  reschedule the consultation",
									android: {
										title: "Reschedule Consultation",
									},
									ios: {
										title: "Reschedule Consultation",
									},
									actions: {
										open: {
											content: JSON.stringify(deeplinkContent),
											type: "deep_link",
											fallback_url:
												"patientapp://aesthetik.app/appointment_screen/" +
												props.appointment._id,
										},
									},
								},
								device_types: ["android", "ios"],
							},
							role: "dashboardpatient",
							patientid: props.appointment.patient_details[0]._id.toString(),
							kind: "consultation",
							appointmentid: props.appointment._id,
						};
						SendTestNotification(patientdata).then((res) => {});
						// SendTestNotification(doctordata).then((res) => {
						//   window.location.reload();
						// });

						// toast.success("Successfully record saved", {
						//   toastId: "calender",
						//   position: "top-right",
						//   autoClose: 5000,
						//   hideProgressBar: false,
						//   closeOnClick: true,
						//   pauseOnHover: true,
						//   draggable: true,
						//   progress: undefined,
						// });
						setLoading(true);
						window.scrollTo(0, 0);
						setTimeout(() => {
							// setIstoastg(false);
							window.location.reload();
						}, 3000);
					});
				})();
			}
		}
	};

	useEffect(() => {
		setappointment(props.appointment);
	}, [props]);

	function calculationoffinalsyringes(j) {
		var val = parseInt(
			appointment.finalsyringes.split(",")[j] -
				appointment.initialsyringes.split(",")[j]
		);
		if (val > 0) {
			return <>+{val}</>;
		} else if (val === 0) {
			return <>{" " + val}</>;
		}
		return <>-{val}</>;
	}

	return (
		<>
			{/* <ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/> */}

			<Modal
				show={showsorry}
				onHide={handleCloseSorry}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header>
					<Modal.Title>
						<Modal.Body style={{ paddingTop: 0 }}>
							<div
								style={{
									cursor: "pointer",
									width: "100%",
									display: "flex",
									justifyContent: "end",
								}}
								onClick={() => {
									handleCloseSorry();
								}}>
								<img alt="close" src="./images/closeone.png"></img>
							</div>
							<div className="f-fm fm-w7-s30 pt-4">
								We are sorry but you cannot send a request to reschedule
							</div>
							<div className="f-fm fm-w4-s18" style={{ marginBottom: "100px" }}>
								It looks like you don’t have any time available for the patient
								to reschedule the consultation 24 hours before the upcoming
								treatment.
							</div>
							{/* <div
                className="col-9 f-fm fm-w4-s16 pt-7"
                style={{
                  cursor: "pointer",
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
                // onClick={() => {
                //   handleConfirmCancel();
                // }}
              >
                <button
                  style={{
                    width: "165px",
                    height: "50px",
                    borderRadius: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    border: "1px solid #000000",
                  }}
                >
                  Confirm Cancel
                </button>
              </div> */}
						</Modal.Body>
					</Modal.Title>
				</Modal.Header>
			</Modal>
			<Modal
				show={showcancel}
				onHide={handleCloseCancel}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				{loading === true && <utils.Loader />}
				<Modal.Header>
					<Modal.Title>
						<Modal.Body style={{ paddingTop: 0 }}>
							<div
								style={{
									cursor: "pointer",
									width: "100%",
									display: "flex",
									justifyContent: "end",
								}}
								onClick={() => {
									handleCloseCancel();
								}}>
								<img alt="close" src="./images/closeone.png"></img>
							</div>
							<div className="f-fm fm-w7-s30 pt-4">
								Do you sure to cancel this consultation?
							</div>
							<div className="f-fm fm-w4-s18">
								If so, the treatment appointment will also be cancelled
								directly, Your patient will get a full refund of the
								consultation fee.
							</div>
							<div
								className="col-9 f-fm fm-w4-s16 pt-7"
								style={{
									// cursor: "pointer",
									width: "100%",
									display: "flex",
									justifyContent: "end",
								}}>
								<button
									style={{
										width: "165px",
										height: "50px",
										borderRadius: "40px",
										backgroundColor: "#000",
										color: "#fff",
										border: "1px solid #000000",
										cursor: "pointer",
									}}
									onClick={() => {
										handleConfirmCancel();
									}}>
									Confirm Cancel
								</button>
							</div>
						</Modal.Body>
					</Modal.Title>
				</Modal.Header>
			</Modal>
			<Modal
				show={showreschedule}
				onHide={handleCloseReschedule}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				{loading === true && <utils.Loader />}
				<Modal.Header>
					<Modal.Title>
						<Modal.Body style={{ paddingTop: 0 }}>
							<div
								style={{
									cursor: "pointer",
									width: "100%",
									display: "flex",
									justifyContent: "end",
								}}
								onClick={() => {
									handleCloseReschedule();
								}}>
								<img alt="close" src="./images/closeone.png"></img>
							</div>
							<div className="f-fm fm-w7-s30 pt-4">
								Request patient reschedule live consultation?
							</div>
							<div className="f-fm fm-w4-s18">
								To ensure the best possible experience of your service, we
								kindly recommend limiting the number of rescheduling requests.
							</div>
							<div
								className="col-9 f-fm fm-w4-s16 pt-7"
								style={{
									// cursor: "pointer",
									width: "100%",
									display: "flex",
									justifyContent: "end",
								}}
								// onClick={() => {
								//   handleShowSorry();
								// }}
							>
								<button
									style={{
										width: "197px",
										height: "60px",
										borderRadius: "40px",
										backgroundColor: "#000",
										color: "#fff",
										border: "1px solid #000000",
									}}
									onClick={() => {
										handleApprove("Reschedule");
									}}>
									Send Request
								</button>
							</div>
						</Modal.Body>
					</Modal.Title>
				</Modal.Header>
			</Modal>
			<Modal
				show={showdecline}
				onHide={handleCloseDecline}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				{loading === true && <utils.Loader />}
				<Modal.Header>
					<Modal.Title>
						<Modal.Body style={{ paddingTop: 0 }}>
							<div
								style={{
									cursor: "pointer",
									width: "100%",
									display: "flex",
									justifyContent: "end",
								}}
								onClick={() => {
									handleCloseDecline();
								}}>
								<img alt="close" src="./images/closeone.png"></img>
							</div>
							<div className="f-fm fm-w4-s14 pt-4">
								You are declining the request because the treatment info of the
								onboarding video(s) sent by the patient may not qualify.
							</div>
							<div className="f-fm fm-w7-s22" style={{ fontWeight: "bold" }}>
								Would you like to send a live consultation request to the
								patient?
							</div>
							<div
								className="col-9 f-fm fm-w4-s16 pt-7"
								style={{
									cursor: "pointer",
									width: "100%",
									display: "flex",
									justifyContent: "end",
								}}
								onClick={() => {
									handleApprove("Declined");
								}}>
								<button
									style={{
										width: "348px",
										height: "60px",
										borderRadius: "40px",
										backgroundColor: "#000",
										color: "#fff",
										border: "1px solid #000000",
									}}>
									Decline & send consultation request
								</button>
							</div>
						</Modal.Body>
					</Modal.Title>
				</Modal.Header>
			</Modal>
			<Modal show={show} onHide={handleClose}>
				{loading === true && <utils.Loader />}
				<Modal.Header>
					<Modal.Title>
						<Modal.Body>
							<div>
								<div>
									<div className="row" style={{ backgroundColor: "#fff" }}>
										<Tabs
											defaultActiveKey="Treatment"
											id="profiletab"
											className="list-tab ptab1"
											style={{ backgroundColor: "#fff" }}>
											<Tab
												eventKey="fa fa-angle-left"
												title={
													<i
														class="fa fa-angle-left"
														aria-hidden="true"
														onClick={hideAndTrue}></i>
												}
												onClick={hideAndTrue}></Tab>
											<Tab
												eventKey="Treatment"
												title="Treatment"
												style={{ backgroundColor: "#fff", marginTop: "7px" }}>
												{appointment.cancelledby !== "" &&
													appointment.missedby === "" && (
														<div className="">
															<div
																className="f-fm fm-w4-s16"
																style={{
																	background: "#F4F4F4",
																	textAlign: "center",
																	marginLeft: "-31px",
																	marginRight: "-31px",
																	paddingTop: "6px",
																	paddingBottom: "6px",
																}}>
																Cancelled
															</div>
														</div>
													)}

												{appointment.cancelledby === "" &&
													appointment.missedby !== "" && (
														<div className="">
															<div
																className="f-fm fm-w4-s16"
																style={{
																	background: "#F4F4F4",
																	textAlign: "center",
																	marginLeft: "-31px",
																	marginRight: "-31px",
																	paddingTop: "6px",
																	paddingBottom: "6px",
																}}>
																Missed
															</div>
														</div>
													)}

												{appointment.missedby === "" &&
													appointment.cancelledby === "patient" &&
													appointment.cancellationstage === "appointment" &&
													appointment.ispaymentdone === true && (
														<div
															className="col-12 pt-4"
															style={{ height: "70vh" }}>
															<div
																style={{
																	width: "426px",
																	height: "91px",
																	background: "#acacac36",
																	borderRadius: "13px 13px",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center",
																	flexWrap: "wrap",
																	flexDirection: "column",
																}}>
																<div
																	className="row f-fm fm-w5-s12 color-00"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																	}}>
																	- Cancelled by{" "}
																	{appointment.cancelledby
																		.charAt(0)
																		.toUpperCase() +
																		appointment.cancelledby.slice(1)}
																	-
																</div>

																{cancelledTime(appointment) <
																	appointmentDateTime(appointment) && (
																	<div
																		className="row f-fm fm-w5-s12 color-00"
																		style={{
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																			color: "#ACACAC",
																		}}>
																		No Cancellation fee
																	</div>
																)}

																{/* {parseInt(
                                  appointment.cancelledtime.split("|")[1]
                                ) >= 48 && (
                                  <div
                                    className="row f-fm fm-w5-s12 color-00"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#ACACAC",
                                    }}
                                  >
                                    No Cancellation fee
                                  </div>
                                )} */}

																{cancelledTime(appointment) >
																	appointmentDateTime(appointment) && (
																	<>
																		{" "}
																		<div
																			className="row f-fm fm-w5-s12 color-00"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																				color: "#ACACAC",
																			}}>
																			A treatment cancellation fee (£
																			{amount && amount.amount})
																		</div>
																		<div
																			className="row f-fm fm-w5-s12 color-00"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																				color: "#ACACAC",
																			}}>
																			of 50% has been charged.
																		</div>
																	</>
																)}

																{/* {parseInt(
                                  appointment.cancelledtime.split("|")[1]
                                ) < 48 && (
                                  <>
                                    {" "}
                                    <div
                                      className="row f-fm fm-w5-s12 color-00"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#ACACAC",
                                      }}
                                    >
                                      A treatment cancellation fee (£
                                      {amount && amount.amount})
                                    </div>
                                    <div
                                      className="row f-fm fm-w5-s12 color-00"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#ACACAC",
                                      }}
                                    >
                                      of 50% has been charged.
                                    </div>
                                  </>
                                )} */}
															</div>
														</div>
													)}

												{appointment.cancellationstage === "appointment" &&
													appointment.missedby === "doctor" &&
													appointment.cancelledby === "" &&
													appointment.ispaymentdone === true && (
														<div
															className="col-12 pt-4"
															style={{ height: "70vh" }}>
															<div
																style={{
																	width: "426px",
																	height: "91px",
																	background: "#acacac36",
																	borderRadius: "13px 13px",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center",
																	flexWrap: "wrap",
																	flexDirection: "column",
																}}>
																<div
																	className="row f-fm fm-w5-s12 color-00"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																	}}>
																	- Missed by{" "}
																	{appointment.missedby
																		.charAt(0)
																		.toUpperCase() +
																		appointment.missedby.slice(1)}
																	-
																</div>

																<div
																	className="row f-fm fm-w5-s12 color-00"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																		color: "#ACACAC",
																	}}>
																	No Cancellation fee
																</div>
																{/* <div
                                className="row f-fm fm-w5-s12 color-00"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#ACACAC",
                                }}
                              >
                                A treatment cancellation fee (£
                                {amount && amount.amount})
                              </div>
                              <div
                                className="row f-fm fm-w5-s12 color-00"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#ACACAC",
                                }}
                              >
                                of 50% has been charged.
                              </div> */}
															</div>
														</div>
													)}

												{appointment.cancellationstage === "appointment" &&
													appointment.missedby === "patient" &&
													appointment.cancelledby === "" &&
													appointment.ispaymentdone === true && (
														<div
															className="col-12 pt-4"
															style={{ height: "70vh" }}>
															<div
																style={{
																	width: "426px",
																	height: "91px",
																	background: "#acacac36",
																	borderRadius: "13px 13px",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center",
																	flexWrap: "wrap",
																	flexDirection: "column",
																}}>
																<div
																	className="row f-fm fm-w5-s12 color-00"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																	}}>
																	- Missed by{" "}
																	{appointment.missedby
																		.charAt(0)
																		.toUpperCase() +
																		appointment.missedby.slice(1)}{" "}
																	-
																</div>

																{/* <div
                                  className="row f-fm fm-w5-s12 color-00"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#ACACAC",
                                  }}
                                >
                                  No Cancellation fee
                                </div> */}
																<div
																	className="row f-fm fm-w5-s12 color-00"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																		color: "#ACACAC",
																	}}>
																	A treatment cancellation fee (£
																	{amount && amount.amount})
																</div>
																<div
																	className="row f-fm fm-w5-s12 color-00"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																		color: "#ACACAC",
																	}}>
																	of 100% has been charged.
																</div>
															</div>
														</div>
													)}
											</Tab>
											<Tab
												eventKey="Appointment Details"
												title="Appointment Details"
												tabIndex="1">
												<>
													{appointment.cancelledby !== "" &&
														appointment.missedby === "" && (
															<div className="pt-2">
																<div
																	className="f-fm fm-w4-s16"
																	style={{
																		background: "#F4F4F4",
																		textAlign: "center",
																		marginLeft: "-31px",
																		marginRight: "-31px",
																		paddingTop: "6px",
																		paddingBottom: "6px",
																	}}>
																	Cancelled
																</div>
															</div>
														)}

													{appointment.cancelledby === "" &&
														appointment.missedby !== "" && (
															<div className="pt-2">
																<div
																	className="f-fm fm-w4-s16"
																	style={{
																		background: "#F4F4F4",
																		textAlign: "center",
																		marginLeft: "-31px",
																		marginRight: "-31px",
																		paddingTop: "6px",
																		paddingBottom: "6px",
																	}}>
																	Missed
																</div>
															</div>
														)}

													{appointment.videostatus === "Pending" &&
														appointment.cancelledby === "" &&
														appointment.missedby === "" &&
														appointment.isappointmentrescheduled === false && (
															<div className="row pt-4">
																<div className="col-6">
																	<div
																		className="col-9 f-fm fm-w4-s16"
																		style={{ cursor: "pointer" }}
																		onClick={() => {
																			handleApprove("Approved");
																			// setLoading(true);
																			// props.cancel();
																		}}>
																		<button
																			style={{
																				width: "175px",
																				height: "50px",
																				borderRadius: "40px",
																				backgroundColor: "#000",
																				color: "#fff",
																				border: "1px solid #000000",
																			}}>
																			Approve Request
																		</button>
																	</div>
																</div>
																<div className="col-4">
																	<div
																		className="col-9 f-fm fm-w4-s16"
																		style={{ cursor: "pointer" }}
																		onClick={() => {
																			handleShowDecline();
																		}}>
																		<button
																			style={{
																				width: "175px",
																				height: "50px",
																				borderRadius: "40px",
																				backgroundColor: "#fff",
																				color: "#404040",
																				border: "1px solid #000000",
																			}}>
																			Decline Request
																		</button>
																	</div>
																</div>
															</div>
														)}

													<div>
														<label
															style={{ color: "#ACACAC" }}
															className="f-fm fm-w6-s12 pt-5">
															TIME AND LOCATION
														</label>
													</div>
													<div>
														<label>
															<img
																style={{
																	width: "14px",
																	height: "14px",
																	color: "#777777",
																}}
																src="./images/time.png"
																alt=""
															/>
															&nbsp;&nbsp;
															<span className="f-fm fm-w4-s16 color-7">
																{/* {appointment.appointmentdate.split("T")[0] +
                                  " "}
                                {appointment.starttime} - {appointment.endtime} */}
																{moment(
																	new Date(
																		appointment.appointmentdate.split("T")[0]
																	)
																).format("ll")}
																&nbsp;&nbsp;
																{utils.convertTo12HourFormat(
																	appointment.starttime
																)}
																&nbsp;&nbsp;-&nbsp;&nbsp;
																{utils.convertTo12HourFormat(
																	appointment.endtime
																)}
															</span>
														</label>
													</div>

													<div className="col">
														<label className="d-flex">
															<img
																style={{
																	width: "14px",
																	height: "14px",
																	color: "#777777",
																	marginTop: "6px",
																	alignSelf: "auto",
																}}
																src="./images/map.png"
																alt=""
															/>
															&nbsp;&nbsp;
															<span className="f-fm fm-w4-s16 color-7">
																{aptaddress.line1}
																&nbsp;
																{aptaddress.line2}
																&nbsp;
																{aptaddress.towncity}
																&nbsp;
																{aptaddress.country}
																&nbsp;
																{aptaddress.postcode}
															</span>
														</label>
													</div>

													<div className="row pt-5">
														<div className="col-9">
															<label
																style={{ color: "#ACACAC" }}
																className="f-fm fm-w6-s12 ">
																TREATMENT-ONBOARDING VIDEO
															</label>
														</div>
														<div className="col-3">
															{appointment.videostatus === "Approved" && (
																<div
																	style={{
																		color: "#00C4A0",
																		display: "contents",
																	}}
																	className="f-fm fm-w7-s16">
																	Approved
																</div>
															)}
															{appointment.videostatus === "Declined" && (
																<div
																	style={{
																		// color: "#00C4A0",
																		display: "contents",
																	}}
																	className="f-fm fm-w7-s16">
																	Declined
																</div>
															)}
														</div>
													</div>

													<div className="row">
														{appointment.treatmentid[0]
															.split(",")
															.map((e, j) => {
																return (
																	<div
																		className="card"
																		style={{
																			backgroundColor: "#F4F4F4",
																			height: "143px",
																			width: "425px",
																			boxShadow:
																				"0px 5px 50px rgba(0, 0, 0, 0.02)",
																			borderRadius: "12.5px",
																			padding: "10px 10px",
																		}}>
																		<div className="row">
																			<div className="col-9">
																				{props.getvalue(e, j)}
																				{props.getprice(
																					e,
																					appointment.finalsyringes.split(","),
																					j
																				)}
																			</div>
																			<div className="col-3">
																				<div
																					style={{ cursor: "pointer" }}
																					onClick={() => {
																						props.videoUrl(
																							process.env.REACT_APP_AWS_S3 +
																								appointment.onboardingvideos.split(
																									","
																								)[j]
																						);
																						props.videoModal(true);
																						// props.hideapointment()
																					}}>
																					<video
																						style={{
																							height: "112px",
																							width: "80px",
																							borderRadius: "7px",
																						}}
																						preload="metadata"
																						// controls
																					>
																						<source
																							src={
																								process.env.REACT_APP_AWS_S3 +
																								appointment.onboardingvideos.split(
																									","
																								)[j]
																							}
																							type="video/mp4"
																						/>
																					</video>
																					<img
																						src="./images/video.png"
																						style={{
																							position: "absolute",
																							height: "20px",
																							width: "20px",
																							marginLeft: "-33px",
																							color: "#000",
																							marginTop: "7px",
																						}}></img>
																				</div>
																			</div>
																		</div>
																	</div>
																);
															})}
													</div>

													{/* <div className="col">
                            <label className="f-fm fm-w3-s12 color-00"></label>
                          </div> */}

													{/* <div className="row">
                            <div
                              className="col-9"
                              style={{
                                paddingBottom: "5px",
                              }}
                            >
                              <label className="f-fm fm-w6-s12 color-AC">
                                CONSULTATION
                              </label>
                            </div>
                          </div> */}

													{appointment.videostatus === "Declined" &&
														appointment.cstarttime !== "" &&
														appointment.cendtime !== "" &&
														appointment.cancelledby === "" &&
														appointment.missedby === "" &&
														appointment.isconsultationrescheduled === false && (
															<>
																<div className="row">
																	<div
																		className="col-10"
																		style={{
																			paddingBottom: "5px",
																		}}>
																		<label className="f-fm fm-w6-s12 color-AC">
																			CONSULTATION
																		</label>
																	</div>
																	{appointment.videourl !== "" && (
																		<div
																			className="col-2"
																			style={{
																				paddingBottom: "5px",

																				color: "#00C4A0",
																				display: "contents",
																			}}>
																			<label
																				className="f-fm fm-w6-s12 color-AC"
																				style={{
																					display: "contents",
																					color: "#00C4A0",
																				}}>
																				Completed
																			</label>
																		</div>
																	)}
																	{appointment.videourl === "" &&
																		request === false && (
																			<div
																				className="col-3"
																				style={{
																					paddingBottom: "5px",

																					color: "#00C4A0",
																					display: "contents",
																				}}>
																				<label
																					className="f-fm fm-w6-s12 color-AC"
																					style={{ display: "contents" }}>
																					Scheduled
																				</label>
																			</div>
																		)}
																</div>

																{appointment.videourl === "" && (
																	<div className="col">
																		<label
																			style={{
																				width: "430px",
																				background: "#446BF7",
																				borderRadius: "5px 5px 5px 5px",
																				float: "left",
																				cursor: "pointer",

																				alignItems: "center",
																			}}>
																			<div className="row">
																				<div className="col-6">
																					<label className="f-fm fm-w5-s12 color-00">
																						<label
																							style={{ padding: "10px 10px" }}>
																							<img
																								style={{
																									width: "20px",
																									height: "20px",
																									color: "#fff",
																									marginTop: "-5px",
																								}}
																								src="../images/Group 446.png"
																								alt=""
																							/>
																							&nbsp;&nbsp;
																							<span className="f-fm fm-w4-s15 color-FF">
																								{/* {appointment.starttime} -{" "}
                                                {appointment.endtime} */}
																								{utils.convertTo12HourFormat(
																									appointment.cstarttime
																								)}
																								&nbsp;-&nbsp;
																								{utils.convertTo12HourFormat(
																									appointment.cendtime
																								)}
																							</span>
																						</label>
																					</label>
																				</div>
																				<div
																					className="col-4 f-fm fm-w4-s15 color-FF"
																					style={{
																						display: "flex",
																						alignItems: "center",
																					}}>
																					{format(
																						new Date(
																							appointment.consultationdate.split(
																								"T"
																							)[0]
																						),
																						"MMM dd"
																					)}
																					&nbsp;&nbsp;│&nbsp;&nbsp;
																					{format(
																						new Date(
																							appointment.consultationdate.split(
																								"T"
																							)[0]
																						),
																						"E"
																					)}
																				</div>
																				<div
																					className="col-2"
																					style={{
																						display: "flex",
																						alignItems: "center",
																						justifyContent: "center",
																					}}>
																					{" "}
																					<span>
																						<OverlayTrigger
																							trigger="click"
																							rootClose
																							placement="left-start"
																							overlay={
																								<Popover
																									id="popover-basic"
																									style={{
																										height: "100px",
																										width: "120px",
																										backgroundColor: "#fff",
																										borderRadius: "10px 10px",
																									}}>
																									<Popover.Body
																										style={{
																											padding: "0px 0px",
																										}}>
																										<button
																											style={{
																												color: rescheduleDisable
																													? "#acacac"
																													: "#000",
																												// paddingTop: "20px",
																												// paddingLeft: "20px",
																												cursor: "pointer",
																												background:
																													rescheduleDisable
																														? "#fff"
																														: "",
																												display: "flex",
																												alignItems: "center",
																												justifyContent:
																													"center",
																												marginTop: "15px",
																												height: "32px",
																												border: "none",
																												width:
																													"-webkit-fill-available",
																											}}
																											onClick={() => {
																												handleShowReschedule();
																											}}
																											className="f-fm fm-w4-s14"
																											disabled={
																												rescheduleDisable
																											}>
																											Reschedule
																										</button>
																										<div
																											style={{
																												color: "#000",
																												// paddingTop: "20px",
																												// paddingLeft: "20px",
																												cursor: "pointer",
																												display: "flex",
																												alignItems: "center",
																												justifyContent: "end",
																												marginTop: "5px",
																												paddingRight: "23px",
																												height: "32px",
																											}}
																											onClick={() => {
																												handleShowCancel();
																											}}
																											className="f-fm fm-w4-s14 re">
																											Cancel
																										</div>
																									</Popover.Body>
																								</Popover>
																							}
																							style={{ alignItems: "end" }}>
																							<img
																								loading="lazy"
																								src="../images/Group 210.png"
																								alt="avatar"
																								style={{
																									cursor: "pointer",
																								}}></img>
																						</OverlayTrigger>
																					</span>
																				</div>
																			</div>
																		</label>
																	</div>
																)}
															</>
														)}

													{appointment.isconsultationrescheduled === true && (
														<div className="">
															<div className="row">
																<div
																	className="col-8"
																	style={{
																		paddingBottom: "5px",
																	}}>
																	<label className="f-fm fm-w6-s12 color-AC">
																		CONSULTATION
																	</label>
																</div>
																{appointment.videourl === "" &&
																	appointment.isconsultationrescheduled ===
																		true && (
																		<div
																			className="col-3"
																			style={{
																				paddingBottom: "5px",

																				color: "#00C4A0",
																				display: "contents",
																			}}>
																			<label
																				className="f-fm fm-w6-s12 color-00"
																				style={{ display: "contents" }}>
																				Pending Consultation
																			</label>
																		</div>
																	)}
															</div>

															<div
																style={{
																	width: "426px",
																	height: "52px",
																	background: "#acacac36",
																	borderRadius: "13px 13px",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center",
																	flexWrap: "wrap",
																	flexDirection: "column",
																}}>
																<div
																	className="row f-fm fm-w5-s12 color-00"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																	}}>
																	- Request has sent to your patient -
																</div>
															</div>
														</div>
													)}

													{appointment.cancelledby === "patient" &&
														appointment.missedby === "" &&
														appointment.cancellationstage === "consultation" &&
														appointment.ispaymentdone === true && (
															<div className="">
																<div
																	style={{
																		paddingBottom: "5px",
																	}}>
																	<label className="f-fm fm-w6-s12 color-AC">
																		CONSULTATION
																	</label>
																</div>
																<div
																	style={{
																		width: "426px",
																		height: "91px",
																		background: "#acacac36",
																		borderRadius: "13px 13px",
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																		flexWrap: "wrap",
																		flexDirection: "column",
																	}}>
																	<div
																		className="row f-fm fm-w5-s12 color-00"
																		style={{
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																		}}>
																		- Cancelled by{" "}
																		{appointment.cancelledby
																			.charAt(0)
																			.toUpperCase() +
																			appointment.cancelledby.slice(1)}{" "}
																		-
																	</div>

																	{cancelledTime(appointment) <
																		consultationtDateTime(appointment) && (
																		<div
																			className="row f-fm fm-w5-s12 color-00"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																				color: "#ACACAC",
																			}}>
																			No Cancellation fee
																		</div>
																	)}

																	{/* {parseInt(
                                    appointment.cancelledtime.split("|")[1]
                                  ) >= 24 && (
                                    <div
                                      className="row f-fm fm-w5-s12 color-00"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#ACACAC",
                                      }}
                                    >
                                      No Cancellation fee
                                    </div>
                                  )} */}

																	{cancelledTime(appointment) >
																		consultationtDateTime(appointment) && (
																		<>
																			<div
																				className="row f-fm fm-w5-s12 color-00"
																				style={{
																					display: "flex",
																					alignItems: "center",
																					justifyContent: "center",
																					color: "#ACACAC",
																				}}>
																				A consultation cancellation fee (£
																				{amount && amount.consultationfee}))
																			</div>
																			<div
																				className="row f-fm fm-w5-s12 color-00"
																				style={{
																					display: "flex",
																					alignItems: "center",
																					justifyContent: "center",
																					color: "#ACACAC",
																				}}>
																				of 50% has been charged.
																			</div>
																		</>
																	)}

																	{/* {parseInt(
                                    appointment.cancelledtime.split("|")[1]
                                  ) < 24 && (
                                    <>
                                      <div
                                        className="row f-fm fm-w5-s12 color-00"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "#ACACAC",
                                        }}
                                      >
                                        A consultation cancellation fee (£
                                        {amount && amount.consultationfee}))
                                      </div>
                                      <div
                                        className="row f-fm fm-w5-s12 color-00"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "#ACACAC",
                                        }}
                                      >
                                        of 50% has been charged.
                                      </div>
                                    </>
                                  )} */}
																</div>
															</div>
														)}

													{appointment.cancelledby === "doctor" &&
														appointment.missedby === "" &&
														appointment.cancellationstage === "consultation" &&
														appointment.ispaymentdone === true && (
															<div className="">
																<div
																	style={{
																		paddingBottom: "5px",
																	}}>
																	<label className="f-fm fm-w6-s12 color-AC">
																		CONSULTATION
																	</label>
																</div>
																<div
																	style={{
																		width: "426px",
																		height: "91px",
																		background: "#acacac36",
																		borderRadius: "13px 13px",
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																		flexWrap: "wrap",
																		flexDirection: "column",
																	}}>
																	<div
																		className="row f-fm fm-w5-s12 color-00"
																		style={{
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																		}}>
																		- Cancelled by{" "}
																		{appointment.cancelledby
																			.charAt(0)
																			.toUpperCase() +
																			appointment.cancelledby.slice(1)}{" "}
																		-
																	</div>

																	<div
																		className="row f-fm fm-w5-s12 color-00"
																		style={{
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																			color: "#ACACAC",
																		}}>
																		No Cancellation fee
																	</div>

																	{/* {parseInt(appointment.cancelledtime.split("|")[1])<24&&<><div
                                    className="row f-fm fm-w5-s12 color-00"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#ACACAC",
                                    }}
                                  >
                                    A consultation cancellation fee (£
                                    {amount && amount.amount}))
                                  </div>
                                  <div
                                    className="row f-fm fm-w5-s12 color-00"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#ACACAC",
                                    }}
                                  >
                                    of 50% has been charged.
                                  </div></>} */}
																</div>
															</div>
														)}

													{appointment.cancelledby === "" &&
														appointment.missedby === "doctor" &&
														appointment.cancellationstage === "consultation" &&
														appointment.ispaymentdone === true && (
															<div className="">
																<div
																	style={{
																		paddingBottom: "5px",
																	}}>
																	<label className="f-fm fm-w6-s12 color-AC">
																		CONSULTATION
																	</label>
																</div>
																<div
																	style={{
																		width: "426px",
																		height: "91px",
																		background: "#acacac36",
																		borderRadius: "13px 13px",
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																		flexWrap: "wrap",
																		flexDirection: "column",
																	}}>
																	<div
																		className="row f-fm fm-w5-s12 color-00"
																		style={{
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																		}}>
																		- Missedby by{" "}
																		{appointment.missedby
																			.charAt(0)
																			.toUpperCase() +
																			appointment.missedby.slice(1)}{" "}
																		-
																	</div>

																	<div
																		className="row f-fm fm-w5-s12 color-00"
																		style={{
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																			color: "#ACACAC",
																		}}>
																		No Cancellation fee
																	</div>
																	{/* <div
                                  className="row f-fm fm-w5-s12 color-00"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#ACACAC",
                                  }}
                                >
                                  A consultation cancellation fee (£{amount})
                                </div>
                                <div
                                  className="row f-fm fm-w5-s12 color-00"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#ACACAC",
                                  }}
                                >
                                  of 50% has been charged.
                                </div> */}
																</div>
															</div>
														)}

													{appointment.cancelledby === "" &&
														appointment.missedby === "patient" &&
														appointment.cancellationstage === "consultation" &&
														appointment.ispaymentdone === true && (
															<div className="">
																<div
																	style={{
																		paddingBottom: "5px",
																	}}>
																	<label className="f-fm fm-w6-s12 color-AC">
																		CONSULTATION
																	</label>
																</div>
																<div
																	style={{
																		width: "426px",
																		height: "91px",
																		background: "#acacac36",
																		borderRadius: "13px 13px",
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																		flexWrap: "wrap",
																		flexDirection: "column",
																	}}>
																	<div
																		className="row f-fm fm-w5-s12 color-00"
																		style={{
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																		}}>
																		- Missedby by{" "}
																		{appointment.missedby
																			.charAt(0)
																			.toUpperCase() +
																			appointment.missedby.slice(1)}{" "}
																		-
																	</div>

																	{/* <div
                                    className="row f-fm fm-w5-s12 color-00"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#ACACAC",
                                    }}
                                  >
                                    No Cancellation fee
                                  </div> */}
																	<div
																		className="row f-fm fm-w5-s12 color-00"
																		style={{
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																			color: "#ACACAC",
																		}}>
																		A consultation cancellation fee (£
																		{amount && amount.consultationfee}))
																	</div>
																	<div
																		className="row f-fm fm-w5-s12 color-00"
																		style={{
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																			color: "#ACACAC",
																		}}>
																		of 100% has been charged.
																	</div>
																</div>
															</div>
														)}

													{appointment.videourl !== "" && (
														<>
															<div className="row">
																<div
																	className="col"
																	style={{
																		paddingBottom: "3px",
																	}}>
																	<video
																		style={{
																			height: "152px",
																			width: "250px",
																		}}
																		controls>
																		<source
																			src={
																				process.env.REACT_APP_AWS_S3 +
																				appointment.videourl
																			}
																			type="video/mp4"
																		/>
																	</video>
																</div>
															</div>
															<div className="row">
																<div
																	className="col"
																	style={{
																		paddingBottom: "5px",
																	}}>
																	<label className="f-fm fm-w3-s16 color-00">
																		{moment(
																			new Date(
																				appointment.appointmentdate.split(
																					"T"
																				)[0]
																			)
																		).format("ll") + " "}
																		{/* {appointment.starttime} -{" "}
                                    {appointment.endtime} */}
																		&nbsp;&nbsp;
																		{utils.convertTo12HourFormat(
																			appointment.starttime
																		)}
																		{/* &nbsp;&nbsp;-&nbsp;&nbsp;
                                    {utils.convertTo12HourFormat(appointment.endtime)} */}
																	</label>
																</div>
															</div>
															<div className="row">
																<div
																	className="col"
																	style={{
																		paddingBottom: "25px",
																	}}>
																	<label className="f-fm fm-w3-s16 color-00">
																		Duration: {appointment.videoduration}
																	</label>
																</div>
															</div>
														</>
													)}

													<div className="row pt-3">
														<div
															className="col"
															style={{
																paddingBottom: "5px",
															}}>
															<label className="f-fm fm-w6-s12 color-AC">
																ALLERGY
															</label>
														</div>
													</div>
													<div className="row">
														<div
															className="col"
															style={{
																paddingBottom: "25px",
															}}>
															<label
																className="f-fm fm-w5-s14 color-00"
																style={{
																	display: "flex",
																	flexFlow: "wrap",
																}}>
																{appointment.patient_details[0].allergies ===
																	"" ||
																appointment.patient_details[0].allergies === " "
																	? "N/A"
																	: appointment.patient_details[0].allergies
																			.split("|")
																			.map((allergy) => {
																				return (
																					<span
																						style={{
																							backgroundColor: "#ACACAC36",
																							margin: "5px",
																							paddingTop: "3px",
																							paddingBottom: "3px",
																							paddingRight: "15px",
																							paddingLeft: "15px",
																							borderRadius: "5px",
																						}}>
																						{allergy}
																					</span>
																				);
																			})}
															</label>
														</div>
													</div>
													<div className="row">
														<div
															className="col"
															style={{
																paddingBottom: "5px",
															}}>
															<label className="f-fm fm-w6-s12 color-Ac">
																TREATMENT HISTORY
															</label>
														</div>
													</div>
													<div className="row">
														<div className="col-10">
															<label
																style={{
																	width: "429px",
																	height: "68px",
																	background: "#acacac36",
																	borderRadius: "5px 5px 0px 0px",
																	float: "left",
																	cursor: "pointer",
																	display: "flex",
																	alignItems: "center",
																	justifyContent:
																		duphistoryTempArray.length === 0
																			? "center"
																			: "left",
																}}
																onClick={() => {
																	getPatients(
																		appointment.patientid,
																		appointment.appointmentdate,
																		appointment.doctorid
																	);

																	handleHistory();
																}}>
																<label
																	className="f-fm fm-w5-s12 color-00"
																	style={{
																		fontWeight: "bold",
																		paddingLeft: "30px",
																	}}>
																	{history1 === false && (
																		<label>previous history</label>
																	)}
																	{duphistoryTempArray.length === 0 &&
																		history1 === true && (
																			<label>- No previous history -</label>
																		)}
																	{duphistoryTempArray.length !== 0 &&
																		history1 === true && (
																			<label>
																				{duphistoryTempArray.length}
																				&nbsp; treatment history
																			</label>
																		)}
																</label>
															</label>
														</div>
														<div
															className="col-1"
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "left",
															}}>
															{history ? (
																<i class="fa fa-light fa-angle-up"></i>
															) : (
																<i class="fa fa-light fa-angle-down"></i>
															)}
														</div>
													</div>
													{history && (
														<div className="row">
															<div className="col">
																<div
																	style={{
																		width: "429px",
																		height: "140px",
																		background: "#acacac36",
																		borderRadius: "0px 0px 5px 5px",
																		overflowY: "auto",
																	}}>
																	{duphistoryTempArray.map((w, k) => {
																		return (
																			<>
																				<div
																					className="f-fm fm-w5-s12 color-00"
																					style={{
																						paddingLeft: "30px",
																					}}>
																					{moment(
																						new Date(
																							w.appointmentdate.split("T")[0]
																						)
																					).format("ll")}
																					&nbsp;&nbsp;
																					{utils.convertTo12HourFormat(
																						w.starttime
																					)}
																					&nbsp;&nbsp;-&nbsp;&nbsp;
																					{utils.convertTo12HourFormat(
																						w.endtime
																					)}
																				</div>

																				<div
																					className="f-fm fm-w6-s16 color-00"
																					style={{
																						paddingLeft: "30px",
																						paddingBottom: "25px",
																					}}>
																					{w.treatmentid[0]
																						.split(",")
																						.map((tid, l) => {
																							return (
																								<>
																									<label>
																										{/* {getTreatmentName(tid)} */}
																										{props.getvalue(tid, l)}
																									</label>
																									<label
																										className="col f-fm fm-w6-s14 color-00"
																										style={{
																											paddingLeft: "20px",
																										}}>
																										<img
																											src="images/injection.png"
																											alt="injection"></img>
																										&nbsp;
																										<label className="f-fm fm-w6-s14 color-00">
																											x
																											{
																												w.finalsyringes.split(
																													","
																												)[l]
																											}
																										</label>
																									</label>
																									;
																								</>
																							);
																						})}
																				</div>
																			</>
																		);
																	})}
																</div>
															</div>
														</div>
													)}
												</>
											</Tab>
										</Tabs>
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal.Title>
				</Modal.Header>
			</Modal>
		</>
	);
};

export default ApprovalModel;
