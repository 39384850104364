import React from "react";
import ReactDOM from "react-dom/client";
import "./fonts/Mulish/Mulish-VariableFont_wght.ttf";
import "./fonts/Raleway/Raleway-VariableFont_wght.ttf";
import "./fonts/Plus_Jakarta_Sans/PlusJakartaSans-VariableFont_wght.ttf";
import "./index.css";
import "./style.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
