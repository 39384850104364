import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../common/logo";
import * as utils from "../common/util";
import Businessmobile from "./businessmobile";
import Popupforbusiness from "./popupforbusiness";

const Business = () => {
	const firstupdate = useRef(true);
	const firststateupdate = useRef(true);
	const [isPageLoaded, setisPageLoaded] = useState(false);
	const navigate = useNavigate();
	const [show, setShow] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);
	const [hoverbar, setHoverBar] = useState(false);
	var timer = null;
	const controlNavbar1 = (event) => {
		console.log(event.target.className);
		if (
			(event.target.className.indexOf("jnav") > 0 && hoverbar) ||
			window.scrollY === 0
		) {
			setShow(true);
			setHoverBar(true);
			clearTimeout(timer);
			timer = null;
		}
	};
	const controlNavbar = () => {
		if (typeof window !== "undefined") {
			//console.log(window.scrollY, lastScrollY, hoverbar)
			if (window.scrollY === 0) {
				console.log("ell", hoverbar);
				clearTimeout(timer);
				timer = null;
				setHoverBar(false);
				setShow(true);
			} else if (window.scrollY > lastScrollY) {
				// if scroll down hide the navbar
				setShow(false);
			} else {
				// if scroll up show the navbar
				//console.log("else", window.scrollY, lastScrollY, hoverbar)
				setShow(true);
				if (hoverbar) {
					const timer = setTimeout(() => {
						setShow(true);
					}, 4000);
				} else {
					if (window.scrollY !== 0) {
						timer = setTimeout(() => {
							if (window.scrollY != 0) {
								setShow(false);
							}
						}, 4000);
					}
				}
			}

			// remember current page location to use in the next move
			setLastScrollY(window.scrollY);
		}
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", controlNavbar);
			//window.addEventListener('mouseover', controlNavbar1);
			window.document
				.getElementById("mynav")
				.addEventListener("mousemove", controlNavbar1);

			// cleanup function
			return () => {
				window.removeEventListener("scroll", controlNavbar);
				console.log("mmm", typeof window.document.getElementById("mynav"));
				if (window.document.getElementById("mynav")) {
					window.document
						.getElementById("mynav")
						.removeEventListener("mousemove", controlNavbar1);
				}
			};
		}
	}, [lastScrollY]);

	useEffect(() => {
		if (firstupdate.current) {
			(async function anyNameFunction() {
				const timer = setTimeout(() => {
					setisPageLoaded(true);
					firstupdate.current = false;
					firststateupdate.current = false;
				}, 2000);
				return () => clearTimeout(timer);
			})();
		}
	}, []);

	const handleMouseEnter = () => {
		setHoverBar(true);
		setShow(true);
	};
	const handleMouseMove = () => {
		setHoverBar(true);
		setShow(true);
	};
	const handleMouseLeave = () => {};
	return (
		<center>
			<div className="body-bg-home color">
				<div id="border">
					<nav
						id="mynav"
						onMouseMove={handleMouseMove}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						className={`navbar  dvall1 container-fluid   fixed-top  navbar-expand-lg navbar-dark bg-light bg-light1  ${
							!show && "hidden"
						} `}
					>
						<div className="container-fluid jnav navh">
							{/* <div className="popupnav">  */}
							<Logo logo="dark" className="jnav"></Logo>
							{/* </div> */}
							{/* <div  className="groupimage380"> <Logo logo="dark1"></Logo></div> */}
							{/* <div className="popup"> */}
							{/* <button
								type="button"
								className="navbar-toggler mr-sm  collapsed "
								data-bs-toggle="collapse"
								data-bs-target="#navbarCollapse"
								aria-expanded="false"
							>
								<img
									className=""
									loading="lazy"
									alt=""
									src="../images/Frame17.png"
									style={{ width: "30px", height: "30px" }}
								></img>
							</button> */}
							<div className="tabletnav">
								<Popupforbusiness></Popupforbusiness>
							</div>
							{/* </div> */}
							{/* <div className="groupimage380">
              <Popupforbusiness></Popupforbusiness>
            </div> */}
							<div
								className="navbar-collapse collapse jnav"
								style={{ whiteSpace: "nowrap", width: "100%", height: "100px" }}
								id="navbarCollapse"
							>
								<div
									className="navbar-nav p-4 p-lg-0 pr-r20 jnav"
									id="navbarnav"
								>
									<a
										className="nav-link1 f-rl fm-w7-s20 jnav"
										href="/everyone"
										style={{
											color: "#000000",
										}}
									>
										For Everyone
										<div
											className="jnav"
											style={{
												backgroundColor: "transparent",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
											}}
										></div>
									</a>
									<a
										className="nav-link1 f-rl fm-w5-s20 jnav"
										href="/business"
										style={{
											color: "#AF805E",
										}}
									>
										For business
										<div
											className="jnav"
											style={{
												backgroundColor: "#B58A6C",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
												width: "123px",
											}}
										></div>
									</a>
									<a
										className="nav-link1 f-rl fm-w5-s20 jnav"
										href="/login"
										style={{
											color: "#000000",
										}}
									>
										Login
										<div
											className="jnav"
											style={{
												backgroundColor: "transparent",
												borderRadius: "5px",
												height: "3px",
												marginTop: "3px",
											}}
										></div>
									</a>
								</div>
								<div className="d-flex burger jnav">
									<button
										className="btn-rounded-black jnav"
										style={{
											border: "1px solid #fff",
										}}
										onClick={() => navigate("/signup/social")}
									>
										Business Sign Up
									</button>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<a
										href="../downloads/app-debug.apk"
										download
										className="jnav"
									>
										<button
											className="btn-rounded-white jnav"
											style={{
												border: "1px solid #000",
											}}
										>
											{" "}
											Download the App
										</button>
									</a>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								</div>
							</div>
						</div>
					</nav>
				</div>
				<div className="businesmobile">
					<Businessmobile />
				</div>
				<div className="container-fluid dvall1 businesmobile1">
					<div
						className="row no-gutters "
						style={{ backgroundColor: "#ffffff", paddingTop: "100px" }}
					>
						<div
							className="col"
							style={{
								backgroundColor: "#fff",
								backgroundImage: "url('../images/Rectangle199.png')",
								backgroundRepeat: "no-repeat",
								height: "500px",
								paddingTop: "115px",
							}}
						>
							<div
								className="row ptbusiness pbbusiness "
								// style={{ paddingTop: "250px" }}
							>
								<div className="col-12">
									<div className="row">
										<div
											className="f-rl fm-w7-s24 coral fontbusiness homefs1"
											style={{ lineHeight: "28.18px" }}
										>
											For Clinics and Doctors
										</div>
									</div>
									<div className="row pt-3 pt1920 ">
										<div
											className="f-rl fm-w3-s68 color-00 pbpfbusines fontbusiness fse1600 fstablet "
											style={{
												paddingLeft: "160px",
												textAlign: "left",
												lineHeight: "80px",
											}}
										>
											HELPING YOU SERVICE BETTER
										</div>
									</div>
									<div className="row pt-3">
										<div
											className="f-rl fm-w4-s28 color-00  pbpfbusines1 fontbusiness mbhero homefs1"
											style={{
												paddingLeft: "160px",
												// textAlign: "left",
												marginBottom: "250px",
												lineHeight: "32.87px",
											}}
										>
											with a simple solution to help you service your<br></br>
											customer while in their own homes.
										</div>
									</div>
								</div>
							</div>
							{/* <div
								className="row"
								style={{
									marginTop: "240px",
									marginBottom: "200px",
									paddingLeft: "80px",
									backgroundColor: "#000000",
								}}
							>
								<div className="col-8"></div>
							</div> */}
						</div>
					</div>
					<div className="row no-gutters">
						<div
							className="col-xl col-lg col-md col-xs col-sm topbusiness topfirstbusiness"
							style={{
								position: "absolute",
								top: "600px",
								backgroundColor: "#FFFFFF",
							}}
						>
							<div
								className="row pt-6 imgbusiness plall pl1399 colreverse pl1199"
								style={{}}
							>
								<div
									className="col-xl-6 col-lg-12 col-md-8 col-xs-11 col-sm-11 "

									// style={{
									//   backgroundImage: "url(../images/screenshot2.png)",
									//   backgroundRepeat: "no-repeat",
									//   height: "640px",
									//   width:"640px"
									// }}
								>
									<div className="resize1">
										<img
											loading="lazy"
											alt=""
											src="../images/screenshot2.png"
											style={{ height: "640px", width: "640px" }}
										></img>
									</div>
									<div className="resize">
										<img
											className="fl"
											loading="lazy"
											alt=""
											src="../images/resize1.png"
											style={{}}
										></img>
									</div>
									<div
										className="calender left485 "
										style={{
											position: "absolute",
											left: "378px",
											top: "240px",
										}}
									>
										<img
											className="calendarwidth"
											loading="lazy"
											alt=""
											src="../images/calendar.png"
											style={{ width: "100%" }}
										></img>
									</div>
								</div>
								<div className="col-xl-1 col-lg-1 col-md-1 col-xs-1 col-sm-1 col-1">
									&nbsp;
								</div>
								<div
									className="col-xl-5 col-lg-5 col-md-10 col-xs-10 col-sm-10 col-11 pt-200 pl-business pb1199"
									style={{ textAlign: "left" }}
								>
									<div
										className="f-rl fm-w3-s52p5 fonttext plf fstablet"
										style={{ lineHeight: "61.34px", paddingBottom: "37px" }}
									>
										Manage Your<br></br>Schedule
									</div>

									<div
										className="f-rl fm-w4-s23p5 plf homeiconfs"
										style={{ lineHeight: "23.51px" }}
									>
										A simple way to set up your<br></br>calender and manage your
										patient<br></br>appointments.
									</div>
								</div>
							</div>
							<div className="row pt-6 bussinesspt pr1399 pl1199">
								<div className="col-xl-1 col-lg-2 col-md-2 col-xs-2 col-sm-2 col-2">
									&nbsp;
								</div>
								<div
									className="col-xl-3 col-lg-5 col-md-10 col-xs-10 col-sm-10 col-10 pt-200 pb1199"
									style={{ textAlign: "left" }}
								>
									<div
										className="f-rl fm-w3-s52p5 plbusiness fonttext fstablet"
										style={{ lineHeight: "61.34px", paddingBottom: "37px" }}
									>
										On-demand<br></br>Home Service
									</div>

									<div
										className="f-rl fm-w4-s23p5 plbusiness homeiconfs"
										style={{ lineHeight: "23.51px" }}
									>
										Consultant with patients with live video, view treatments,
										and track your orders.
									</div>
								</div>
								<div className="col-xl-2 col-lg-2  col-0"></div>
								<div
									className="col-xl-6 col-lg-12 col-md-8 col-xs-11 col-sm-11 col-12"
									// style={{
									//   backgroundPosition: "top left",
									//   backgroundImage: "url(../images/screenshot4.png)",
									//   backgroundRepeat: "no-repeat",
									//   height: "640px",
									//   width: "640px"
									// }}
								>
									<div className="resize1">
										<img
											loading="lazy"
											alt=""
											src="../images/screenshot4.png"
											style={{ height: "640px", width: "640px" }}
										></img>
									</div>
									<div className="resize">
										<img
											className="fl"
											loading="lazy"
											alt=""
											src="../images/resize2.png"
											style={{}}
										></img>
									</div>
									<div
										className="imgimg left725 tlbus"
										style={{
											position: "absolute",
											left: "-30px",
											top: "1240px",
										}}
									>
										<img
											loading="lazy"
											alt=""
											src="../images/Treatments.png"
											style={{ width: "100%" }}
										></img>
									</div>
								</div>
							</div>
							<div className="row pt-6 imgbusiness2 plall pbtext pl1399 colreverse pl1199 pbpb">
								<div
									className="col-xl-6 col-lg-10 col-md-8 col-xs-11 col-sm-11"
									// style={{
									//   backgroundImage: "url(../images/screenshot3.png)",
									//   backgroundRepeat: "no-repeat",
									//   height: "640px",
									//   width: "640px"
									// }}
								>
									<div className="resize1">
										<img
											loading="lazy"
											alt=""
											src="../images/screenshot3.png"
											style={{ height: "640px", width: "640px" }}
										></img>
									</div>
									<div className="resize">
										<img
											className="fl"
											loading="lazy"
											alt=""
											src="../images/resize3.png"
											style={{}}
										></img>
									</div>
									<div
										className="checkin left560 "
										style={{
											position: "absolute",
											left: "402px",
											top: "1730px",
										}}
									>
										<img
											loading="lazy"
											alt=""
											src="../images/check in.png"
											style={{ width: "100%" }}
										></img>
									</div>
								</div>
								<div className="col-xl-1 col-lg-1 col-md-1 col-xs-1 col-sm-1 col-1">
									&nbsp;
								</div>
								<div
									className="col-xl-5 col-lg-5 col-md-10 col-xs-10 col-sm-10 col-11 pt-230 pb1199"
									style={{ textAlign: "left" }}
								>
									<div
										className="f-rl fm-w3-s52p5 plf fonttext fstablet"
										style={{ lineHeight: "61.34px", paddingBottom: "37px" }}
									>
										Secure and<br></br>
										Safe Check-in
									</div>

									<div
										className="f-rl fm-w4-s23p5 plf homeiconfs"
										style={{ lineHeight: "23.51px" }}
									>
										Home service via secure pin code<br></br>
										with Uber-like map tracking.
									</div>
								</div>
							</div>

							<div
								className="row ptt1399 pt-6 bussinesspt1 hmacbook "
								style={{ backgroundColor: "#EDE3DC", height: "1000px" }}
							>
								<div
									className=" pl768 col-xl-6 col-lg-5 col-md-5 col-xs-12 col-sm-12 pt-90"
									style={{
										textAlign: "left",
										paddingLeft: "110px",
									}}
								>
									<div
										className="f-rl fm-w3-s52p5 lapyfont fstablet pb1200 "
										style={{ paddingLeft: "97px", lineHeight: "61.13px" }}
									>
										Manage Your Business In<br></br>The Dashboard
									</div>
									<div
										className="lapy lappypadding"
										style={{
											position: "absolute",
											left: "0px",
											top: "2318px",
										}}
									>
										<img
											className="widthlappy whnewmac wlappy1300 wh1200 whlappy900"
											loading="lazy"
											alt=""
											src="../images/newmacbook.png"
											style={{ width: "792px", height: "555px" }}
										></img>
									</div>
								</div>
								<div
									className="col-xl-1 col-lg-1"
									style={{ width: "100px" }}
								></div>

								<div
									className="col-xl-5 col-lg-5 col-md-5 col-xs-12 col-sm-12 businessww  "
									// style={{
									// 	backgroundImage: "url(../images/screenshotwomen.png)",
									// 	// backgroundPosition: "top right",
									// 	backgroundRepeat: "no-repeat",
									// 	height: "1294px",
									// }}
								>
									<img
										className="whwomen mr150 fleft"
										loading="lazy"
										alt=""
										src="../images/screenshotwomen.png"
										style={{ width: "100%" }}
									></img>
								</div>
							</div>

							<div className="pt1399">
								<div
									className="row mobilereverse  pt-6 pt1920 mobilepadding1 pttop plall pl1399 pl1199"
									style={{ backgroundColor: "#F3F1F0" }}
								>
									<div
										className="col-xl-6 col-lg-6 col-md-8 col-xs-12 col-sm-12 ht-1250 mobilepadding"
										style={{
											backgroundImage: "url(../images/screenshot.png)",
											backgroundRepeat: "no-repeat",
											borderRadius: "28px",
											height: "894px",
										}}
									>
										<div
											className="mobile mobilepadding2 tlmobile tlmobile900"
											style={{
												position: "absolute",
												left: "500px",
												top: "4400px",
											}}
										>
											<img
												className="widthmobile whmobilebusiness "
												loading="lazy"
												alt=""
												src="../images/mobile.png"
												style={{ width: "100%" }}
											></img>
										</div>
									</div>
									<div
										className="col-xl-6 col-lg-6 col-md-8 col-xs-12 col-sm-12 mobilepadding paddingleftzero"
										style={{
											textAlign: "left",
											paddingLeft: "50px",
										}}
									>
										<div
											className="f-rl fm-w3-s52p5 lapyfont fonttext pfpadding fstablet pb1199"
											style={{ lineHeight: "61.13px", paddingLeft: "30px" }}
										>
											Make The Treatment Easy By Using doctor’s App
										</div>
									</div>
								</div>
							</div>
							<div className="row no-gutters">
								{/* <div style={{width:"541px"}}> */}
								<div
									className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 col-12 d-flex ht-1"
									style={{
										textAlign: "center",
										backgroundColor: "#b58a6c",
										flexDirection: "column",
										alignContent: "center",
										justifyContent: "center",
										height: "541px",
									}}
								>
									<div className="row ">
										<div className="col-12">
											<div
												className="f-rl fm-w3-s52p5 color-FF fstablet"
												style={{}}
											>
												Let's Get Started
											</div>
										</div>
									</div>
									<br />
									<br />
									<div className="row">
										<div
											className="col-12 signflex ml1399"
											style={{ marginLeft: "-54px" }}
										>
											<button
												className="btn-rounded-black"
												onClick={() => navigate("/signup/social")}
											>
												Sign Up
											</button>
										</div>
									</div>
								</div>
								{/* </div> */}
								<div
									className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-12 "
									style={{
										padding: "0px",
									}}
								>
									<img
										className="ht-1"
										loading="lazy"
										alt=""
										src="../images/Rectangle 194.png"
										style={{}}
									></img>
								</div>
								<div
									className="view1920"
									style={{ backgroundColor: "#000000" }}
								>
									{" "}
									{utils.Footer1920()}
								</div>
								<div className="view992" style={{ backgroundColor: "#000000" }}>
									{" "}
									{utils.Footer1()}
								</div>
								<div
									className="view19201 view9921"
									style={{ backgroundColor: "#000000" }}
								>
									{" "}
									{utils.Footer()}
								</div>
							</div>

							{/* </div> */}
						</div>
					</div>
				</div>
			</div>
		</center>
	);
};

export default Business;
