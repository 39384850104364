import React from "react";

export default function Search() {
	return (
		<div style={{ position: "relative" }}>
			{/* <img
				style={{ width: "100%", height: "auto" }}
				src="../images/Group626.png"
			></img> */}
			<div
				style={{
					position: "absolute",
					top: "10%",
					left: "18%",
					color: "white",
				}}
				alt="img"
			>
				<div
					className="col-xl-12 col-lg-8 col-md-6 col-xs-1 col-sm-1 d-flex justify-content-start"
					style={{ textAlign: "left" }}
				>
					<div
						className="f-rl   color-FF  pb332 "
						style={{
							// paddingLeft: "160px",
							lineHeight: "82.18px",
							// paddingTop: "250px",
							// paddingBottom: "165px",
						}}
					>
						MEET THE BEST
						<div className="">COSMETIC TREATMENTS</div>
						<div className="" style={{ fontSize: "88", fontWeight: "600" }}>
							AT HOME
						</div>
						<div
							className="roundWhiteDownload1920 roundWhiteDownload mbutton1920 mt61 roundWhiteDownloadmobile homechangebtn homechangebtnin"
							style={{
								marginTop: "20px",
							}}
						>
							Download the App&nbsp;&nbsp;&nbsp;&nbsp;
							<img
								className="homechangebtnarrow"
								loading="lazy"
								alt=""
								src="../images/Arrowb4.png"
								style={{ width: "38.11px", height: "12px" }}
							></img>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{ position: "absolute", top: "70%", color: "white" }}
				alt="img"
			>
				<div
					className="row  "
					style={{
						// paddingTop: "72px",
						//   marginBottom: "277px",
						paddingLeft: "80px",
						// paddingBottom: "50px",
					}}
				>
					<div className="col-xl-8 col-lg-10 col-md-12 col-xs-8 col-sm-12">
						<div className="row color-FF">
							<div className="col-xl col-lg col-md col-xs col-sm">
								<div className="row">
									<div className="col">
										<img
											className="h40w40 homeiconswh"
											loading="lazy"
											src="../images/onlinechat.png"
											alt="logo"
											style={{ width: "60px", height: "61px" }}
										></img>
									</div>
								</div>
								<div className="row pt-4 pt1920">
									<div
										className="col fm-w60-s22 smallimg s18 homeiconfs"
										style={{ lineHeight: "23.48px" }}
									>
										FREE CONSULTATION
									</div>
								</div>
								<div className="row pt-3 pt1920">
									<div
										className="col-xl-11 fm-w4-s20 smallimg1 pt1920 s16 homeiconfs1"
										style={{ lineHeight: "22.59px" }}
									>
										Talk face-to-face with your doctor online to discuss your
										unique style
									</div>
								</div>
							</div>
							{/* <div class="col-xl-1"></div> */}
							<div className="col-xl col-lg col-md col-xs col-sm homeml">
								<div className="row paddingtop">
									<div className="col">
										<img
											className="h40w40 homeiconswh"
											loading="lazy"
											src="../images/booking.png"
											alt="logo"
											style={{ width: "53px", height: "60px" }}
										></img>
									</div>
								</div>
								<div className="row pt-4 pt1920">
									<div
										className="col fm-w60-s22 smallimg s18 homeiconfs"
										style={{ lineHeight: "23.48px" }}
									>
										INSTANT BOOKING
									</div>
								</div>
								<div className="row pt-3 pt1920">
									<div
										className="col-xl-11 fm-w4-s20 smallimg1 pt1920 s16 homeiconfs1"
										style={{ lineHeight: "22.59px" }}
									>
										A seamless booking process, no payment taken till the
										treatment is completed
									</div>
								</div>
							</div>
							{/* <div class="col-xl-1"></div> */}
							<div className="col-xl col-lg col-md col-xs col-sm homeml">
								<div className="row paddingtop">
									<div className="col">
										<img
											className="h40w40 homeiconswh"
											loading="lazy"
											src="../images/loan.png"
											alt="logo"
											style={{ width: "62px", height: "62px" }}
										></img>
									</div>
								</div>
								<div className="row pt-4 pt1920">
									<div
										className="col fm-w60-s22 smallimg s18 homeiconfs"
										style={{ lineHeight: "23.48px" }}
									>
										SECURE HOME SERVICE
									</div>
								</div>
								<div className="row pt-3 pt1920">
									<div
										className="col-xl-11 fm-w4-s20 smallimg1 pt1920 s16 homeiconfs1"
										style={{ lineHeight: "22.59px" }}
									>
										Secure check-in with a meet and greet verification code.
									</div>
								</div>
							</div>
							{/* <div class="col-xl-1"></div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}



