import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import * as utils from "../../common/util";

const Apointment = (props) => {
	console.log("props app", props);
	const [show, setShow] = useState(true);
	const [appointment, setappointment] = useState(props.appointment);
	const [viewDetails, setViewDetails] = useState(false);
	const [viewDetailsUpButton, setViewDetailsUpButton] = useState(false);
	const handleClose = () => {
		setShow(false);
		props.show();
	};
	const handleShow = () => setShow(false);
	console.log(props.hideapointment);
	const hideAndTrue = () => {
		setShow(false);
		props.show();
	};

	useEffect(() => {
		setappointment(props.appointment);
	}, [props]);

	var aptaddress = utils.FormatAddress(appointment);

	function calculationoffinalsyringes(j) {
		var val = parseInt(
			appointment.finalsyringes.split(",")[j] -
				appointment.initialsyringes.split(",")[j]
		);
		if (val > 0) {
			return <>+{val}</>;
		} else if (val === 0) {
			return <>{" " + val}</>;
		}
		return <>{val}</>;
	}
	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title>
						<Modal.Body style={{ paddingTop: 0 }}>
							<div>
								<div>
									<div className="row" style={{ backgroundColor: "#fff" }}>
										<Tabs
											defaultActiveKey="Treatment"
											id="profiletab"
											className="list-tab ptab1"
											style={{ backgroundColor: "#fff" }}>
											<Tab
												eventKey="fa fa-angle-left"
												title={
													<i
														class="fa fa-angle-left"
														aria-hidden="true"
														style={{ fontSize: "25px" }}
														onClick={hideAndTrue}></i>
												}
												onClick={hideAndTrue}></Tab>
											<Tab
												eventKey="Treatment"
												title="Treatment"
												style={{ backgroundColor: "#fff", marginTop: "57px" }}>
												<div className="row">
													<div className="col-3 f-fm fm-w7-s16">
														Treatment Time
													</div>
													<div
														className="col-4 f-fm fm-w6-s14"
														style={{
															color: "#ACACAC",
														}}>
														TIME STARTED
														<br />
														<span
															className="f-fm fm-w6-s14"
															style={{
																color: "#000",
															}}>
															{appointment.treatmentstartedtime !== "" &&
																utils.convertTo12HourFormat(
																	appointment.treatmentstartedtime.split(" ")[1]
																)}
														</span>
													</div>
													<div
														className="col-5 f-fm fm-w6-s14"
														style={{
															color: "#ACACAC",
														}}>
														TIME ENDED
														<br />
														<span
															className="f-fm fm-w6-s14"
															style={{
																color: "#000",
															}}>
															{appointment.treatmentendtime !== "" &&
																utils.convertTo12HourFormat(
																	appointment.treatmentendtime.split(" ")[1]
																)}
														</span>
													</div>
												</div>
												<div className="row pt-2">
													<div className="col-3 f-fm fm-w7-s14"></div>
													<div
														className="col-5 f-fm fm-w4-s14"
														style={{
															color: "#ACACAC",
															cursor: "pointer",
														}}
														onClick={() => {
															setViewDetails(!viewDetails);
															setViewDetailsUpButton(!viewDetailsUpButton);
														}}>
														View Details
														{viewDetailsUpButton ? (
															<i
																class="fa fa-light fa-angle-up fa-2x"
																style={{
																	verticalAlign: "middle",
																	paddingLeft: "10px",
																}}></i>
														) : (
															<i
																class="fa fa-light fa-angle-down fa-2x"
																style={{
																	verticalAlign: "middle",
																	paddingLeft: "10px",
																}}></i>
														)}
														{viewDetails && (
															<>
																<div
																	className="f-fm fm-w6-s14"
																	style={{
																		color: "#ACACAC",
																	}}>
																	Treatment time
																	<span
																		style={{
																			color: "#000",
																			paddingLeft: "10px",
																		}}>
																		{moment(
																			appointment.treatmentendtime,
																			"YYYY-MM-DD HH:mm"
																		).diff(
																			moment(
																				appointment.treatmentstartedtime,
																				"YYYY-MM-DD HH:mm"
																			),
																			"minutes"
																		)}
																		&nbsp;min
																	</span>
																</div>
																<div
																	className="f-fm fm-w6-s14"
																	style={{
																		color: "#ACACAC",
																	}}>
																	Snooze time
																	<span
																		style={{
																			color: "#000",
																			paddingLeft: "10px",
																		}}>
																		{appointment.snoozetime !== ""
																			? appointment.snoozetime + " min"
																			: "--"}
																	</span>
																</div>
																<div
																	className="f-fm fm-w6-s14"
																	style={{
																		color: "#ACACAC",
																	}}>
																	Additional time
																	<span
																		style={{
																			color: "#000",
																			paddingLeft: "10px",
																		}}>
																		{appointment.additionaltime !== "0"
																			? appointment.additionaltime + " min"
																			: "--"}
																	</span>
																</div>
															</>
														)}
													</div>
												</div>
												<div className="row pt-5 d-none">
													<div className="col-3 f-fm fm-w7-s16">Syringes</div>
													<div className="col-9">
														<div className="row">
															<div
																className="col-12 f-fm fm-w6-s14"
																style={{
																	color: "#ACACAC",
																}}>
																BEFORE
															</div>
															<div className="col-12 pt-1">
																{appointment.starttreatmentsyringes === ""
																	? "No images"
																	: appointment.starttreatmentsyringes
																			.split(",")
																			.map((start) => {
																				return (
																					<img
																						key={start}
																						className="p-2"
																						alt="before"
																						width="100"
																						height="100"
																						src={
																							process.env.REACT_APP_AWS_S3 +
																							start
																						}></img>
																				);
																			})}
															</div>
															<div
																className="col-12 pt-4 f-fm fm-w6-s14"
																style={{
																	color: "#ACACAC",
																}}>
																AFTER
															</div>
															<div className="col-12">
																{appointment.endtreatmentsyringes === ""
																	? "No images"
																	: appointment.endtreatmentsyringes
																			.split(",")
																			.map((start) => {
																				return (
																					<img
																						key={start}
																						className="p-2"
																						alt="before"
																						width="100"
																						height="100"
																						src={
																							process.env.REACT_APP_AWS_S3 +
																							start
																						}></img>
																				);
																			})}
															</div>
														</div>
													</div>
												</div>
												<div className="row pt-5">
													<div className="col-3 f-fm fm-w7-s16">
														Medical Photos
													</div>
													<div className="col-9">
														<div className="row">
															<div
																className="col-12 f-fm fm-w6-s14"
																style={{
																	color: "#ACACAC",
																}}>
																BEFORE
															</div>
															<div className="col-12 pt-1">
																{appointment.startmedicalphotos === ""
																	? "No images"
																	: appointment.startmedicalphotos
																			.split(",")
																			.map((start) => {
																				return (
																					<img
																						key={start}
																						className="p-2"
																						alt="before"
																						width="100"
																						height="100"
																						src={
																							process.env.REACT_APP_AWS_S3 +
																							start
																						}></img>
																				);
																			})}
															</div>
															<div
																className="col-12 pt-4 f-fm fm-w6-s14"
																style={{
																	color: "#ACACAC",
																}}>
																AFTER
															</div>
															<div className="col-12">
																{appointment.endmedicalphotos === ""
																	? "No images"
																	: appointment.endmedicalphotos
																			.split(",")
																			.map((start) => {
																				return (
																					<img
																						key={start}
																						className="p-2"
																						alt="before"
																						width="100"
																						height="100"
																						src={
																							process.env.REACT_APP_AWS_S3 +
																							start
																						}></img>
																				);
																			})}
															</div>
														</div>
													</div>
												</div>
												<div className="row pt-5">
													<div className="col-3 f-fm fm-w7-s16">Others</div>
													<div className="col-9">
														<div className="row">
															<div
																className="col-12 pb-2 f-fm fm-w6-s14"
																style={{
																	color: "#ACACAC",
																}}>
																ADDITIONAL SYRINGES
															</div>
															{appointment.treatmentid[0]
																.split(",")
																.map((e, j) => {
																	return (
																		<div
																			className="col-10"
																			style={{
																				padding: "8px",
																				color: "#AF805E",
																				display: "flex",
																				alignItems: "center",
																				background: "#F4F4F4",
																				justifyContent: "space-between",
																				boxShadow:
																					"0px 4.69421px 46.9421px rgba(0, 0, 0, 0.02)",
																				borderRadius: "11.7355px",
																			}}>
																			{props.getvalue(e, j, "none", "app")}
																			<span
																				style={{
																					color: "#fff",
																					paddingLeft: "10px",
																					paddingRight: "10px",
																					width: "43.64px",
																					height: "24.24px",
																					fontWeight: 700,
																					fontSize: "16px",
																					fontFamily: "mulish",
																					background: "#000000",
																					boxShadow:
																						"0px 4.69421px 46.9421px rgba(0, 0, 0, 0.02)",
																					borderRadius: "11.7355px",
																				}}>
																				{calculationoffinalsyringes(j)}
																			</span>
																		</div>
																	);
																})}
														</div>
													</div>
													<div class="col-3"></div>
													<div className="col-9 mt-3">
														<div className="row">
															<div
																className="col-12 pb-2 f-fm fm-w6-s14"
																style={{
																	color: "#ACACAC",
																}}>
																ADDITIONAL TIME REPORT
															</div>

															{/* <div
																className="col-8"
																style={{
																	background: "#F4F4F4",
																	height: "31px",
																	boxShadow:
																		"0px 4.69421px 46.9421px rgba(0, 0, 0, 0.02)",
																	borderRadius: "11.7355px",
																	display: "flex",
																	alignItems: "center",
																	color: "#777777",
																}}>
																--
															</div> */}
															<div
																className="f-fm fm-w6-s14"
																style={{
																	color: "#ACACAC",
																}}>
																Snooze time
																<span
																	style={{
																		color: "#000",
																		paddingLeft: "10px",
																	}}>
																	{appointment.snoozetime !== ""
																		? appointment.snoozetime + " min"
																		: "--"}
																</span>
															</div>
															<div
																className="f-fm fm-w6-s14"
																style={{
																	color: "#ACACAC",
																}}>
																Additional time
																<span
																	style={{
																		color: "#000",
																		paddingLeft: "10px",
																	}}>
																	{appointment.additionaltime !== "0"
																		? appointment.additionaltime + " min"
																		: "--"}
																</span>
															</div>
														</div>
													</div>
													<div class="col-3"></div>
													<div className="col-9 mt-3">
														<div className="row">
															<div
																className="col-12 pb-2 f-fm fm-w6-s14"
																style={{
																	color: "#ACACAC",
																}}>
																OTHER ISSUES
															</div>

															<div
																className="col-8"
																style={{
																	background: "#F4F4F4",
																	height: "31px",
																	boxShadow:
																		"0px 4.69421px 46.9421px rgba(0, 0, 0, 0.02)",
																	borderRadius: "11.7355px",
																	display: "flex",
																	alignItems: "center",
																	color: "#777777",
																}}>
																--
															</div>
														</div>
													</div>
												</div>
											</Tab>
											<Tab
												eventKey="Appointment Details"
												title="Appointment Details"
												tabIndex="1">
												<>
													<div className="row">
														<div className="col-4"></div>
													</div>
													<div>
														<label
															style={{ color: "#ACACAC" }}
															className="f-fm fm-w6-s12 ">
															Time and Location
														</label>
													</div>
													<div>
														<label>
															<img
																style={{
																	width: "14px",
																	height: "14px",
																	color: "#777777",
																}}
																src="./images/time.png"
																alt=""
															/>
															&nbsp;&nbsp;
															<span className="f-fm fm-w4-s16 color-7">
																{/* {appointment.appointmentdate.split("T")[0] +
                                  " "}
                                {appointment.starttime} - {appointment.endtime} */}
																{moment(
																	new Date(
																		appointment.appointmentdate.split("T")[0]
																	)
																).format("ll")}
																&nbsp;&nbsp;
																{utils.convertTo12HourFormat(
																	appointment.starttime
																)}
																&nbsp;&nbsp;-&nbsp;&nbsp;
																{utils.convertTo12HourFormat(
																	appointment.endtime
																)}
															</span>
														</label>
													</div>

													{/* <div className="col">
														<img
															style={{
																width: "14px",
																height: "14px",
																color: "#777777",
															}}
															src="./images/map.png"
															alt=""
														/>
														&nbsp;&nbsp;
														<label className="f-fm fm-w4-s16 color-7">
															Not available to view
														</label>
													</div> */}
													<div className="col">
														<label className="d-flex">
															<img
																style={{
																	width: "14px",
																	height: "14px",
																	color: "#777777",
																	marginTop: "6px",
																	alignSelf: "auto",
																}}
																src="./images/map.png"
																alt=""
															/>
															&nbsp;&nbsp;
															<span className="f-fm fm-w4-s16 color-7">
																{aptaddress.line1}
																&nbsp;
																{aptaddress.line2}
																&nbsp;
																{aptaddress.towncity}
																&nbsp;
																{aptaddress.country}
																&nbsp;
																{aptaddress.postcode}
															</span>
														</label>
													</div>

													<div className="row pt-5">
														<div className="col-9">
															<label
																style={{ color: "#ACACAC" }}
																className="f-fm fm-w6-s12 ">
																TREATMENT-ONBOARDING VIDEO
															</label>
														</div>
														<div className="col-3">
															{appointment.videostatus === "Approved" && (
																<div
																	style={{
																		color: "#00C4A0",
																		display: "contents",
																	}}
																	className="f-fm fm-w7-s16">
																	Approved
																</div>
															)}
															{appointment.videostatus === "Declined" && (
																<div
																	style={{
																		// color: "#00C4A0",
																		display: "contents",
																	}}
																	className="f-fm fm-w7-s16">
																	Declined
																</div>
															)}
														</div>
													</div>

													<div className="row">
														{appointment.treatmentid[0]
															.split(",")
															.map((e, j) => {
																return (
																	<div
																		className="card"
																		style={{
																			backgroundColor: "#F4F4F4",
																			height: "160px",
																			width: "425px",
																			boxShadow:
																				"0px 5px 50px rgba(0, 0, 0, 0.02)",
																			borderRadius: "12.5px",
																			padding: "10px 10px",
																		}}>
																		<div className="row">
																			<div className="col-9 f-fm fm-w6-s14">
																				{props.getvalue(e, j)}
																				{props.getprice(
																					e,
																					appointment.finalsyringes.split(","),
																					j
																				)}
																			</div>
																			<div className="col-3">
																				<div
																					style={{ cursor: "pointer" }}
																					onClick={() => {
																						props.videoUrl(
																							process.env.REACT_APP_AWS_S3 +
																								appointment.onboardingvideos.split(
																									","
																								)[j]
																						);
																						props.videoModal(true);
																						// props.hideapointment()
																					}}>
																					<video
																						style={{
																							height: "112px",
																							width: "80px",
																							borderRadius: "7px",
																						}}
																						preload="metadata"
																						// controls
																					>
																						<source
																							src={
																								process.env.REACT_APP_AWS_S3 +
																								appointment.onboardingvideos.split(
																									","
																								)[j]
																							}
																							type="video/mp4"
																						/>
																					</video>
																					<img
																						src="./images/video.png"
																						style={{
																							position: "absolute",
																							height: "20px",
																							width: "20px",
																							marginLeft: "-33px",
																							color: "#000",
																							marginTop: "7px",
																						}}></img>
																				</div>
																			</div>
																		</div>
																		<div className="f-fm fm-w6-s14">
																			{props.getsyringes(appointment, j, e)}
																		</div>
																	</div>
																);
															})}
													</div>

													{appointment.videourl !== "" && (
														<>
															<div className="row">
																<div
																	className="col"
																	style={{
																		paddingBottom: "5px",
																	}}>
																	<label className="f-fm fm-w6-s12 color-AC">
																		CONSULTATION
																	</label>
																</div>
															</div>
															<div className="row">
																<div
																	className="col"
																	style={{
																		paddingBottom: "3px",
																	}}>
																	<video
																		style={{
																			height: "200px",
																			width: "400px",
																		}}
																		controls>
																		<source
																			src={
																				process.env.REACT_APP_AWS_S3 +
																				appointment.videourl
																			}
																			type="video/mp4"
																		/>
																	</video>
																</div>
															</div>
															<div className="row">
																<div
																	className="col"
																	style={{
																		paddingBottom: "5px",
																	}}>
																	<label className="f-fm fm-w3-s10 color-00">
																		{moment(
																			new Date(
																				appointment.appointmentdate.split(
																					"T"
																				)[0]
																			)
																		).format("ll") + " | "}
																		{utils.convertTo12HourFormat(
																			appointment.starttime
																		)}{" "}
																		-{" "}
																		{utils.convertTo12HourFormat(
																			appointment.endtime
																		)}
																	</label>
																</div>
															</div>
															<div className="row">
																<div
																	className="col"
																	style={{
																		paddingBottom: "25px",
																	}}>
																	<label className="f-fm fm-w3-s10 color-00">
																		Duration: {appointment.videoduration}
																	</label>
																</div>
															</div>
														</>
													)}
													<div className="row pt-3">
														<div
															className="col"
															style={{
																paddingBottom: "5px",
															}}>
															<label className="f-fm fm-w6-s12 color-AC">
																ALLERGY
															</label>
														</div>
													</div>
													<div className="row">
														<div
															className="col"
															style={{
																paddingBottom: "25px",
															}}>
															<label
																className="f-fm fm-w5-s14 color-00"
																style={{
																	display: "flex",
																	flexFlow: "wrap",
																}}>
																{appointment.patient_details[0].allergies ===
																	"" ||
																appointment.patient_details[0].allergies === " "
																	? "N/A"
																	: appointment.patient_details[0].allergies
																			.split("|")
																			.map((allergy) => {
																				return (
																					<span
																						style={{
																							backgroundColor: "#ACACAC36",
																							margin: "5px",
																							paddingTop: "3px",
																							paddingBottom: "3px",
																							paddingRight: "15px",
																							paddingLeft: "15px",
																							borderRadius: "5px",
																						}}>
																						{allergy}
																					</span>
																				);
																			})}
															</label>
														</div>
													</div>
												</>
											</Tab>
										</Tabs>
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal.Title>
				</Modal.Header>
			</Modal>
		</>
	);
};

export default Apointment;
