import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./popup.css";
import { useNavigate, Link } from "react-router-dom";

const Popupforhome = () => {
	const [open, setOpen] = useState(false);
	const closeModal = () => setOpen(false);
	const navigate = useNavigate();
	return (
		<div style={{ border: "0px" }}>
			<button
				style={{ border: "0px", backgroundColor: "#ffffff00" }}
				type="button"
				className="button"
				onClick={() => setOpen((o) => !o)}
			>
				<img
					className=""
					loading="lazy"
					alt=""
					src="../images/Frame 17.png"
					style={{ width: "24px", height: "24px" }}
				></img>
			</button>
			<Modal show={open} style={{}} className="modalpopp">
				<div
					style={{
						width: "307px",
						height: "812px",
						backgroundColor: "#000000",
						marginRight: "-7px",
						marginTop: "-7px",
					}}
				>
					<div
						onClick={closeModal}
						style={{
							color: "white",
							float: "right",
							paddingRight: "24px",
							paddingTop: "23px",
						}}
					>
						<img
							className=""
							loading="lazy"
							alt=""
							src="../images/Frame 7.png"
							style={{ width: "", height: "" }}
						></img>
					</div>

					<div style={{ paddingTop: "141px" }}>
						<hr style={{}} className="hr1"></hr>
					</div>
					<a
						className="pl fm-sw f-rl "
						href="/everyone"
						style={{
							color: "#FFFFFF",
							textDecoration: "none",
						}}
					>
						For Everyone
					</a>

					<hr className="hr1"></hr>
					<a
						className="pl fm-sw f-rl "
						href="/business"
						style={{
							color: "#ffffff",
							textDecoration: "none",
						}}
					>
						For business
					</a>

					<hr className="hr1"></hr>
					<a
						className="pl fm-sw f-rl "
						href="/login"
						style={{
							color: "#FFFFFF",
							textDecoration: "none",
						}}
					>
						Login
					</a>
					<div style={{ paddingBottom: "187px" }}>
						<hr style={{}} className="hr1"></hr>
					</div>
					<div className="d-flex burger1 pl" style={{}}>
						<a
							href="../downloads/app-debug.apk"
							download
							style={{ paddingBottom: "24px" }}
						>
							<button
								className="btn-rounded-white1"
								style={{
									border: "1px solid #000",
								}}
							>
								Download the App
							</button>
						</a>
						<button
							className="btn-rounded-black1"
							style={{
								border: "1px solid #fff",
							}}
							onClick={() => navigate("/signup/social")}
						>
							Business Sign Up
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};
export default Popupforhome;
