import React from "react";
import * as utils from "../common/util";
import Groupimage from "./groupimage";
import { useNavigate, Link } from "react-router-dom";
import Popupforhome from "./popupforhome";
import "./website1920.css";
import Logo from "../common/logo";

const Homemobile = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="container-fluid" style={{ backgroundColor: "#000000" }}>
				<nav
					className="navbar container-fluid fixed-topmobile  navbar-expand-lg navbar-dark bg-lightmobile"
					style={{ height: "70px" }}
				>
					<div className="container-fluid navh" style={{}}>
						<Logo logo="dark-light1"></Logo>
						<div className="groupimage380">
							<Popupforhome></Popupforhome>
						</div>
						
					</div>
				</nav>
				<div
					className="row "
					style={{
						paddingTop: "108px",
						paddingBottom: "37px",
					}}
				>
					<div
						className="col d-flex justify-content-start"
						style={{ textAlign: "left", padding: "0px" }}
					>
						<div
							className="f-rl fm-w6-s881 color-FF"
							style={{
								paddingLeft: "20px",
								lineHeight: "56px",
								fontWeight: "200",
							}}
						>
							MEET THE <br /> BEST
							<div className="">COSMETIC TREATMENTS</div>
							<div className="" style={{ fontWeight: "600" }}>
								AT HOME
							</div>
						</div>
					</div>
				</div>

				<div
					className="row homebgImage "
					style={{
						backgroundImage: "url('../images/Group 571.png')",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						height: "333px",
					}}
				>
					<div
						className="roundWhiteDownloadmobile homechangebtn homechangebtnin"
						style={{}}
					>
						Download the App
						<img
							className="homechangebtnarrow"
							loading="lazy"
							alt=""
							src="../images/Arrowb4.png"
							style={{}}
						></img>
					</div>
				</div>

				<br></br>

				<div
					className="row homeImage homechangeicons"
					style={{ paddingRight: "120px" }}
				>
					<div className="col-xl-8 col-lg-10 col-md-12 col-xs-8 col-sm-12">
						<div className="row color-FF">
							<div className="col-xl col-lg col-md col-xs col-sm">
								<div className="row">
									<div className="col" style={{ padding: "0px" }}>
										<img
											className="homeicons"
											loading="lazy"
											src="../images/onlinechat.png"
											alt="logo"
											style={{ width: "60px", height: "61px" }}
										></img>
									</div>
								</div>
								<div className="row ">
									<div
										className="col fm-w60-s22 iconfwfs pt-pb"
										style={{ padding: "0px" }}
									>
										FREE CONSULTATION
									</div>
								</div>
								<div className="row ">
									<div
										className="col-xl-11 fm-w4-s20 iconfwfs1"
										style={{ padding: "0px", lineHeight: "18px" }}
									>
										Talk face-to-face with your doctor online to discuss your
										unique style
									</div>
								</div>
							</div>
							{/* <div class="col-xl-1"></div> */}
							<div className="col-xl col-lg col-md col-xs col-sm homeml">
								<div className="row " style={{ paddingTop: "60px" }}>
									<div className="col" style={{ padding: "0px" }}>
										<img
											className="homeicons"
											loading="lazy"
											src="../images/booking.png"
											alt="logo"
											style={{ width: "53px", height: "60px" }}
										></img>
									</div>
								</div>
								<div className="row ">
									<div
										className="col fm-w60-s22 iconfwfs pt-pb"
										style={{ padding: "0px" }}
									>
										INSTANT BOOKING
									</div>
								</div>
								<div className="row ">
									<div
										className="col-xl-11 fm-w4-s20 iconfwfs1"
										style={{ padding: "0px", lineHeight: "18px" }}
									>
										A seamless booking process, no payment taken till the
										treatment is completed
									</div>
								</div>
							</div>
							{/* <div class="col-xl-1"></div> */}
							<div className="col-xl col-lg col-md col-xs col-sm homeml">
								<div className="row " style={{ paddingTop: "60px" }}>
									<div className="col" style={{ padding: "0px" }}>
										<img
											className="homeicons"
											loading="lazy"
											src="../images/loan.png"
											alt="logo"
											style={{ width: "62px", height: "62px" }}
										></img>
									</div>
								</div>
								<div className="row ">
									<div
										className="col fm-w60-s22 iconfwfs pt-pb"
										style={{ padding: "0px" }}
									>
										SECURE HOME SERVICE
									</div>
								</div>
								<div className="row ">
									<div
										className="col-xl-11 fm-w4-s20 iconfwfs1"
										style={{ padding: "0px", lineHeight: "18px" }}
									>
										Secure check-in with a meet and greet verification code.
									</div>
								</div>
							</div>
							{/* <div class="col-xl-1"></div> */}
						</div>
					</div>
				</div>

				<div
					className="row no-gutters"
					style={{
						height: "206px",
					}}
				>
					<div
						className="col"
						style={{
							height: "206px",
							background:
								"linear-gradient(90deg, #AF805E 10.93%, #979289 101.33%)",
						}}
					>
						<div
							className="row"
							style={{ paddingTop: "63px", paddingBottom: "70px" }}
						>
							<div className="col d-flex justify-content-center">
								<div className="" style={{ textAlign: "center" }}>
									<div
										className=" f-rl fm-w6-s48 homepage color-FF homefont"
										style={{ lineHeight: "28.18px" }}
									>
										UNIQUE EXPERIENCE
									</div>
									<div
										className="f-rl fm-w3-s48 homepage1 color-FF homefont1"
										style={{ lineHeight: "23.48px" }}
									>
										Seamless connection between you and your doctor
									</div>
								</div>
								<br></br>
							</div>
						</div>
					</div>
				</div>
				<div className="row no-gutters" style={{}}>
					<img
						className=""
						loading="lazy"
						alt=""
						src="../images/Rectangle 164.png"
						style={{ height: "200px", padding: "0px", paddingRight: "0px" }}
					></img>
				</div>

				<div className="row">
					<div
						className="col f-rl fm-w3-s58 fm-w6-s88 color-FF homefont3 "
						style={{
							paddingLeft: "128px",
							textAlign: "left",
							lineHeight: "47px",
						}}
					>
						BOOK ON-DEMAND<br></br>
						COSMETIC <br></br> TREATMENTS
					</div>
				</div>
				<div className="row" style={{ paddingLeft: "0px" }}>
					<div className="roundWhiteDownloadmobile homechangebtn1 homechangebtnin11">
						Visit User App
						<img
							className="homechangebtnarrow11"
							loading="lazy"
							alt=""
							src="../images/Arrowb4.png"
							style={{ width: "38.11px", height: "20px" }}
						></img>
					</div>
					<br></br>
				</div>
				<div className="row" style={{ padding: "0px" }}>
					<div className="col " style={{ padding: "0px" }}>
						<img
							className="s320"
							loading="lazy"
							src="../images/home screen 1.png"
							alt=".."
							style={{
								width: "298px",
								height: "482px",
								marginLeft: "39px",
								paddingTop: "43px",
							}}
						></img>
					</div>
				</div>
				<div className="row no-gutters" style={{ backgroundColor: "#ffffff" }}>
					<img
						className=""
						loading="lazy"
						alt=""
						src="../images/Rectangle217.png"
						style={{ height: "233px", padding: "0px", paddingRight: "0px" }}
					></img>
				</div>

				<div className="row" style={{ backgroundColor: "#ffffff" }}>
					<div
						className="col f-rl fm-w3-s58 fm-w6-s882 color-00 homefont3 "
						style={{
							paddingLeft: "128px",
							textAlign: "left",
							lineHeight: "47px",
						}}
					>
						HELP DOCTORS <br></br> SERVICE <br /> BETTER
					</div>
				</div>
				<div
					className="row"
					style={{ paddingLeft: "0px", backgroundColor: "#ffffff" }}
				>
					<div
						className="roundBlackDownloadmobile homechangebtn2 homechangebtnin2"
						style={{ cursor: "pointer" }}
						onClick={() => navigate("/login")}
					>
						&nbsp;&nbsp;&nbsp;Manage Business&nbsp;&nbsp;&nbsp;&nbsp;
						<img
							className="homechangebtnarrow2"
							loading="lazy"
							alt=""
							src="../images/Arrow4.png"
							style={{ width: "38.11px", height: "20px" }}
						></img>
					</div>
					<br></br>
				</div>
				<div
					className="row"
					style={{ padding: "0px", backgroundColor: "#ffffff" }}
				>
					<div className="col " style={{ padding: "0px" }}>
						<img
							className="s3201 macw576home"
							loading="lazy"
							src="../images/macbookpro.png"
							alt=".."
							style={{
								width: "345px",
								height: "307px",
								marginLeft: "30px",
								paddingTop: "29px",
								float: "right",
							}}
						></img>
					</div>
				</div>
				<div className="row " style={{ backgroundColor: "#000000" }}>
					<center style={{ padding: "0px" }}>
						<div className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6">
							<div
								className="f-rl fm-w3-s60 color-FF everyonetext1 textalign "
								style={{ padding: "71px 0px 20px 28px", lineHeight: "47px" }}
							>
								OUR TREATMENTS
							</div>
						</div>
					</center>
				</div>

				{/* <!-- Photo Grid --> */}
				<div className="groupimage380" style={{ paddingBottom: "110.61px" }}>
					<Groupimage />
				</div>
				<center>
					<div className=" row no-gutters">
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
							<div className="row " style={{ backgroundColor: "#ffffff" }}>
								<div
									className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 col-12"
									style={{
										backgroundColor: "#b58a6c",
										display: "flex",
										flexDirection: "Column",
										justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									<div
										className="f-rl fm-w6-s42p5 color-FF everyonetext4"
										style={
											{
												// marginTop: "150px",
												// marginBottom: "34px",
											}
										}
									>
										START A TREATMENT AT
										<br />
										YOUR PLACE RIGHT NOW!
									</div>
									<div className="row" style={{ marginBottom: "56.99px" }}>
										<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12  d-flex justify-content-center">
											<div className="roundWhiteDownloadmobile everyonebtn">
												Download the Aesthetik App
											</div>
											<br></br>
										</div>
									</div>

									<div className="col-xl-9 col-lg-5 col-md-9 col-xs-9 col-sm-9">
										<img
											loading="lazy"
											alt=""
											src="../images/iPhone131.png"
											style={{
												width: "309px",
												height: "442px",
											}}
										></img>
									</div>
								</div>

								<div
									className="row no-gutters"
									style={{ backgroundColor: "#ffffff", paddingRight: "0px" }}
								>
									<div
										className="col-12"
										style={{ padding: "0px", paddingRight: "0px" }}
									>
										<img
											className="s3202"
											src="../images/Frame 18.png"
											style={{}}
											alt=""
										/>
									</div>
								</div>
								{/* <div
                  className="row no-gutters "
                  style={{ backgroundColor: "#ffffff", paddingRight: "0px" }}
                >
                  <div className="col-1"></div>
                  <div
                    className="col-7"
                    style={{ padding: "0px", marginLeft: "5px" }}
                  >
                    <img
                      src="../images/Rectangle162.png"
                      style={{ height: "207px", width: "170px" }}
                      alt=""
                    />
                  </div>
                  <div
                    className="col"
                    style={{ padding: "0px", marginLeft: "-22px" }}
                  >
                    <img
                      src="../images/Rectangle183.png"
                      style={{ height: "207px", width: "135px" }}
                      alt=""
                    />
                  </div>
                </div> */}

								{utils.Footer1()}
							</div>
						</div>
					</div>
				</center>
			</div>
		</>
	);
};
export default Homemobile;
