import { Baseurl } from "../common/util";
const axios = require("axios").default;

// var baseurl1;
// if (process.env.REACT_APP_HOST === "local") {
// 	baseurl1 = "http://localhost:3001/";
// } else if (process.env.REACT_APP_HOST === "123") {
// 	baseurl1 = "http://123.176.43.187:3004/";
// } else {
// 	baseurl1 = window.location.origin + "/api/";
// }

// const baseurl = Baseurl() + "notification";

const formatYmd = (date) => date.toISOString().slice(0, 10);

const CREATE_CONTACTUS =
	() => `mutation create($firstname: String!, $lastname:String!, $email: String!, $phone: String!, $textarea: String!){
    contactus(firstname:$firstname, lastname:$lastname, email:$email, phone:$phone, textarea:$textarea){
	    id,
        firstname,
        lastname,
        email,
        phone,
        textarea
    }
  }`;

const UPDATE_CONTACTUS = () => `mutation create($id:ID,$obj:String){
    update(id:$id, obj:$obj){
      id
    }
  }`;

export const returnUpdateVariables = (props) => {
	console.log(props);
	var returnstring = `{
        "id": "#id#",
        "obj": "#obj#"
    }`;
	const id = props.id;
	delete props.id;
	const escapestr = JSON.stringify(props).replaceAll(/"/g, '\\"');
	returnstring = returnstring.replaceAll(/#id#/g, id);
	returnstring = returnstring.replaceAll(/#obj#/g, escapestr);

	return returnstring;
};

export const returnCreateVariables = (props) => {
	console.log(props);

	var returnstring = `{
        "firstname": "#firstname#",
        "lastname": "#lastname#",
        "email": "#email#",
        "phone": "#phone#",
        "textarea": "#textarea#"
      }`;

	Object.keys(props).forEach((key, index) => {
		returnstring = returnstring.replace(
			"#" + key.toLowerCase() + "#",
			props[key]
		);
	});

	returnstring = returnstring.replaceAll(/#[a-z0-9]{1,}#/g, "");

	return returnstring;
};

const CONTACTUS_BYID = () => `query {
  contactusByID(id: "#id#"){
	    id,
	    firstname,
        lastname,
        email,
        phone,
				textarea
  	}
  }
`;

const GET_CONTACTUS = () => `query{
    contactus{
		id,
	    firstname,
        lastname,
        email,
        phone,
				textarea
}
}`;

export const GetContactus = () =>
	axios({
		url: Baseurl() + "contactus",
		method: "post",
		data: {
			query: GET_CONTACTUS(),
		},
	}).then((result) => {
		console.log(result);
		return result.data.data;
	});

export const GetContactusById = (id) =>
	axios({
		url: Baseurl() + "contactus",
		method: "post",
		data: {
			query: CONTACTUS_BYID().replace("#id#", id),
		},
	}).then((result) => {
		console.log(result);
		return result.data.data;
	});

export const CreateContactus = (props) =>
	axios({
		url: Baseurl() + "contactus",
		method: "post",
		data: {
			query: CREATE_CONTACTUS(),
			variables: props,
		},
	})
		.then((res) => {
			console.log(res);
			return res.data.data;
		})
		.catch((err) => console.log(err));

export const UpdateContactus = (props) =>
	axios({
		url: Baseurl() + "contactus",
		method: "post",
		data: {
			query: UPDATE_CONTACTUS(),
			variables: props,
		},
	})
		.then((res) => {
			console.log(res);
			return res.data.data;
		})
		.catch((err) => console.log(err));

export async function DeleteContactus(props) {
	console.log("ee", props);
	var propss = `{
    "id": "#id#"
  }`;
	propss = propss.replace("#id#", props);
	console.log("eew", propss);
	try {
		const response = await axios({
			url: Baseurl() + "contactus",
			method: "post",
			data: {
				query: `{
          deletecontactus (id:"#id#"){
          id
             }
           }`.replace("#id#", props),
			},
		});
		console.log("user", response.data);
		return response.data;
	} catch (error) {
		return [""];
	}
}
