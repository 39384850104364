import React, { useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import SignUp from "./components/signUp";
import Home from "./components/home";
import Pagenotfound from "./components/pagenotfound";
import Faq from "./components/faq";
import Gethelp from "./components/gethelp";
import Contactus from "./components/contactus";
import Everyone from "./components/everyone";
import Business from "./components/business";
import Login from "./components/login";
import ForgotPassword from "./components/forgotpassword";
import EmailNotification from "./components/SignUpPages/emailnotification";
import Welcome from "./components/ProfileSetup/welcome";
import DoctorOrClinic from "./components/ProfileSetup/doctororclinic";
import ClinicName from "./components/ProfileSetup/clinicname";
import CreateBusiness from "./components/ProfileSetup/createbusiness";
import Personalprofile from "./components/ProfileSetup/personalprofile";
import AddDocument from "./components/ProfileSetup/adddocument";
import Payment from "./components/ProfileSetup/payment";
import Stripeaccountdone from "./components/ProfileSetup/stripeaccountdone";
import Subscriptiondone from "./components/ProfileSetup/subscriptiondone";
import Subscribe from "./components/ProfileSetup/subscribe";
import DashBoard from "./components/DashBoard/dashboard";
import Treatments from "./components/Treatments/treatment";
import NewTreatment from "./components/Treatments/newtreatment";
import Payments from "./components/Settings/payments";
import Setting from "./components/Settings/setting";
import Subscription from "./components/Settings/subscription";
import ManageTeam from "./components/Settings/manageteam";
import Profile from "./components/Settings/profile";
import Analytics from "./components/Analytics/analytics";
import Appointments from "./components/Appointments/appointments";
import Patients from "./components/Patients/patients";
import Notification from "./components/Notifications/notification";
import Accept from "./components/Notifications/accept";
import Error from "./components/error";
import Sumsub from "./components/sumsub";
import Stripepayment from "./components/stripepayment";
import Logout from "./components/logout";
import Businessrequest from "./common/request";
import Inviteaccept from "./components/ProfileSetup/inviteaccept";
import Resetpassword from "./components/ProfileSetup/resetpassword";
import Onboardingrefresh from "./components/ProfileSetup/onboardingrefresh";
import Patientresetpassword from "./components/ProfileSetup/patient_resetpassword";
import Sumsuberror from "./components/ProfileSetup/sumsubstatuspage";
import Thankyou from "./components/ProfileSetup/thankyou";
import ThirdPartyLogin from "./components/thirdpartylogin";
import Privacypolicy from "./components/privacypolicy";
import Msg from "./components/Msg";
import { useEffect } from "react";
import Questions from "./components/questions/question";
import Search from "./components/search";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import IdleTimeOutHandler from "./components/IdleTimeOutHandler";

const App = () => {
	function ScrollToTop() {
		const { pathname } = useLocation();
		useEffect(() => {
			window.scrollTo(0, 0);
		}, [pathname]);
		return null;
	}
	const [isActive, setIsActive] = useState(true);
	const [isLogout, setLogout] = useState(false);

	return (
		<>
			<BrowserRouter>
				<ScrollToTop />

				{/* <Msg></Msg> */}
				<Routes>
					<Route path="/" element={<Msg />}>
						<Route exact path="/" element={<Home />} />
						<Route exact path="/*" element={<Pagenotfound />} />
						<Route exact path="/home" element={<Home />} />
						<Route exact path="/faq" element={<Faq />} />
						<Route exact path="/gethelp" element={<Gethelp />} />
						<Route exact path="/contactus" element={<Contactus />} />
						<Route exact path="/everyone" element={<Everyone />} />
						<Route exact path="/business" element={<Business />} />
						<Route exact path="/error" element={<Error />} />
						<Route exact path="/login" element={<Login />} />
						<Route exact path="/logout" element={<Logout />} />
						<Route exact path="/question" element={<Questions />} />
						<Route exact path="/forgotpassword" element={<ForgotPassword />} />
						<Route exact path="/signup/:signupstate" element={<SignUp />} />
						<Route exact path="/emailnotify" element={<EmailNotification />} />
						<Route exact path="/privacypolicy" element={<Privacypolicy />} />
						<Route
							exact
							path="/accept/:userid/:userid1/:userid2"
							element={<Accept />}
						/>
						<Route exact path="/search" element={<Search />} />
						<Route
							exact
							path="/inviteaccept/:userid"
							element={<Inviteaccept />}
						/>
						<Route
							exact
							path="/thirdpartylogin"
							element={<ThirdPartyLogin />}
						/>

						{/*	 Dashboard pages	 */}
						{/* <Route
							path="/"
							element={
								<IdleTimeOutHandler
									onActive={() => {
										setIsActive(true);
									}}
									onIdle={() => {
										setIsActive(false);
									}}
									onLogout={() => {
										setLogout(true);
									}}
								/>
							}
						> */}
						<Route
							exact
							path="/welcome/:userid"
							element={
								<>
									<Welcome />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>
								</>
							}
						/>
						<Route
							exact
							path="/doctororclinic"
							element={
								<>
									<DoctorOrClinic />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>
								</>
							}
						/>
						<Route
							exact
							path="/clinicname"
							element={
								<>
									<ClinicName />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>
								</>
							}
						/>
						<Route
							exact
							path="/createbusiness"
							element={
								<>
									<CreateBusiness />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>
								</>
							}
						/>
						<Route
							exact
							path="/personalprofile"
							element={
								<>
									<Personalprofile />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>
								</>
							}
						/>
						<Route
							exact
							path="/adddocument"
							element={
								<>
									<AddDocument />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>
								</>
							}
						/>
						<Route
							exact
							path="/payment"
							element={
								<>
									<Payment />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/stripepayment"
							element={
								<>
									<Stripepayment />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/subscribe"
							element={
								<>
									<Subscribe />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/dashboard"
							element={
								<>
									<DashBoard />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/treatments"
							element={
								<>
									<Treatments />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/newtreatment"
							element={
								<>
									<NewTreatment />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/payments"
							element={
								<>
									<Payments />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/setting"
							element={
								<>
									<Setting />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/sumsuberror"
							element={
								<>
									<Sumsuberror />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/subscription"
							element={
								<>
									<Subscription />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/manageteam"
							element={
								<>
									<ManageTeam />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/profile"
							element={
								<>
									<Profile />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/analytics"
							element={
								<>
									<Analytics />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/request"
							element={
								<>
									<Businessrequest />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/appointments"
							element={
								<>
									<Appointments page="appointments" />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/patients"
							element={
								<>
									<Patients />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>
								</>
							}
						/>
						<Route
							exact
							path="/notifications"
							element={
								<>
									<Notification />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/sumsub"
							element={
								<>
									<Sumsub />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/stripeaccountdone"
							element={
								<>
									<Stripeaccountdone />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>

						<Route
							exact
							path="/thankyou"
							element={
								<>
									<Thankyou />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/subscriptiondone"
							element={
								<>
									<Subscriptiondone />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
						<Route
							exact
							path="/onboard-user/refresh"
							element={
								<>
									<Onboardingrefresh />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>

						<Route
							exact
							path="/resetpassword/:userid"
							element={
								<>
									<Resetpassword />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>

						<Route
							exact
							path="/patientresetpassword/:userid"
							element={
								<>
									<Patientresetpassword />
									<IdleTimeOutHandler
										onActive={() => {
											setIsActive(true);
										}}
										onIdle={() => {
											setIsActive(false);
										}}
										onLogout={() => {
											setLogout(true);
										}}
									/>{" "}
								</>
							}
						/>
					</Route>
					{/* </Route> */}
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default App;
