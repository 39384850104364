import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Animated } from "react-animated-css";
// import * as utils from "../../common/util";
import * as Subscriptionhistory from "../../services/subscriptionhistory";
import * as UserServices from "../../services/user";
import { Baseurl, Loader } from "../../common/util";
import axios from "axios";

export default function Subscriptiondone() {
	// const [dstate, setDstate] = useState({});
	const navigate = useNavigate();
	const query = new URLSearchParams(window.location.search);
	// console.log("query", query);
	const token = query.get("id");
	// console.log("token", token);
	// useEffect(() => {
	const baseurl = Baseurl() + "getsubscription";
	const baseurl1 = Baseurl() + "subscriptiondone";

	useEffect(() => {
		if (token !== null) {
			var dstate;
			UserServices.GetUserById(token).then((val) => {
				console.log("req user", val.userByID);
				// setDstate(val.userByID);
				dstate = val.userByID;

				Subscriptionhistory.GetSubscriptionhistoryByDoctorId(token).then(
					(value) => {
						console.log("req", value.subscriptionhistoryByDoctorid);
						axios
							.post(baseurl, {
								subscription:
									value.subscriptionhistoryByDoctorid[0].transactionid,
							})
							.then((response) => {
								console.log("response", response.data.payment_status);

								if (response.data.payment_status === "paid") {
									axios
										.post(baseurl1, {
											id: response.data.id,
											customer: response.data.customer,
										})
										.then((res) => {
											console.log("res", res);
											console.log("enter");
											dstate.currentstep = (
												parseInt(dstate.currentstep) + 1
											).toString();
											const updateVariables =
												UserServices.returnUpdateVariables({
													id: dstate.id,
													currentstep: "8",
													entered: "entered",
													plan: "Standard Plan",
													complete: "complete",
												});
											console.log(updateVariables);
											// UserServices.GetUserSettingsById(dstate.id).then(
											// 	(res) => {
											// 		console.log("res", res);
											// 		if (res.userSettingsByUserID !== null) {
											const settingsVariable =
												UserServices.returnCreateSettings({
													id: dstate.id,
													subscription_id:
														value.subscriptionhistoryByDoctorid[0].planid,
												});
											console.log(settingsVariable);

											UserServices.CreateSettings(settingsVariable).then(
												(result) => {
													console.log("entered update");
													console.log(result);
												}
											);
											UserServices.CreateAgoraUser({
												username: dstate.email.replace(/[^a-zA-Z0-9]/g, ""),
												password: "agorachat",
												nickname: dstate.firstName,
											});
											// }
											UserServices.UpdateUser(updateVariables).then((value) => {
												console.log(value);
												localStorage.setItem("throughsignup", true);
												navigate("../dashboard", { state: dstate });
											});
											// 	}
											// );
										})
										.catch((err) => console.log(err.message));
								} else {
									navigate("../subscribe", { state: dstate });
									return;
								}
							})
							.catch((err) => console.log(err.message));
					}
				);
				// Subscriptionhistory.GetSubscriptionhistoryByDoctorId(token).then(
				// 	(value) => {
				// 		console.log("req", value.subscriptionhistoryByDoctorid);

				// 		if (
				// 			value.subscriptionhistoryByDoctorid[0].paymentstatus === "success"
				// 		) {
				// 			console.log("enter");
				// 			dstate.currentstep = (
				// 				parseInt(dstate.currentstep) + 1
				// 			).toString();
				// 			const updateVariables = UserServices.returnUpdateVariables({
				// 				id: dstate.id,
				// 				currentstep: "8",
				// 				entered: "entered",
				// 				plan: "Standard Plan",
				// 				complete: "complete",
				// 			});
				// 			console.log(updateVariables);
				// 			const settingsVariable = UserServices.returnCreateSettings({
				// 				id: dstate.id,
				// 				subscription_id: value.subscriptionhistoryByDoctorid[0].planid,
				// 			});
				// 			console.log(settingsVariable);
				// 			UserServices.CreateSettings(settingsVariable).then((result) => {
				// 				console.log("entered update");
				// 				console.log(result);
				// 			});
				// 			UserServices.CreateAgoraUser({
				// 				username: dstate.email.replace(/[^a-zA-Z0-9]/g, ""),
				// 				password: "agorachat",
				// 				nickname: dstate.firstName,
				// 			});
				// 			UserServices.UpdateUser(updateVariables).then((value) => {
				// 				console.log(value);
				// 				localStorage.setItem("throughsignup", true);
				// 				navigate("../dashboard", { state: dstate });
				// 			});
				// 		} else {
				// 			navigate("../subscribe", { state: dstate });
				// 		}
				// 	}
				// );
			});
			console.log(dstate);
		}
	}, []);

	return (
		<div>
			<Loader />
		</div>
	);
}
