import React, { useState } from "react";
import "./tabs.css";
import { alldata } from "./data";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useContext } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Animated } from "react-animated-css";
import * as utils from "../common/util";

const Tab = () => {
	const [item, setItem] = useState(alldata);
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState("All");
	const params = useLocation().state;
	console.log("params", params);
	const handleTabClick = (id) => {
		setActiveTab(id);
		if (id === "All") {
			setItem(alldata);
		} else {
			const newItem = alldata.filter((newVal) => {
				return newVal.category === id;
				// comparing category for displaying data
			});
			setItem(newItem);
		}
	};
	console.log("active tab", activeTab);
	const defaultac = "0";
	const COLLAPSE = "accordion-button";
	const NOCOLLAPSE = "accordion-button collapsed";

	const navigatetopage = (path, obj) => {
		localStorage.removeItem("comeFrom");
		localStorage.removeItem("preference");
		params["page"] = obj;
		console.log("navigatetopage " + path, params);
		navigate(path, { state: params });
	};
	const menuItems = [...new Set(alldata.map((Val) => Val.category))];

	console.log("menuItems", menuItems);

	function CustomToggle({ children, eventKey, callback }) {
		const { activeEventKey } = useContext(AccordionContext);

		const decoratedOnClick = useAccordionButton(
			eventKey,
			() => callback && callback(eventKey)
		);

		const isCurrentEventKey = activeEventKey === eventKey;

		return (
			<div
				// aria-expanded="true"

				className={isCurrentEventKey ? COLLAPSE : NOCOLLAPSE}
				style={{
					width: "100%",
					backgroundColor: "transparent",
					color: "#000",
					cursor: "pointer",
					boxShadow: "inset 0 0px 0 rgba(0,0,0,.125)",
				}}
				onClick={decoratedOnClick}>
				{children}
			</div>
		);
	}
	return (
		<>
			<Animated
				style={{
					height: "100%",
					bottom: "0px",
					left: "0px",
					display: "grid",
					alignItems: "center",
					minHeight: "100%",
					color: "#FFFFFF",
					textAlign: "center",
					verticalAlign: "middle",
				}}
				animationIn="slideInDown"
				animationOut="fadeOutDown"
				animationInDuration={1000}
				animationOutDuration={1000}
				isVisible={true}>
				<div
					style={{
						backgroundColor: "#F9F9FB",
						overflowX: "hidden",
						overflowY: "hidden",
						position: "relative",
						minHeight: "100%",
					}}>
					<utils.AeNav
						clinicname={params?.clinicname || params.mpage?.clinicname}
						userid={params?.firstName || params.mpage?.firstName}
						avatar={params?.avatar || params.mpage?.avatar}
						goto={navigatetopage}
						params={params}
						dropMenuClassName="btn-darkorange"
					/>

					<div style={{ backgroundColor: "#F9F9FB", height: "100%" }}>
						<div className="row">
							<div className="col">
								<div
									className="fheader color-00  dcenter "
									style={{ paddingTop: "50px" }}>
									Frequently Asked Questions
								</div>
							</div>
						</div>
						<div
							className="row justify-content-center"
							style={{ margin: "50px 0 0 0" }}>
							<div
								className="col-8 cwidth"
								style={{
									boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
									height: "auto",
									backgroundColor: "white",
									color: "#000",
								}}>
								<div className="m1200" style={{ margin: "60px 60px" }}>
									<ul
										className="tabs p1200"
										style={{
											paddingLeft: "50px",
											paddingRight: "60px",
											justifyContent: "space-between",
										}}>
										<li
											className={activeTab === "All" ? "active" : ""}
											onClick={() => handleTabClick("All")}>
											<div className="ftabs">All</div>
											{activeTab === "All" && (
												<hr
													style={{
														height: "2px",
														color: "#af805e",
														opacity: "1",
													}}></hr>
											)}
										</li>
										<li>
											<div className="ftabs" style={{ color: "#B0B0B0" }}>
												|
											</div>
										</li>

										{menuItems.map((Val, id) => {
											return (
												<li
													className={activeTab === Val ? "active" : ""}
													onClick={() => handleTabClick(Val)}
													key={id}>
													<div className="ftabs">{Val}</div>
													{activeTab === Val && (
														<hr
															style={{
																height: "2px",
																color: "#af805e",
																opacity: "1",
																margin: "0rem",
																// paddingTop:"3px"
																marginTop: "6px",
															}}></hr>
													)}
												</li>
											);
										})}
									</ul>

									<div className="tab_content">
										<div className="tab_panel ">
											<Accordion
												defaultActiveKey={defaultac}
												className="ddcenter">
												<div className="faqtabcard ">
													{item.map((alldata, i) => (
														<div
															className="btnb"
															style={{
																borderBottom: "0.5px solid #BCBCBC",
															}}
															key={alldata.category + i}>
															<CustomToggle
																className="accordion-collapse collapse gethelp "
																eventKey={alldata.category + i}>
																<div className="fquestions ">
																	{" "}
																	{alldata.question}{" "}
																	{alldata.subcateogory !== "" && (
																		<span className="fsubquestion">
																			{alldata.subcateogory}
																		</span>
																	)}
																</div>
															</CustomToggle>
															<Accordion.Collapse
																eventKey={alldata.category + i}
																className="">
																<div
																	className="fanswer"
																	style={{ marginBottom: "30px" }}>
																	{alldata.answer}
																</div>
															</Accordion.Collapse>
														</div>
													))}
												</div>
											</Accordion>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div
							className="row justify-content-center"
							style={{ margin: "24px 0 20px 0" }}>
							<div
								className="col-8 cwidth"
								style={{
									boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
									height: "auto",
									backgroundColor: "white",
								}}>
								<div
									className="fcontact m600"
									style={{ margin: "20px 100px", textAlign: "center" }}>
									Can't find the answer you are looking for?&nbsp;
									<span
										className=""
										style={{
											cursor: "pointer",
											textDecoration: "underline",
										}}
										onClick={() => navigate("/contactus")}>
										Contact Us
									</span>
									&nbsp; directly.
								</div>
							</div>
						</div>
						<div className="row">
							<div
								className="col"
								style={{
									height: "128px",
									backgroundColor: "rgb(249, 249, 251)",
								}}></div>
						</div>
					</div>

					<br></br>
					<br></br>
					<br></br>
					<br></br>
					<br></br>
					<br></br>
				</div>
			</Animated>
		</>
	);
};

export default Tab;
