export const alldata = [
	{
		category: "Personal info",
		subcategory: "",
		question:
			"Why do you ask to fill out a medical professional questionnaire?",
		answer:
			"This information helps assess your experience and estimate your weekly workload, including the number of treatments and syringes you'll likely use.",
	},
	{
		category: "Personal info",
		subcategory: "",
		question: "Why do you ask to upload personal identification?",
		answer:
			"This is required to confirm your identity and age eligibility for performing these treatments.",
	},
	{
		category: "Personal info",
		subcategory: "",
		question: "Why do you ask to upload medical certificate?",
		answer:
			"Required to guarantee our community's patients receive treatments from certified professionals.",
	},
	{
		category: "Business info",
		subcategory: "",
		question: "Why do you ask to upload business logo and profile image?",
		answer:
			"Displayed on the patient app for community members to discover your profile.",
	},
	{
		category: "Business info",
		subcategory: "",
		question: "How do I manage my team",
		answer:
			"You can add team members, assign treatments, and each will have their own dashboard login. However, all revenue goes to the admin for independent management and distribution.",
	},
	{
		category: "Subscription",
		subcategory: "",
		question: "Why do we pay a monthly subscription fee?",
		answer:
			"This grants you full access to all dashboard and doctor's app features. The fee is a monthly recurring subscription.",
	},
	{
		category: "Account settings",
		subcategory: "General",
		question: "Why set up your language and region of work",
		answer:
			"Setting your time zone and country informs our community about your location.",
	},
	{
		category: "Account settings",
		subcategory: "Calendar",
		question: "Why do we set up our work times?",
		answer:
			"Set your working hours to inform patients of your availability for appointments, ensuring they only book within those times.",
	},
	{
		category: "Account settings",
		subcategory: "Appointment",
		question: "What is booking service area?",
		answer:
			"By specifying your location and travel radius, you'll receive appointments within your preferred distance.",
	},
	{
		category: "Account settings",
		subcategory: "Appointment",
		question: "Why set a consultation rate?",
		answer:
			"This is your consultation fee for time-efficient live video sessions with patients.",
	},
	{
		category: "Account settings",
		subcategory: "Appointment",
		question: "What does appointment interval?",
		answer:
			"This is the buffer time between appointments for travel to and from your next location",
	},
	{
		category: "Account settings",
		subcategory: "Appointment",
		question: "What is difference between manual vs auto accept bookings?",
		answer:
			"Unchecked boxes require manual acceptance or decline of appointments. Checked boxes enable automatic acceptance based on your calendar availability.",
	},
	{
		category: "Appointments",
		subcategory: "",
		question: "How do I accept onboarding video submitted by patients?",
		answer: "This can be done via doctor’s app or dashboard ",
	},
	{
		category: "Appointments",
		subcategory: "",
		question:
			"If I decline an onboarding video who sets the live video consultation?",
		answer:
			"If you decline the onboarding video, a live consultation is necessary. A notification prompts the patient to book based on your availability.",
	},
	{
		category: "Appointments",
		subcategory: "",
		question: "How do I reschedule an appointment or consultation?",
		answer:
			"Each appointment card has a 'reschedule' button. Clicking it sends a request to the patient to reschedule based on your availability.",
	},
	{
		category: "Appointments",
		subcategory: "",
		question: "How do I cancel appointment?",
		answer:
			"Clicking the 'cancel' button on an appointment card triggers a notification to the patient (as per the cancellation policy).",
	},
];