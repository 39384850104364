import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tabs";
import Apointment from "./apointment";
import { ListGroupItem } from "react-bootstrap";
import * as HistoryServices from "../../services/patienthistory";
import moment from "moment";
import ApprovalModel from "./approvalmodel";
import * as utils from "../../common/util";
import ImageViewer from "react-simple-image-viewer";

const Patient2 = (props) => {
	console.log("patient2...", props);
	const [appointments, setAppointments] = useState(props.appointments);
	const [patients, setPatients] = useState(props.patients);
	// const [imagemodaldisplay, setPatients] = useState(props.patients);
	const [payment, setPayment] = useState(props.payment);
	const [selectedAppointment, setSelectedAppointment] = useState("");
	const [selectedAppointmentapproval, setSelectedAppointmentapproval] =
		useState("");
	const [allquestions, setAllQuestions] = useState(props.allquestions);
	const [allquestionsp, setAllQuestionsP] = useState(props.allquestionsP);
	const [allquestionsc, setAllQuestionsC] = useState(props.allquestionsC);
	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [images, setImages] = useState([]);
	// const images = [
	// 	"http://placeimg.com/1200/800/nature",
	// 	"http://placeimg.com/800/1200/nature",
	// 	"http://placeimg.com/1920/1080/nature",
	// 	"http://placeimg.com/1500/500/nature",
	// ];

	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	const upp = [];
	const uppappointments = [];
	const arr = [];
	const mis = [];
	const can = [];

	const currentTime = moment().valueOf();
	const consultDT = (appointment) => {
		return appointment.consultationdate
			? moment(
					appointment.consultationdate.slice(0, 10) +
						" " +
						appointment.cstarttime,
					"YYYY-MM-DD HH:mm"
			  ).valueOf()
			: moment(
					appointment.appointmentdate.slice(0, 10) +
						" " +
						appointment.starttime,
					"YYYY-MM-DD HH:mm"
			  ).valueOf();
	};

	const treatmentDateTime = (appointment) => {
		return moment(
			appointment.appointmentdate.slice(0, 10) + " " + appointment.endtime,
			"YYYY-MM-DD HH:mm"
		).valueOf();
	};

	const videoDateTime3hr = (appointment) => {
		return appointment.videodatetime
			? moment(appointment.videodatetime, "YYYY-MM-DD HH:mm:ss")
					.add(3, "hours")
					.valueOf()
			: 0;
	};

	const [show, setShow] = useState(false);
	const [showMedicalHistory, setShowMedicalHistory] = useState(false);
	const [value, setValue] = useState("");

	const handleClose = () => {
		setShow(false);
	};
	const handleCloseMedicalHistory = () => {
		setShowMedicalHistory(false);
		setShow(true);
	};
	const handleShow = () => setShow(true);

	const [apointment, setapointment] = useState("none");
	const [apointmentapproval, setapointmentapproval] = useState("none");
	const [answers, setAnswers] = useState([]);
	const [updateDate, setUpdatedDate] = useState("");
	const [isupdated, setIsUpdated] = useState("0");
	const hideapointment = () => {
		setapointment("none");
	};
	var image = [];
	const hideapointmentapproval = () => {
		setapointmentapproval("none");
	};

	const [videoModal, setVideoModal] = useState(false);
	const [videoUrl, setVideoUrl] = useState("");
	const [videoModalComplete, setVideoModalComplete] = useState(false);
	const [videoUrlComplete, setVideoUrlComplete] = useState("");
	const handleCloseVideo = () => {
		setVideoModal(false);
		setapointmentapproval("block");
	};

	const handleCloseVideoComplete = () => {
		setVideoModalComplete(false);
		setapointment("block");
	};

	useEffect(() => {
		setAppointments(props.appointments);
		setPatients(props.patients);
		setPayment(props.payment);
		console.log(props.allquestions);

		console.log("image12", image, props.patients);
	}, [props]);

	useEffect(() => {
		if (
			patients.scannedimages !== "" ||
			patients.scannedimages !== undefined ||
			patients.scannedimages !== null
		) {
			patients.scannedimages.split("|").forEach((img) => {
				if (!image.includes(process.env.REACT_APP_AWS_S3 + img)) {
					image.push(process.env.REACT_APP_AWS_S3 + img);
				}
			});
		}
		setImages(image);
		console.log("image", image);
	}, []);

	useEffect(() => {
		if (isupdated === "0") {
			HistoryServices.GetPatienthistoryBypatientid(patients.id).then((app) => {
				console.log("app", app);

				if (app.patienthistorybypatientid.length !== 0) {
					var answers = app.patienthistorybypatientid[0].answers;
					var updateddate = app.patienthistorybypatientid[0].updateddate;

					setUpdatedDate(updateddate);

					// allquestions.map(function (question) {
					//   console.log("aaaa", question.id, question.question);
					//   answers =
					//     answers == ""
					//       ? app.patienthistorybypatientid[0].answers.replaceAll(
					//           question.id,
					//           question.question
					//         )
					//       : answers.replaceAll(question.id, question.question);
					// });
					console.log("aaaa", answers);
					setAnswers(answers.split("##"));
				}
			});
			setIsUpdated("1");
		} else {
			//answers.map(function (i, id) {});
		}
	}, [answers]);

	console.log(answers);

	Object.values(appointments).forEach((a) => {
		Object.values(a).forEach((app) => {
			if (
				// app.iscompleted !== "true" &&
				// ((app.complete !== "true" && app.ispaymentdone === false) ||
				//   (app.complete === "true" && currentTime > videoDateTime3hr(app))) &&
				// (currentTime > treatmentDateTime(app) ||
				//   currentTime > consultDT(app)) &&
				// app.cancelledby === "" &&
				app.missedby !== "" &&
				app.cancelledby === "" &&
				patients.id === app.patientid
			) {
				mis.push(app._id);
			}
			// console.log(
			//   "temp",
			//   (app.complete !== "true" && app.ispaymentdone === false) ||
			//     (app.complete === "true" && currentTime < videoDateTime3hr(app)),
			//   currentTime < treatmentDateTime(app),
			//   currentTime,
			//   treatmentDateTime(app)
			// );
			else if (
				app.iscompleted !== "true" &&
				app.missedby === "" &&
				app.cancelledby === "" &&
				((app.complete !== "true" && app.ispaymentdone === false) ||
					(app.complete !== "true" && app.ispaymentdone === true) ||
					(app.complete === "true" &&
						app.ispaymentdone &&
						treatmentDateTime(app) > currentTime)) &&
				(treatmentDateTime(app) > currentTime ||
					(currentTime > treatmentDateTime(app) &&
						app.isdoctorcheckedin === "true")) &&
				patients.id === app.patientid
			) {
				upp.push(app._id);
				uppappointments.push(app);
			} else if (app.iscompleted === "true" && patients.id === app.patientid) {
				arr.push(app._id);
			} else if (
				app.missedby === "" &&
				app.cancelledby !== "" &&
				patients.id === app.patientid
			) {
				can.push(app._id);
			}

			// console.log(
			//   "can",
			//   can,
			//   (app.cancelledby === "doctor" || app.cancelledby === "patient") &&
			//     patients.id === app.patientid
			// );
		});
	});

	const showAgain = () => {
		setapointment("none");
		handleShow();
	};

	const showAgainApproval = () => {
		setapointmentapproval("none");
		handleShow();
	};

	const showAnswers = (i) => {
		return (
			<>
				{answers.map((answer) => {
					return (
						<>
							{i.id == answer.split("|")[0] && answer.split("|")[1] !== "" && (
								<>
									<div style={{ fontWeight: "700" }}>{i.question}</div>
									<div style={{ color: "#AF805E" }}>
										{answer.split("|")[1].charAt(0).toUpperCase() +
											answer.split("|")[1].slice(1)}
									</div>
								</>
							)}
						</>
					);
				})}
			</>
		);
	};
	const returnQuestionaire = () => {
		return (
			<>
				<div>
					{allquestionsp.length > 0 &&
						allquestionsp.map(function (i, id) {
							return (
								<>
									<div
										className="f-fm"
										style={{
											paddingLeft: "10px",
											paddingBottom: "5px",
										}}
									>
										{i.childquestionid !== "" && i.answer !== "" && (
											<div key={"mainq" + i.id} id={i.id}>
												{showAnswers(i)}
												{
													<span>
														{allquestionsc
															.filter((question) => question.parentid === i.id)
															.map((h) => renderChilds(h, i, "childq "))}
													</span>
												}
											</div>
										)}
										{i.childquestionid !== "" && i.answer === "" && (
											<div key={"mainq" + i.id} id={i.id}>
												<div style={{ fontWeight: "700" }}>{i.question}</div>
												<span>
													{allquestionsc
														.filter((question) => question.parentid === i.id)
														.map((h) => renderChilds(h, i, "childq1 "))}
												</span>
											</div>
										)}
										{i.childquestionid === "" &&
											i.parentid === "" &&
											i.answer === "" && (
												<>
													<div key={"mainq" + i.id} id={i.id}>
														<div>{showAnswers(i)}</div>
													</div>
												</>
											)}
									</div>
								</>
							);
						})}
				</div>
			</>
		);
	};

	const renderChilds1 = (h, i, classn) => {
		return (
			<div
				style={{
					padding: "5px",
				}}
			>
				<div key={"childq" + h.id} id={h.id}>
					<div
						style={{
							width: "100%",
						}}
					>
						{/* <div className="question"> {h.question}</div> */}

						{showAnswers(h)}
					</div>
				</div>
			</div>
		);
	};

	const renderChilds = (h, i, classn) => {
		return (
			<div
			// style={{
			// 	padding: "5px 5px 5px 20px",
			// }}
			>
				<div key={"childq" + h.id} id={h.id}>
					<div
						style={{
							width: "100%",
						}}
					>
						{/* &nbsp; */}
						{/* <div className="question"> {h.question}</div> */}
						{showAnswers(h)}
					</div>
					{
						<span>
							{allquestionsc
								.filter((question) => question.parentid === h.id)
								.map((h1) => renderChilds1(h1, h, "childq2 "))}
						</span>
					}
				</div>
			</div>
		);
	};

	const returnRadio = (checked, i, value) => {
		return checked === "true" ? (
			<div className="custom-disabled">
				<input type="radio" name={i.id} id="l" value={value} checked disabled />
			</div>
		) : checked === "false" ? (
			<div className="custom-disabled1">
				<input type="radio" name={i.id} id="l" value={value} disabled />
			</div>
		) : (
			""
		);
	};

	return (
		<>
			<Modal
				show={videoModal}
				onHide={handleCloseVideo}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header>
					<Modal.Body style={{ paddingTop: 0 }}>
						<div
							style={{
								cursor: "pointer",
								width: "100%",
								display: "flex",
								justifyContent: "end",
								paddingBottom: "30px",
							}}
							onClick={() => {
								handleCloseVideo();
							}}>
							<img alt="close" src="./images/closeone.png"></img>
						</div>
						<video
							style={{
								height: "300px",
								width: "400px",
								borderRadius: "7px",
							}}
							preload="metadata"
							controls>
							<source src={videoUrl} type="video/mp4" />
						</video>
					</Modal.Body>
				</Modal.Header>
			</Modal>
			<Modal
				show={videoModalComplete}
				onHide={handleCloseVideoComplete}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header>
					<Modal.Body style={{ paddingTop: 0 }}>
						<div
							style={{
								cursor: "pointer",
								width: "100%",
								display: "flex",
								justifyContent: "end",
								paddingBottom: "30px",
							}}
							onClick={() => {
								handleCloseVideoComplete();
							}}>
							<img alt="close" src="./images/closeone.png"></img>
						</div>
						<video
							style={{
								height: "300px",
								width: "400px",
								borderRadius: "7px",
							}}
							preload="metadata"
							controls>
							<source src={videoUrlComplete} type="video/mp4" />
						</video>
					</Modal.Body>
				</Modal.Header>
			</Modal>
			<div onClick={handleShow} style={{ marginLeft: "25px" }}>
				<img
					alt="loading"
					style={{ cursor: "pointer", display: props.image }}
					src="./images/Group210.png"
				/>
			</div>
			<div onClick={handleShow}>
				<button
					style={{
						background: "#F4F4F4",
						borderRadius: "12.5px",
						marginTop: "15px",
						display: props.view,
						border: "none",
					}}>
					<span
						style={{
							fontFamily: "Mulish",
							fontStyle: "normal",
							fontWeight: "500",
							fontSize: "14px",
							lineHeight: "18px",
							color: "#777777",
						}}>
						View more
					</span>
				</button>
			</div>

			<Modal
				show={showMedicalHistory}
				onHide={handleCloseMedicalHistory}
				dialogClassName="modal-sm-30px"
				size="md"
				position="top-right">
				<Modal.Body>
					<div className="col-2">
						<img
							alt="close"
							style={{
								height: "24px",
								width: "24px",
								cursor: "pointer",
							}}
							onClick={handleCloseMedicalHistory}
							src="./images/close.png"
						/>
					</div>
					<div style={{ textAlign: "center", justifyContent: "center" }}>
						{props.patients.avatar === "" ? (
							<img
								alt="patient avatar"
								src="./images/usernew.png"
								style={{
									textAlign: "center",
									justifyContent: "center",
									width: "73px",
									borderRadius: "50%",
									height: "73px",
								}}></img>
						) : (
							<img
								onError={(e) => {
									e.target.src = "./images/usernew.png";
								}}
								src={process.env.REACT_APP_AWS_S3 + props.patients.avatar}
								style={{
									textAlign: "center",
									justifyContent: "center",
									width: "73px",
									borderRadius: "50%",
									height: "73px",
								}}
								alt="patient avatar"></img>
						)}
						<br></br>
						<label className="f-fm fm-w6-s20 color-00">
							{patients.firstName} {patients.lastName}
						</label>
						<br></br>
						{patients.gender === "Male" ? (
							<i
								class="fa fa-regular fa-mars"
								style={{
									color: "red",
								}}></i>
						) : (
							<i
								class="fa fa-solid fa-venus"
								style={{
									color: "red",
								}}></i>
						)}
						&nbsp;
						<label className="f-fm fm-w6-s16 " style={{ color: "#404040" }}>
							{props.calculate_age(patients.dob.split("T")[0])}
						</label>
						<br></br> <br></br>
						<div class="ribbon">
							<span>Medical Notes</span>
						</div>
					</div>
					<div //style={{ border: "solid 1px black" }}
					>
						{returnQuestionaire()}
					</div>
				</Modal.Body>
			</Modal>

			<div
				style={{
					paddingTop: "4vh",
					float: "right",
					justifyContent: "right",
					justifyItems: "right",
				}}>
				{apointment === "none" && (
					<Modal
						show={show}
						onHide={handleClose}
						dialogClassName="modal-sm-30px"
						size="md"
						position="top-right">
						<div
							className="row"
							style={{
								marginTop: "53px",
								float: "right",
								justifyContent: "right",
								justifyItems: "right",
							}}>
							<div className="col-1"></div>
							<div className="col-2">
								<img
									alt="close"
									style={{
										height: "24px",
										width: "24px",
										cursor: "pointer",
									}}
									onClick={handleClose}
									src="./images/close.png"
								/>
							</div>

							<div
								className="col-6"
								style={{ textAlign: "center", justifyContent: "center" }}>

								{props.patients.avatar === "" ? (
									<img
										alt="patient avatar"
										src="./images/usernew.png"
										style={{
											textAlign: "center",
											justifyContent: "center",
											width: "60px",
											borderRadius: "50%",
											height: "60px",
										}}></img>
								) : (
									<img
										onError={(e) => {
											e.target.src = "../images/usernew.png";
										}}
										src={process.env.REACT_APP_AWS_S3 + props.patients.avatar}
										style={{
											textAlign: "center",
											justifyContent: "center",
											width: "73px",
											borderRadius: "50%",
											height: "73px",
										}}
										alt="patient avatar"></img>
								)}
								<br></br>
								<label className="f-fm fm-w6-s20 color-00">
									{patients.firstName} {patients.lastName}
								</label>
								<br></br>
								{patients.gender === "Male" ? (
									<i
										class="fa fa-regular fa-mars"
										style={{
											color: "red",
										}}></i>
								) : (
									<i
										class="fa fa-solid fa-venus"
										style={{
											color: "red",
										}}></i>
								)}
								&nbsp;
								<label className="f-fm fm-w6-s16 " style={{ color: "#404040" }}>
									{props.calculate_age(patients.dob.split("T")[0])}
								</label>
								<div class="ribbon">
									<span style={{ fontSize: "7px" }}>Treatment History</span>
								</div>
							</div>
							<div
								className="col-2"
								style={{
									display: "flex",
									justifyContent: "end",
									alignContent: "end",
									height: "30px",
								}}></div>
							<div className="col-1"></div>
						</div>

						<Modal.Body>
							<Card
								style={{
									backgroundColor: "#fff",
									border: "1px solid #D6D6D6",
								}}>
								<ListGroup>
									{/* <ListGroupItem>
										<div className="row" style={{ alignItems: "center" }}>
											<div
												className="col-3"
												style={{ whiteSpace: "nowrap", marginRight: "8px" }}
											>
												<label
													className="f-fm fm-w6-s16 "
													style={{ color: "#404040" }}
												>
													Contact Info
												</label>
											</div>
											<div
												className="col-3"
												style={{
													width: "45px",
													height: "45px",
													backgroundColor: "#F4F4F4",
													boxSizing: "border-box",
													borderRadius: "22px",
												}}
											>
												<label
													className="color-7"
													style={{
														width: "14px",
														height: "14px",
														textAlign: "center",
														marginTop: "10px",
														marginLeft: "6px",
													}}
												>
													<i class="fa fa-phone"></i>
												</label>
											</div>
											&nbsp;&nbsp;&nbsp;
											<div
												className="col-3"
												style={{
													width: "45px",
													height: "45px",
													backgroundColor: "#F4F4F4",
													boxSizing: "border-box",
													borderRadius: "22px",
												}}
											>
												<label
													className="color-7"
													style={{
														width: "14px",
														height: "14px",
														textAlign: "center",
														marginTop: "10px",
														marginLeft: "3px",
													}}
												>
													<i class="fa fa-envelope"></i>
												</label>
											</div>
										</div>
									</ListGroupItem> */}
									<ListGroupItem>
										<div
											className="row"
											style={{
												alignItems: "center",
												paddingTop: "6px",
												paddingBottom: "6px",
												marginRight: "5px",
											}}>
											<div className="col-3" style={{ paddingRight: "0" }}>
												<label
													className="f-fm fm-w6-s16 "
													style={{ color: "#404040" }}>
													Registered Facial Scan
												</label>
											</div>
											<div
												className="col-9 d-flex"
												style={{
													gap: "5px",
												}}>
												{/* {patients.scannedimages === "" ||
												patients.scannedimages === undefined ||
												patients.scannedimages === null
													? "No scanned Images"
													: patients.scannedimages.split("|").map((img) => {
															return (
																// <a
																// 	href={process.env.REACT_APP_AWS_S3 + img}
																// 	target="_blank"
																// 	rel="noreferrer"
																// >
																<img
																	width="58px"
																	height="55px"
																	src={process.env.REACT_APP_AWS_S3 + img}
																	alt=""
																	className="facescan"
																	style={{
																		// paddingRight: "15px",
																		borderRadius: "10px",
																	}}
																	// onClick={window.open(
																	// 	process.env.REACT_APP_AWS_S3 + img
																	// )}
																/>
																// </a>
															);
													  })} */}
												{images.map((src, index) => (
													<img
														src={src}
														width="58px"
														height="55px"
														onClick={() => openImageViewer(index)}
														// width="300"
														key={index}
														style={{
															margin: "2px",
															borderRadius: "10px",
															cursor: "pointer",
														}}
														alt=""
													/>
												))}
												{isViewerOpen && (
													<ImageViewer
														src={images}
														currentIndex={currentImage}
														disableScroll={false}
														closeOnClickOutside={true}
														onClose={closeImageViewer}
													/>
												)}
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="row" style={{ alignItems: "center" }}>
											<div className="col-3">
												<label
													className="f-fm fm-w6-s16 "
													style={{ color: "#404040" }}>
													Allergy
												</label>
											</div>
											<div className="col-9">
												<label
													className="f-fm fm-w4-s16 "
													style={{ color: "#404040" }}>
													{patients.allergies === "" ||
													patients.allergies === " "
														? "No Allergies"
														: // patients.allergies.split("|").map((e) => {
														  // 		return e + ", ";
														  // }
														  patients.allergies.replaceAll("|", ", ")}
												</label>
											</div>
											{/* <div class="col-1">
												<img
													style={{ width: "7.41px", height: "12px" }}
													src="./images/Vector-new.png"
													alt=""
												/>
											</div> */}
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="row" style={{ alignItems: "center" }}>
											<div className="col-3">
												<label
													className="f-fm fm-w6-s16"
													style={{ color: "#404040" }}>
													Medical Notes
												</label>
											</div>
											<div
												className="col-9 f-fm fm-w4-s16"
												style={{ cursor: "pointer" }}
												onClick={() => {
													setShowMedicalHistory(true);
													handleClose();
												}}>
												<button
													style={{
														width: "208px",
														height: "42px",
														borderRadius: "40px",
														backgroundColor: "#fff",
														color: "#404040",
														border: "1px solid #000000",
													}}>
													View Medical History
												</button>
												<div
													className="f-fm fm-w4-s12"
													style={{ paddingLeft: "45px", paddingTop: "5px" }}>
													Last edit&nbsp;
													{moment(updateDate).format("DD/MM/YYYY")}
												</div>
											</div>
										</div>
									</ListGroupItem>
								</ListGroup>
							</Card>
						</Modal.Body>

						<Tabs
							className="ptab"
							defaultActiveKey="upcoming App"
							id="upcomingtab"
							style={{ justifyContent: "center" }}>
							<Tab
								eventKey="upcoming App"
								title={"Upcoming Appt (" + upp.length + ")"}>
								{upp.length === 0 ? (
									<Card
										style={{
											background: "#FFFFFF",
											boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
											borderRadius: "10px",
											padding: "30px",
											textAlign: "center",
										}}>
										<div>
											<label
												style={{
													color: "#000",
													fontStyle: "normal",
													lineHeight: "15px",
													textTransform: "uppercase",
												}}
												className="f-fm fm-w6-s12 ">
												No upcoming appointments
											</label>
										</div>
									</Card>
								) : (
									Object.values(appointments).map((a) => {
										return (
											<>
												{Object.values(a)
													.sort((a, b) =>
														a.appointmentdate < b.appointmentdate ? 1 : -1
													)
													.map((app) => {
														var aptaddress = utils.FormatAddress(app);
														return (
															patients.id === app.patientid && (
																<>
																	{app.iscompleted !== "true" &&
																		app.missedby === "" &&
																		app.cancelledby === "" &&
																		((app.complete !== "true" &&
																			app.ispaymentdone === false) ||
																			(app.complete !== "true" &&
																				app.ispaymentdone === true) ||
																			(app.complete === "true" &&
																				app.ispaymentdone &&
																				treatmentDateTime(app) >
																					currentTime)) &&
																		(treatmentDateTime(app) > currentTime ||
																			(currentTime > treatmentDateTime(app) &&
																				app.isdoctorcheckedin === "true")) && (
																			// app.iscompleted !== "true" &&
																			//   ((app.complete !== "true" &&
																			//     app.ispaymentdone === false) ||
																			//     (app.complete === "true" &&
																			//       currentTime < videoDateTime3hr(app))) &&
																			//   currentTime < treatmentDateTime(app) &&
																			//   app.missedby === "" &&
																			//   app.cancelledby === ""
																			// ||
																			// currentTime < consultDT(app)
																			<>
																				<Card
																					className="pointer"
																					style={{
																						background: "#FFFFFF",
																						boxShadow:
																							"0px 4px 30px rgba(0, 0, 0, 0.1)",
																						borderRadius: "10px",
																						padding: "13px",
																					}}
																					onClick={() => {
																						setapointmentapproval("block");
																						handleClose();
																						setSelectedAppointmentapproval(app);
																					}}>
																					<div className="row">
																						<div className="col-11">
																							<div>
																								<label
																									style={{
																										color: "#ACACAC",
																										fontStyle: "normal",
																										lineHeight: "15px",
																										textTransform: "uppercase",
																									}}
																									className="f-fm fm-w6-s12 ">
																									Time and Location
																								</label>
																							</div>
																							<div>
																								<label>
																									<img
																										style={{
																											width: "14px",
																											height: "14px",
																											color: "#777777",
																										}}
																										src="./images/Group.png"
																										alt=""
																									/>
																									&nbsp;&nbsp;
																									<span className="f-fm fm-w4-s16 color-7">
																										{moment(
																											new Date(
																												app.appointmentdate.split(
																													"T"
																												)[0]
																											)
																										).format("ll")}
																										&nbsp;&nbsp;
																										{utils.convertTo12HourFormat(
																											app.starttime
																										)}
																										&nbsp;&nbsp;-&nbsp;&nbsp;
																										{utils.convertTo12HourFormat(
																											app.endtime
																										)}
																									</span>
																								</label>
																							</div>
																							<div className="col">
																								<label className="d-flex">
																									<img
																										style={{
																											width: "14px",
																											height: "14px",
																											color: "#777777",
																											marginTop: "6px",
																											alignSelf: "auto",
																										}}
																										src="./images/map.png"
																										alt=""
																									/>
																									&nbsp;&nbsp;
																									<span className="f-fm fm-w4-s16 color-7">
																										{aptaddress.line1}
																										&nbsp;
																										{aptaddress.line2}
																										&nbsp;
																										{aptaddress.towncity}
																										&nbsp;
																										{aptaddress.country}
																										&nbsp;
																										{aptaddress.postcode}
																									</span>
																								</label>
																							</div>
																							<div
																								className="row"
																								style={{ paddingTop: "30px" }}>
																								<label
																									style={{ color: "#ACACAC" }}
																									className="f-fm fm-w6-s12 "></label>
																							</div>
																							{app.treatmentid[0]
																								.split(",")
																								.map((e, j) => {
																									return (
																										<div
																											style={{
																												display: "flex",
																												alignItems: "flex-end",
																												justifyContent:
																													"space-between",
																											}}>
																											<>
																												{props.getvalue(e, j)}
																												&nbsp;
																												{props.getprice(
																													e,
																													app.finalsyringes.split(
																														","
																													),
																													j
																												)}
																											</>
																										</div>
																									);
																								})}
																							<div>
																								<label
																									style={{
																										color: "#ACACAC",
																										paddingTop: "30px",
																									}}
																									className="f-fm fm-w6-s12"></label>
																							</div>

																							<label
																								style={{
																									color: "#ACACAC",
																									fontStyle: "normal",
																									lineHeight: "15px",
																									textTransform: "uppercase",
																								}}
																								className="f-fm fm-w6-s12 ">
																								consultation
																							</label>
																							<div
																								style={{
																									color: "#ACACAC",
																									paddingTop: "10px",
																								}}>
																								{app.videourl === "" ? (
																									"No Consultation Video"
																								) : (
																									<>
																										<video
																											style={{
																												height: "122px",
																												width: "200px",
																											}}
																											controls>
																											<source
																												src={
																													process.env
																														.REACT_APP_AWS_S3 +
																													app.videourl
																												}
																												type="video/mp4"
																											/>
																										</video>
																										<div className="f-fm fm-w3-s12 color-00">
																											{app.videodatetime}
																										</div>
																										<div className="f-fm fm-w3-s12 color-00">
																											Duration:{" "}
																											{app.videoduration}
																										</div>
																									</>
																								)}
																								{/* <br /> */}
																							</div>
																						</div>
																						<div
																							className="col-1"
																							style={{ display: "flex" }}>
																							<div
																								style={{
																									display: "flex",
																									alignItems: "center",
																								}}>
																								<div
																									style={{
																										textAlign: "right",
																										marginRight: "29px",
																									}}>
																									<img
																										src="./images/Vector-new.png"
																										alt=""
																									/>
																								</div>
																							</div>
																						</div>
																					</div>

																					{/* <div className="col">
																				<label className="f-fm fm-w3-s12 color-00"></label>
																			</div>
																			<div className="col">
																				<label className="f-fm fm-w3-s12 color-00"></label>
																			</div> */}
																				</Card>
																			</>
																		)}
																</>
															)
														);
													})}
											</>
										);
									})
								)}
							</Tab>
							<Tab
								eventKey="past App"
								title={
									"Past Appt (" +
									Number(arr.length + mis.length + can.length) +
									")"
								}>
								<div
									className=""
									style={{
										backgroundColor: "#F9F9FB",
										padding: "0px 20px 0px 20px",
									}}>
									<Tabs
										defaultActiveKey="All"
										id="profiletab"
										className="alltab"
										style={{ padding: "20px 20px" }}>
										<Tab eventKey="All" title={"All"}>
											<div>
												{mis.length === 0 && can.length === 0 ? (
													<Card
														style={{
															background: "#FFFFFF",
															boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
															borderRadius: "10px",
															padding: "13px",
															textAlign: "center",
														}}>
														<div>
															<label
																style={{
																	color: "#000",
																	fontStyle: "normal",
																	lineHeight: "15px",
																	textTransform: "uppercase",
																}}
																className="f-fm fm-w6-s12 ">
																No all appointments
															</label>
														</div>
													</Card>
												) : (
													Object.values(appointments).map((a) => {
														return (
															<>
																{Object.values(a)
																	.sort((a, b) =>
																		a.appointmentdate < b.appointmentdate
																			? 1
																			: -1
																	)
																	.map((app) => {
																		var aptaddress = utils.FormatAddress(app);
																		return (
																			patients.id === app.patientid && (
																				<>
																					{(app.iscompleted === "true" || //completed
																						(app.cancelledby !== "" &&
																							app.missedby === "") || //cancel
																						(app.missedby !== "" &&
																							app.cancelledby === "")) && (
																						// (app.iscompleted !== "true" &&
																						//   ((app.complete !== "true" &&
																						//     app.ispaymentdone === false) ||
																						//     (app.complete === "true" &&
																						//       currentTime >
																						//         videoDateTime3hr(app))) &&
																						//   (currentTime >
																						//     treatmentDateTime(app) ||
																						//     currentTime >
																						//       consultDT(app)))
																						<>
																							<Card
																								style={{
																									background: "#FFFFFF",
																									boxShadow:
																										"0px 4px 30px rgba(0, 0, 0, 0.1)",
																									borderRadius: "10px",
																									padding: "13px",
																								}}>
																								<div>
																									<label
																										style={{
																											color: "#ACACAC",
																											fontStyle: "normal",
																											lineHeight: "15px",
																											textTransform:
																												"uppercase",
																										}}
																										className="f-fm fm-w6-s12 ">
																										Time and Location
																									</label>
																								</div>
																								<div>
																									<label>
																										<img
																											style={{
																												width: "14px",
																												height: "14px",
																												color: "#777777",
																											}}
																											src="./images/Group.png"
																											alt=""
																										/>
																										&nbsp;&nbsp;
																										<span className="f-fm fm-w4-s16 color-7">
																											{moment(
																												new Date(
																													app.appointmentdate.split(
																														"T"
																													)[0]
																												)
																											).format("ll")}
																											&nbsp;&nbsp;
																											{utils.convertTo12HourFormat(
																												app.starttime
																											)}
																											&nbsp;&nbsp;-&nbsp;&nbsp;
																											{utils.convertTo12HourFormat(
																												app.endtime
																											)}
																										</span>
																									</label>
																								</div>
																								<div className="col">
																									<label className="d-flex">
																										<img
																											style={{
																												width: "14px",
																												height: "14px",
																												color: "#777777",
																												marginTop: "6px",
																												alignSelf: "auto",
																											}}
																											src="./images/map.png"
																											alt=""
																										/>
																										&nbsp;&nbsp;
																										<span className="f-fm fm-w4-s16 color-7">
																											{aptaddress.line1}
																											&nbsp;
																											{aptaddress.line2}
																											&nbsp;
																											{aptaddress.towncity}
																											&nbsp;
																											{aptaddress.country}
																											&nbsp;
																											{aptaddress.postcode}
																										</span>
																									</label>
																								</div>
																								<div
																									className="row"
																									style={{
																										paddingTop: "30px",
																									}}>
																									<label
																										style={{ color: "#ACACAC" }}
																										className="f-fm fm-w6-s12 "></label>
																								</div>
																								{app.treatmentid[0]
																									.split(",")
																									.map((e, j) => {
																										return (
																											<div
																												style={{
																													display: "flex",
																													alignItems:
																														"flex-end",
																													justifyContent:
																														"space-between",
																												}}>
																												<>
																													{props.getvalue(e, j)}
																													&nbsp;
																													{props.getprice(
																														e,
																														app.finalsyringes.split(
																															","
																														),
																														j
																													)}
																												</>
																											</div>
																										);
																									})}
																								<div>
																									<label
																										style={{
																											color: "#ACACAC",
																											paddingTop: "30px",
																										}}
																										className="f-fm fm-w6-s12"></label>
																								</div>

																								<label
																									style={{
																										color: "#ACACAC",
																										fontStyle: "normal",
																										lineHeight: "15px",
																										textTransform: "uppercase",
																									}}
																									className="f-fm fm-w6-s12 ">
																									consultation
																								</label>
																								<div
																									style={{
																										color: "#ACACAC",
																										paddingTop: "10px",
																									}}>
																									{app.videourl === "" ? (
																										"No Consultation Video"
																									) : (
																										<>
																											<video
																												style={{
																													height: "122px",
																													width: "200px",
																												}}
																												controls>
																												<source
																													src={
																														process.env
																															.REACT_APP_AWS_S3 +
																														app.videourl
																													}
																													type="video/mp4"
																												/>
																											</video>
																											<div className="f-fm fm-w3-s12 color-00">
																												{app.videodatetime}
																											</div>
																											<div className="f-fm fm-w3-s12 color-00">
																												Duration:{" "}
																												{app.videoduration}
																											</div>
																										</>
																									)}
																								</div>
																							</Card>
																						</>
																					)}
																				</>
																			)
																		);
																	})}
															</>
														);
													})
												)}
											</div>
										</Tab>
										{/* <Tab title="|" style={{ width: "100px" }}></Tab> */}
										<Tab eventKey="Complete" title="Complete">
											{arr.length === 0 ? (
												<Card
													style={{
														background: "#FFFFFF",
														boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
														borderRadius: "10px",
														padding: "13px",
														textAlign: "center",
													}}>
													<div>
														<label
															style={{
																color: "#000",
																fontStyle: "normal",
																lineHeight: "15px",
																textTransform: "uppercase",
															}}
															className="f-fm fm-w6-s12 ">
															No Complete appointments
														</label>
													</div>
												</Card>
											) : (
												Object.values(appointments).map((a) => {
													return (
														<>
															{Object.values(a)
																.sort((a, b) =>
																	a.appointmentdate < b.appointmentdate ? 1 : -1
																)
																.map((app) => {
																	var aptaddress = utils.FormatAddress(app);
																	return (
																		patients.id === app.patientid && (
																			<>
																				{app.iscompleted === "true" && (
																					<>
																						<Card
																							className="pointer"
																							onClick={() => {
																								setapointment("block");
																								handleClose();
																								setSelectedAppointment(app);
																							}}
																							style={{
																								background: "#FFFFFF",
																								boxShadow:
																									"0px 4px 30px rgba(0, 0, 0, 0.1)",
																								borderRadius: "10px",
																								padding: "13px",
																								cursor: "pointer",
																							}}>
																							<>
																								<div class="row">
																									<div class="col-11">
																										<div>
																											<label
																												style={{
																													color: "#ACACAC",
																													textTransform:
																														"uppercase",
																												}}
																												className="f-fm fm-w6-s12 ">
																												Time and Location
																											</label>
																										</div>
																										<div>
																											<label>
																												<img
																													style={{
																														width: "14px",
																														height: "14px",
																														color: "#777777",
																													}}
																													src="./images/Group.png"
																													alt=""
																												/>
																												&nbsp;&nbsp;
																												<span className="f-fm fm-w4-s16 color-7">
																													{/* {app.appointmentdate.split(
                                                            "T"
                                                          )[0] + " "}
                                                          |{" "}
                                                          {" " +
                                                            app.starttime +
                                                            " "}
                                                          -{" " + app.endtime} */}
																													{moment(
																														new Date(
																															app.appointmentdate.split(
																																"T"
																															)[0]
																														)
																													).format("ll")}
																													&nbsp;&nbsp;
																													{utils.convertTo12HourFormat(
																														app.starttime
																													)}
																													&nbsp;&nbsp;-&nbsp;&nbsp;
																													{utils.convertTo12HourFormat(
																														app.endtime
																													)}
																												</span>
																											</label>
																										</div>

																										<div className="col">
																											<label className="d-flex">
																												<img
																													style={{
																														width: "14px",
																														height: "14px",
																														color: "#777777",
																														marginTop: "6px",
																														alignSelf: "auto",
																													}}
																													src="./images/map.png"
																													alt=""
																												/>
																												&nbsp;&nbsp;
																												<span className="f-fm fm-w4-s16 color-7">
																													{aptaddress.line1}
																													&nbsp;
																													{aptaddress.line2}
																													&nbsp;
																													{aptaddress.towncity}
																													&nbsp;
																													{aptaddress.country}
																													&nbsp;
																													{aptaddress.postcode}
																												</span>
																											</label>
																										</div>

																										<div
																											className="row"
																											style={{
																												paddingTop: "28px",
																											}}>
																											<label
																												style={{
																													color: "#ACACAC",
																												}}
																												className="f-fm fm-w6-s12 ">
																												TREATMENT
																											</label>
																										</div>

																										{app.treatmentid[0]
																											.split(",")
																											.map((e, j) => {
																												return (
																													<>
																														{props.getvalue(e)}
																													</>
																												);
																											})}
																									</div>
																									<div
																										class="col-1"
																										style={{
																											display: "flex",
																											alignItems: "center",
																										}}>
																										<div
																											style={{
																												textAlign: "right",
																												marginRight: "29px",
																											}}>
																											<img
																												src="./images/Vector-new.png"
																												alt=""
																											/>
																										</div>
																									</div>
																								</div>
																							</>
																						</Card>
																					</>
																				)}
																			</>
																		)
																	);
																})}
														</>
													);
												})
											)}
										</Tab>

										<Tab eventKey="Cancelled" title="Cancelled">
											{can.length === 0 ? (
												<Card
													style={{
														background: "#FFFFFF",
														boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
														borderRadius: "10px",
														padding: "13px",
														textAlign: "center",
													}}>
													<div>
														<label
															style={{
																color: "#000",
																fontStyle: "normal",
																lineHeight: "15px",
																textTransform: "uppercase",
															}}
															className="f-fm fm-w6-s12 ">
															No cancel appointments
														</label>
													</div>
												</Card>
											) : (
												Object.values(appointments).map((a) => {
													return (
														<>
															{Object.values(a)
																.sort((a, b) =>
																	a.appointmentdate < b.appointmentdate ? 1 : -1
																)
																.map((app) => {
																	var aptaddress = utils.FormatAddress(app);
																	return (
																		patients.id === app.patientid && (
																			<>
																				{app.missedby === "" &&
																					app.cancelledby !== "" && (
																						<>
																							<Card
																								className="pointer"
																								onClick={() => {
																									setapointmentapproval(
																										"block"
																									);
																									setValue("Cancelled");
																									handleClose();
																									setSelectedAppointmentapproval(
																										app
																									);
																								}}
																								style={{
																									background: "#FFFFFF",
																									boxShadow:
																										"0px 4px 30px rgba(0, 0, 0, 0.1)",
																									borderRadius: "10px",
																									padding: "0px 13px 13px 13px",
																									cursor: "pointer",
																									marginBottom: "30px",
																								}}>
																								<>
																									<div
																										className="row f-fm fm-w4-s14"
																										style={{
																											backgroundColor:
																												"#F4F4F4",
																											height: "34px",
																											display: "flex",
																											justifyContent: "center",
																											alignItems: "center",
																											margin:
																												"-14px -14px 0px -14px",
																										}}>
																										Cancelled by{" "}
																										{app.cancelledby
																											.charAt(0)
																											.toUpperCase() +
																											app.cancelledby.slice(1)}
																									</div>
																									<div class="row">
																										<div class="col-11">
																											<div className="pb-2">
																												<label
																													style={{
																														color: "#ACACAC",
																														textTransform:
																															"uppercase",
																													}}
																													className="f-fm fm-w6-s12 pt-4">
																													Time and Location
																												</label>
																											</div>
																											<div>
																												<label>
																													<img
																														style={{
																															width: "14px",
																															height: "14px",
																															color: "#777777",
																														}}
																														src="./images/Group.png"
																														alt=""
																													/>
																													&nbsp;&nbsp;
																													<span className="f-fm fm-w4-s16 color-7">
																														{/* {app.appointmentdate.split(
                                                            "T"
                                                          )[0] + " "}
                                                          |{" "}
                                                          {" " +
                                                            app.starttime +
                                                            " "}
                                                          -{" " + app.endtime} */}
																														{moment(
																															new Date(
																																app.appointmentdate.split(
																																	"T"
																																)[0]
																															)
																														).format("ll")}
																														&nbsp;&nbsp;
																														{utils.convertTo12HourFormat(
																															app.starttime
																														)}
																														&nbsp;&nbsp;-&nbsp;&nbsp;
																														{utils.convertTo12HourFormat(
																															app.endtime
																														)}
																													</span>
																												</label>
																											</div>

																											<div className="col">
																												<label className="d-flex">
																													<img
																														style={{
																															width: "14px",
																															height: "14px",
																															color: "#777777",
																															marginTop: "6px",
																															alignSelf: "auto",
																														}}
																														src="./images/map.png"
																														alt=""
																													/>
																													&nbsp;&nbsp;
																													<span className="f-fm fm-w4-s16 color-7">
																														{aptaddress.line1}
																														&nbsp;
																														{aptaddress.line2}
																														&nbsp;
																														{
																															aptaddress.towncity
																														}
																														&nbsp;
																														{aptaddress.country}
																														&nbsp;
																														{
																															aptaddress.postcode
																														}
																													</span>
																												</label>
																											</div>

																											<div
																												className="row"
																												style={{
																													paddingTop: "28px",
																												}}>
																												<label
																													style={{
																														color: "#ACACAC",
																													}}
																													className="f-fm fm-w6-s12 ">
																													TREATMENT
																												</label>
																											</div>

																											{app.treatmentid[0]
																												.split(",")
																												.map((e, j) => {
																													return (
																														<div
																															style={{
																																display: "flex",
																																alignItems:
																																	"flex-end",
																																justifyContent:
																																	"space-between",
																															}}>
																															<>
																																{props.getvalue(
																																	e,
																																	j
																																)}
																																&nbsp;
																																{props.getprice(
																																	e,
																																	app.finalsyringes.split(
																																		","
																																	),
																																	j
																																)}
																															</>
																														</div>
																													);
																												})}
																										</div>
																										<div
																											class="col-1"
																											style={{
																												display: "flex",
																												alignItems: "center",
																											}}>
																											<div
																												style={{
																													textAlign: "right",
																													marginRight: "29px",
																												}}>
																												<img
																													src="./images/Vector-new.png"
																													alt=""
																												/>
																											</div>
																										</div>
																									</div>
																								</>
																							</Card>
																						</>
																					)}
																			</>
																		)
																	);
																})}
														</>
													);
												})
											)}
										</Tab>
										<Tab eventKey="Missed" title="Missed">
											<div>
												{mis.length === 0 ? (
													<Card
														style={{
															background: "#FFFFFF",
															boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
															borderRadius: "10px",
															padding: "13px",
															textAlign: "center",
														}}>
														<div>
															<label
																style={{
																	color: "#000",
																	fontStyle: "normal",
																	lineHeight: "15px",
																	textTransform: "uppercase",
																}}
																className="f-fm fm-w6-s12 ">
																No missed appointments
															</label>
														</div>
													</Card>
												) : (
													Object.values(appointments).map((a) => {
														return (
															<>
																{Object.values(a)
																	.sort((a, b) =>
																		a.appointmentdate < b.appointmentdate
																			? 1
																			: -1
																	)
																	.map((app) => {
																		var aptaddress = utils.FormatAddress(app);
																		return (
																			patients.id === app.patientid && (
																				<>
																					{
																						// app.iscompleted !== "true" &&
																						//   ((app.complete !== "true" &&
																						//     app.ispaymentdone === false) ||
																						//     (app.complete === "true" &&
																						//       currentTime >
																						//         videoDateTime3hr(app))) &&
																						//   (currentTime >
																						//     treatmentDateTime(app) ||
																						//     (currentTime > consultDT(app) &&
																						//       app.cancelledby === "")) &&
																						app.missedby !== "" &&
																							app.cancelledby === "" && (
																								<>
																									<Card
																										className="pointer"
																										style={{
																											background: "#FFFFFF",
																											boxShadow:
																												"0px 4px 30px rgba(0, 0, 0, 0.1)",
																											borderRadius: "10px",
																											padding:
																												"0px 13px 13px 13px",
																											marginBottom: "35px",
																											cursor: "pointer",
																										}}
																										onClick={() => {
																											setapointmentapproval(
																												"block"
																											);
																											setValue("Missed");
																											handleClose();
																											setSelectedAppointmentapproval(
																												app
																											);
																										}}>
																										<div
																											className="row f-fm fm-w4-s14"
																											style={{
																												backgroundColor:
																													"#F4F4F4",
																												height: "34px",
																												display: "flex",
																												justifyContent:
																													"center",
																												alignItems: "center",
																												margin:
																													"-14px -14px 0px -14px",
																											}}>
																											Missed by{" "}
																											{app.missedby
																												.charAt(0)
																												.toUpperCase() +
																												app.missedby.slice(1)}
																										</div>
																										<div className="row">
																											<div className="col-11">
																												<div>
																													<label
																														style={{
																															color: "#ACACAC",
																															fontStyle:
																																"normal",
																															lineHeight:
																																"15px",
																															textTransform:
																																"uppercase",
																														}}
																														className="f-fm fm-w6-s12 pt-4">
																														Time and Location
																													</label>
																												</div>
																												<div>
																													<label>
																														<img
																															style={{
																																width: "14px",
																																height: "14px",
																																color:
																																	"#777777",
																															}}
																															src="./images/Group.png"
																															alt=""
																														/>
																														&nbsp;&nbsp;
																														<span className="f-fm fm-w4-s16 color-7">
																															{moment(
																																new Date(
																																	app.appointmentdate.split(
																																		"T"
																																	)[0]
																																)
																															).format("ll")}
																															&nbsp;&nbsp;
																															{utils.convertTo12HourFormat(
																																app.starttime
																															)}
																															&nbsp;&nbsp;-&nbsp;&nbsp;
																															{utils.convertTo12HourFormat(
																																app.endtime
																															)}
																														</span>
																													</label>
																												</div>
																												<div className="col">
																													<label className="d-flex">
																														<img
																															style={{
																																width: "14px",
																																height: "14px",
																																color:
																																	"#777777",
																																marginTop:
																																	"6px",
																																alignSelf:
																																	"auto",
																															}}
																															src="./images/map.png"
																															alt=""
																														/>
																														&nbsp;&nbsp;
																														<span className="f-fm fm-w4-s16 color-7">
																															{aptaddress.line1}
																															&nbsp;
																															{aptaddress.line2}
																															&nbsp;
																															{
																																aptaddress.towncity
																															}
																															&nbsp;
																															{
																																aptaddress.country
																															}
																															&nbsp;
																															{
																																aptaddress.postcode
																															}
																														</span>
																													</label>
																												</div>
																												<div
																													className="row"
																													style={{
																														paddingTop: "30px",
																													}}>
																													<label
																														style={{
																															color: "#ACACAC",
																														}}
																														className="f-fm fm-w6-s12 pb-2">
																														TREATMENT
																													</label>
																												</div>
																												{app.treatmentid[0]
																													.split(",")
																													.map((e, j) => {
																														return (
																															<div
																																style={{
																																	display:
																																		"flex",
																																	alignItems:
																																		"flex-end",
																																	justifyContent:
																																		"space-between",
																																}}>
																																<>
																																	{props.getvalue(
																																		e,
																																		j
																																	)}
																																	&nbsp;
																																	{props.getprice(
																																		e,
																																		app.finalsyringes.split(
																																			","
																																		),
																																		j
																																	)}
																																</>
																															</div>
																														);
																													})}
																											</div>
																											<div
																												class="col-1"
																												style={{
																													display: "flex",
																													alignItems: "center",
																												}}>
																												<div
																													style={{
																														textAlign: "right",
																														marginRight: "29px",
																													}}>
																													<img
																														src="./images/Vector-new.png"
																														alt=""
																													/>
																												</div>
																											</div>
																										</div>

																										{/* <label
                                                    style={{
                                                      color: "#ACACAC",
                                                      fontStyle: "normal",
                                                      lineHeight: "15px",
                                                      textTransform:
                                                        "uppercase",
                                                    }}
                                                    className="f-fm fm-w6-s12 "
                                                  >
                                                    consultation
                                                  </label>
                                                  <div
                                                    style={{
                                                      color: "#ACACAC",
                                                      paddingTop: "10px",
                                                    }}
                                                  >
                                                    {app.videourl === "" ? (
                                                      "No Consultation Video"
                                                    ) : (
                                                      <>
                                                        <video
                                                          style={{
                                                            height: "122px",
                                                            width: "200px",
                                                          }}
                                                          controls
                                                        >
                                                          <source
                                                            src={
                                                              process.env
                                                                .REACT_APP_AWS_S3 +
                                                              app.videourl
                                                            }
                                                            type="video/mp4"
                                                          />
                                                        </video>
                                                        <div className="f-fm fm-w3-s12 color-00">
                                                          {app.videodatetime}
                                                        </div>
                                                        <div className="f-fm fm-w3-s12 color-00">
                                                          Duration:{" "}
                                                          {app.videoduration}
                                                        </div>
                                                      </>
                                                    )}
                                                  </div> */}
																									</Card>
																								</>
																							)
																					}
																				</>
																			)
																		);
																	})}
															</>
														);
													})
												)}
											</div>
										</Tab>
									</Tabs>
								</div>
							</Tab>
							{/* <Tab
                eventKey="Missed App"
                title={"Missed Appt (" + mis.length + ")"}
              >
                {mis.length === 0 ? (
                  <Card
                    style={{
                      background: "#FFFFFF",
                      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      padding: "30px",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <label
                        style={{
                          color: "#000",
                          fontStyle: "normal",
                          lineHeight: "15px",
                          textTransform: "uppercase",
                        }}
                        className="f-fm fm-w6-s12 "
                      >
                        No missed appointments
                      </label>
                    </div>
                  </Card>
                ) : (
                  Object.values(appointments).map((a) => {
                    return (
                      <>
                        {Object.values(a)
                          .sort((a, b) =>
                            a.appointmentdate < b.appointmentdate ? 1 : -1
                          )
                          .map((app) => {
                            return (
                              patients.id === app.patientid && (
                                <>
                                  {app.iscompleted !== "true" &&
                                    ((app.complete !== "true" &&
                                      app.ispaymentdone === false) ||
                                      (app.complete === "true" &&
                                        currentTime > videoDateTime3hr(app))) &&
                                    (currentTime > treatmentDateTime(app) ||
                                      currentTime > consultDT(app)) && (
                                      <>
                                        <Card
                                          style={{
                                            background: "#FFFFFF",
                                            boxShadow:
                                              "0px 4px 30px rgba(0, 0, 0, 0.1)",
                                            borderRadius: "10px",
                                            padding: "30px",
                                          }}
                                        >
                                          <div>
                                            <label
                                              style={{
                                                color: "#ACACAC",
                                                fontStyle: "normal",
                                                lineHeight: "15px",
                                                textTransform: "uppercase",
                                              }}
                                              className="f-fm fm-w6-s12 "
                                            >
                                              Time and Location
                                            </label>
                                          </div>
                                          <div>
                                            <label>
                                              <img
                                                style={{
                                                  width: "14px",
                                                  height: "14px",
                                                  color: "#777777",
                                                }}
                                                src="./images/Group.png"
                                                alt=""
                                              />
                                              &nbsp;&nbsp;
                                              <span className="f-fm fm-w4-s16 color-7">
                                                {
                                                  app.appointmentdate.split(
                                                    "T"
                                                  )[0]
                                                }{" "}
                                                | {" " + app.starttime + " "}-
                                                {" " + app.endtime}
                                              </span>
                                            </label>
                                          </div>
                                          <div className="col">
                                            <img
                                              style={{
                                                width: "14px",
                                                height: "14px",
                                                color: "#777777",
                                              }}
                                              src="./images/maps-and-flags.png"
                                              alt=""
                                            />
                                            &nbsp;&nbsp;
                                            <label className="f-fm fm-w4-s16 color-7">
                                              Not available to view
                                            </label>
                                          </div>
                                          <div
                                            className="row"
                                            style={{ paddingTop: "30px" }}
                                          >
                                            <label
                                              style={{ color: "#ACACAC" }}
                                              className="f-fm fm-w6-s12 "
                                            ></label>
                                          </div>
                                          {app.treatmentid[0]
                                            .split(",")
                                            .map((e, j) => {
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <>
                                                    {props.getvalue(e, j)}
                                                    &nbsp;
                                                    {props.getprice(
                                                      e,
                                                      app.finalsyringes.split(
                                                        ","
                                                      ),
                                                      j
                                                    )}
                                                  </>
                                                </div>
                                              );
                                            })}
                                          <div>
                                            <label
                                              style={{
                                                color: "#ACACAC",
                                                paddingTop: "30px",
                                              }}
                                              className="f-fm fm-w6-s12"
                                            ></label>
                                          </div>

                                          <label
                                            style={{
                                              color: "#ACACAC",
                                              fontStyle: "normal",
                                              lineHeight: "15px",
                                              textTransform: "uppercase",
                                            }}
                                            className="f-fm fm-w6-s12 "
                                          >
                                            consultation
                                          </label>
                                          <div
                                            style={{
                                              color: "#ACACAC",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {app.videourl === "" ? (
                                              "No Consultation Video"
                                            ) : (
                                              <>
                                                <video
                                                  style={{
                                                    height: "122px",
                                                    width: "200px",
                                                  }}
                                                  controls
                                                >
                                                  <source
                                                    src={
                                                      process.env
                                                        .REACT_APP_AWS_S3 +
                                                      app.videourl
                                                    }
                                                    type="video/mp4"
                                                  />
                                                </video>
                                                <div className="f-fm fm-w3-s12 color-00">
                                                  {app.videodatetime}
                                                </div>
                                                <div className="f-fm fm-w3-s12 color-00">
                                                  Duration: {app.videoduration}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </Card>
                                      </>
                                    )}
                                </>
                              )
                            );
                          })}
                      </>
                    );
                  })
                )}
              </Tab> */}
						</Tabs>
					</Modal>
				)}
				{apointment === "block" && (
					<Apointment
						hideapointment={hideapointment}
						appointment={selectedAppointment}
						show={showAgain}
						videoUrl={(v) => {
							console.log(v);
							setVideoUrlComplete(v);
						}}
						videoModal={(v) => {
							setVideoModalComplete(v);
							hideapointment();
						}}
						getvalue={props.getvalue}
						getprice={props.getprice}
						getsyringes={props.getSyringes}></Apointment>
				)}
				{apointmentapproval === "block" && (
					<ApprovalModel
						hideapointment={hideapointmentapproval}
						appointment={selectedAppointmentapproval}
						show={showAgainApproval}
						// cancel={hideapointmentapproval}
						getvalue={props.getvalueapproval}
						getprice={props.getpriceapproval}
						value={value}
						params={props.params}
						allappointments={uppappointments}
						videoUrl={(v) => {
							console.log(v);
							setVideoUrl(v);
						}}
						videoModal={(v) => {
							setVideoModal(v);
							hideapointmentapproval();
						}}></ApprovalModel>
				)}
			</div>
		</>
	);
};

export default Patient2;
