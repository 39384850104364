import React from "react";

const ThankyouModal = (props) => {
	return (
		<>
			<div style={{ padding: "30px 30px" }}>
				<div
					style={{
						cursor: "pointer",
						width: "100%",
						display: "flex",
						justifyContent: "end",
					}}
					onClick={() => {
						props.cancel(false);
					}}
				>
					<img alt="close" src="./images/closeone.png"></img>
				</div>
				<div
					className="col-1 pt-2"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						paddingLeft: "0px",
						width: "40px",
					}}
				>
					<span
						style={{
							height: "36px",
							width: "36px",
							background: "#AF805E",
							borderRadius: "20px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<i class="fa fa-thin fa-check" style={{ color: "#fff" }}></i>
					</span>
				</div>
				<div className="f-fm fm-w6-s36 pt-5" style={{ lineHeight: "45px" }}>
					Thank you for submitting the questionnaire, this will be accessed and
					reviewed against your treatment time activity.
				</div>
				<div
					className="col-9 f-fm fm-w4-s16 pt-2"
					style={{
						cursor: "pointer",
						width: "100%",
						display: "flex",
						justifyContent: "end",
					}}
					onClick={() => {
						props.cancel(false);
						props.save(true);
					}}
				>
					<button
						style={{
							width: "165px",
							height: "50px",
							whiteSpace: "nowrap",
							borderRadius: "40px",
							backgroundColor: "#000",
							color: "#fff",
							border: "1px solid #000000",
						}}
					>
						Add New Treatment
					</button>
				</div>
			</div>
		</>
	);
};
export default ThankyouModal;
