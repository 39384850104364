import { Baseurl } from "../common/util";
const axios = require("axios").default;

const formatYmd = (date) => date.toISOString().slice(0, 10);

const CREATE_SUBSCRIPTIONHISTORY =
	() => `mutation create($paymentstatus: String!, $doctorid:String!, $transactionid: String!){
    subscriptionhistory($paymentstatus:$paymentstatus, doctorid:$doctorid, transactionid:$transactionid){
	    id,
        transactionid,
        doctorid,
        paymentstatus
    }
  }`;

const UPDATE_SUBSCRIPTIONHISTORY = () => `mutation create($id:ID,$obj:String){
    update(id:$id, obj:$obj){
      id
    }
  }`;

const UPDATE_SUBSCRIPTIONHISTORY_TRANSACTIONID =
	() => `mutation create($id:ID,$obj:String){
    updateByTransactionid(id:$id, obj:$obj){
      id
    }
  }`;

export const returnUpdateVariables = (props) => {
	console.log(props);
	var returnstring = `{
        "id": "#id#",
        "obj": "#obj#"
    }`;
	const id = props.id;
	delete props.id;
	const escapestr = JSON.stringify(props).replaceAll(/"/g, '\\"');
	returnstring = returnstring.replaceAll(/#id#/g, id);
	returnstring = returnstring.replaceAll(/#obj#/g, escapestr);

	return returnstring;
};

export const returnCreateVariables = (props) => {
	console.log(props);

	var returnstring = `{
        "transactionid": "#transactionid#",
        "doctorid": "#doctorid#",
        "paymentstatus": "#paymentstatus#"
      }`;

	Object.keys(props).forEach((key, index) => {
		returnstring = returnstring.replace(
			"#" + key.toLowerCase() + "#",
			props[key]
		);
	});

	returnstring = returnstring.replaceAll(/#[a-z0-9]{1,}#/g, "");

	return returnstring;
};

const SUBSCRIPTIONHISTORY_BYID = () => `query {
  subscriptionhistoryByID(id: "#id#"){
	    id,
        transactionid,
        amount,
        planid,
		cardbrand,
		cardlast4,
        planname,
        startdate,
        enddate,
        doctorid,
        paymentstatus
  	}
  }
`;

const SUBSCRIPTIONHISTORY_BYDOCTORID = () => `query {
	subscriptionhistoryByDoctorid(doctorid: "#doctorid#"){
		id,
        transactionid,
        doctorid,
        planid,
		cardbrand,
		cardlast4,
        amount,
        planname,
        startdate,
        enddate,
        paymentstatus
		}
	}`;

const SUBSCRIPTIONHISTORY_BYDOCTORIDSUCCESS = () => `query {
	subscriptionhistoryByDoctoridSuccess(doctorid: "#doctorid#"){
		id,
        transactionid,
        doctorid,
        planid,
		cardbrand,
		cardlast4,
        amount,
        planname,
        startdate,
        enddate,
        paymentstatus
		}
	}`;

const SUBSCRIPTIONHISTORY_BYDOCTORIDSUCCESSADMIN = () => `query {
	subscriptionhistoryByDoctoridSuccessAdmin(doctorid: "#doctorid#"){
		id,
        transactionid,
        doctorid,
        planid,
		cardbrand,
		cardlast4,
        amount,
        planname,
        startdate,
        enddate,
        paymentstatus
		}
	}`;

const GET_SUBSCRIPTIONHISTORY = () => `query{
    subscriptionhistory{
		id,
        transactionid,
        doctorid,
        planid,
		cardbrand,
		cardlast4,
        amount,
        planname,
        startdate,
        enddate,
        paymentstatus
    }
}`;

export const GetSubscriptionhistory = () =>
	axios({
		url: Baseurl() + "subscriptionhistory",
		method: "post",
		data: {
			query: GET_SUBSCRIPTIONHISTORY(),
		},
	}).then((result) => {
		console.log(result);
		return result.data.data;
	});

export const GetSubscriptionhistoryById = (id) =>
	axios({
		url: Baseurl() + "subscriptionhistory",
		method: "post",
		data: {
			query: SUBSCRIPTIONHISTORY_BYID().replace("#id#", id),
		},
	}).then((result) => {
		console.log(result);
		return result.data.data;
	});

export const GetSubscriptionhistoryByDoctorId = (id) =>
	axios({
		url: Baseurl() + "subscriptionhistory",
		method: "post",
		data: {
			query: SUBSCRIPTIONHISTORY_BYDOCTORID().replace("#doctorid#", id),
		},
	}).then((result) => {
		console.log(result);
		return result.data.data;
	});

export const GetSubscriptionhistoryByDoctorIdSuccess = (id) =>
	axios({
		url: Baseurl() + "subscriptionhistory",
		method: "post",
		data: {
			query: SUBSCRIPTIONHISTORY_BYDOCTORIDSUCCESS().replace("#doctorid#", id),
		},
	}).then((result) => {
		console.log(result);
		return result.data.data;
	});

export const GetSubscriptionhistoryByDoctorIdSuccessAdmin = (id) =>
	axios({
		url: Baseurl() + "subscriptionhistory",
		method: "post",
		data: {
			query: SUBSCRIPTIONHISTORY_BYDOCTORIDSUCCESSADMIN().replace(
				"#doctorid#",
				id
			),
		},
	}).then((result) => {
		console.log(result);
		return result.data.data;
	});

export const CreateSubscriptionhistory = (props) =>
	axios({
		url: Baseurl() + "subscriptionhistory",
		method: "post",
		data: {
			query: CREATE_SUBSCRIPTIONHISTORY(),
			variables: props,
		},
	})
		.then((res) => {
			console.log(res);
			return res.data.data;
		})
		.catch((err) => console.log(err));

export const UpdateSubscriptionhistory = (props) =>
	axios({
		url: Baseurl() + "subscriptionhistory",
		method: "post",
		data: {
			query: UPDATE_SUBSCRIPTIONHISTORY(),
			variables: props,
		},
	})
		.then((res) => {
			console.log(res);
			return res.data.data;
		})
		.catch((err) => console.log(err));

export const UpdateSubscriptionhistoryByTransactionid = (props) =>
	axios({
		url: Baseurl() + "subscriptionhistory",
		method: "post",
		data: {
			query: UPDATE_SUBSCRIPTIONHISTORY_TRANSACTIONID(),
			variables: props,
		},
	})
		.then((res) => {
			console.log(res);
			return res.data.data;
		})
		.catch((err) => console.log(err));

export async function DeleteSubscriptionhistory(props) {
	console.log("ee", props);
	var propss = `{
    "id": "#id#"
  }`;
	propss = propss.replace("#id#", props);
	console.log("eew", propss);
	try {
		const response = await axios({
			url: Baseurl() + "subscriptionhistory",
			method: "post",
			data: {
				query: `{
          deletesubscriptionhistory (id:"#id#"){
          id
             }
           }`.replace("#id#", props),
			},
		});
		console.log("user", response.data);
		return response.data;
	} catch (error) {
		return [""];
	}
}
