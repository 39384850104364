import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import * as TreatmentService from "../../services/treatments";
import "react-toastify/dist/ReactToastify.css";
import * as UserServices from "../../services/user";

const DocorQuestioniareModal = (props) => {
	console.log(props);
	const [firstAnswer, setFirstAnswer] = useState("");
	const [secondAnswer, setSecondAnswer] = useState("");
	const [thirdAnswer, setThirdAnswer] = useState("");
	const [fourthAnswer, setFourthAnswer] = useState("");
	const [fifthAnswer, setFifthAnswer] = useState("");
	const [sixthAnswer, setSixthAnswer] = useState("");
	const [seventhAnswer, setSeventhAnswer] = useState("");
	const [eightAnswer, setEightAnswer] = useState("");
	const [thankyoumodal, setShowThankYouModal] = useState(false);

	//   console.log(
	//     "answers",
	//     firstAnswer,
	//     secondAnswer,
	//     thirdAnswer,
	//     fourthAnswer,
	//     fifthAnswer,
	//     sixthAnswer,
	//     seventhAnswer,
	//     eightAnswer
	//   );

	const handleCloseThankyou = () => {
		setShowThankYouModal(false);
	};

	const handleSubmit = () => {
		(async function anyNameFunction() {
			let answerstring =
				firstAnswer +
				"|" +
				secondAnswer +
				"|" +
				thirdAnswer +
				"|" +
				fourthAnswer +
				"|" +
				fifthAnswer +
				"|" +
				sixthAnswer +
				"|" +
				seventhAnswer +
				"|" +
				eightAnswer;
			const updateSettingsvariables = UserServices.returnUpdateSettings({
				id: props.userid,
				answers: answerstring,
			});
			console.log(updateSettingsvariables);
			UserServices.UpdateSettings(updateSettingsvariables).then((value) => {
				console.log(value);
				// window.location.reload();
				toast.success("Successfully record saved", {
					toastId: "calender",
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				window.scrollTo(0, 0);

				props.cancel(false);
				props.save(true);
			});
			//   setShowThankYouModal(true);
			setTimeout(() => {
				// setIstoastc(false);
				// window.location.reload();
				// setShowThankYouModal(true)
			}, 1000);
		})();
	};
	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
			<div style={{ padding: "20px 20px" }}>
				<div className="row" style={{ borderRadius: "10px 10px" }}>
					<div className="col-xl col-lg col-md col-xs col-sm">
						<img
							alt="Close"
							onClick={() => {
								props.cancel(false);
							}}
							style={{
								cursor: "pointer",

								float: "right",
								// paddingRight: "52px",
							}}
							src="../images/close.png"
						></img>
					</div>
				</div>
				<div className="row">
					<div className="col-11">
						<div className="f-fm fm-w6-s36" style={{ color: "#AF805E" }}>
							You need to complete this questionnaire first.
						</div>
						<div className="f-fm fm-w4-s16">
							To provide you with the best possible experience, we kindly
							request you to answer the following questions before creating your
							first treatment. It should take you approximately 1 minute.
						</div>
					</div>

					<div className="row pt-5" style={{ paddingLeft: "20px" }}>
						<div
							className="col-1"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								paddingLeft: "0px",
								width: "40px",
							}}
						>
							<span
								style={{
									height: "28px",
									width: "28px",
									background: "#F4F4F4",
									borderRadius: "14px",
									display: "inline-block",
									textAlign: "center",
								}}
							>
								1
							</span>
						</div>
						<div
							className="col-9 f-fm fm-w6-s18"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "0px",
							}}
						>
							<span className="f-fm fm-w6-s18">
								How many days a week do you work as a doctor / nurse?
							</span>
						</div>
						<div
							className="col-2"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "30px",
							}}
						>
							<select onChange={(e) => setFirstAnswer(e.target.value)}>
								<option value=""></option>
								<option value="1">01</option>
								<option value="2">02</option>
								<option value="3">03</option>
								<option value="4">04</option>
								<option value="5">05</option>
								<option value="6">06</option>
								<option value="7">07</option>
							</select>
							<span className="f-fm fm-w6-s18" style={{ paddingLeft: "10px" }}>
								days
							</span>
						</div>
						<div style={{ paddingLeft: "0px" }}>
							<hr style={{ paddingLeft: "20px", size: "1px", color: "grey" }} />
						</div>
					</div>
					<div className="row" style={{ paddingLeft: "20px" }}>
						<div
							className="col-1"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								paddingLeft: "0px",
								width: "40px",
							}}
						>
							<span
								style={{
									height: "28px",
									width: "28px",
									background: "#F4F4F4",
									borderRadius: "14px",
									display: "inline-block",
									textAlign: "center",
								}}
							>
								2
							</span>
						</div>
						<div
							className="col-9 f-fm fm-w6-s18"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "0px",
							}}
						>
							<span className="f-fm fm-w6-s18">
								How many hours do you work per day on average?
							</span>
						</div>
						<div
							className="col-2"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "30px",
							}}
						>
							<select onChange={(e) => setSecondAnswer(e.target.value)}>
								<option value=""></option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="8">8</option>
								<option value="9">9</option>
								<option value="10">10</option>
								<option value="11">11</option>
								<option value="12">12</option>
								<option value="13">13</option>
								<option value="14">14</option>
								<option value="15">15</option>
								<option value="16">16</option>
								<option value="17">17</option>
								<option value="18">18</option>
								<option value="19">19</option>
								<option value="20">20</option>
								<option value="21">21</option>
								<option value="22">22</option>
								<option value="23">23</option>
								<option value="24">24</option>
							</select>
							<span className="f-fm fm-w6-s18" style={{ paddingLeft: "10px" }}>
								hours
							</span>
						</div>
						<div style={{ paddingLeft: "0px" }}>
							<hr style={{ paddingLeft: "20px", size: "1px", color: "grey" }} />
						</div>
					</div>
					<div className="row" style={{ paddingLeft: "20px" }}>
						<div
							className="col-1"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								paddingLeft: "0px",
								width: "40px",
							}}
						>
							<span
								style={{
									height: "28px",
									width: "28px",
									background: "#F4F4F4",
									borderRadius: "14px",
									display: "inline-block",
									textAlign: "center",
								}}
							>
								3
							</span>
						</div>
						<div
							className="col-9 f-fm fm-w6-s18"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "0px",
							}}
						>
							<span className="f-fm fm-w6-s18">
								How many minutes do you spend on each consultation on average?
							</span>
						</div>
						<div
							className="col-2"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "30px",
							}}
						>
							<select onChange={(e) => setThirdAnswer(e.target.value)}>
								<option value=""></option>
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="25">25</option>
								<option value="30">30</option>
								<option value="35">35</option>
								<option value="40">40</option>
								<option value="45">45</option>
								<option value="50">50</option>
								<option value="55">55</option>
								<option value="60">60</option>
							</select>
							<span className="f-fm fm-w6-s18" style={{ paddingLeft: "10px" }}>
								minutes
							</span>
						</div>
						<div style={{ paddingLeft: "0px" }}>
							<hr style={{ paddingLeft: "20px", size: "1px", color: "grey" }} />
						</div>
					</div>
					<div className="row" style={{ paddingLeft: "20px" }}>
						<div
							className="col-1"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								paddingLeft: "0px",
								width: "40px",
							}}
						>
							<span
								style={{
									height: "28px",
									width: "28px",
									background: "#F4F4F4",
									borderRadius: "14px",
									display: "inline-block",
									textAlign: "center",
								}}
							>
								4
							</span>
						</div>
						<div
							className="col-9 f-fm fm-w6-s18"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "0px",
							}}
						>
							<span className="f-fm fm-w6-s18">
								On average, how many patients receiving hyaluronic acid
								injections do you see per day?
							</span>
						</div>
						<div
							className="col-2"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "30px",
							}}
						>
							<select onChange={(e) => setFourthAnswer(e.target.value)}>
								<option value=""></option>
								<option value="0">0</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="8">8</option>
								<option value="9">9</option>
								<option value="10">10</option>
							</select>
							<span className="f-fm fm-w6-s18" style={{ paddingLeft: "10px" }}>
								patients
							</span>
						</div>
						<div style={{ paddingLeft: "0px" }}>
							<hr style={{ paddingLeft: "20px", size: "1px", color: "grey" }} />
						</div>
					</div>
					<div className="row" style={{ paddingLeft: "20px" }}>
						<div
							className="col-1"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								paddingLeft: "0px",
								width: "40px",
							}}
						>
							<span
								style={{
									height: "28px",
									width: "28px",
									background: "#F4F4F4",
									borderRadius: "14px",
									display: "inline-block",
									textAlign: "center",
								}}
							>
								5
							</span>
						</div>
						<div
							className="col-9 f-fm fm-w6-s18"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "0px",
							}}
						>
							<span className="f-fm fm-w6-s18">
								How many hyaluronic acid syringes do you use on average per
								patient?
							</span>
						</div>
						<div
							className="col-2"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "30px",
							}}
						>
							<select onChange={(e) => setFifthAnswer(e.target.value)}>
								<option value=""></option>
								<option value="0">0</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="8">8</option>
								<option value="9">9</option>
								<option value="10">10</option>
								<option value="11">11</option>
								<option value="12">12</option>
								<option value="13">13</option>
								<option value="14">14</option>
								<option value="15">15</option>
								<option value="16">16</option>
								<option value="17">17</option>
								<option value="18">18</option>
								<option value="19">19</option>
								<option value="20">20</option>
							</select>
							<span className="f-fm fm-w6-s18" style={{ paddingLeft: "10px" }}>
								syringes
							</span>
						</div>
						<div style={{ paddingLeft: "0px" }}>
							<hr style={{ paddingLeft: "20px", size: "1px", color: "grey" }} />
						</div>
					</div>
					<div className="row" style={{ paddingLeft: "20px" }}>
						<div
							className="col-1"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								paddingLeft: "0px",
								width: "40px",
							}}
						>
							<span
								style={{
									height: "28px",
									width: "28px",
									background: "#F4F4F4",
									borderRadius: "14px",
									display: "inline-block",
									textAlign: "center",
								}}
							>
								6
							</span>
						</div>
						<div
							className="col-9 f-fm fm-w6-s18"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "0px",
							}}
						>
							<span className="f-fm fm-w6-s18">
								How many minutes on average do you spend preparing each syringe
								of hyaluronic acid (including syringe loading time and
								disinfection of the injection area)?
							</span>
						</div>
						<div
							className="col-2"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "30px",
							}}
						>
							<select onChange={(e) => setSixthAnswer(e.target.value)}>
								<option value=""></option>
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="25">25</option>
								<option value="30">30</option>
								<option value="35">35</option>
								<option value="40">40</option>
								<option value="45">45</option>
								<option value="50">50</option>
								<option value="55">55</option>
								<option value="60">60</option>
							</select>
							<span className="f-fm fm-w6-s18" style={{ paddingLeft: "10px" }}>
								minutes
							</span>
						</div>
						<div style={{ paddingLeft: "0px" }}>
							<hr style={{ paddingLeft: "20px", size: "1px", color: "grey" }} />
						</div>
					</div>
					<div className="row" style={{ paddingLeft: "20px" }}>
						<div
							className="col-1"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								paddingLeft: "0px",
								width: "40px",
							}}
						>
							<span
								style={{
									height: "28px",
									width: "28px",
									background: "#F4F4F4",
									borderRadius: "14px",
									display: "inline-block",
									textAlign: "center",
								}}
							>
								7
							</span>
						</div>
						<div
							className="col-9 f-fm fm-w6-s18"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "0px",
							}}
						>
							<span className="f-fm fm-w6-s18">
								How many minutes on average do you spend injecting each syringe
								of hyaluronic acid?
							</span>
						</div>
						<div
							className="col-2"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "30px",
							}}
						>
							<select onChange={(e) => setSeventhAnswer(e.target.value)}>
								<option value=""></option>
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="25">25</option>
								<option value="30">30</option>
								<option value="35">35</option>
								<option value="40">40</option>
								<option value="45">45</option>
								<option value="50">50</option>
								<option value="55">55</option>
								<option value="60">60</option>
							</select>
							<span className="f-fm fm-w6-s18" style={{ paddingLeft: "10px" }}>
								minutes
							</span>
						</div>
						<div style={{ paddingLeft: "0px" }}>
							<hr style={{ paddingLeft: "20px", size: "1px", color: "grey" }} />
						</div>
					</div>
					<div className="row" style={{ paddingLeft: "20px" }}>
						<div
							className="col-1"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								paddingLeft: "0px",
								width: "40px",
							}}
						>
							<span
								style={{
									height: "28px",
									width: "28px",
									background: "#F4F4F4",
									borderRadius: "14px",
									display: "inline-block",
									textAlign: "center",
								}}
							>
								8
							</span>
						</div>
						<div
							className="col-9 f-fm fm-w6-s18"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "0px",
							}}
						>
							<span className="f-fm fm-w6-s18">
								How many minutes on average do you spend after each injection to
								observe the result and make adjustments if necessary?
							</span>
						</div>
						<div
							className="col-2"
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								paddingLeft: "30px",
							}}
						>
							<select onChange={(e) => setEightAnswer(e.target.value)}>
								<option value=""></option>
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="25">25</option>
								<option value="30">30</option>
								<option value="35">35</option>
								<option value="40">40</option>
								<option value="45">45</option>
								<option value="50">50</option>
								<option value="55">55</option>
								<option value="60">60</option>
							</select>
							<span className="f-fm fm-w6-s18" style={{ paddingLeft: "10px" }}>
								minutes
							</span>
						</div>
						<div style={{ paddingLeft: "0px" }}>
							<hr style={{ paddingLeft: "20px", size: "1px", color: "grey" }} />
						</div>
					</div>
				</div>
				<div
					className="col-9 f-fm fm-w4-s16 pt-4"
					style={{
						cursor: "pointer",
						width: "100%",
						display: "flex",
						justifyContent: "end",
					}}
					onClick={() => {
						handleSubmit();
					}}
				>
					<button
						style={{
							width: "267px",
							height: "60px",
							borderRadius: "40px",
							backgroundColor:
								firstAnswer !== "" &&
								secondAnswer !== "" &&
								thirdAnswer !== "" &&
								fourthAnswer !== "" &&
								fifthAnswer !== "" &&
								sixthAnswer !== "" &&
								seventhAnswer !== "" &&
								eightAnswer !== ""
									? "#000"
									: "#ACACAC",
							color: "#fff",
							border: "none",
						}}
						disabled={
							firstAnswer !== "" &&
							secondAnswer !== "" &&
							thirdAnswer !== "" &&
							fourthAnswer !== "" &&
							fifthAnswer !== "" &&
							sixthAnswer !== "" &&
							seventhAnswer !== "" &&
							eightAnswer !== ""
								? false
								: true
						}
					>
						Submit
					</button>
				</div>
			</div>
		</>
	);
};
export default DocorQuestioniareModal;
