import { Baseurl } from "../common/util";
const axios = require("axios").default;

// var baseurl1;
// if (process.env.REACT_APP_HOST === "local") {
// 	baseurl1 = "http://localhost:3001/";
// } else if (process.env.REACT_APP_HOST === "123") {
// 	baseurl1 = "http://123.176.43.187:3004/";
// } else {
// 	baseurl1 = window.location.origin + "/api/";
// }

const baseurl = Baseurl() + "patientpayment";

const formatYmd = (date) => date.toISOString().slice(0, 10);

const CREATE_PATIENTPAYMENT =
	() => `mutation create($appointmentid: String!, $date:String!, $amount: String!, $preamount: String!, $postamount: String!, $refund: String!, $kind: String!, $tax: String!, $prepaymentintent: String!, $postpaymentintent: String!, $prepaymentstatus: String!, $postpaymentstatus: String!, $cardbrand: String!, $applicationfee: String!, $cardlast4: String!, $bookingfee: String!){
    patientpayment(appointmentid:$appointmentid, date:$date, amount:$amount, kind:$kind, tax:$tax,  paymentintent:$paymentintent, refund:$refund, paymentstatus:$paymentstatus, cardbrand:$cardbrand, cardlast4:$cardlast4, bookingfee:$bookingfee, applicationfee:$applicationfee){
        appointmentid,
        prepaymentintent,
        postpaymentintent,
		prepaymentstatus,
		postpaymentstatus,
		cardbrand,
	    cardlast4,
        amount,
        preamount,
        postamount,
        kind,
		bookingfee,
		cancelledstatus,
	cancelledsubtotal,
	cancelledconsultationfee,
	cancelledtax,
		applicationfee,
        tax,
        month,
		refund
    }
  }`;

const UPDATE_PATIENTPAYMENT = () => `mutation create($id:ID,$obj:String){
    update(id:$id, obj:$obj){
      appointmentid
    }
  }`;

export const returnUpdateVariables = (props) => {
	// console.log(props);
	var returnstring = `{
        "id": "#id#",
        "obj": "#obj#"
    }`;
	const id = props.id;
	delete props.id;
	const escapestr = JSON.stringify(props).replaceAll(/"/g, '\\"');
	returnstring = returnstring.replaceAll(/#id#/g, id);
	returnstring = returnstring.replaceAll(/#obj#/g, escapestr);

	return returnstring;
};

export const returnCreateVariables = (props) => {
	// console.log(props);

	var returnstring = `{
        "appointmentid": "#appointmentid#",
        "date": "#date#",
        "amount": "#amount#",
        "preamount": "#preamount#",
        "postamount": "#postamount#",
        "kind": "#kind#",
        "bookingfee": "#bookingfee#",
        "tax": "#tax#",
        "cancelledstatus": "#cancelledstatus#",
        "cancelledsubtotal": "#cancelledsubtotal#",
        "cancelledconsultationfee": "#cancelledconsultationfee#",
        "cancelledtax": "#cancelledtax#",
        "subtotal": "#subtotal#",
        "consultationfee": "#consultationfee#",
        "applicationfee": "#applicationfee#",
        "postpaymentintent": "#postpaymentintent#",
        "prepaymentintent": "#prepaymentintent#",
        "paymentstatus": "#paymentstatus#",
        "prepaymentstatus": "#prepaymentstatus#",
        "postpaymentstatus": "#postpaymentstatus#",
        "cardbrand": "#cardbrand#",
        "paymentmethodid": "#paymentmethodid#",
        "cardlast4": "#cardlast4#",
    	"refund": "#refund#"
      }`;

	Object.keys(props).forEach((key, index) => {
		returnstring = returnstring.replace(
			"#" + key.toLowerCase() + "#",
			props[key]
		);
	});

	returnstring = returnstring.replaceAll(/#[a-z0-9]{1,}#/g, "");

	return returnstring;
};

const PATIENTPAYMENT_BYID = () => `query {
  patientpaymentByID(id: "#id#"){
     id,
	prepaymentintent,
	postpaymentintent,
	prepaymentstatus,
	postpaymentstatus,
	bookingfee,
	cancelledstatus,
	cancelledsubtotal,
	cancelledconsultationfee,
	cancelledtax,
	appointmentid,
	subtotal,
	consultationfee,
	date,
	cardbrand,
	paymentmethodid,
	cardlast4,
	preamount,
	applicationfee,
	postamount,
	amount,
	kind,
	tax,
	refund
  	}
  }
`;

const PATIENTPAYMENT_BYAPPOINTMENTID = () => `query {
  patientpaymentByAppointmentID(appointmentid: "#id#"){
  id,
	prepaymentintent,
	postpaymentintent,
	prepaymentstatus,
	postpaymentstatus,
	cardbrand,
	paymentmethodid,
	applicationfee,
	cardlast4,
	appointmentid,
	bookingfee,
	cancelledstatus,
	cancelledsubtotal,
	cancelledconsultationfee,
	cancelledtax,
	date,
	preamount,
	postamount,
	amount,
	subtotal,
	consultationfee,
	kind,
	tax,
	refund
  	}
  }
`;

const PATIENTPAYMENT_BYAPPOINTMENTIDSUCCESS = () => `query {
  patientpaymentByAppointmentIDSuccess(appointmentid: "#id#"){
    id,
	prepaymentintent,
	postpaymentintent,
	prepaymentstatus,
	postpaymentstatus,
	cardbrand,
	paymentmethodid,
	cardlast4,
	appointmentid,
	date,
	bookingfee,
	cancelledstatus,
	cancelledsubtotal,
	cancelledconsultationfee,
	cancelledtax,
	preamount,
	applicationfee,
	postamount,
	amount,
	kind,
	subtotal,
	consultationfee,
	tax,
	refund
  	}
  }
`;

const GET_PATIENTPAYMENT = () => `query{
    patientpayment{
     id,
	prepaymentintent,
	postpaymentintent,
	prepaymentstatus,
	postpaymentstatus,
	cardbrand,
	paymentmethodid,
	bookingfee,
	cancelledstatus,
	cancelledsubtotal,
	cancelledconsultationfee,
	cancelledtax,
	cardlast4,
	appointmentid,
	applicationfee,
	date,
	preamount,
	postamount,
	amount,
	kind,
	subtotal,
	consultationfee,
	tax,
	refund
    }
}`;

const PATIENTPAYMENT_BYAPPOINTMENTIDALLSUCCESS = () => `query {
  patientpaymentByAppointmentIDAllSuccess(appointmentid: "#id#"){
    id,
	prepaymentintent,
	postpaymentintent,
	prepaymentstatus,
	postpaymentstatus,
	cardbrand,
	paymentmethodid,
	cardlast4,
	appointmentid,
	date,
	bookingfee,
	cancelledstatus,
	cancelledsubtotal,
	cancelledconsultationfee,
	cancelledtax,
	preamount,
	applicationfee,
	postamount,
	amount,
	kind,
	subtotal,
	consultationfee,
	tax,
	refund
  	}
  }
`;

export const GetPatientpayment = () =>
	axios({
		url: baseurl,
		method: "post",
		data: {
			query: GET_PATIENTPAYMENT(),
		},
	}).then((result) => {
		// console.log(result);
		return result.data.data;
	});

export const GetPatientpaymentById = (id) =>
	axios({
		url: baseurl,
		method: "post",
		data: {
			query: PATIENTPAYMENT_BYID().replace("#id#", id),
		},
	}).then((result) => {
		// console.log(result);
		return result.data.data;
	});

export const GetPatientpaymentByAppointmentIdSuccess = (id) =>
	axios({
		url: baseurl,
		method: "post",
		data: {
			query: PATIENTPAYMENT_BYAPPOINTMENTIDSUCCESS().replace("#id#", id),
		},
	}).then((result) => {
		console.log("result", result);
		return result.data.data;
	});

export const GetPatientpaymentByAppointmentIdAllSuccess = (id) =>
	axios({
		url: baseurl,
		method: "post",
		data: {
			query: PATIENTPAYMENT_BYAPPOINTMENTIDALLSUCCESS().replace("#id#", id),
		},
	}).then((result) => {
		// console.log(result);
		return result.data.data;
	});

export const CreatePatientpayment = (props) =>
	axios({
		url: baseurl,
		method: "post",
		data: {
			query: CREATE_PATIENTPAYMENT(),
			variables: props,
		},
	})
		.then((res) => {
			// console.log(res);
			return res.data.data;
		})
		.catch((err) => console.log(err));

export const UpdatePatientpayment = (props) =>
	axios({
		url: baseurl,
		method: "post",
		data: {
			query: UPDATE_PATIENTPAYMENT(),
			variables: props,
		},
	})
		.then((res) => {
			// console.log(res);
			return res.data.data;
		})
		.catch((err) => console.log(err));

export async function DeletePatientpayment(props) {
	// console.log("ee", props);
	var propss = `{
    "id": "#id#"
  }`;
	propss = propss.replace("#id#", props);
	// console.log("eew", propss);

	try {
		const response = await axios({
			url: baseurl,
			method: "post",
			data: {
				query: `{
          deletePatientpayment (id:"#id#"){
          id
             }
           }`.replace("#id#", props),
			},
		});
		// console.log("user", response.data);
		return response.data;
	} catch (error) {
		return [""];
	}
}
