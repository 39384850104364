import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../common/logo";
import * as utils from "../common/util";
import Groupimage from "./groupimage";
import "./website1920.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Everyone1920 = () => {
	const responsive = {
		// desktop1: {
		// 	breakpoint: { max: 1980, min: 1850 },
		// 	items: 6,
		// 	// slidesToSlide: 1, // optional, default to 1.
		// 	partialVisibilityGutter: 40,
		// },
		// desktop: {
		// 	breakpoint: { max: 1850, min: 1580 },
		// 	items: 5,
		// 	// slidesToSlide: 1, // optional, default to 1.
		// 	partialVisibilityGutter: 40,
		// },
		// tablet: {
		// 	breakpoint: { max: 1579, min: 1300 },
		// 	items: 4,
		// 	// slidesToSlide: 1, // optional, default to 001.
		// 	partialVisibilityGutter: 30,
		// },
		// tablet1: {
		// 	breakpoint: { max: 1300, min: 860 },
		// 	items: 3,
		// 	// slidesToSlide: 1, // optional, default to 1.
		// 	partialVisibilityGutter: 30,
		// },
		// mobile: {
		// 	breakpoint: { max: 860, min: 0 },
		// 	items: 2,
		// 	// slidesToSlide: 1, // optional, default to 1.
		// 	partialVisibilityGutter: 30,
		// },
		desktop: {
			breakpoint: {
				max: 3000,
				min: 1400,
			},
			items: 5,
			partialVisibilityGutter: 40,
		},
		desktop1: {
			breakpoint: {
				max: 1400,
				min: 1024,
			},
			items: 4,
			partialVisibilityGutter: 40,
		},
		mobile: {
			breakpoint: {
				max: 767,
				min: 0,
			},
			items: 2,
			partialVisibilityGutter: 30,
		},
		tablet: {
			breakpoint: {
				max: 1024,
				min: 767,
			},
			items: 3,
			partialVisibilityGutter: 30,
		},
	};
	const [windowSize, setWindowSize] = useState(getWindowSize());
	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);
	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}
	const firstupdate = useRef(true);
	const firststateupdate = useRef(true);
	const [isPageLoaded, setisPageLoaded] = useState(false);
	const navigate = useNavigate();

	const [show, setShow] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);
	const [hoverbar, setHoverBar] = useState(false);
	var timer = null;
	const controlNavbar1 = (event) => {
		console.log(event.target.className);
		if (
			(event.target.className.indexOf("jnav") > 0 && hoverbar) ||
			window.scrollY === 0
		) {
			setShow(true);
			setHoverBar(true);
			clearTimeout(timer);
			timer = null;
		}
	};
	const controlNavbar = () => {
		if (typeof window !== "undefined") {
			//console.log(window.scrollY, lastScrollY, hoverbar)
			if (window.scrollY === 0) {
				console.log("ell", hoverbar);
				clearTimeout(timer);
				timer = null;
				setHoverBar(false);
				setShow(true);
			} else if (window.scrollY > lastScrollY) {
				// if scroll down hide the navbar
				setShow(false);
			} else {
				// if scroll up show the navbar
				//console.log("else", window.scrollY, lastScrollY, hoverbar)
				setShow(true);
				if (hoverbar) {
					const timer = setTimeout(() => {
						setShow(true);
					}, 4000);
				} else {
					if (window.scrollY !== 0) {
						timer = setTimeout(() => {
							if (window.scrollY != 0) {
								setShow(false);
							}
						}, 4000);
					}
				}
			}

			// remember current page location to use in the next move
			setLastScrollY(window.scrollY);
		}
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", controlNavbar);
			//window.addEventListener('mouseover', controlNavbar1);
			window.document
				.getElementById("mynav")
				.addEventListener("mousemove", controlNavbar1);

			// cleanup function
			return () => {
				window.removeEventListener("scroll", controlNavbar);
				console.log("mmm", typeof window.document.getElementById("mynav"));
				if (window.document.getElementById("mynav")) {
					window.document
						.getElementById("mynav")
						.removeEventListener("mousemove", controlNavbar1);
				}
			};
		}
	}, [lastScrollY]);

	useEffect(() => {
		if (firstupdate.current) {
			(async function anyNameFunction() {
				const timer = setTimeout(() => {
					setisPageLoaded(true);
					firstupdate.current = false;
					firststateupdate.current = false;
				}, 2000);
				return () => clearTimeout(timer);
			})();
		}
	}, []);

	const handleMouseEnter = () => {
		setHoverBar(true);
		setShow(true);
	};
	const handleMouseMove = () => {
		setHoverBar(true);
		setShow(true);
	};
	const handleMouseLeave = () => {};
	useEffect(() => {
		if (firstupdate.current) {
			(async function anyNameFunction() {
				const timer = setTimeout(() => {
					setisPageLoaded(true);
					firstupdate.current = false;
					firststateupdate.current = false;
				}, 2000);
				return () => clearTimeout(timer);
			})();
		}
	}, []);

	return (
		<center>
			<div className="body-bg-home">
				{/* <nav className="navbar  container-fluid   fixed-top  navbar-expand-lg navbar-dark bg-light">
					<div className="container-fluid">
						<Logo logo="dark-light"></Logo>
						<button
							type="button"
							className="navbar-toggler mr-sm  collapsed"
							data-bs-toggle="collapse"
							data-bs-target="#navbarCollapse"
							aria-expanded="false"
						>
							<span className="navbar-toggler-icon"></span>
						</button>
						<div
							className="navbar-collapse collapse"
							style={{ whiteSpace: "nowrap", width: "100%", height: "100px" }}
							id="navbarCollapse"
						>
							<div className="navbar-nav p-4 p-lg-0 pr-r20">
								<a
									className="nav-link1 f-rl fm-w7-s20"
									href="/everyone"
									style={{
										color: "#AF805E",
									}}
								>
									For Everyone
									<div
										style={{
											backgroundColor: "#B58A6C",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
											width: "123px",
										}}
									></div>
								</a>
								<a
									className="nav-link1 f-rl fm-w5-s20"
									href="/business"
									style={{
										color: "#FFFFFF",
									}}
								>
									For business
									<div
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
										}}
									></div>
								</a>
								<a
									className="nav-link1 f-rl fm-w5-s20"
									href="/login"
									style={{
										color: "#FFFFFF",
									}}
								>
									Login
									<div
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
										}}
									></div>
								</a>
							</div>
							<div className="d-flex burger">
								<button
									className="btn-rounded-black"
									onClick={() => navigate("/signup/social")}
								>
									Business Sign Up
								</button>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<a href="../downloads/app-debug.apk" download>
									<button className="btn-rounded-white">
										{" "}
										Download the App
									</button>
								</a>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						</div>
					</div>
				</nav> */}
				<nav
					id="mynav"
					onMouseMove={handleMouseMove}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					className={`navbar  dvall1 container-fluid   fixed-top  navbar-expand-lg navbar-dark bg-light bg-light1  ${
						!show && "hidden"
					}`}
				>
					<div className="container-fluid jnav navh">
						{/* <div className="popupnav">  */}
						<Logo logo="dark-light" className="jnav"></Logo>
						{/* </div> */}
						{/* <div  className="groupimage380"> <Logo logo="dark1"></Logo></div> */}
						{/* <div className="popup"> */}
						<button
							type="button"
							className="navbar-toggler mr-sm  collapsed "
							data-bs-toggle="collapse"
							data-bs-target="#navbarCollapse"
							aria-expanded="false"
						>
							<img
								className=""
								loading="lazy"
								alt=""
								src="../images/Frame 17.png"
								style={{ width: "30px", height: "30px" }}
							></img>
						</button>
						{/* </div> */}
						{/* <div className="groupimage380">
              <Popupforbusiness></Popupforbusiness>
            </div> */}
						<div
							className="navbar-collapse collapse jnav"
							style={{ whiteSpace: "nowrap", width: "100%", height: "100px" }}
							id="navbarCollapse"
						>
							<div className="navbar-nav p-4 p-lg-0 pr-r20 jnav" id="navbarnav">
								<a
									className="nav-link1 f-rl fm-w7-s20 jnav"
									href="/everyone"
									style={{
										color: "#AF805E",
									}}
								>
									For Everyone
									<div
										className="jnav"
										style={{
											backgroundColor: "#B58A6C",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
											width: "123px",
										}}
									></div>
								</a>
								<a
									className="nav-link1 f-rl fm-w5-s20 jnav"
									href="/business"
									style={{
										color: "#FFFFFF",
									}}
								>
									For business
									<div
										className="jnav"
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
										}}
									></div>
								</a>
								<a
									className="nav-link1 f-rl fm-w5-s20 jnav"
									href="/login"
									style={{
										color: "#FFFFFF",
									}}
								>
									Login
									<div
										className="jnav"
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
										}}
									></div>
								</a>
							</div>
							<div className="d-flex burger jnav">
								<button
									className="btn-rounded-black jnav"
									style={{
										border: "1px solid #fff",
									}}
									onClick={() => navigate("/signup/social")}
								>
									Business Sign Up
								</button>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<a href="../downloads/app-debug.apk" download className="jnav">
									<button
										className="btn-rounded-white jnav"
										style={{
											border: "1px solid #000",
										}}
									>
										{" "}
										Download the App
									</button>
								</a>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						</div>
					</div>
				</nav>

				<div className="container-fluid">
					<div className="row no-gutters" style={{ paddingTop: "100px" }}>
						<div
							className="col everyoneimage"
							style={{
								backgroundColor: "#1F1F1F",
								backgroundImage: "url('../images/Rectangle148.png')",
								// backgroundRepeat: "no-repeat",
								height: "500px",
								paddingTop: "123px",
							}}
						>
							<div className="row pbpte">
								<div className="col justify-content-center">
									<div className="row">
										<div
											className="f-rl fm-w7-s24 coral  textalign pl1 "
											style={{ lineHeight: "28px", paddingBottom: "40px" }}
										>
											For Everyone
										</div>
									</div>
									<div className="row">
										<div
											className="f-rl fm-w3-s68 color-FF textalign pl1 fse1600"
											style={{ lineHeight: "80px", paddingBottom: "40px" }}
										>
											BOOK ON-DEMAND TREATMENTS
										</div>
									</div>
									<div className="row">
										<div
											className="f-rl fm-w4-s28 color-FF textalign pl1  fse1"
											style={{ textAlign: "center", lineHeight: "33px" }}
										>
											at home with your favourite doctor
										</div>
									</div>
								</div>
							</div>
							{/* <div
                className="row"
                style={{
                  marginTop: "240px",
                  marginBottom: "200px",
                  paddingLeft: "80px",
                  backgroundColor: "#000000",
                }}
              >
                <div className="col-8"></div>
              </div> */}
						</div>
					</div>
					<div className="row no-gutters">
						<div
							className="col"
							style={{
								backgroundColor: "#000000",
							}}
						>
							<div
								className="row"
								style={{
									backgroundColor: "rgb(0, 0 , 0, 0.6)",
									backgroundImage: "url(../images/Rectangle180.png)",
									backgroundRepeat: "no-repeat",
								}}
							>
								<div className="col">
									<div className="row" style={{ paddingTop: "172px" }}>
										<div
											className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6"
											//   style={{
											//     backgroundColor: "rgb(0, 0 , 0, 0.6)",
											//     backgroundImage: "url(../images/Rectangle180.png)",
											//     backgroundRepeat: "no-repeat",
											//   }}
										>
											<div className="row">
												<div className="col-5"></div>
												<div className="col-7" style={{ paddingTop: "72px" }}>
													{" "}
													<div
														className="f-rl color-AC pl pt"
														style={{
															textAlign: "left",
															fontStyle: "normal",
															fontWeight: "600",
															fontSize: "17px",
															lineHeight: "23px",
														}}
													>
														DON'T WORRY IF THIS IS YOUR FIRST<br></br>COSMETIC
														TREATMENT -{" "}
													</div>
													<div
														className="f-rl color-FF pt-2 "
														style={{
															textAlign: "left",
															fontStyle: "normal",
															fontWeight: "300",
															fontSize: "42.25px",
															lineHeight: "61px",
														}}
													>
														BEFORE YOU BOOK,<br></br>TAKE A{" "}
														<span
															style={{
																fontStyle: "normal",
																fontWeight: "700",
																fontSize: "42.25px",
															}}
														>
															LIVE CONSULTANT
														</span>
														<br></br>WITH OUR MEDICALLY
														<br /> APPROVED STAFFS.
													</div>
												</div>
											</div>
										</div>
										<div
											className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6 "
											style={{ textAlign: "left", paddingLeft: "45px" }}
										>
											<img
												className="pl"
												loading="lazy"
												alt=""
												src="../images/Group560.png"
												style={{ width: "382px", height: "755px" }}
											></img>
										</div>
									</div>
									<div className="row columnreverse">
										<div
											className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6"
											style={{
												// backgroundColor: "rgb(0, 0 , 0, 0.6)",
												// backgroundImage: "url(../images/Rectangle180.png)",
												backgroundRepeat: "no-repeat",
												paddingTop: "118px",
											}}
										>
											<img
												className="fr"
												loading="lazy"
												alt=""
												src="../images/Group505.png"
												style={{ width: "690px", height: "763.78px" }}
											></img>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6">
											<div
												className="f-rl color-AC pl pt"
												style={{
													textAlign: "left",
													fontStyle: "normal",
													fontWeight: "600",
													fontSize: "15.8438px",
													paddingTop: "261px",
													lineHeight: "23px",
												}}
											>
												AESTHETIK APP ALLOWS YOU TO -{" "}
											</div>
											<div
												className="f-rl color-FF pt-2 pl"
												style={{
													textAlign: "left",
													fontStyle: "normal",
													fontWeight: "300",
													fontSize: "42.25px",
													lineHeight: "61px",
												}}
											>
												BOOK A TREATMENT<br></br>INSTANTLY WITH OUR ON -{" "}
												<br></br>DEMAND{" "}
												<span
													style={{
														fontStyle: "normal",
														fontWeight: "700",
														fontSize: "42.25px",
													}}
												>
													INSTANT BOOKING
												</span>
												<br></br>FEATURE.
											</div>
										</div>
									</div>
									<div className="row" style={{ paddingBottom: "308px" }}>
										<div
											className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6"
											style={{
												// backgroundColor: "rgb(0, 0 , 0, 0.6)",
												// backgroundImage: "url(../images/Rectangle180.png)",
												backgroundRepeat: "no-repeat",
											}}
										>
											<div className="row">
												<div className="col-4"></div>
												<div className="col-8">
													{" "}
													<div
														className="f-rl color-AC pl pt"
														style={{
															textAlign: "left",
															fontStyle: "normal",
															fontWeight: "600",
															fontSize: "15.8438px",
															lineHeight: "23px",
															paddingTop: "297px",
														}}
													>
														WE GUIDE YOU ON HOW TO -{" "}
													</div>
													<div
														className="f-rl color-FF pt-2 everyonetext1 pl"
														style={{
															textAlign: "left",
															fontStyle: "normal",
															fontWeight: "300",
															fontSize: "42.25px",
															lineHeight: "61px",
														}}
													>
														SET UP YOUR HOME BEFORE<br></br>THE DOCTORS ARRIVE
														<br></br>
														<br></br>
														<div
															className="everyonetext2"
															style={{
																fontStyle: "mulish",
																fontWeight: "300",
																fontSize: "31.6875px",
																lineHeight: "37px",
															}}
														>
															All our doctors are medically approved <br />
															and a secure check-in is done and all is tracked.
														</div>
													</div>
												</div>
											</div>
										</div>
										<div
											className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6"
											style={{
												paddingTop: "129px",
												textAlign: "left",
												paddingLeft: "96px",
											}}
										>
											<img
												loading="lazy"
												alt=""
												src="../images/Group55.png"
												style={{ width: "569.44px", height: "653.54px" }}
											></img>
										</div>
									</div>
									{/* <div className="row mt-5"></div> */}
									{/* <div
										className="row"
										style={{ backgroundColor: "#1F1F1F", paddingTop: "57px" }}
									>
										<div
											//   className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6"
											style={{ textAlign: "center" }}
										>
											<div
												className="f-rl fm-w3-s60 color-FF everyonetext1 textalign pl"
												style={{ textAlign: "center", lineHeight: "61px" }}
											>
												OUR TREATMENTS
											</div>
										</div>


									</div> */}
									<div className="row ">
										<div
											className="col pdf"
											style={{ paddingLeft: "140px", paddingBottom: "100px" }}
										>
											<div className="pt-9 ourtreatmenthome ourimgs ">
												<div
													className="color-FF f-rl fm-w3-s60 fs fstablet"
													style={{ paddingLeft: "0px" }}
												>
													OUR TREATMENTS
												</div>
											</div>
											{/* <Carousel
												swipeable={false}
												draggable={false}
												showDots={false}
												responsive={responsive}
												ssr={true} // means to render carousel on server-side.
												infinite={true}
												autoPlay={true}
												autoPlaySpeed={2000}
												keyBoardControl={true}
												customTransition="all .8"
												transitionDuration={500}
												// containerClass="carousel-container"
												removeArrowOnDeviceType={["tablet", "mobile"]}
												// deviceType={this.props.deviceType}
												// dotListClass="custom-dot-list-style"
												itemClass="carousel-item-padding-40-px"
												style={{
													marginBottom: "100px",
													display: "flex",
													justifyContent: "space-between",
													flexDirection: "row",
												}}
												className="scroll-container"
											>
												<div>
													<img
														src="../images/Group577.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group591.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group578.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group579.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group580.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group581.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group582.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group583.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group590.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group584.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group585.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group586.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group587.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group588.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group589.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/imagelast1.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
											</Carousel> */}
											{/* <Carousel
												additionalTransfrom={0}
												arrows
												autoPlay
												autoPlaySpeed={1}
												centerMode={false}
												// className=""
												containerClass="container-with-dots"
												customTransition="all 10s linear"
												dotListClass=""
												draggable
												focusOnSelect={false}
												infinite
												itemClass=""
												keyBoardControl
												minimumTouchDrag={80}
												pauseOnHover
												renderArrowsWhenDisabled={false}
												renderButtonGroupOutside={false}
												renderDotsOutside={false}
												// removeArrowOnDeviceType={[
												// 	"desktop",
												// 	"tablet",
												// 	"mobile",
												// ]}
												// responsive={{
												// 	desktop: {
												// 		breakpoint: {
												// 			max: 3000,
												// 			min: 1024,
												// 		},
												// 		items: 3,
												// 		partialVisibilityGutter: 40,
												// 	},
												// 	mobile: {
												// 		breakpoint: {
												// 			max: 464,
												// 			min: 0,
												// 		},
												// 		items: 1,
												// 		partialVisibilityGutter: 30,
												// 	},
												// 	tablet: {
												// 		breakpoint: {
												// 			max: 1024,
												// 			min: 464,
												// 		},
												// 		items: 2,
												// 		partialVisibilityGutter: 30,
												// 	},
												// }}
												responsive={responsive}
												rewind={false}
												rewindWithAnimation={false}
												rtl={false}
												// shouldResetAutoplay
												showDots={false}
												sliderClass=""
												slidesToSlide={10}
												swipeable
												transitionDuration={10000}
												className="scroll-container"
												style={{
													marginBottom: "100px",
													display: "flex",
													justifyContent: "space-between",
													flexDirection: "row",
												}}
											>
												<div>
													<img
														src="../images/Group577.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group591.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group578.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group579.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group580.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group581.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group582.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group583.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group590.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group584.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group585.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group586.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group587.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group588.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/Group589.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
												<div>
													<img
														src="../images/imagelast1.png"
														width="380px"
														height="380px"
														alt="Facial Treatment"
													/>
												</div>
											</Carousel>
											; */}
											<Carousel
												additionalTransfrom={0}
												arrows
												autoPlay
												autoPlaySpeed={1}
												infinite
												centerMode={false}
												// className=""
												containerClass="container-with-dots"
												customTransition="all 3s linear"
												// swipeable={false}
												draggable={false}
												showDots={false}
												responsive={responsive}
												// ssr={true} // means to render carousel on server-side.
												// infinite={true}
												// autoPlay={true}
												// autoPlaySpeed={1000}
												keyBoardControl={true}
												// customTransition="all .8"
												// transitionDuration={500}
												// containerClass="carousel-container"
												removeArrowOnDeviceType={[
													"desktop",
													"desktop1",
													"tablet",
													"mobile",
												]}
												// deviceType={this.props.deviceType}
												// dotListClass="custom-dot-list-style"
												itemClass="carousel-item-padding-10-px"
												rewind={false}
												rewindWithAnimation={false}
												rtl={false}
												// shouldResetAutoplay
												// showDots={false}
												sliderClass=""
												slidesToSlide={1}
												swipeable
												transitionDuration={3000}
												style={{
													marginBottom: "300px",
													display: "flex",
													justifyContent: "space-between",
													flexDirection: "row",
												}}
												className="scroll-container"
											>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle1.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">BOTOX</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle2.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">HYALURONIC ACID</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle3.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">LIPS HA</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle4.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">JAWLINE HA</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle5.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">PRP</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle6.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">NOSE JOB HA</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle7.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">EYES</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle8.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">SKIN CARE</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle9.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">CHIN</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle10.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">PEELING</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle11.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">CHEEK BONE HA</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle12.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">BODY(ANY)</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle13.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">BUTTOCK HA</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle14.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">ARM BTX</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle15.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">EAR LOBES HA</div>
													</div>
												</div>
												<div
													style={{
														backgroundImage: "url('../images/Rectangle16.png')",
													}}
												>
													<div
														style={{
															position: "absolute",
															top: "75%",
															left: "10%",
														}}
													>
														<div className="tlist">DARK CIRCLES</div>
													</div>
												</div>
											</Carousel>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className=" row no-gutters">
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
							<div className="row ">
								<div
									className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 col-12"
									style={{
										backgroundColor: "#b58a6c",
										display: "flex",
										flexDirection: "Column",
										justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									<div
										className="f-rl fm-w6-s42p5 color-FF finalimgs wsimgs"
										style={{
											// marginTop: "150px",
											marginBottom: "34px",
											lineHeight: "60.73px",
											fontWeight: "500px",
										}}
									>
										START A TREATMENT AT YOUR
										<br />
										PLACE RIGHT NOW!
									</div>
									<div
										className="roundWhiteDownload1920 roundWhiteDownload mbutton1920"
										style={{
											marginTop: "20px",
										}}
									>
										Download the App&nbsp;&nbsp;&nbsp;&nbsp;
										<img
											className=""
											loading="lazy"
											alt=""
											src="../images/Arrowb4.png"
											style={{ width: "38.11px", height: "12px" }}
										></img>
									</div>

									<div className="col-xl-9 col-lg-5 col-md-9 col-xs-9 col-sm-9">
										<img
											className="whmobile"
											loading="lazy"
											alt=""
											src="../images/iPhone13.png"
											style={{
												width: "100%",
											}}
										></img>
									</div>
								</div>

								<div
									className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 col-12"
									style={{ padding: "0px", backgroundColor: "#b58a6c" }}
								>
									<div className="">
										<img
											className="whimg"
											src="../images/Group 602.png"
											style={{
												float: "right",
												width: "897px",
												height: "1408px",
											}}
											alt=""
										/>
									</div>
								</div>
								{/* <div
                  className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 col-12"
                  style={{
                    backgroundColor: "#b58a6c",
                    display: "flex",
                    flexDirection: "Column",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="f-rl fm-w6-s42p5 color-FF everyonetext"
                    style={{
                     lineHeight:"61px",
                      marginBottom: "58px",
                    }}
                  >
                    START A TREATMENT AT
                    <br />
                    YOUR PLACE RIGHT NOW!
                  </div>
                  <div className="row pt-4" style={{ marginBottom: "34px" }}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12  d-flex justify-content-center">
                      <div className="roundWhiteDownload everyonebtn">
                        Download the Aesthetik App
                      </div>
                      <br></br>
                    </div>
                  </div>

                  <div className="col-xl-9 col-lg-5 col-md-9 col-xs-9 col-sm-9" style={{paddingTop:"95px"}}>
                    <img
                      loading="lazy"
                      alt=""
                      src="../images/screenshot1.png"
                      style={{
                        width: "100%",
                      }}
                    ></img>
                  </div>
                </div> */}

								{/* <div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 col-12">
                  <div className="row  bottomimg">
                    <div className="col-8" style={{ padding: 0 }}>
                      <img
                        src="../images/Rectangle162.png"
                        style={{ width: "100%" }}
                        alt=""
                      />
                    </div>
                    <div className="col-4" style={{ padding: 0 }}>
                      <img
                        src="../images/Rectangle183.png"
                        style={{ height: "100%", width: "100%" }}
                        alt=""
                      />
                    </div>
                    <div className="col-12" style={{ padding: 0 }}>
                      <img
                        src="../images/Rectangle195.png"
                        style={{ width: "100%" }}
                        alt=""
                      />
                    </div>
                  </div>
                </div> */}
								{/* <div className="" style={{ backgroundColor: "#101011" }}>
                  {utils.Footer1920()}
                </div> */}
								{/* <div
                  className="groupimage380"
                  style={{ backgroundColor: "#101011" }}
                >
                  {utils.Footer1()}
                </div> */}
								<div
									className="view1920"
									style={{ backgroundColor: "#000000" }}
								>
									{" "}
									{utils.Footer1920()}
								</div>
								<div
									className="view19201"
									style={{ backgroundColor: "#000000" }}
								>
									{" "}
									{utils.Footer()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</center>
	);
};

export default Everyone1920;
