import React from "react";
import * as utils from "../common/util";
import Groupimage from "./groupimage";
import { useNavigate, Link } from "react-router-dom";
import "./website1920.css";
import Logo from "../common/logo";
import Popupforeveryone from "./popupforeveryone";

const Everyonemobile = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="container-fluid" style={{ backgroundColor: "#000000" }}>
				<nav
					className="navbar container-fluid fixed-topmobile  navbar-expand-lg navbar-dark bg-lightmobile"
					style={{ height: "70px" }}
				>
					<div className="container-fluid navh" style={{}}>
						<Logo logo="dark-light1"></Logo>
						<div className="groupimage380">
							<Popupforeveryone />
						</div>
						<div
							className="navbar-collapse collapse"
							style={{ whiteSpace: "nowrap", width: "100%" }}
							id="navbarCollapse"
						>
							<div className="navbar-nav p-4 pr-r20 p-lg-0">
								<a
									className="nav-link1 f-rl fm-w5-s20"
									href="/everyone"
									style={{ color: "#FFFFFF" }}
								>
									For Everyone
									<div
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
											width: "123px",
										}}
									></div>
								</a>
								<a
									className="nav-link1  f-rl fm-w5-s20"
									href="/business"
									style={{ color: "#FFFFFF" }}
								>
									For business
									<div
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
										}}
									></div>
								</a>
								<a
									className="nav-link1  f-rl fm-w5-s20"
									href="/login"
									style={{ color: "#FFFFFF" }}
								>
									Login
									<div
										style={{
											backgroundColor: "transparent",
											borderRadius: "5px",
											height: "3px",
											marginTop: "3px",
										}}
									></div>
								</a>
							</div>
							<div className="d-flex burger">
								<button
									className="btn-rounded-black"
									style={{
										border: "1px solid #fff",
									}}
									onClick={() => navigate("/signup/social")}
								>
									Business Sign Up
								</button>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<a href="../downloads/app-debug.apk" download>
									<button
										className="btn-rounded-white"
										style={{
											border: "1px solid #000",
										}}
									>
										{" "}
										Download the App
									</button>
								</a>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						</div>
					</div>
				</nav>
				<div style={{ paddingTop: "70px" }}>
					<div
						className="row "
						style={{
							backgroundImage: "url('../images/Rectangle1481.png')",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							height: "430px",
							paddingTop: "61px",
						}}
					>
						<div
							className="col justify-content-start"
							style={{ textAlign: "left", paddingLeft: "22px" }}
						>
							<div className="f-rl fm-w7-s24  coral">For Everyone</div>

							<div className="f-rl fm-w6-s8811 color-FF">
								BOOK <br></br>ON-DEMAND <br></br>TREATMENTS
							</div>

							<div className="f-rl fm-w4-s28 color-FF" style={{}}>
								at home with your favourite doctor
							</div>
						</div>
					</div>
				</div>
				<div
					className="row"
					style={{ paddingLeft: "20px", paddingRight: "20px" }}
				>
					<div className="col " style={{ padding: "0px" }}>
						<div
							className="f-rl color-AC fm-fs-fw"
							style={{ paddingTop: "95px" }}
						>
							DON'T WORRY IF THIS IS YOUR FIRST COSMETIC TREATMENT -{" "}
						</div>
						<div className="f-rl color-FF fm-fs-fw1" style={{}}>
							BEFORE YOU BOOK,<br></br>TAKE A{" "}
							<span className="fm-fs-fw2" style={{}}>
								LIVE CONSULTANT{" "}
							</span>
							&nbsp;WITH OUR MEDICALLY APPROVED STAFFS.
						</div>
					</div>
				</div>
				<div className="row no-gutters" style={{ backgroundColor: "#000000" }}>
					<img
						className=""
						loading="lazy"
						alt=""
						src="../images/Group 560.png"
						style={{
							paddingBottom: "130px",
							paddingLeft: "20px",
							width: "198.6px",
							height: "400px",
						}}
					></img>
				</div>

				<div
					className="row"
					style={{ paddingLeft: "20px", paddingRight: "20px" }}
				>
					<div className="col " style={{ padding: "0px" }}>
						<div className="f-rl color-AC fm-fs-fw">
							AESTHETIK APP ALLOWS YOU TO -{" "}
						</div>
						<div className="f-rl color-FF fm-fs-fw1" style={{}}>
							BOOK A TREATMENT INSTANTLY WITH OUR ON-DEMAND &nbsp;
							<span className="fm-fs-fw2" style={{}}>
								INSTANT BOOKING &nbsp;
							</span>
							FEATURE.
						</div>
					</div>
				</div>
				<div
					className="row no-gutters"
					style={{ backgroundColor: "#000000", paddingLeft: "" }}
				>
					<img
						className=""
						loading="lazy"
						alt=""
						src="../images/Group 505.png"
						style={{
							paddingBottom: "100px",
							paddingLeft: "20px",
							width: "198.6px",
							height: "355.398px",
						}}
					></img>
				</div>
				<div
					className="row"
					style={{ paddingLeft: "20px", paddingRight: "20px" }}
				>
					<div className="col " style={{ padding: "0px" }}>
						<div className="f-rl color-AC fm-fs-fw">
							WE GUIDE YOU ON HOW TO -{" "}
						</div>
						<div className="f-rl color-FF fm-fs-fw1" style={{}}>
							SET UP YOUR HOME BEFORE THE DOCTORS ARRIVE
						</div>
						<div
							className="color-FF"
							style={{
								fontStyle: "mulish",
								fontWeight: "400",
								fontSize: "16px",
								lineHeight: "20.08px",
								textAlign: "left",

								paddingBottom: "30px",
							}}
						>
							ALL OUR DOCTORS ARE MEDICALLY APPROVED AND A SECURE CHECK-IN IS
							DONE AND ALL IS TRACKED.
						</div>
					</div>
				</div>
				<div
					className="row no-gutters"
					style={{ backgroundColor: "#000000", paddingLeft: "" }}
				>
					<img
						className=""
						loading="lazy"
						alt=""
						src="../images/Group 551.png"
						style={{
							paddingBottom: "100px",
							paddingLeft: "20px",
							width: "198.706px",
							height: "392.126px",
						}}
					></img>
				</div>
				{/* <div className="row" style={{height:"144.36px",backgroundColor:"#000000",color:"#e5e5e5"}}>
            <div className="col"></div>
         </div> */}
				<div className="row " style={{ backgroundColor: "#000000" }}>
					<center style={{ padding: "0px" }}>
						<div className="col-xl-6 col-lg-6 col-md-6 col-xs-6 col-sm-6">
							<div
								className="f-rl fm-w3-s60 color-FF everyonetext1 textalign "
								style={{
									paddingLeft: "20px",
									paddingTop: "72.6",
									paddingBottom: "14.29px",
									lineHeight: "47px",
									paddingTop: "72.6px",
								}}
							>
								OUR TREATMENTS
							</div>
						</div>
					</center>
				</div>

				{/* <!-- Photo Grid --> */}
				<div className="groupimage380" style={{ paddingBottom: "101.02px" }}>
					<Groupimage />
					<div className="row">
						<div className="col">
							<img
								// className="s280"
								loading="lazy"
								alt=""
								src="../images/Group 563.png"
								style={{
									width: "inherit",
									padding: "0 5px 0 5px",
								}}
							></img>
						</div>
					</div>
				</div>
				<center>
					<div className=" row no-gutters">
						<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
							<div className="row " style={{ backgroundColor: "#000000" }}>
								<div
									className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12 col-12"
									style={{
										backgroundColor: "#b58a6c",
										display: "flex",
										flexDirection: "Column",
										justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									<div
										className="f-rl fm-w6-s42p5 color-FF everyonetext4"
										style={
											{
												// marginTop: "150px",
												// marginBottom: "34px",
											}
										}
									>
										START A TREATMENT AT
										<br />
										YOUR PLACE RIGHT NOW!
									</div>
									<div className="row" style={{ marginBottom: "56.99px" }}>
										<div className="col  d-flex justify-content-center">
											<div className="roundWhiteDownloadmobile everyonebtn">
												Download the Aesthetik App
											</div>
										</div>
									</div>

									<div className="col">
										<img
											loading="lazy"
											alt=""
											src="../images/iPhone131.png"
											style={{
												width: "275px",
												height: "442px",
											}}
										></img>
									</div>
								</div>

								<div
									className="row no-gutters"
									style={{ backgroundColor: "#000000", paddingRight: "0px" }}
								>
									<div
										className="col-12"
										style={{ padding: "0px", paddingRight: "0px" }}
									>
										<img
											className="s3202"
											src="../images/Frame 18.png"
											style={{}}
											alt=""
										/>
									</div>
								</div>

								{utils.Footer1()}
							</div>
						</div>
					</div>
				</center>
			</div>
		</>
	);
};
export default Everyonemobile;
